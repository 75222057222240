import React, { useEffect, useState } from "react";
import api from "../../../axiosConfig";
import {
  Card,
  Avatar,
  Typography,
  Button,
  Row,
  Col,
  Tooltip,
  Dropdown,
  Space,
} from "antd";
import {
  MessageOutlined,
  BlockOutlined,
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

function UsuariosTarjetas() {
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const response = await api.get("https://mogotaxsas.com/api/usuarios");
        setUsuarios(response.data);
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      }
    };

    fetchUsuarios();
  }, []);

  const getMenuItems = (usuarioId) => [
    {
      key: `edit-${usuarioId}`,
      label: <Button type="text">Edit</Button>,
    },
    {
      key: `delete-${usuarioId}`,
      label: <Button type="text">Delete</Button>,
    },
  ];

  return (
    <Row gutter={[16, 16]} justify="center">
      {usuarios.map((usuario) => (
        <Col key={usuario.id} xs={24} sm={12} md={8} lg={6}>
          <Card
            style={{ width: 300 }}
            cover={
              <Avatar
                size={80}
                style={{
                  margin: "20px auto",
                  backgroundColor: "#d9d9d9", // Fondo gris claro
                  fontSize: 32,
                }}
              >
                {usuario.avatar ? (
                  <img src={usuario.avatar} alt={usuario.nombre} />
                ) : (
                  usuario.nombre.charAt(0).toUpperCase() // Mostrar la primera letra del nombre si no hay avatar
                )}
              </Avatar>
            }
            actions={[
              <Button
                key={`message-${usuario.id}`}
                icon={<MessageOutlined />}
                type="link"
              >
                Message
              </Button>,
              <Button
                key={`block-${usuario.id}`}
                icon={<BlockOutlined />}
                type="link"
                danger
              >
                Block
              </Button>,
              <Dropdown
                key={`dropdown-${usuario.id}`}
                menu={{ items: getMenuItems(usuario.id) }}
                trigger={["click"]}
              >
                <EllipsisOutlined />
              </Dropdown>,
            ]}
          >
            <Meta
              title={
                <Tooltip title={usuario.nombre}>
                  <Typography.Text ellipsis>{usuario.nombre}</Typography.Text>
                </Tooltip>
              }
              description={
                <Space direction="vertical" size="small">
                  <Typography.Text>{usuario.cargo}</Typography.Text>
                  <Space>
                    <MailOutlined />
                    <Typography.Text>
                      {usuario.correo_electronico}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <PhoneOutlined />
                    <Typography.Text>{usuario.telefono}</Typography.Text>
                  </Space>
                  <Space>
                    <EnvironmentOutlined />
                    <Typography.Text>{usuario.ubicacion}</Typography.Text>
                  </Space>
                </Space>
              }
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default UsuariosTarjetas;
