import React from "react";
import { Tabs, Card } from "antd";
import { HourglassEmpty, Assignment, CheckCircle } from "@mui/icons-material";
import PropTypes from "prop-types"; // Importar PropTypes

function AutorizacionesTabs({ autorizaciones }) {
  const renderAutorizacionCard = (autorizacion) => (
    <Card
      style={{ marginBottom: 10 }}
      key={autorizacion.id}
      title={`Autorización: ${
        autorizacion.numero_autorizacion || autorizacion.numero_pre_autorizacion
      }`}
    >
      <p>
        <strong>Paciente:</strong> {autorizacion.nombre_paciente}
      </p>
      <p>
        <strong>Número de Documento:</strong>{" "}
        {autorizacion.numero_documento_paciente}
      </p>
      <p>
        <strong>Autorización:</strong>{" "}
        {autorizacion.numero_autorizacion ||
          autorizacion.numero_pre_autorizacion}
      </p>
    </Card>
  );

  const autorizacionesSinGestionar = autorizaciones.filter(
    (autorizacion) => autorizacion.estado_traslado === "Traslados Sin Gestionar"
  );

  const autorizacionesGestionadasIncompletas = autorizaciones.filter(
    (autorizacion) =>
      autorizacion.estado_traslado === "Traslados Gestionados Incompletos"
  );

  const autorizacionesGestionadasCompletadas = autorizaciones.filter(
    (autorizacion) =>
      autorizacion.estado_traslado === "Traslados Gestionados Completados"
  );

  const tabItems = [
    {
      key: "1",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <HourglassEmpty style={{ fontSize: 20, marginRight: 5 }} />
          Sin Gestionar
        </span>
      ),
      children:
        autorizacionesSinGestionar.length > 0 ? (
          autorizacionesSinGestionar.map((autorizacion) =>
            renderAutorizacionCard(autorizacion)
          )
        ) : (
          <p>No hay autorizaciones sin gestionar.</p>
        ),
    },
    {
      key: "2",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <Assignment style={{ fontSize: 20, marginRight: 5 }} />
          Gestionadas Incompletas
        </span>
      ),
      children:
        autorizacionesGestionadasIncompletas.length > 0 ? (
          autorizacionesGestionadasIncompletas.map((autorizacion) =>
            renderAutorizacionCard(autorizacion)
          )
        ) : (
          <p>No hay autorizaciones gestionadas incompletas.</p>
        ),
    },
    {
      key: "3",
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <CheckCircle style={{ fontSize: 20, marginRight: 5 }} />
          Gestionadas Completadas
        </span>
      ),
      children:
        autorizacionesGestionadasCompletadas.length > 0 ? (
          autorizacionesGestionadasCompletadas.map((autorizacion) =>
            renderAutorizacionCard(autorizacion)
          )
        ) : (
          <p>No hay autorizaciones gestionadas completadas.</p>
        ),
    },
  ];

  return <Tabs defaultActiveKey="1" centered items={tabItems} />;
}

// Validación de las props con PropTypes
AutorizacionesTabs.propTypes = {
  autorizaciones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre_paciente: PropTypes.string.isRequired,
      numero_documento_paciente: PropTypes.string.isRequired,
      numero_autorizacion: PropTypes.string,
      numero_pre_autorizacion: PropTypes.string,
      estado_traslado: PropTypes.string.isRequired,
    })
  ).isRequired, // autorizaciones es obligatorio y debe ser un array de objetos con esta estructura
};

export default AutorizacionesTabs;
