// src/components/Drivers/ViewDriverDialog.jsx

import React from "react";
import { Modal, Descriptions, Tag } from "antd";
import PropTypes from "prop-types";

const ViewDriverDialog = ({ driver, open, handleClose }) => {
  if (!driver) return null;

  const getEstadoTag = (estado) => {
    let color = "";
    switch (estado.toLowerCase()) {
      case "activo":
        color = "green";
        break;
      case "inactivo":
        color = "red";
        break;
      default:
        color = "gray";
    }
    return (
      <Tag color={color}>
        {estado.charAt(0).toUpperCase() + estado.slice(1)}
      </Tag>
    );
  };

  return (
    <Modal
      title="Detalles del Conductor"
      open={open}
      onCancel={handleClose}
      footer={null}
      width={600}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Nombre">{driver.nombre}</Descriptions.Item>
        <Descriptions.Item label="Correo Electrónico">
          {driver.correo}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo de Documento">
          {driver.tipoDocumento?.descripcion || "Desconocido"}
        </Descriptions.Item>
        <Descriptions.Item label="Número de Documento">
          {driver.numero_documento}
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          {driver.telefono}
        </Descriptions.Item>
        <Descriptions.Item label="Dirección">
          {driver.direccion}
        </Descriptions.Item>
        <Descriptions.Item label="Ciudad, Departamento">
          {driver.ciudad?.nombre || "Sin Ciudad"},{" "}
          {driver.departamento?.nombre || "Sin Departamento"}
        </Descriptions.Item>
        <Descriptions.Item label="Estado">
          {getEstadoTag(driver.estado)}
        </Descriptions.Item>
        {/* Añade más campos según sea necesario */}
      </Descriptions>
    </Modal>
  );
};

ViewDriverDialog.propTypes = {
  driver: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewDriverDialog;
