import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Space, Divider, Button, Card } from "antd";
import { CarOutlined, CheckCircleOutlined, CarFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

// Componente ConfirmacionAsignacionDialog para confirmar la pre-asignación de un vehículo
function ConfirmacionAsignacionDialog({
  open,
  onCancel, // Cambiamos onClose a onCancel para consistencia
  onConfirm,
  trasladoId,
  selectedMovil,
}) {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      title={
        <Space align="center">
          <CarOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          <Title level={5} style={{ margin: 0 }}>
            Confirmar Pre-Asignación de Móvil
          </Title>
        </Space>
      }
      width={600}
      centered
      destroyOnClose
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          padding: "24px",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {/* Información del Traslado */}
          <div>
            <Text type="secondary">
              Vas a pre-asignar un móvil al traslado con el siguiente{" "}
              <strong>CS-ID:</strong>
            </Text>
            <Title
              level={3}
              style={{
                backgroundColor: "#e6f7ff",
                padding: "12px",
                borderRadius: "8px",
                textAlign: "center",
                color: "#1890ff",
                marginTop: "8px",
              }}
            >
              {trasladoId || "CS-ID no disponible"}
            </Title>
          </div>
          <Divider />

          {/* Información del Vehículo */}
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <CarFilled style={{ fontSize: "40px", color: "#52c41a" }} />
            <Text strong>
              Vehículo: {selectedMovil?.codigo || "No asignado"}
            </Text>
            <Text strong>Placa: {selectedMovil?.placa || "No disponible"}</Text>
          </Space>
          <Divider />

          {/* Confirmación de Pre-Asignación */}
          <Space align="center">
            <CheckCircleOutlined
              style={{ fontSize: "20px", color: "#52c41a" }}
            />
            <Text>
              ¿Estás seguro de que deseas pre-asignar este móvil al traslado?
            </Text>
          </Space>
        </Space>
        <Divider />
        {/* Acciones */}
        <Space
          style={{ display: "flex", justifyContent: "flex-end" }}
          size="middle"
        >
          <Button onClick={onCancel}>Cancelar</Button>
          <Button
            type="primary"
            onClick={onConfirm}
            icon={<CheckCircleOutlined />}
          >
            Pre-Asignar
          </Button>
        </Space>
      </Card>
    </Modal>
  );
}

// **Validación de las props con PropTypes**
ConfirmacionAsignacionDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Booleano que indica si el diálogo está abierto
  onCancel: PropTypes.func.isRequired, // Función para cerrar el diálogo
  onConfirm: PropTypes.func.isRequired, // Función para confirmar la pre-asignación
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // CS-ID del traslado, puede ser string o número
  selectedMovil: PropTypes.shape({
    codigo: PropTypes.string,
    placa: PropTypes.string,
  }),
};

export default ConfirmacionAsignacionDialog;
