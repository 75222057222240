// src/Components/modules/Coordinacion/SnackbarMensaje.js
import { useEffect } from "react";
import PropTypes from "prop-types";
import { notification } from "antd";

/**
 * Componente SnackbarMensaje para mostrar notificaciones.
 * Utiliza el API de Ant Design para mostrar notificaciones de manera imperativa.
 */
function SnackbarMensaje({
  snackbarOpen,
  handleSnackbarClose,
  snackbarMessage,
  severity = "success", // Tipo de mensaje: "success", "error", "warning", "info"
}) {
  useEffect(() => {
    if (snackbarOpen) {
      // Mapeo de severidades a mensajes
      const messageMap = {
        success: "Éxito",
        error: "Error",
        warning: "Advertencia",
        info: "Información",
      };

      const messageText = messageMap[severity] || "Información";

      // Mostrar la notificación
      notification.open({
        message: messageText,
        description: snackbarMessage,
        type: severity, // 'success', 'info', 'warning', 'error'
        onClose: handleSnackbarClose,
        placement: "topRight",
        duration: 1, // Duración en segundos
      });
    }
  }, [snackbarOpen, snackbarMessage, severity, handleSnackbarClose]);

  return null; // Este componente no renderiza nada
}

// **Validación de las props con PropTypes**
SnackbarMensaje.propTypes = {
  snackbarOpen: PropTypes.bool.isRequired, // Indica si la notificación está abierta
  handleSnackbarClose: PropTypes.func.isRequired, // Función para cerrar la notificación
  snackbarMessage: PropTypes.string.isRequired, // Mensaje de la notificación
  severity: PropTypes.oneOf(["success", "error", "warning", "info"]), // Tipo de mensaje
};

export default SnackbarMensaje;
