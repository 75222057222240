// ConfirmDialog.js
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Typography, Space } from "antd";

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  aviso,
  currentPrincipal,
}) => {
  return (
    <Modal
      title="Confirmación"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Confirmar
        </Button>,
      ]}
    >
      <Typography.Text>{aviso}</Typography.Text>
      {currentPrincipal && (
        <Space direction="vertical" style={{ marginTop: 16 }}>
          <Typography.Title level={5}>
            Conductor Principal Actual
          </Typography.Title>
          <Typography.Text>
            Nombre: {currentPrincipal.Conductor?.nombre}
          </Typography.Text>
          <Typography.Text>
            Documento: {currentPrincipal.Conductor?.numero_documento}
          </Typography.Text>
        </Space>
      )}
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  aviso: PropTypes.string,
  currentPrincipal: PropTypes.shape({
    Conductor: PropTypes.shape({
      nombre: PropTypes.string,
      numero_documento: PropTypes.string,
    }),
  }),
};

export default ConfirmDialog;
