import React, { useState } from "react";
import PropTypes from "prop-types"; // Importar prop-types
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import RouteIcon from "@mui/icons-material/Route";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CerrarTrasladoDialog from "../Dialog/CerrarTrasladoDialog";
import ValidarTrasladoDialog from "../Dialog/ValidarTrasladoDialog";
import ObservacionesDialog from "../Dialog/ObservacionesDialog";
import HistorialTrasladoDialog from "../Dialog/HistorialTrasladoDialog";
import VerTrasladoDialog from "../Dialog/VerTrasladoDialog"; // Importar el nuevo diálogo
import MapDialog from "./MapDialog";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext"; // Importar el contexto de usuario
import ConfirmarTrasladoDialog from "../Dialog/ConfirmarTrasladoDialog"; // Importar el nuevo diálogo

function MenuContextualTraslado({
  contextMenu,
  handleCloseContextMenu,
  handleOptionClick,
  trasladoId,
  onEstadoCambiado,
  origin,
  destination,
  traslado,
}) {
  const { user } = useUser(useUser); // Obtener el usuario del contexto
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [cerrarDialogOpen, setCerrarDialogOpen] = useState(false);
  const [validarDialogOpen, setValidarDialogOpen] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [observacionesDialogOpen, setObservacionesDialogOpen] = useState(false);
  const [historialDialogOpen, setHistorialDialogOpen] = useState(false);
  const [confirmarDialogOpen, setConfirmarDialogOpen] = useState(false);
  const [verDialogOpen, setVerDialogOpen] = useState(false);

  const handleOpenSubMenu = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setSubMenuAnchorEl(null);
    handleCloseContextMenu();
  };

  const handleSubMenuOptionClick = (option) => {
    handleOptionClick(option);
    handleCloseSubMenu();
  };

  const handleOpenCerrarDialog = () => {
    setCerrarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseCerrarDialog = () => {
    setCerrarDialogOpen(false);
  };

  const handleOpenObservaciones = () => {
    setObservacionesDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseObservacionesDialog = () => {
    setObservacionesDialogOpen(false);
  };

  const handleOpenHistorial = () => {
    setHistorialDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseHistorialDialog = () => {
    setHistorialDialogOpen(false);
  };

  const handleOpenConfirmarDialog = () => {
    setConfirmarDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseConfirmarDialog = () => {
    setConfirmarDialogOpen(false);
  };

  const handleOpenVerTrasladoDialog = () => {
    console.log("Ver traslado dialog open triggered");
    setVerDialogOpen(true);
    handleCloseContextMenu();
  };

  const handleCloseVerTrasladoDialog = () => {
    setVerDialogOpen(false);
  };

  // Confirmar traslado y actualizar el estado
  const handleConfirmarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      await api.put(
        `https://mogotaxsas.com/api/traslados/confirmar/${trasladoId}`,
        {
          confirmacion: "Confirmado",
          usuario_id: user.id, // Enviar el usuario_id desde el contexto
        }
      );

      console.log("Traslado confirmado");

      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Confirmado", "confirmacion");
      }
    } catch (error) {
      console.error("Error al confirmar el traslado:", error);
    }

    setConfirmarDialogOpen(false);
  };

  // Manejar el cierre del traslado
  const handleCerrarTraslado = async (estado) => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      // Enviar solicitud para cerrar el traslado con estado y usuario_id
      await api.put(
        `https://mogotaxsas.com/api/traslados/cerrar/${trasladoId}`,
        {
          estado,
          usuario_id: user.id, // Enviar el usuario_id desde el contexto
        }
      );

      console.log("Traslado cerrado con estado:", estado);

      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, estado, "status");
      }
    } catch (error) {
      console.error("Error al cerrar el traslado:", error);
    }

    setCerrarDialogOpen(false);
  };

  // Abrir el diálogo de validación del traslado
  const handleOpenValidarDialog = () => {
    setValidarDialogOpen(true);
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de validación del traslado
  const handleCloseValidarDialog = () => {
    setValidarDialogOpen(false);
  };

  // Validar traslado y actualizar estado sin refrescar el navegador
  const handleValidarTraslado = async () => {
    if (!trasladoId || !user) {
      console.error("Error: trasladoId o usuario_id no definidos");
      return;
    }

    try {
      // Hacer la solicitud al backend para validar el traslado
      const response = await api.put(
        `https://mogotaxsas.com/api/traslados/${trasladoId}`,
        {
          seguimiento: "Validado",
          usuario_id: user.id, // Enviar el usuario_id desde el contexto
        }
      );

      console.log("Traslado validado correctamente", response.data);

      // Aquí llamamos al callback para actualizar el estado del traslado en el componente padre
      if (onEstadoCambiado) {
        onEstadoCambiado(trasladoId, "Validado", "seguimiento");
      }

      setValidarDialogOpen(false); // Cerrar el diálogo de validación
    } catch (error) {
      console.error("Error al validar el traslado:", error);
    }
  };

  // Abrir el diálogo de mapa para ver la ruta
  const handleVerRuta = () => {
    if (
      origin &&
      destination &&
      origin.lat &&
      origin.lng &&
      destination.lat &&
      destination.lng
    ) {
      setMapVisible(true);
    } else {
      console.error("Origen o destino no definidos o coordenadas inválidas.");
    }
    handleCloseContextMenu();
  };

  // Cerrar el diálogo de mapa
  const handleCloseMap = () => {
    setMapVisible(false);
  };

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleOpenVerTrasladoDialog}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText>Ver Traslado</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenSubMenu}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText>Copiar teléfonos</ListItemText>
          <ArrowRightIcon />
        </MenuItem>
        <MenuItem onClick={handleOpenConfirmarDialog}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          Confirmar traslado
        </MenuItem>
        <MenuItem onClick={handleVerRuta}>
          <ListItemIcon>
            <RouteIcon />
          </ListItemIcon>
          Ver ruta
        </MenuItem>
        <MenuItem onClick={handleOpenHistorial}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          Ver Historial
        </MenuItem>
        <MenuItem onClick={handleOpenObservaciones}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Observaciones de seguimiento
        </MenuItem>
        <MenuItem onClick={handleOpenValidarDialog}>
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          Validar traslado
        </MenuItem>
        <MenuItem onClick={handleOpenCerrarDialog}>
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          Cerrar traslado
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleCloseSubMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={() => handleSubMenuOptionClick("copyPhone")}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del paciente
        </MenuItem>
        <MenuItem
          onClick={() => handleSubMenuOptionClick("copyResponsiblePhone")}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          Copiar teléfono del responsable
        </MenuItem>
      </Menu>
      {/* Diálogo para cerrar traslado */}
      <CerrarTrasladoDialog
        open={cerrarDialogOpen}
        onClose={handleCloseCerrarDialog}
        onSubmit={handleCerrarTraslado}
      />
      {/* Diálogo para validar traslado */}
      <ValidarTrasladoDialog
        open={validarDialogOpen}
        onClose={handleCloseValidarDialog}
        onSubmit={handleValidarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo para Observaciones */}
      <ObservacionesDialog
        open={observacionesDialogOpen}
        onClose={handleCloseObservacionesDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* Diálogo de Historial */}
      <HistorialTrasladoDialog
        open={historialDialogOpen}
        onClose={handleCloseHistorialDialog}
        trasladoId={trasladoId.toString()} // Convertir a string
      />
      {/* Diálogo de Confirmación de Traslado */}
      <ConfirmarTrasladoDialog
        open={confirmarDialogOpen}
        onClose={handleCloseConfirmarDialog}
        onConfirm={handleConfirmarTraslado}
        trasladoId={trasladoId.toString()} // Convertir a string
      />

      {/* Diálogo para ver los detalles del traslado */}
      <VerTrasladoDialog
        open={verDialogOpen}
        onClose={handleCloseVerTrasladoDialog}
        traslado={traslado} // Usa 'traslado' en lugar de 'selectedTraslado'
      />

      {/* Diálogo para ver ruta */}
      <MapDialog
        open={mapVisible}
        onClose={handleCloseMap}
        origin={origin}
        destination={destination}
      />
    </>
  );
}

// Validación de las props con PropTypes
MenuContextualTraslado.propTypes = {
  contextMenu: PropTypes.shape({
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }), // Validar la posición del menú contextual
  handleCloseContextMenu: PropTypes.func.isRequired, // La función para cerrar el menú es obligatoria
  handleOptionClick: PropTypes.func.isRequired, // La función para manejar el clic en una opción es obligatoria
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Puede ser string o number
  onEstadoCambiado: PropTypes.func, // Opcional: la función para manejar el cambio de estado del traslado
  origin: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }), // Objeto opcional con latitud y longitud para el origen
  destination: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }), // Objeto opcional con latitud y longitud para el destino
  traslado: PropTypes.object, // El objeto traslado, opcional
};

export default MenuContextualTraslado;
