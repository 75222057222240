// src/Components/modules/Auth/LoginForm.js
import React, { useState } from "react";
import api from "../../../axiosConfig"; // Importar la instancia personalizada de axios
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../UserContext";
import "./LoginForm.css";
import logo from "../../../Assets/img/logo.webp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Lock from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { message } from "antd";

/**
 * Componente para el formulario de inicio de sesión.
 * Permite a los usuarios autenticarse con su correo electrónico/número de documento y contraseña.
 */
function LoginForm() {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { loginUser } = useUser();

  /**
   * Maneja el envío del formulario de inicio de sesión.
   * @param {object} e - Evento de envío del formulario.
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      const response = await api.post("/auth/login", {
        identifier,
        password,
      });

      const {
        accessToken,
        refreshToken,
        user,
        message: serverMessage,
      } = response.data;

      // Almacenar los tokens y usuario en UserContext
      loginUser(user, accessToken, refreshToken);

      // Navegar al dashboard o módulo principal
      navigate("/modules"); // Redirigir al usuario al dashboard o módulo principal
      message.success(serverMessage || "Inicio de sesión exitoso.");
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      // Mostrar el mensaje de error específico del servidor si existe
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        message.error(error.response.data.message);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        setErrorMessage("No se recibió respuesta del servidor.");
        message.error("No se recibió respuesta del servidor.");
      } else {
        // Algo ocurrió al configurar la solicitud
        setErrorMessage("Error al iniciar sesión.");
        message.error("Error al iniciar sesión.");
      }
    }
  };

  /**
   * Alterna la visibilidad de la contraseña.
   */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" className="login-logo" />
      <div className="login-form-container">
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-container">
            <AccountCircle style={{ color: "#007bff" }} />
            <input
              type="text"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              placeholder="Email o Número de Documento"
              className="login-input"
              required
            />
          </div>
          <div className="input-container password-container">
            <Lock style={{ color: "#007bff" }} />
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              className="login-input password-input"
              required
            />
            {showPassword ? (
              <VisibilityOff
                style={{ color: "#007bff" }}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <Visibility
                style={{ color: "#007bff" }}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button type="submit" className="login-button">
            Iniciar
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
