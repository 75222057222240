// src/Components/modules/PatientList/EditUserForm.js

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types"; // Importar PropTypes
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Typography,
} from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  IdcardOutlined,
  ApartmentOutlined,
  TeamOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";

const { Option } = Select;
const { Title } = Typography;

/**
 * Componente para editar un usuario existente.
 * Muestra un formulario modal con los detalles del usuario para editar.
 */
const EditUserForm = ({ user, open, handleClose, fetchUsuarios }) => {
  const [form] = Form.useForm();
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTiposDocumento = useCallback(async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/tipos-documento"
      );
      setTiposDocumento(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de documento:", error);
      message.error("Error al cargar los tipos de documento.");
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchTiposDocumento();
      if (user) {
        form.setFieldsValue({
          nombre: user.nombre,
          correo_electronico: user.correo_electronico,
          tipo_documento_id: user.tipo_documento_id || "",
          numero_documento: user.numero_documento,
          telefono: user.telefono || "",
          cargo: user.cargo || "",
          area: user.area || "",
          estado: user.estado,
          es_administrador: user.es_administrador,
        });
      }
    }
  }, [open, user, fetchTiposDocumento, form]);

  /**
   * Función para capitalizar la primera letra de cada palabra.
   * @param {string} value - Texto a capitalizar.
   * @returns {string} - Texto capitalizado.
   */
  // Función para capitalizar la primera letra de cada palabra y convertir el resto a minúsculas
  const capitalizeWords = (value) => {
    return value
      .split(" ")
      .map((word) => {
        // Asegurar que la palabra no esté vacía
        if (word.length === 0) return "";
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  /**
   * Maneja el cambio del campo de nombre para capitalizarlo automáticamente.
   * @param {object} e - Evento del input.
   */
  const handleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = capitalizeWords(value);
    form.setFieldsValue({ nombre: capitalizedValue });
  };

  /**
   * Maneja el envío del formulario para actualizar el usuario.
   * @param {object} values - Valores del formulario.
   */
  const handleSave = async (values) => {
    setLoading(true);
    try {
      await api.put(`https://mogotaxsas.com/api/usuarios/${user.id}`, values);
      fetchUsuarios();
      handleClose();
      message.success("Usuario actualizado correctamente.");
      form.resetFields();
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
      // Mostrar el mensaje de error específico del servidor si existe
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Ocurrió un error al actualizar el usuario.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose();
        form.resetFields(); // Resetear el formulario al cerrar
      }}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "white",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SafetyOutlined style={{ marginRight: "8px" }} />
          Editar Usuario
        </div>
      }
      footer={null}
      width={1000} // Ajustar el ancho del modal según necesidad
      destroyOnClose={true} // Destruye el modal al cerrarlo para limpiar el estado
    >
      <div
        style={{
          padding: "16px",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
          initialValues={{
            estado: true,
            es_administrador: false,
          }}
        >
          <Row gutter={24}>
            {/* Columna Izquierda */}
            <Col xs={24} sm={24} md={12}>
              {/* Información Básica */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MailOutlined style={{ marginRight: "8px" }} />
                  Información Básica
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Nombre"
                      name="nombre"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el nombre",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El nombre solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        onChange={handleNameChange}
                        placeholder="Ingrese el nombre"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Correo Electrónico"
                      name="correo_electronico"
                      rules={[
                        {
                          type: "email",
                          message: "Ingrese un correo electrónico válido",
                        },
                        {
                          required: true,
                          message: "Por favor ingrese el correo",
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="Ingrese el correo electrónico"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Información Laboral */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TeamOutlined style={{ marginRight: "8px" }} />
                  Información Laboral
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Cargo"
                      name="cargo"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el cargo",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El cargo solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        prefix={<ApartmentOutlined />}
                        placeholder="Ingrese el cargo"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Área"
                      name="area"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el área",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El área solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        prefix={<ApartmentOutlined />}
                        placeholder="Ingrese el área"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Estado y Rol */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SafetyOutlined style={{ marginRight: "8px" }} />
                  Estado y Rol
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Estado"
                      name="estado"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione el estado",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione el estado">
                        <Option value={true}>Activo</Option>
                        <Option value={false}>Suspendido</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Es Administrador"
                      name="es_administrador"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione el rol",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione el rol">
                        <Option value={true}>Sí</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Columna Derecha */}
            <Col xs={24} sm={24} md={12}>
              {/* Información de Seguridad */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SafetyOutlined style={{ marginRight: "8px" }} />
                  Información de Seguridad
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contraseña"
                      name="contraseña"
                      rules={[
                        {
                          required: false, // Opcional en edición
                          message: "Por favor ingrese la contraseña",
                        },
                        {
                          min: 6,
                          message:
                            "La contraseña debe tener al menos 6 caracteres",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<SafetyOutlined />}
                        placeholder="Ingrese la contraseña"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Teléfono"
                      name="telefono"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el teléfono",
                        },
                        {
                          len: 10,
                          message: "El teléfono debe tener 10 dígitos",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "El teléfono solo debe contener números",
                        },
                      ]}
                    >
                      <Input
                        prefix={<PhoneOutlined />}
                        maxLength={10}
                        placeholder="Ingrese el teléfono"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Información Adicional */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IdcardOutlined style={{ marginRight: "8px" }} />
                  Información Adicional
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Tipo de Documento"
                      name="tipo_documento_id"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione un tipo de documento",
                        },
                      ]}
                    >
                      {tiposDocumento.length > 0 ? (
                        <Select placeholder="Seleccione un tipo de documento">
                          {tiposDocumento.map((tipo) => (
                            <Option key={tipo.id} value={tipo.id}>
                              {tipo.descripcion}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          disabled
                          placeholder="Cargando tipos de documento..."
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Número de Documento"
                      name="numero_documento"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el número de documento",
                        },
                        {
                          pattern: /^\d+$/,
                          message:
                            "El número de documento solo debe contener números",
                        },
                      ]}
                    >
                      <Input
                        prefix={<IdcardOutlined />}
                        placeholder="Ingrese el número de documento"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Botones de Acción */}
          <Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  onClick={() => {
                    handleClose();
                    form.resetFields(); // Resetear el formulario al cancelar
                  }}
                  style={{
                    backgroundColor: "#d32f2f",
                    color: "white",
                    borderColor: "#d32f2f",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#b71c1c")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#d32f2f")
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    borderColor: "#0a2e5c",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    marginRight: "8px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#004080")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0a2e5c")
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

// Validación de props con PropTypes
EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchUsuarios: PropTypes.func.isRequired,
};

export default EditUserForm;
