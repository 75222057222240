import React, { useState, useRef } from "react";
import {
  Button,
  Snackbar,
  Alert,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useFileUpload } from "./useFileUpload";
import LoadingIndicator from "./LoadingIndicator";
import MessageBox from "./MessageBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./BulkUpload.css"; // Archivo CSS adicional para estilos

const BulkUpload = ({ fetchAuthorizations }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const {
    loading,
    verificationStatus,
    setVerificationStatus,
    verifyFile,
    uploadFile,
    uploadStatus,
    resetUploadStatus,
  } = useFileUpload();

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      verifyFile(uploadedFile, setSnackbar);
    }
  };

  const handleFileSubmit = () => {
    if (file) {
      uploadFile(file, setSnackbar, fetchAuthorizations);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const setSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const resetForm = () => {
    setFile(null);
    setVerificationStatus({
      errors: [],
      correctEntries: 0,
      errorEntries: 0,
    });
    resetUploadStatus();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Card sx={{ maxWidth: 1200, margin: "auto", mt: 5, p: 3, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Carga Masiva de Autorizaciones
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h6" component="div" gutterBottom>
                Instrucciones
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText primary="1. Seleccione el archivo en formato .xlsx o .xls utilizando el botón 'Seleccionar Archivo'." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="2. El sistema verificará el archivo para asegurarse de que los datos sean correctos." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="3. Si hay errores, se mostrarán los detalles de los mismos. Corrija los errores en el archivo y vuelva a cargarlo." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="4. Si el archivo es verificado correctamente, haga clic en 'Cargar' para subir las autorizaciones." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="5. Puede reiniciar el proceso en cualquier momento haciendo clic en 'Reiniciar'." />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: "none" }}
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  fullWidth
                  color="primary"
                  startIcon={<UploadFileIcon />}
                >
                  Seleccionar Archivo
                </Button>
              </label>
              {loading && (
                <Box mt={2}>
                  <LoadingIndicator />
                </Box>
              )}
              {verificationStatus.errors.length > 0 && (
                <Box mt={2}>
                  <MessageBox
                    message="Errores de verificación:"
                    severity="error"
                  />
                  {verificationStatus.errors.map((error, index) => (
                    <Typography key={index} color="error">
                      {`Fila: ${error.row} - Error: ${error.error}`}
                    </Typography>
                  ))}
                  <Typography variant="body1" color="error">
                    {`Total de errores: ${verificationStatus.errorEntries}`}
                  </Typography>
                </Box>
              )}
              {verificationStatus.correctEntries > 0 && (
                <Box mt={2}>
                  <MessageBox
                    message="Verificación exitosa"
                    severity="primary"
                  />
                  <Typography variant="body1" color="primary">
                    {`Total de autorizaciones correctas: ${verificationStatus.correctEntries}`}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {`Total de autorizaciones con errores: ${verificationStatus.errorEntries}`}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {`Total de autorizaciones a cargar: ${verificationStatus.correctEntries}`}
                  </Typography>
                </Box>
              )}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFileSubmit}
                    disabled={
                      verificationStatus.errors.length > 0 || !file || loading
                    }
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                  >
                    Cargar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={resetForm}
                    disabled={loading}
                    fullWidth
                    startIcon={<RefreshIcon />}
                  >
                    Reiniciar
                  </Button>
                </Grid>
              </Grid>
              {uploadStatus.totalUploaded > 0 && (
                <Box mt={2}>
                  <MessageBox
                    message={`Total de autorizaciones cargadas: ${uploadStatus.totalUploaded}`}
                    severity="primary"
                  />
                  <Typography variant="body1" color="primary">
                    {`Total de autorizaciones con errores: ${uploadStatus.totalErrors}`}
                  </Typography>
                  {uploadStatus.errorDetails.length > 0 && (
                    <Box mt={2}>
                      <MessageBox
                        message="Errores durante la carga:"
                        severity="error"
                      />
                      {uploadStatus.errorDetails.map((error, index) => (
                        <Typography key={index} color="error">
                          {`Fila: ${error.row} - Error: ${error.error}`}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
              {loading && <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

export default BulkUpload;
