import React, { useEffect, useState } from "react";
import api from "../../axiosConfig";
import moment from "moment-timezone";

const TimeCheck = () => {
  const [times, setTimes] = useState({
    serverTimeUTC: "",
    serverTimeLocal: "",
    dbTimeUTC: "",
    dbTimeLocal: "",
    clientTimeUTC: "",
    clientTimeLocal: "",
  });

  useEffect(() => {
    const fetchTimes = async () => {
      try {
        const response = await api.get("https://mogotaxsas.com/api/time/time");

        // Obtener hora en UTC y también convertirla a la zona horaria local
        setTimes({
          serverTimeUTC: moment(response.data.serverTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss [UTC]"),
          serverTimeLocal: moment(response.data.serverTime)
            .tz("America/Bogota")
            .format("YYYY-MM-DD HH:mm:ss Z"),
          dbTimeUTC: moment(response.data.dbTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss [UTC]"),
          dbTimeLocal: moment(response.data.dbTime)
            .tz("America/Bogota")
            .format("YYYY-MM-DD HH:mm:ss Z"),
          clientTimeUTC: moment().utc().format("YYYY-MM-DD HH:mm:ss [UTC]"),
          clientTimeLocal: moment()
            .tz("America/Bogota")
            .format("YYYY-MM-DD HH:mm:ss Z"),
        });
      } catch (error) {
        console.error("Error fetching times:", error);
      }
    };

    fetchTimes();
  }, []);

  return (
    <div>
      <h1>Time Check</h1>
      <p>
        <strong>Server Time (UTC):</strong> {times.serverTimeUTC}
      </p>
      <p>
        <strong>Server Time (Local - Bogotá):</strong> {times.serverTimeLocal}
      </p>
      <p>
        <strong>Database Time (UTC):</strong> {times.dbTimeUTC}
      </p>
      <p>
        <strong>Database Time (Local - Bogotá):</strong> {times.dbTimeLocal}
      </p>
      <p>
        <strong>Client Time (UTC):</strong> {times.clientTimeUTC}
      </p>
      <p>
        <strong>Client Time (Local - Bogotá):</strong> {times.clientTimeLocal}
      </p>
    </div>
  );
};

export default TimeCheck;
