// src/components/Clients/ViewClientDialog.js

import React from "react";
import { Modal, Typography, Descriptions, Button, Row, Col } from "antd";
import dayjs from "../../../utils/dayjs"; // Ajusta la ruta según tu estructura de carpetas
import PropTypes from "prop-types";
import {
  UserOutlined,
  FileTextOutlined,
  HomeOutlined,
  TeamOutlined,
  CommentOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const headerStyle = {
  background: "linear-gradient(90deg, #0a2e5c, #004d99)",
  padding: "12px",
  color: "white",
  borderRadius: "8px",
};

const sectionTitleStyle = {
  background: "#0a2e5c",
  color: "white",
  padding: "8px",
  borderRadius: "4px",
  marginTop: "16px",
};

const ViewClientDialog = ({ client, open, handleClose }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = dayjs(dateString).tz("America/Bogota");

    if (!date.isValid()) {
      console.error(`Fecha inválida: ${dateString}`);
      return "Fecha inválida";
    }

    return date.format("LL");
  };

  return (
    <Modal
      title={
        <div style={headerStyle}>
          <Title level={3} style={{ color: "white", margin: 0 }}>
            <UserOutlined style={{ marginRight: "8px" }} />
            Ver Cliente
          </Title>
        </div>
      }
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="close" type="primary" onClick={handleClose}>
          Cerrar
        </Button>,
      ]}
      width={900}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Title level={4} style={sectionTitleStyle}>
            <FileTextOutlined style={{ marginRight: "8px" }} />
            Información General
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Nombre del Cliente">
              {client.nombre_cliente}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre del Contacto">
              {client.nombre_contacto}
            </Descriptions.Item>
            <Descriptions.Item label="Teléfono">
              {client.telefono}
            </Descriptions.Item>
            <Descriptions.Item label="Correo Electrónico">
              {client.correo_electronico}
            </Descriptions.Item>
          </Descriptions>

          <Title level={4} style={sectionTitleStyle}>
            <CommentOutlined style={{ marginRight: "8px" }} />
            Fechas Importantes
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Fecha Última Revisión">
              {formatDate(client.fecha_ultima_revision)}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha Próxima Revisión">
              {formatDate(client.fecha_proxima_revision)}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha Contrato Firmado">
              {formatDate(client.fecha_contrato_firmado)}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha Vencimiento Contrato">
              {formatDate(client.fecha_vencimiento_contrato)}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={12}>
          <Title level={4} style={sectionTitleStyle}>
            <TeamOutlined style={{ marginRight: "8px" }} />
            Información Adicional
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Nombre del Patrocinador">
              {client.nombre_patrocinador || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Descripción del Producto/Servicio">
              {client.descripcion_producto_servicio || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Notas">
              {client.notas || "N/A"}
            </Descriptions.Item>
          </Descriptions>

          <Title level={4} style={sectionTitleStyle}>
            <HomeOutlined style={{ marginRight: "8px" }} />
            Dirección
          </Title>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Dirección">
              {client.direccion}
            </Descriptions.Item>
            <Descriptions.Item label="País">{client.pais}</Descriptions.Item>
            <Descriptions.Item label="Zona">
              {client.zona?.nombre || "Sin Zona"}
            </Descriptions.Item>
            <Descriptions.Item label="Departamento">
              {client.departamento?.nombre || "Sin Departamento"}
            </Descriptions.Item>
            <Descriptions.Item label="Ciudad/Municipio">
              {client.ciudad?.nombre || "Sin Ciudad"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  );
};

ViewClientDialog.propTypes = {
  client: PropTypes.shape({
    nombre_cliente: PropTypes.string.isRequired,
    nombre_contacto: PropTypes.string.isRequired,
    telefono: PropTypes.string.isRequired,
    correo_electronico: PropTypes.string.isRequired,
    fecha_ultima_revision: PropTypes.string,
    fecha_proxima_revision: PropTypes.string,
    fecha_contrato_firmado: PropTypes.string,
    fecha_vencimiento_contrato: PropTypes.string,
    nombre_patrocinador: PropTypes.string,
    descripcion_producto_servicio: PropTypes.string,
    notas: PropTypes.string,
    direccion: PropTypes.string.isRequired,
    pais: PropTypes.string.isRequired,
    zona: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
    }),
    departamento: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
    }),
    ciudad: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
    }),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewClientDialog;
