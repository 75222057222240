// src/components/Clients/Form/ImportantDates.js

import React from "react";
import { Form, DatePicker, Typography, Row, Col } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ImportantDates = () => (
  <div style={{ marginBottom: "24px" }}>
    <Title
      level={4}
      style={{
        marginBottom: 16,
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CalendarOutlined style={{ marginRight: "8px" }} />
      Fechas Importantes
    </Title>

    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label="Fecha Última Revisión" name="fecha_ultima_revision">
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Fecha Próxima Revisión" name="fecha_proxima_revision">
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Fecha Contrato Firmado" name="fecha_contrato_firmado">
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Fecha Vencimiento Contrato"
          name="fecha_vencimiento_contrato"
        >
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Fecha Creación" name="fecha_creacion">
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Fecha Revisión Contrato"
          name="fecha_revision_contrato"
        >
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Fecha Aprobación" name="fecha_aprobacion">
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default ImportantDates;
