// DeletePatientDialog.js
import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes para la validación de props
import { Modal, Button, Typography } from "antd";
import api from "../../../axiosConfig";

const { Text } = Typography;

const DeletePatientDialog = ({
  open,
  onClose,
  patientId,
  patientName,
  fetchPatients,
}) => {
  const handleDelete = async () => {
    try {
      await api.delete(`https://mogotaxsas.com/api/pacientes/${patientId}`);
      fetchPatients();
      onClose();
    } catch (error) {
      console.error("Error al eliminar el paciente:", error);
      // Opcional: Mostrar un mensaje de error al usuario
    }
  };

  return (
    <Modal
      title="Eliminar Paciente"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="delete" type="primary" danger onClick={handleDelete}>
          Eliminar
        </Button>,
      ]}
    >
      <Text>
        ¿Estás seguro de que quieres eliminar al paciente{" "}
        <strong>{patientName}</strong>?
      </Text>
    </Modal>
  );
};

// Agregar validación de props
DeletePatientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  patientName: PropTypes.string.isRequired,
  fetchPatients: PropTypes.func.isRequired,
};

export default DeletePatientDialog;
