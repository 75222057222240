import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Descriptions,
  Button,
  Row,
  Col,
  Divider,
} from "antd";
import PropTypes from "prop-types";
import {
  UserOutlined,
  FileTextOutlined,
  MedicineBoxOutlined,
  HomeOutlined,
  CommentOutlined,
  ContactsOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const ViewPatientDialog = ({ patient, open, handleClose }) => {
  const [edad, setEdad] = useState(""); // Estado para la edad calculada

  useEffect(() => {
    if (patient?.fecha_nacimiento) {
      setEdad(calculateAge(patient.fecha_nacimiento));
    }
  }, [patient]);

  const calculateAge = (fechaNacimiento) => {
    const birthDate = new Date(fechaNacimiento);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const googleMapsLink = (coordenadas) => {
    if (coordenadas?.coordinates) {
      const [longitude, latitude] = coordenadas.coordinates;
      return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    }
    return "#";
  };

  const googleStreetViewLink = (coordenadas) => {
    if (coordenadas?.coordinates) {
      const [longitude, latitude] = coordenadas.coordinates;
      return `https://www.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}`;
    }
    return "#";
  };

  // Estilos personalizados para títulos y descripciones
  const titleStyle = {
    background: "#0a2e5c",
    color: "white",
    padding: "6px",
    borderRadius: "4px",
    fontSize: "14px",
    marginBottom: "6px",
    display: "flex",
    alignItems: "center",
  };

  const descriptionItemStyle = {
    marginBottom: "2px",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "12px",
  };

  const contentStyle = {
    fontSize: "12px",
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width={1200} // Aumentado para acomodar tres columnas
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "10px",
            color: "white",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={5}
            style={{ color: "white", margin: 0, fontSize: "16px" }}
          >
            <UserOutlined style={{ marginRight: "8px" }} />
            Ver Paciente
          </Title>
        </div>
      }
      footer={[
        <Button key="close" type="primary" onClick={handleClose}>
          Cerrar
        </Button>,
      ]}
      style={{ maxHeight: "80vh", overflow: "auto" }} // Limitar la altura máxima del modal y permitir scroll si es necesario
      destroyOnClose
    >
      <div style={{ padding: "12px" }}>
        <Row gutter={[8, 8]}>
          {/* Columna 1: Información Personal y Datos de Contacto */}
          <Col span={8}>
            {/* Información Personal */}
            <Title level={5} style={titleStyle}>
              <FileTextOutlined style={{ marginRight: "6px" }} />
              Información Personal
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
              style={{ marginBottom: "12px" }}
            >
              <Descriptions.Item label="Nombre" style={descriptionItemStyle}>
                {patient.nombre}
              </Descriptions.Item>
              <Descriptions.Item
                label="Tipo de Documento"
                style={descriptionItemStyle}
              >
                {patient.TipoDocumento?.descripcion || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item
                label="Número de Documento"
                style={descriptionItemStyle}
              >
                {patient.numero_documento}
              </Descriptions.Item>
              <Descriptions.Item label="Género" style={descriptionItemStyle}>
                {patient.genero}
              </Descriptions.Item>
              <Descriptions.Item
                label="Fecha de Nacimiento"
                style={descriptionItemStyle}
              >
                {patient.fecha_nacimiento}
              </Descriptions.Item>
              <Descriptions.Item label="Edad" style={descriptionItemStyle}>
                {edad} años
              </Descriptions.Item>
            </Descriptions>

            <Divider />

            {/* Datos de Contacto */}
            <Title level={5} style={titleStyle}>
              <ContactsOutlined style={{ marginRight: "6px" }} />
              Datos de Contacto
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
              style={{ marginBottom: "12px" }}
            >
              <Descriptions.Item label="Teléfono" style={descriptionItemStyle}>
                {patient.telefono}
              </Descriptions.Item>
              <Descriptions.Item
                label="Correo Electrónico"
                style={descriptionItemStyle}
              >
                {patient.correo}
              </Descriptions.Item>
            </Descriptions>
          </Col>

          {/* Columna 2: Domicilio e Información Médica */}
          <Col span={8}>
            {/* Domicilio */}
            <Title level={5} style={titleStyle}>
              <HomeOutlined style={{ marginRight: "6px" }} />
              Domicilio
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
              style={{ marginBottom: "12px" }}
            >
              <Descriptions.Item label="Zona" style={descriptionItemStyle}>
                {patient.Zona?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item
                label="Departamento"
                style={descriptionItemStyle}
              >
                {patient.Ciudad?.Departamento?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item
                label="Ciudad/Municipio"
                style={descriptionItemStyle}
              >
                {patient.Ciudad?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item label="Localidad" style={descriptionItemStyle}>
                {patient.localidad}
              </Descriptions.Item>
              <Descriptions.Item label="Barrio" style={descriptionItemStyle}>
                {patient.barrio}
              </Descriptions.Item>
              <Descriptions.Item label="Dirección" style={descriptionItemStyle}>
                {patient.direccion}
              </Descriptions.Item>
              <Descriptions.Item
                label="Indicaciones"
                style={descriptionItemStyle}
              >
                {patient.indicaciones || "N/A"}
              </Descriptions.Item>
            </Descriptions>
            {patient.coordenadas?.coordinates && (
              <div style={{ marginBottom: "12px" }}>
                <Button
                  type="primary"
                  href={googleMapsLink(patient.coordenadas)}
                  target="_blank"
                  style={{ marginRight: "8px", marginBottom: "4px" }}
                >
                  Ver en Google Maps
                </Button>
                <Button
                  type="default"
                  href={googleStreetViewLink(patient.coordenadas)}
                  target="_blank"
                >
                  Ver en Street View
                </Button>
              </div>
            )}

            <Divider />

            {/* Información Médica */}
            <Title level={5} style={titleStyle}>
              <MedicineBoxOutlined style={{ marginRight: "6px" }} />
              Información Médica
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
            >
              <Descriptions.Item label="EPS" style={descriptionItemStyle}>
                {patient.Cliente?.nombre_cliente || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item label="Régimen" style={descriptionItemStyle}>
                {patient.Regimen?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item
                label="Afiliación"
                style={descriptionItemStyle}
              >
                {patient.Afiliacion?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item
                label="Diagnóstico"
                style={descriptionItemStyle}
              >
                {patient.Diagnostico?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo" style={descriptionItemStyle}>
                {patient.Tipo?.nombre || "Desconocido"}
              </Descriptions.Item>
              <Descriptions.Item label="Estado" style={descriptionItemStyle}>
                {patient.Estado?.descripcion || "Desconocido"}
              </Descriptions.Item>
            </Descriptions>
          </Col>

          {/* Columna 3: Responsable y Observaciones */}
          <Col span={8}>
            {/* Responsable */}
            <Title level={5} style={titleStyle}>
              <TeamOutlined style={{ marginRight: "6px" }} />
              Responsable
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
              style={{ marginBottom: "12px" }}
            >
              <Descriptions.Item
                label="Nombre del Responsable"
                style={descriptionItemStyle}
              >
                {patient.nombre_responsable}
              </Descriptions.Item>
              <Descriptions.Item
                label="Parentesco"
                style={descriptionItemStyle}
              >
                {patient.parentesco_responsable}
              </Descriptions.Item>
              <Descriptions.Item
                label="Teléfono del Responsable"
                style={descriptionItemStyle}
              >
                {patient.telefono_responsable}
              </Descriptions.Item>
            </Descriptions>

            <Divider />

            {/* Observaciones */}
            <Title level={5} style={titleStyle}>
              <CommentOutlined style={{ marginRight: "6px" }} />
              Observaciones
            </Title>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={labelStyle}
              contentStyle={contentStyle}
            >
              <Descriptions.Item
                label="Observaciones"
                style={descriptionItemStyle}
              >
                {patient.observaciones || "N/A"}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

// Validación de las props con PropTypes
ViewPatientDialog.propTypes = {
  patient: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    TipoDocumento: PropTypes.shape({
      descripcion: PropTypes.string,
    }),
    numero_documento: PropTypes.string.isRequired,
    genero: PropTypes.string,
    fecha_nacimiento: PropTypes.string.isRequired,
    Zona: PropTypes.shape({
      nombre: PropTypes.string,
    }),
    Ciudad: PropTypes.shape({
      nombre: PropTypes.string,
      Departamento: PropTypes.shape({
        nombre: PropTypes.string,
      }),
    }),
    localidad: PropTypes.string,
    barrio: PropTypes.string,
    direccion: PropTypes.string,
    telefono: PropTypes.string,
    correo: PropTypes.string,
    Diagnostico: PropTypes.shape({
      nombre: PropTypes.string,
    }),
    Afiliacion: PropTypes.shape({
      nombre: PropTypes.string,
    }),
    Regimen: PropTypes.shape({
      nombre: PropTypes.string,
    }),
    Tipo: PropTypes.shape({
      nombre: PropTypes.string,
    }),
    Estado: PropTypes.shape({
      descripcion: PropTypes.string,
    }),
    Cliente: PropTypes.shape({
      nombre_cliente: PropTypes.string,
    }),
    nombre_responsable: PropTypes.string,
    parentesco_responsable: PropTypes.string,
    telefono_responsable: PropTypes.string,
    coordenadas: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    observaciones: PropTypes.string,
    indicaciones: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewPatientDialog;
