// src/hooks/useFetchDepartamentosYZonas.js

import { useState, useEffect } from "react";
import api from "../axiosConfig";
import { message } from "antd";

const useFetchDepartamentosYZonas = () => {
  const [departamentos, setDepartamentos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
  const [loadingZonas, setLoadingZonas] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingDepartamentos(true);
      setLoadingZonas(true);
      try {
        const [depResponse, zonasResponse] = await Promise.all([
          api.get("/departamentos"),
          api.get("/zonas"),
        ]);

        setDepartamentos(
          Array.isArray(depResponse.data)
            ? depResponse.data
            : depResponse.data.data
        );

        setZonas(
          Array.isArray(zonasResponse.data)
            ? zonasResponse.data
            : zonasResponse.data.data
        );
      } catch (error) {
        console.error("Error al obtener datos:", error);
        message.error("Error al obtener datos necesarios");
      } finally {
        setLoadingDepartamentos(false);
        setLoadingZonas(false);
      }
    };

    fetchData();
  }, []);

  return { departamentos, zonas, loadingDepartamentos, loadingZonas };
};

export default useFetchDepartamentosYZonas;
