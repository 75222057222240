// src/Components/modules/Coordinacion/PaginationControl.js

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Pagination, Select } from "antd";

const { Option } = Select;

const PaginationControl = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
}) => (
  <Row
    justify="space-between"
    align="middle"
    style={{ padding: "16px", backgroundColor: "#ffffff" }}
  >
    <Col>
      <Select
        value={itemsPerPage}
        onChange={(value) => onItemsPerPageChange(value)}
        style={{ width: 120 }}
        size="middle"
      >
        <Option value={5}>5 / página</Option>
        <Option value={10}>10 / página</Option>
        <Option value={20}>20 / página</Option>
        <Option value={50}>50 / página</Option>
      </Select>
    </Col>

    <Col>
      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={totalItems}
        onChange={onPageChange}
        showSizeChanger={false}
        showTotal={(total) => `Total ${total} traslados`}
      />
    </Col>
  </Row>
);

PaginationControl.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
};

export default PaginationControl;
