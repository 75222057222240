// src/Components/modules/authorizations/Form/ViewAuthorizationDialog.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Typography,
  Descriptions,
  Button,
  Row,
  Col,
  Divider,
  Spin,
  Calendar,
  Tooltip,
} from "antd";
import {
  FileTextOutlined,
  CommentOutlined,
  HomeOutlined,
  TeamOutlined,
  MedicineBoxOutlined,
  InfoCircleOutlined,
  BarChartOutlined,
  CarOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import dayjs from "../../../utils/dayjs"; // Ajusta la ruta según tu estructura de carpetas
import "./ViewAuthorizationDialog.css"; // Asegúrate de tener este archivo para estilos personalizados

const { Title } = Typography;

const ViewAuthorizationDialog = ({ authorizationId, open, handleClose }) => {
  const [authorization, setAuthorization] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAuthorization = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `https://mogotaxsas.com/api/autorizaciones/${authorizationId}`
        );
        const data = response.data;

        // Verificar si data.calendario es una cadena no vacía
        let calendarioRaw = data.calendario;
        if (typeof calendarioRaw === "string" && calendarioRaw.trim() !== "") {
          // Parsear el JSON ya que es una cadena válida
          data.calendario = JSON.parse(calendarioRaw);
        } else if (Array.isArray(calendarioRaw)) {
          // Si ya es un arreglo, no hacer nada
          data.calendario = calendarioRaw;
        } else {
          // Si es null, undefined, o cadena vacía, usar []
          data.calendario = [];
        }

        setAuthorization(data);
      } catch (error) {
        console.error("Error al obtener la autorización:", error);
      } finally {
        setLoading(false);
      }
    };

    if (authorizationId) {
      fetchAuthorization();
    }

    return () => {
      setAuthorization(null);
    };
  }, [authorizationId]);

  // Actualiza la función de renderizado para usar cellRender
  const cellRender = (date, info) => {
    if (info.type === "date") {
      if (!authorization || !Array.isArray(authorization.calendario))
        return null;

      const dateString = dayjs(date).tz("America/Bogota").format("YYYY-MM-DD");
      if (authorization.calendario.includes(dateString)) {
        return (
          <Tooltip title="Día de Traslado">
            <div className="traslado-day">
              <CarOutlined style={{ color: "#fff" }} />
            </div>
          </Tooltip>
        );
      }
    }
    return null;
  };

  // Formatear fechas para mostrar
  const formatDate = (dateString) => {
    return dayjs(dateString).tz("America/Bogota").format("DD/MM/YYYY");
  };

  // Funciones para generar enlaces de Google Maps y Street View
  const googleMapsLink = (coordinates) =>
    `https://www.google.com/maps/search/?api=1&query=${coordinates}`;
  const googleStreetViewLink = (coordinates) =>
    `https://www.google.com/maps?q=&layer=c&cbll=${coordinates}`;

  // Estilos personalizados para títulos y descripciones
  const titleStyle = {
    background: "#0a2e5c",
    color: "white",
    padding: "6px",
    borderRadius: "4px",
    fontSize: "14px",
    marginBottom: "6px",
    display: "flex",
    alignItems: "center",
  };

  const descriptionItemStyle = {
    marginBottom: "2px",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "12px",
  };

  const contentStyle = {
    fontSize: "12px",
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width="90%" // Usar porcentaje para ser más responsivo
      maxWidth={1100} // Establecer un ancho máximo
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "10px",
            color: "white",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title
            level={5}
            style={{ color: "white", margin: 0, fontSize: "16px" }}
          >
            <FileTextOutlined style={{ marginRight: "8px" }} />
            Ver Autorización
          </Title>
        </div>
      }
      footer={[
        <Button key="close" type="primary" onClick={handleClose}>
          Cerrar
        </Button>,
      ]}
      style={{ maxHeight: "80vh", overflow: "auto" }}
      destroyOnClose
      centered // Centrar el modal en la pantalla
      // bodyStyle eliminado
    >
      {loading || !authorization ? (
        <div className="authorization-loading">
          <Spin />
        </div>
      ) : (
        <div className="authorization-content">
          {/* Primera Fila */}
          <Row gutter={[16, 16]}>
            {/* Columna Izquierda: MIPRES */}
            <Col xs={24} sm={24} md={12} lg={8}>
              <Title level={5} style={titleStyle}>
                <MedicineBoxOutlined style={{ marginRight: "6px" }} />
                MIPRES
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item
                  label="Código MIPRES"
                  style={descriptionItemStyle}
                >
                  {authorization.codigo_mipres ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="CUPS Código"
                  style={descriptionItemStyle}
                >
                  {authorization.cups_codigo ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="CUPS Descripción"
                  style={descriptionItemStyle}
                >
                  {authorization.cups_descripcion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Número de Entrega"
                  style={descriptionItemStyle}
                >
                  {authorization.numero_entrega ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Entregas Autorizadas"
                  style={descriptionItemStyle}
                >
                  {authorization.entregas_autorizadas ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>

              <Title level={5} style={titleStyle}>
                <InfoCircleOutlined style={{ marginRight: "6px" }} />
                Información General
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item label="Cliente" style={descriptionItemStyle}>
                  {authorization.empresa ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Número de Pre-Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.numero_pre_autorizacion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Número de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.numero_autorizacion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Propósito"
                  style={descriptionItemStyle}
                >
                  {authorization.proposito ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Tipo de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.tipo_autorizacion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Copago Total"
                  style={descriptionItemStyle}
                >
                  {authorization.copago ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>

              <Title level={5} style={titleStyle}>
                <TeamOutlined style={{ marginRight: "6px" }} />
                Estado de la Autorización
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
              >
                <Descriptions.Item
                  label="Inicio Fecha de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.inicio_fecha_autorizacion
                    ? formatDate(authorization.inicio_fecha_autorizacion)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Final Fecha de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.final_fecha_autorizacion
                    ? formatDate(authorization.final_fecha_autorizacion)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Estado de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.estado_autorizacion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Categoría de Autorización"
                  style={descriptionItemStyle}
                >
                  {authorization.categoria_autorizacion ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            {/* Columna Central: Observaciones */}
            <Col xs={24} sm={24} md={12} lg={8}>
              <Title level={5} style={titleStyle}>
                <TeamOutlined style={{ marginRight: "6px" }} />
                Paciente
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item
                  label="Nombre del Paciente:"
                  style={descriptionItemStyle}
                >
                  {authorization.nombre_paciente ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Tipo de Documento:"
                  style={descriptionItemStyle}
                >
                  {authorization.tipo_documento_paciente ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Número de Documento:"
                  style={descriptionItemStyle}
                >
                  {authorization.numero_documento_paciente ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>

              <Title level={5} style={titleStyle}>
                <CommentOutlined style={{ marginRight: "6px" }} />
                Observaciones
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item style={descriptionItemStyle}>
                  {authorization.observaciones ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>

              <Title level={5} style={titleStyle}>
                <TeamOutlined style={{ marginRight: "6px" }} />
                Tipo de Traslado
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item
                  label="Tipo de Viaje"
                  style={descriptionItemStyle}
                >
                  {authorization.tipo_viaje ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Tipo de Traslado"
                  style={descriptionItemStyle}
                >
                  {authorization.tipo_traslado ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>

              <Title level={5} style={titleStyle}>
                <BarChartOutlined style={{ marginRight: "6px" }} />
                Estadísticas de Traslado
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
              >
                <Descriptions.Item
                  label="Traslados Autorizados"
                  style={descriptionItemStyle}
                >
                  {authorization.total_traslados_asignados ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Traslados Restantes"
                  style={descriptionItemStyle}
                >
                  {authorization.traslados_restantes ?? "N/A"}
                </Descriptions.Item>
                {/* Nuevos Campos Agregados */}
                <Descriptions.Item
                  label="Traslados en Curso"
                  style={descriptionItemStyle}
                >
                  {authorization.traslados_en_curso ?? 0}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Traslados Usados"
                  style={descriptionItemStyle}
                >
                  {authorization.usados ?? 0}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Traslados Fallidos"
                  style={descriptionItemStyle}
                >
                  {authorization.fallidos ?? 0}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Traslados Cancelados"
                  style={descriptionItemStyle}
                >
                  {authorization.cancelados ?? 0}
                </Descriptions.Item>
                {/* Fin de Nuevos Campos */}
                <Descriptions.Item
                  label="Kilómetros Autorizados"
                  style={descriptionItemStyle}
                >
                  {authorization.kilometros_autorizados ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Kilómetros por Servicios"
                  style={descriptionItemStyle}
                >
                  {authorization.kilometros_por_servicios ?? "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            {/* Columna Derecha: Origen y Destino del Traslado */}
            <Col xs={24} sm={24} md={24} lg={8}>
              {/* Origen del Traslado */}
              <Title level={5} style={titleStyle}>
                <HomeOutlined style={{ marginRight: "6px" }} />
                Origen del Traslado
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={{ marginBottom: "12px" }}
              >
                <Descriptions.Item
                  label="Lugar de Origen"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_institucion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Departamento"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_departamento ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Ciudad/Municipio"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_ciudad ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Localidad"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_localidad ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Barrio" style={descriptionItemStyle}>
                  {authorization.origen_barrio ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Dirección"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_direccion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Indicaciones"
                  style={descriptionItemStyle}
                >
                  {authorization.origen_indicaciones ?? "N/A"}
                </Descriptions.Item>
                {authorization.origen_coordenadas && (
                  <Descriptions.Item
                    label="Ver Ubicación"
                    style={descriptionItemStyle}
                  >
                    <Button
                      type="primary"
                      href={googleMapsLink(
                        authorization.origen_coordenadas.coordinates
                      )}
                      target="_blank"
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      block
                    >
                      Google Maps
                    </Button>
                    <Button
                      type="default"
                      href={googleStreetViewLink(
                        authorization.origen_coordenadas.coordinates
                      )}
                      target="_blank"
                      block
                    >
                      Street View
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>

              {/* Destino del Traslado */}
              <Title level={5} style={titleStyle}>
                <HomeOutlined style={{ marginRight: "6px" }} />
                Destino del Traslado
              </Title>
              <Descriptions
                column={1}
                bordered
                size="small"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
              >
                <Descriptions.Item
                  label="Lugar de Destino"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_institucion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Departamento"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_departamento ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Ciudad/Municipio"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_ciudad ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Localidad"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_localidad ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Barrio" style={descriptionItemStyle}>
                  {authorization.destino_barrio ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Dirección"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_direccion ?? "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Indicaciones"
                  style={descriptionItemStyle}
                >
                  {authorization.destino_indicaciones ?? "N/A"}
                </Descriptions.Item>
                {authorization.destino_coordenadas && (
                  <Descriptions.Item
                    label="Ver Ubicación"
                    style={descriptionItemStyle}
                  >
                    <Button
                      type="primary"
                      href={googleMapsLink(
                        authorization.destino_coordenadas.coordinates
                      )}
                      target="_blank"
                      style={{ marginRight: "8px", marginBottom: "8px" }}
                      block
                    >
                      Google Maps
                    </Button>
                    <Button
                      type="default"
                      href={googleStreetViewLink(
                        authorization.destino_coordenadas.coordinates
                      )}
                      target="_blank"
                      block
                    >
                      Street View
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </Row>

          {/* Mostrar Calendario si la autorización está cerrada */}
          {authorization.tipo_autorizacion.toLowerCase() === "cerrada" && (
            <div style={{ marginTop: "24px" }}>
              <Divider />
              <Title level={5} style={titleStyle}>
                <InfoCircleOutlined style={{ marginRight: "6px" }} />
                Calendario de Autorización Cerrada
              </Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <Calendar
                  value={
                    authorization.inicio_fecha_autorizacion
                      ? dayjs(authorization.inicio_fecha_autorizacion)
                      : dayjs()
                  }
                  validRange={
                    authorization.inicio_fecha_autorizacion &&
                    authorization.final_fecha_autorizacion
                      ? [
                          dayjs(authorization.inicio_fecha_autorizacion),
                          dayjs(authorization.final_fecha_autorizacion),
                        ]
                      : undefined
                  }
                  cellRender={cellRender} // Reemplazado por cellRender
                  fullscreen={false}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

// Validación de las props con PropTypes
ViewAuthorizationDialog.propTypes = {
  authorizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired, // authorizationId es obligatorio y puede ser string o número
  open: PropTypes.bool.isRequired, // open es obligatorio y debe ser booleano
  handleClose: PropTypes.func.isRequired, // handleClose es obligatorio y debe ser función
};

export default ViewAuthorizationDialog;
