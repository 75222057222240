// src/SocketContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types"; // Importar PropTypes para la validación
import { io } from "socket.io-client";
import { useUser } from "./UserContext";
import { message } from "antd";

export const SocketContext = createContext(); // Exportar SocketContext

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const { user, accessToken, refreshToken, updateTokens, logoutUser } =
    useUser();
  const [socketConnected, setSocketConnected] = useState(false);
  const socketRef = useRef(null);

  useEffect(() => {
    if (user?.id && !socketRef.current) {
      console.log("Usuario autenticado, iniciando la conexión del socket...");

      const newSocket = io("https://mogotaxsas.com", {
        withCredentials: true,
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 20000,
        auth: {
          token: accessToken,
          refreshToken: refreshToken,
        },
      });

      newSocket.on("connect", () => {
        console.log("Socket conectado:", newSocket.id);
        setSocketConnected(true);

        const existingConexionId = localStorage.getItem("conexionId");

        // Si el conexionId ya existe, verificar que sea válido antes de enviarlo
        if (existingConexionId) {
          console.log("Reutilizando conexionId:", existingConexionId);
          newSocket.emit(
            "verificar_conexion",
            existingConexionId,
            (isValid) => {
              if (isValid) {
                newSocket.emit("login", user.id, existingConexionId);
              } else {
                console.log(
                  "ConexionId inválido, eliminándolo y creando nueva conexión."
                );
                localStorage.removeItem("conexionId"); // Elimina el conexionId inválido
                newSocket.emit("login", user.id);
              }
            }
          );
        } else {
          console.log("No se encontró conexionId, creando una nueva conexión.");
          newSocket.emit("login", user.id);
        }
      });

      newSocket.on("conexion_establecida", ({ conexionId }) => {
        localStorage.setItem("conexionId", conexionId); // Guarda solo el nuevo conexionId
        console.log("Nueva conexionId establecida:", conexionId);
      });

      newSocket.on("disconnect", (reason) => {
        console.log("Socket desconectado:", newSocket.id, "Razón:", reason);
        setSocketConnected(false);
      });

      newSocket.on("ping", () => {
        console.log("Ping recibido del servidor");
        newSocket.emit("pong");
      });

      // Manejar token expirado
      newSocket.on("token_expired", () => {
        console.log("Token expirado, solicitando nuevos tokens...");
        newSocket.emit("request_new_token");
      });

      // Manejar nuevos tokens
      newSocket.on(
        "new_tokens",
        ({ accessToken: newAccessToken, refreshToken: newRefreshToken }) => {
          console.log("Recibidos nuevos tokens desde el servidor.");
          // Actualizar los tokens en UserContext y localStorage
          updateTokens(newAccessToken, newRefreshToken);

          // Desconectar y reconectar con los nuevos tokens
          newSocket.auth.token = newAccessToken;
          newSocket.auth.refreshToken = newRefreshToken;
          newSocket.disconnect();
          newSocket.connect();
        }
      );

      // Manejar errores al refrescar tokens
      newSocket.on("token_error", ({ message: errorMessage }) => {
        console.log("Error al refrescar tokens:", errorMessage);
        message.error("Sesión expirada. Por favor, inicia sesión nuevamente.");
        logoutUser(); // Limpiar el estado de usuario y tokens
        newSocket.disconnect();
      });

      socketRef.current = newSocket;
    }

    // Manejar tokens actualizados para reconectar con nuevos tokens
    const handleTokensUpdated = () => {
      if (socketRef.current) {
        console.log("Tokens actualizados, reconectando socket...");
        socketRef.current.auth.token = accessToken;
        socketRef.current.auth.refreshToken = refreshToken;
        socketRef.current.disconnect();
        socketRef.current.connect();
      }
    };

    window.addEventListener("tokens_updated", handleTokensUpdated);

    return () => {
      if (socketRef.current) {
        console.log("Desconectando socket...");
        socketRef.current.disconnect();
        socketRef.current = null;
      }
      window.removeEventListener("tokens_updated", handleTokensUpdated);
    };
  }, [user, accessToken, refreshToken, updateTokens, logoutUser]);

  // Memoizar el valor para evitar que se recree en cada render
  const contextValue = useMemo(
    () => ({
      socket: socketRef.current,
      socketConnected,
    }),
    [socketConnected]
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

// Añadir PropTypes para validar las props
SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
