// src/components/Clients/Clients.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import api from "../../../axiosConfig";
import {
  Table,
  Button,
  Input,
  Space,
  Pagination,
  Tooltip,
  Spin,
  message,
  Select,
  Row,
  Col,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ViewClientDialog from "./ViewClientDialog";
import DeleteClientDialog from "./DeleteClientDialog";
import ClientForm from "./Form/ClientForm";

const { Search } = Input;
const { Option } = Select;

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loadingZonas, setLoadingZonas] = useState(false);
  const [selectedZona, setSelectedZona] = useState(null);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [clientNameToDelete, setClientNameToDelete] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false); // Mantener esta línea

  // Función para obtener clientes con manejo de carga
  const fetchClients = useCallback(async () => {
    setListLoading(true);
    try {
      const response = await api.get("/clientes/list");
      setClients(response.data);
      console.log("Clientes cargados:", response.data);
    } catch (error) {
      console.error("Error al obtener los clientes:", error);
      message.error("Error al obtener los clientes");
    } finally {
      setListLoading(false);
    }
  }, []);

  // Función para obtener zonas
  const fetchZonas = useCallback(async () => {
    setLoadingZonas(true);
    try {
      const response = await api.get("/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener las zonas:", error);
      message.error("Error al obtener las zonas");
    } finally {
      setLoadingZonas(false);
    }
  }, []);

  useEffect(() => {
    fetchClients();
    fetchZonas();
  }, [fetchClients, fetchZonas]);

  useEffect(() => {
    setPage(1);
  }, [filter, selectedZona]);

  const handleSearchChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setPage(1);
  };

  const handleEditClick = useCallback(async (client) => {
    try {
      setLoadingClient(true); // Inicia el estado de carga
      const response = await api.get(`/clientes/${client.id}`);
      setSelectedClient(response.data);
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el cliente:", error);
      message.error("Error al obtener los datos del cliente");
    } finally {
      setLoadingClient(false); // Finaliza el estado de carga
    }
  }, []);

  const handleViewClick = useCallback(async (client) => {
    try {
      setLoadingClient(true); // Inicia el estado de carga
      const response = await api.get(`/clientes/${client.id}`);
      setSelectedClient(response.data);
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el cliente:", error);
      message.error("Error al obtener los datos del cliente");
    } finally {
      setLoadingClient(false); // Finaliza el estado de carga
    }
  }, []);

  const handleOpenDeleteDialog = useCallback((client) => {
    setClientToDelete(client.id);
    setClientNameToDelete(client.nombre_cliente);
    setDeleteDialogOpen(true);
  }, []);

  const filteredClients = useMemo(() => {
    return clients.filter((client) => {
      const matchesFilter =
        client.nombre_cliente.toLowerCase().includes(filter.toLowerCase()) ||
        client.correo_electronico.toLowerCase().includes(filter.toLowerCase());

      const matchesZona = selectedZona
        ? client.zona?.nombre === selectedZona
        : true;

      return matchesFilter && matchesZona;
    });
  }, [clients, filter, selectedZona]);

  const paginatedClients = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return filteredClients.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredClients, page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        title: "Cliente",
        dataIndex: "nombre_cliente",
        key: "nombre_cliente",
        sorter: (a, b) => a.nombre_cliente.localeCompare(b.nombre_cliente),
      },
      {
        title: "Contacto",
        dataIndex: "nombre_contacto",
        key: "nombre_contacto",
        sorter: (a, b) => a.nombre_contacto.localeCompare(b.nombre_contacto),
      },
      {
        title: "Teléfono",
        dataIndex: "telefono",
        key: "telefono",
        sorter: (a, b) => a.telefono.localeCompare(b.telefono),
      },
      {
        title: "Correo Electrónico",
        dataIndex: "correo_electronico",
        key: "correo_electronico",
        sorter: (a, b) =>
          a.correo_electronico.localeCompare(b.correo_electronico),
      },
      {
        title: "Zona",
        dataIndex: "zona",
        key: "zona",
        render: (zona) => zona?.nombre || "Sin Zona",
        sorter: (a, b) =>
          (a.zona?.nombre || "").localeCompare(b.zona?.nombre || ""),
      },
      {
        title: "Departamento",
        dataIndex: "departamento",
        key: "departamento",
        render: (departamento) => departamento?.nombre || "Sin Departamento",
        sorter: (a, b) =>
          (a.departamento?.nombre || "").localeCompare(
            b.departamento?.nombre || ""
          ),
      },
      {
        title: "Ciudad",
        dataIndex: "ciudad",
        key: "ciudad",
        render: (ciudad) => ciudad?.nombre || "Sin Ciudad",
        sorter: (a, b) =>
          (a.ciudad?.nombre || "").localeCompare(b.ciudad?.nombre || ""),
      },
      {
        title: "País",
        dataIndex: "pais",
        key: "pais",
        sorter: (a, b) => a.pais.localeCompare(b.pais),
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, client) => (
          <Space size="middle">
            <Tooltip title="Ver">
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewClick(client)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Editar">
              <Button
                icon={<EditOutlined />}
                onClick={() => handleEditClick(client)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Eliminar">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleOpenDeleteDialog(client)}
                type="default"
                danger
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>
          </Space>
        ),
        width: 150,
      },
    ],
    [handleEditClick, handleViewClick, handleOpenDeleteDialog]
  );

  // Estilos para encabezados fijos usando CSS-in-JS
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 1,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
  };

  return (
    <div className="clients-container">
      {/* Barra de herramientas superior */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            <Search
              placeholder="Buscar cliente"
              enterButton={<SearchOutlined />}
              value={filter}
              onChange={handleSearchChange}
              style={{ width: 500 }}
              allowClear
            />
            <Select
              placeholder="Filtrar por Zona"
              allowClear
              style={{ width: 200 }}
              onChange={(value) => setSelectedZona(value)}
              loading={loadingZonas}
              value={selectedZona}
            >
              {zonas.map((zona) => (
                <Option key={zona.id} value={zona.nombre}>
                  {zona.nombre}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDialogOpen(true)}
            >
              Agregar Cliente
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Contenedor de la tabla con desplazamiento personalizado usando Ant Design */}
      <Spin spinning={listLoading} tip="Cargando clientes...">
        <Table
          columns={columns.map((col) => ({
            ...col,
            onHeaderCell: () => ({
              style: tableHeaderStyle,
            }),
          }))}
          dataSource={paginatedClients}
          pagination={false}
          rowKey="id"
          bordered
          scroll={{ y: 600 }} // Establece la altura del cuerpo de la tabla
        />
        {/* Paginación siempre visible */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
        >
          <Pagination
            current={page}
            pageSize={rowsPerPage}
            total={filteredClients.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={handleRowsPerPageChange}
            pageSizeOptions={["5", "10", "25", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} clientes`
            }
          />
        </div>
      </Spin>

      {/* Formularios y Diálogos */}

      {/* Editar Cliente - Renderizado Condicionalmente con Spin */}
      {selectedClient && editDialogOpen && (
        <Spin spinning={loadingClient} tip="Cargando cliente...">
          <ClientForm
            key={`edit-${selectedClient.id}`} // Clave única para React
            initialData={selectedClient} // Nombre de prop correcto
            open={editDialogOpen}
            handleClose={() => setEditDialogOpen(false)}
            fetchClients={fetchClients}
            isEdit={true}
          />
        </Spin>
      )}

      {/* Ver Cliente - Renderizado Condicionalmente con Spin */}
      {viewDialogOpen && (
        <Spin spinning={loadingClient} tip="Cargando cliente...">
          <ViewClientDialog
            client={selectedClient}
            open={viewDialogOpen}
            handleClose={() => setViewDialogOpen(false)}
          />
        </Spin>
      )}

      {/* Eliminar Cliente */}
      {deleteDialogOpen && (
        <DeleteClientDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          clientId={clientToDelete}
          clientName={clientNameToDelete}
          fetchClients={fetchClients}
        />
      )}

      {/* Agregar Cliente - Renderizado Condicionalmente */}
      {addDialogOpen && (
        <ClientForm
          key="add-client" // Clave única para React
          open={addDialogOpen}
          handleClose={() => setAddDialogOpen(false)}
          fetchClients={fetchClients}
          isEdit={false}
        />
      )}
    </div>
  );
};

export default Clients;
