// src/Components/modules/authorizations/Form/AuthorizationDates.js

import React from "react";
import PropTypes from "prop-types";
import { DatePicker, Form, Divider, Input, Calendar } from "antd";
import dayjs from "../../../../utils/dayjs"; // Asegúrate de ajustar la ruta según tu estructura de carpetas
import "./AuthorizationDates.css"; // Asegúrate de que este archivo contiene estilos compatibles con `antd`

const AuthorizationDates = ({
  form,
  handleChange,
  handleDaySelection,
  selectedDays,
  dateRange,
  setDateRange,
  calendarStartDate,
  setCalendarStartDate,
}) => {
  // Función para deshabilitar fechas en el DatePicker
  const disabledStartDate = (current) => {
    const endDate = dayjs(form.final_fecha_autorizacion);
    return endDate && dayjs(current).isAfter(endDate, "day");
  };

  const disabledEndDate = (current) => {
    const startDate = dayjs(form.inicio_fecha_autorizacion);
    return startDate && dayjs(current).isBefore(startDate, "day");
  };

  // Manejo del cambio de fecha
  const onStartDateChange = (date) => {
    const dateString = date ? date.format("YYYY-MM-DD") : null; // Cambiar undefined a null
    handleChange({
      target: {
        name: "inicio_fecha_autorizacion",
        value: dateString,
      },
    });
    setDateRange([date, dateRange[1]]);
    setCalendarStartDate(date);
  };

  const onEndDateChange = (date) => {
    const dateString = date ? date.format("YYYY-MM-DD") : null; // Cambiar undefined a null
    handleChange({
      target: {
        name: "final_fecha_autorizacion",
        value: dateString,
      },
    });
    setDateRange([dateRange[0], date]);
  };

  // Función para manejar la selección de fechas en el Calendario de `antd`
  const handleSelectDate = (value) => {
    // `value` es un objeto Dayjs de Ant Design
    handleDaySelection(value); // Pasar el objeto `dayjs` directamente
  };

  // Función para personalizar el renderizado de los días en el Calendario de `antd`
  const cellRender = (current, info) => {
    if (info.type === "date") {
      const formattedDate = current.format("YYYY-MM-DD");
      const isSelected = selectedDays.includes(formattedDate);
      return isSelected ? (
        <div className="selected-day">{current.format("DD/MM/YYYY")}</div>
      ) : null;
    }
    return null;
  };

  return (
    <>
      <Divider />

      {/* Inicio de Fecha de Autorización */}
      <Form.Item
        label="Inicio de Fecha de Autorización"
        // Eliminamos el prop `name` para evitar conflictos con el manejo de estado externo
        rules={[
          {
            required: true,
            message: "Por favor, selecciona la fecha de inicio.",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const endDate = form.final_fecha_autorizacion
                ? dayjs(form.final_fecha_autorizacion)
                : null;
              if (value && endDate && dayjs(value).isAfter(endDate, "day")) {
                return Promise.reject(
                  new Error(
                    "La fecha de inicio no puede ser posterior a la fecha final."
                  )
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        style={{ marginBottom: "16px" }}
      >
        <DatePicker
          name="inicio_fecha_autorizacion"
          value={
            form.inicio_fecha_autorizacion
              ? dayjs(form.inicio_fecha_autorizacion)
              : null // Cambiar undefined a null
          }
          onChange={onStartDateChange}
          format="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          style={{ width: "100%" }}
          disabledDate={disabledStartDate}
        />
      </Form.Item>

      {/* Final de Fecha de Autorización */}
      <Form.Item
        label="Final de Fecha de Autorización"
        // Eliminamos el prop `name` para evitar conflictos con el manejo de estado externo
        rules={[
          { required: true, message: "Por favor, selecciona la fecha final." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const startDate = form.inicio_fecha_autorizacion
                ? dayjs(form.inicio_fecha_autorizacion)
                : null;
              if (
                value &&
                startDate &&
                dayjs(value).isBefore(startDate, "day")
              ) {
                return Promise.reject(
                  new Error(
                    "La fecha final no puede ser anterior a la fecha de inicio."
                  )
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        style={{ marginBottom: "16px" }}
      >
        <DatePicker
          name="final_fecha_autorizacion"
          value={
            form.final_fecha_autorizacion
              ? dayjs(form.final_fecha_autorizacion)
              : null // Cambiar undefined a null
          }
          onChange={onEndDateChange}
          format="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          style={{ width: "100%" }}
          disabledDate={disabledEndDate}
        />
      </Form.Item>

      {/* Mostrar el calendario y los días del servicio solo si el tipo de autorización es "cerrada" */}
      {form.tipo_autorizacion === "cerrada" && (
        <>
          <Form.Item label="Días del Servicio">
            <Input value={selectedDays.join(", ")} readOnly />
          </Form.Item>
          <div className="calendar-container">
            <Calendar
              onSelect={handleSelectDate} // Usar `onSelect` para Ant Design's Calendar
              value={calendarStartDate || dayjs()} // Asegurar que siempre tenga un valor válido
              fullscreen={false}
              style={{ width: "100%" }}
              cellRender={cellRender} // Personalizar el renderizado de las celdas
            />
          </div>
        </>
      )}
    </>
  );
};

AuthorizationDates.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDaySelection: PropTypes.func.isRequired,
  selectedDays: PropTypes.array.isRequired,
  dateRange: PropTypes.array.isRequired, // [Dayjs, Dayjs]
  setDateRange: PropTypes.func.isRequired,
  calendarStartDate: PropTypes.object, // Dayjs object
  setCalendarStartDate: PropTypes.func.isRequired,
};

export default AuthorizationDates;
