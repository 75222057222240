import api from "../../../../axiosConfig";

const API_URL = "https://mogotaxsas.com/api";

export const fetchClientes = async () => {
  try {
    const response = await api.get(`${API_URL}/clientes`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los clientes:", error);
  }
};

export const fetchCiudades = async (departamentoId) => {
  try {
    const response = await api.get(
      `${API_URL}/ciudades?departamento_id=${departamentoId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener ciudades:", error);
  }
};

export const fetchTiposDocumento = async () => {
  try {
    const response = await api.get(`${API_URL}/tipos-documento`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener tipos de documento:", error);
  }
};

export const fetchDepartamentos = async () => {
  try {
    const response = await api.get(`${API_URL}/departamentos`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener departamentos:", error);
  }
};

export const fetchZonas = async () => {
  try {
    const response = await api.get(`${API_URL}/zonas`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener zonas:", error);
  }
};

export const fetchDiagnosticos = async () => {
  try {
    const response = await api.get(`${API_URL}/diagnosticos`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener diagnósticos:", error);
  }
};

export const fetchAfiliaciones = async () => {
  try {
    const response = await api.get(`${API_URL}/afiliaciones`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener afiliaciones:", error);
  }
};

export const fetchRegimenes = async () => {
  try {
    const response = await api.get(`${API_URL}/regimenes`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener regimenes:", error);
  }
};

export const fetchTipos = async () => {
  try {
    const response = await api.get(`${API_URL}/tipos`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener tipos:", error);
  }
};

export const fetchEstados = async () => {
  try {
    const response = await api.get(`${API_URL}/estados`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener estados:", error);
  }
};
