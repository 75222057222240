// src/components/Authorizations/AuthorizationCard.js

import React from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Tooltip,
  Tag,
  Space,
  Alert,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileTextOutlined,
  MedicineBoxOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  UserOutlined,
  IdcardOutlined,
  PhoneOutlined,
  CarOutlined,
  PlusCircleOutlined,
  HistoryOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types"; // Importar PropTypes
import "./AuthorizationCard.css"; // Importar el archivo CSS

const { Text } = Typography;

const AuthorizationCard = ({
  authorization,
  isSelected,
  onSelect,
  onEdit,
  onView,
  onDelete,
  onCreateNovedad,
  onHistorialNovedades,
  getBorderColor,
  onHistorialAutorizacion,
}) => {
  const {
    id,
    estado_autorizacion,
    numero_autorizacion,
    numero_pre_autorizacion,
    codigo_mipres,
    origen_direccion,
    origen_institucion,
    destino_institucion,
    origen_ciudad,
    origen_departamento,
    destino_direccion,
    destino_ciudad,
    destino_departamento,
    inicio_fecha_autorizacion,
    final_fecha_autorizacion,
    nombre_paciente,
    tipo_documento_paciente,
    numero_documento_paciente,
    telefono_responsable,
    empresa,
    observaciones,
    tipo_traslado,
    tipo_viaje,
    copago, // Asegurarse de incluir copago
    copago_diario, // Añadido copago_diario
  } = authorization;

  // Definir el color del Tag según el estado
  const getStatusTagColor = (estado) => {
    switch (estado.toLowerCase()) {
      case "activa":
        return "geekblue";
      case "suspendida":
        return "volcano";
      case "completada":
        return "green";
      case "cerrada":
        return "purple"; // Puedes elegir el color que desees aquí
      default:
        return "default";
    }
  };

  // Formatear el copago con formato de moneda
  const formatCurrency = (value) => {
    if (value === undefined || value === null) return "N/A";
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);
  };

  return (
    <Card
      className="authorization-card"
      onClick={() => onSelect(id)}
      style={{
        borderLeft: `4px solid ${getBorderColor(estado_autorizacion)}`,
      }}
      hoverable
    >
      <Row gutter={[16, 16]} className="card-row">
        {/* Información de Autorización */}
        <Col xs={24} sm={8}>
          <div className="authorization-header">
            {numero_autorizacion && (
              <div className="authorization-info">
                <FileTextOutlined
                  className="authorization-icon"
                  style={{ color: "#cccccc" }}
                />
                <strong>AUT:</strong>
                <Text ellipsis={{ tooltip: numero_autorizacion }}>
                  {numero_autorizacion}
                </Text>
              </div>
            )}
            {numero_pre_autorizacion && (
              <div className="authorization-info">
                <FileTextOutlined
                  className="authorization-icon"
                  style={{ color: "#cccccc" }}
                />
                <strong>PRE:</strong>
                <Text ellipsis={{ tooltip: numero_pre_autorizacion }}>
                  {numero_pre_autorizacion}
                </Text>
              </div>
            )}
            {codigo_mipres && (
              <div className="authorization-info">
                <FileTextOutlined
                  className="authorization-icon"
                  style={{ color: "#cccccc" }}
                />
                <strong>MIPRES:</strong>
                <Text ellipsis={{ tooltip: codigo_mipres }}>
                  {codigo_mipres}
                </Text>
              </div>
            )}
          </div>
        </Col>

        {/* Información de Origen y Destino */}
        <Col xs={24} sm={8}>
          <div className="authorization-info">
            <EnvironmentOutlined
              className="authorization-icon"
              style={{ color: origen_institucion ? "green" : "#cccccc" }}
            />
            <Text
              ellipsis={{
                tooltip: origen_institucion
                  ? `${origen_institucion}, ${origen_direccion}, ${origen_ciudad}, ${origen_departamento}`
                  : "Origen no especificado",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {origen_institucion
                  ? `${origen_institucion}`
                  : "Origen no especificado"}
              </span>
              {origen_institucion
                ? `, ${origen_direccion}, ${origen_ciudad}, ${origen_departamento}`
                : ""}
            </Text>
          </div>
          <div className="authorization-info">
            <EnvironmentOutlined
              className="authorization-icon"
              style={{ color: destino_institucion ? "red" : "#cccccc" }}
            />
            <Text
              ellipsis={{
                tooltip: destino_institucion
                  ? `${destino_institucion}, ${destino_direccion}, ${destino_ciudad}, ${destino_departamento}`
                  : "Destino no especificado",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {destino_institucion
                  ? `${destino_institucion}`
                  : "Destino no especificado"}
              </span>
              {destino_institucion
                ? `, ${destino_direccion}, ${destino_ciudad}, ${destino_departamento}`
                : ""}
            </Text>
          </div>
          <div className="authorization-info">
            <CalendarOutlined
              className="authorization-icon"
              style={{ color: "#cccccc" }}
            />
            <Text>
              {new Date(inicio_fecha_autorizacion).toLocaleDateString()} -{" "}
              {new Date(final_fecha_autorizacion).toLocaleDateString()}
            </Text>
          </div>
        </Col>

        {/* Información del Paciente */}
        <Col xs={24} sm={8}>
          <div className="authorization-info right-aligned">
            <UserOutlined
              className="authorization-icon"
              style={{ color: "#cccccc" }}
            />
            <Text ellipsis={{ tooltip: nombre_paciente }}>
              {nombre_paciente}
            </Text>
          </div>
          {tipo_documento_paciente && (
            <div className="authorization-info right-aligned">
              <IdcardOutlined
                className="authorization-icon"
                style={{ color: "#cccccc" }}
              />
              <Text>{tipo_documento_paciente}</Text>
            </div>
          )}
          {numero_documento_paciente && (
            <div className="authorization-info right-aligned">
              <IdcardOutlined
                className="authorization-icon"
                style={{ color: "#cccccc" }}
              />
              <Text>{numero_documento_paciente}</Text>
            </div>
          )}
          <div className="authorization-info right-aligned">
            <PhoneOutlined
              className="authorization-icon"
              style={{ color: "#cccccc" }}
            />
            <Text>{telefono_responsable}</Text>
          </div>
          <div className="authorization-info right-aligned">
            <MedicineBoxOutlined
              className="authorization-icon"
              style={{ color: "#cccccc" }}
            />
            <Text>{empresa}</Text>
          </div>
        </Col>
      </Row>

      {/* Estado de la Autorización y Tipo de Traslado con Copago */}
      <Row
        justify="space-between"
        align="middle"
        className="authorization-footer"
      >
        <Col>
          {/* Estado principal */}
          <Tag
            color={getStatusTagColor(estado_autorizacion)}
            className="status-tag"
          >
            {estado_autorizacion}
          </Tag>

          {/* Estado secundario (si está presente) */}
          {authorization.estado_secundario && (
            <Tag
              color={getStatusTagColor(estado_autorizacion)}
              className="status-tag"
            >
              {authorization.estado_secundario}
            </Tag>
          )}
          {authorization.estado_terciario && (
            <Tag
              color={getStatusTagColor(estado_autorizacion)}
              className="status-tag"
            >
              {authorization.estado_terciario}
            </Tag>
          )}
          {/* Traslados fallidos (solo si > 0) */}
          {authorization.fallidos > 0 && (
            <Tag color="brown">{authorization.fallidos}</Tag>
          )}

          {/* Traslados cancelados (solo si > 0) */}
          {authorization.cancelados > 0 && (
            <Tag color="red">{authorization.cancelados}</Tag>
          )}
        </Col>
        <Col>
          <Space>
            {/* Convertir Tipo de Traslado y Tipo de Viaje en un Tag */}
            <Tag
              icon={<CarOutlined />}
              color={getStatusTagColor(estado_autorizacion)}
            >
              {tipo_traslado} - {tipo_viaje}
            </Tag>

            {/* Convertir Copago en un Tag, si está definido */}
            {copago !== undefined && copago !== null && (
              <Tag icon={<DollarOutlined />} color="blue">
                T {formatCurrency(copago)}
              </Tag>
            )}

            {/* Convertir Copago Diario en un Tag, si está definido */}
            {copago_diario !== undefined && copago_diario !== null && (
              <Tag icon={<DollarOutlined />} color="orange">
                S {formatCurrency(copago_diario)}
              </Tag>
            )}
          </Space>
        </Col>
      </Row>

      {/* Observaciones */}
      {observaciones && (
        <Row>
          <Col span={24}>
            <Alert
              description={observaciones}
              type="warning"
              showIcon
              icon={<ExclamationCircleOutlined />} // Icono personalizado
              className="observations-alert" // Clase CSS para estilos adicionales
            />
          </Col>
        </Row>
      )}

      {/* Botones de Acción */}
      {isSelected && (
        <Row justify="end" className="authorization-actions">
          <Tooltip title="Historial de Autorización">
            <Button
              type="primary"
              shape="circle"
              icon={<ProfileOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onHistorialAutorizacion(authorization.id);
              }}
              style={{ marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip title="Editar">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(authorization);
              }}
              style={{ marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip title="Ver">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onView(id);
              }}
              style={{ marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button
              type="primary"
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(id);
              }}
              style={{ marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip title="Crear Novedad">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onCreateNovedad(authorization);
              }}
              style={{ marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip title="Historial de Novedades">
            <Button
              type="primary"
              shape="circle"
              icon={<HistoryOutlined />}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onHistorialNovedades(id);
              }}
            />
          </Tooltip>
        </Row>
      )}
    </Card>
  );
};

// Definir PropTypes
AuthorizationCard.propTypes = {
  authorization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    estado_autorizacion: PropTypes.string.isRequired,
    numero_autorizacion: PropTypes.string,
    numero_pre_autorizacion: PropTypes.string,
    codigo_mipres: PropTypes.string,
    origen_direccion: PropTypes.string,
    origen_institucion: PropTypes.string,
    destino_institucion: PropTypes.string,
    origen_ciudad: PropTypes.string,
    origen_departamento: PropTypes.string,
    destino_direccion: PropTypes.string,
    destino_ciudad: PropTypes.string,
    destino_departamento: PropTypes.string,
    inicio_fecha_autorizacion: PropTypes.string,
    final_fecha_autorizacion: PropTypes.string,
    nombre_paciente: PropTypes.string,
    tipo_documento_paciente: PropTypes.string,
    numero_documento_paciente: PropTypes.string,
    telefono_responsable: PropTypes.string,
    empresa: PropTypes.string,
    observaciones: PropTypes.string,
    tipo_traslado: PropTypes.string,
    tipo_viaje: PropTypes.string,
    copago: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    copago_diario: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    estado_secundario: PropTypes.string, // Agregado
    estado_terciario: PropTypes.string, // Agregado
    fallidos: PropTypes.number, // Agregado
    cancelados: PropTypes.number, // Agregado
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreateNovedad: PropTypes.func.isRequired,
  onHistorialNovedades: PropTypes.func.isRequired,
  getBorderColor: PropTypes.func.isRequired,
  onHistorialAutorizacion: PropTypes.func.isRequired,
};

export default AuthorizationCard;
