// src/Components/modules/Authorizations/TrasladoDialog.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Select,
  DatePicker,
  TimePicker,
  message,
  Card,
  InputNumber,
} from "antd";
import { useUser } from "../../../UserContext";
import {
  UserOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  CarOutlined,
  AimOutlined,
  SwapOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import dayjs from "../../../utils/dayjs"; // Importar Day.js desde tu configuración personalizada
import MapDialog from "./MapDialog";
import AgendarDialog from "./AgendarDialog";
import "./TrasladoDialog.css";
import { formatCurrencyCOP } from "../../../utils/formatCurrency";

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

function TrasladoDialog({ open, onClose, onSubmit }) {
  const { user } = useUser();
  const [patients, setPatients] = useState([]);
  const [autorizaciones, setAutorizaciones] = useState([]);
  const [minFecha, setMinFecha] = useState("");
  const [maxFecha, setMaxFecha] = useState("");
  const [mapVisible, setMapVisible] = useState(false);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [distanceReturn, setDistanceReturn] = useState(null);
  const [durationReturn, setDurationReturn] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [isAgendarVisible, setIsAgendarVisible] = useState(false);
  const [isAgendarDisabled, setIsAgendarDisabled] = useState(true);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null); // Nueva variable de estado

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get("https://mogotaxsas.com/api/pacientes");
        setPatients(response.data);
        console.log("Pacientes cargados:", response.data); // Depuración
      } catch (error) {
        console.error("Error al obtener pacientes:", error);
      }
    };
    fetchPatients();
  }, []);

  // Función para calcular la edad a partir de la fecha de nacimiento
  const calculateAge = (fechaNacimiento) => {
    const birthDate = dayjs(fechaNacimiento);
    const today = dayjs();
    let age = today.year() - birthDate.year();
    if (
      today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())
    ) {
      age--;
    }
    return age;
  };

  const resetFormFields = () => {
    form.resetFields([
      "autorizacion",
      "preautorizacion",
      "tipo_autorizacion",
      "empresa",
      "proposito",
      "diagnostico_nombre",
      "copago",
      "tipo_traslado",
      "tipo_viaje",
      "traslados_restantes",
      "parentesco_responsable",
      "numero_contacto_responsable",
      "origen_direccion",
      "origen_departamento",
      "origen_ciudad",
      "origen_localidad",
      "origen_barrio",
      "origen_indicaciones",
      "origen_coordenadas",
      "destino_direccion",
      "destino_departamento",
      "destino_ciudad",
      "destino_localidad",
      "destino_barrio",
      "destino_indicaciones",
      "destino_coordenadas",
      "observaciones_autorizacion",
      "fecha",
      "hora_recogida",
      "hora_llegada",
      "hora_retorno_recogida",
      "hora_retorno_llegada",
    ]);
  };

  const patientOptions = useMemo(() => {
    return patients.map((patient) => {
      const nombre = patient.nombre || "Sin Nombre";
      const numeroDocumento = patient.numero_documento || "Sin Documento";
      return (
        <Option
          key={patient.id}
          value={patient.id}
          label={`${nombre} - ${numeroDocumento}`}
        >
          {`${nombre} - ${numeroDocumento}`}
        </Option>
      );
    });
  }, [patients]);

  const fetchPatientInfo = async (patient) => {
    try {
      const tipoResponse = await api.get(
        `https://mogotaxsas.com/api/tipos/${patient.tipo_id}`
      );
      const zonaResponse = await api.get(
        `https://mogotaxsas.com/api/zonas/${patient.zona_id}`
      );

      const tipoNombre = tipoResponse?.data?.nombre;
      const zonaNombre = zonaResponse?.data?.nombre;

      const calculatedAge = calculateAge(patient.fecha_nacimiento);

      form.setFieldsValue({
        nombre_paciente: patient.nombre,
        tipo_documento_paciente: patient.TipoDocumento?.descripcion || "",
        numero_documento_paciente: patient.numero_documento || "",
        tipo_usuario: tipoNombre || "",
        genero_paciente: patient.genero || "",
        numero_contacto_paciente: patient.telefono || "",
        zona_paciente: zonaNombre || "",
        edad_paciente: calculatedAge || "",
      });
    } catch (error) {
      console.error("Error al obtener la información del paciente:", error);
      form.setFieldsValue({
        nombre_paciente: patient.nombre,
        tipo_documento_paciente: patient.TipoDocumento?.descripcion || "",
        numero_documento_paciente: patient.numero_documento || "",
        tipo_usuario: "Desconocido",
        genero_paciente: patient.genero || "",
        numero_contacto_paciente: patient.telefono || "Desconocido",
      });
    }
  };

  const fetchPatientAuthorizations = async (patientId) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/autorizaciones/paciente/${patientId}`
      );
      setAutorizaciones(response.data);
    } catch (error) {
      console.error("Error al obtener autorizaciones:", error);
    }
  };

  const handlePatientChange = async (value) => {
    resetFormFields();

    setAutorizaciones([]);
    setMinFecha(null);
    setMaxFecha(null);
    setOrigin(null);
    setDestination(null);
    setSelectedDate(null); // Resetear selectedDate

    const selectedPatient = patients.find((p) => p.id === value);

    if (selectedPatient) {
      await fetchPatientInfo(selectedPatient);
      await fetchPatientAuthorizations(value);
    } else {
      form.resetFields();
      setAutorizaciones([]);
    }
  };

  const updateDistanceAndDuration = useCallback(async () => {
    // Obtener las coordenadas de origen y destino desde el formulario
    const originCoords =
      form.getFieldValue("origen_coordenadas")?.split(",") || [];
    const destinationCoords =
      form.getFieldValue("destino_coordenadas")?.split(",") || [];

    // Validar y parsear las coordenadas en el orden correcto: [lat, lng]
    const newOrigin =
      originCoords.length === 2
        ? { lat: parseFloat(originCoords[0]), lng: parseFloat(originCoords[1]) }
        : null;

    const newDestination =
      destinationCoords.length === 2
        ? {
            lat: parseFloat(destinationCoords[0]),
            lng: parseFloat(destinationCoords[1]),
          }
        : null;

    if (newOrigin && newDestination) {
      try {
        const response = await api.get(
          `https://mogotaxsas.com/osrm/route/v1/driving/${newOrigin.lng},${newOrigin.lat};${newDestination.lng},${newDestination.lat}?overview=false`
        );
        const route = response?.data?.routes[0];
        setDistance(route ? (route.distance / 1000).toFixed(2) : null);
        setDuration(route ? (route.duration / 60).toFixed(2) : null);
        setOrigin(newOrigin);
        setDestination(newDestination);

        const responseReturn = await api.get(
          `https://mogotaxsas.com/osrm/route/v1/driving/${newDestination.lng},${newDestination.lat};${newOrigin.lng},${newOrigin.lat}?overview=false`
        );
        const returnRoute = responseReturn?.data?.routes[0];
        setDistanceReturn(
          returnRoute ? (returnRoute.distance / 1000).toFixed(2) : null
        );
        setDurationReturn(
          returnRoute ? (returnRoute.duration / 60).toFixed(2) : null
        );
      } catch (error) {
        console.error("Error al calcular la ruta:", error);
        message.error(
          "No se pudo calcular la distancia y duración de la ruta."
        );
        setDistance(null);
        setDuration(null);
        setDistanceReturn(null);
        setDurationReturn(null);
      }
    }
  }, [form]);

  useEffect(() => {
    updateDistanceAndDuration();
  }, [updateDistanceAndDuration]);

  const fetchTraslados = async () => {
    try {
      const response = await api.get("https://mogotaxsas.com/api/traslados");
      console.log(response.data);
    } catch (error) {
      console.error("Error al obtener traslados:", error);
    }
  };

  const formFieldMapping = {
    autorizacion_id: "id",
    autorizacion: "numero_autorizacion",
    preautorizacion: "numero_pre_autorizacion",
    tipo_autorizacion: "tipo_autorizacion",
    empresa: "empresa",
    nombre_paciente: "nombre_paciente",
    proposito: "proposito",
    diagnostico_nombre: "diagnostico_nombre",
    copago: "copago",
    tipo_traslado: "tipo_traslado",
    tipo_viaje: "tipo_viaje",
    traslados_restantes: "traslados_restantes",
    parentesco_responsable: "parentesco_responsable",
    numero_contacto_responsable: "telefono_responsable",
    origen_direccion: "origen_direccion",
    origen_departamento: "origen_departamento",
    origen_ciudad: "origen_ciudad",
    origen_localidad: "origen_localidad",
    origen_barrio: "origen_barrio",
    origen_indicaciones: "origen_indicaciones",
    origen_coordenadas: "origen_coordenadas",
    destino_direccion: "destino_direccion",
    destino_departamento: "destino_departamento",
    destino_ciudad: "destino_ciudad",
    destino_localidad: "destino_localidad",
    destino_barrio: "destino_barrio",
    destino_indicaciones: "destino_indicaciones",
    destino_coordenadas: "destino_coordenadas",
    observaciones_autorizacion: "observaciones",
  };

  const findSelectedAutorizacion = (autorizaciones, value) => {
    return autorizaciones.find(
      (a) =>
        (a.id || `${a.numero_autorizacion}-${a.numero_pre_autorizacion}`) ===
        value
    );
  };

  const formatDate = (dateString) => dayjs(dateString).format("YYYY-MM-DD");

  const determineSelectedDate = (inicio, fin) => {
    const defaultDate = dayjs();
    return defaultDate.isBetween(dayjs(inicio), dayjs(fin), null, "[]")
      ? defaultDate
      : dayjs(inicio);
  };

  const processDates = (selectedAutorizacion) => {
    const inicio = formatDate(selectedAutorizacion.inicio_fecha_autorizacion);
    const fin = formatDate(selectedAutorizacion.final_fecha_autorizacion);
    const selectedDateLocal = determineSelectedDate(inicio, fin);

    return { inicio, fin, selectedDate: selectedDateLocal };
  };

  const formatCoordinates = (coordinates) => {
    return coordinates?.coordinates
      ? `${coordinates.coordinates[0]}, ${coordinates.coordinates[1]}`
      : "";
  };

  const getFormValues = (selectedAutorizacion) => {
    const formValues = {};

    Object.entries(formFieldMapping).forEach(([formKey, authKey]) => {
      if (authKey.includes("coordenadas")) {
        formValues[formKey] = formatCoordinates(selectedAutorizacion[authKey]);
      } else {
        formValues[formKey] = selectedAutorizacion[authKey] || "";
      }
    });

    return formValues;
  };

  const handleAutorizacionChange = (value) => {
    if (!value) {
      resetFormFields();
      setMinFecha(null);
      setMaxFecha(null);
      setOrigin(null);
      setDestination(null);
      setSelectedDate(null); // Resetear selectedDate
      return;
    }

    const selectedAutorizacion = findSelectedAutorizacion(
      autorizaciones,
      value
    );
    if (!selectedAutorizacion) return;

    const {
      inicio,
      fin,
      selectedDate: newSelectedDate,
    } = processDates(selectedAutorizacion);
    setMinFecha(inicio);
    setMaxFecha(fin);

    const formValues = getFormValues(selectedAutorizacion);

    // Establecer los valores del formulario incluyendo la fecha seleccionada
    form.setFieldsValue({ ...formValues, fecha: newSelectedDate });

    // Actualizar el estado de selectedDate
    setSelectedDate(newSelectedDate);

    updateDistanceAndDuration();
  };

  const swapOrigenDestino = () => {
    const origen = {
      direccion: form.getFieldValue("origen_direccion"),
      departamento: form.getFieldValue("origen_departamento"),
      ciudad: form.getFieldValue("origen_ciudad"),
      localidad: form.getFieldValue("origen_localidad"),
      barrio: form.getFieldValue("origen_barrio"),
      indicaciones: form.getFieldValue("origen_indicaciones"),
      coordenadas: form.getFieldValue("origen_coordenadas"),
    };

    const destino = {
      direccion: form.getFieldValue("destino_direccion"),
      departamento: form.getFieldValue("destino_departamento"),
      ciudad: form.getFieldValue("destino_ciudad"),
      localidad: form.getFieldValue("destino_localidad"),
      barrio: form.getFieldValue("destino_barrio"),
      indicaciones: form.getFieldValue("destino_indicaciones"),
      coordenadas: form.getFieldValue("destino_coordenadas"),
    };

    form.setFieldsValue({
      origen_direccion: destino.direccion,
      origen_departamento: destino.departamento,
      origen_ciudad: destino.ciudad,
      origen_localidad: destino.localidad,
      origen_barrio: destino.barrio,
      origen_indicaciones: destino.indicaciones,
      origen_coordenadas: destino.coordenadas,
      destino_direccion: origen.direccion,
      destino_departamento: origen.departamento,
      destino_ciudad: origen.ciudad,
      destino_localidad: origen.localidad,
      destino_barrio: origen.barrio,
      destino_indicaciones: origen.indicaciones,
      destino_coordenadas: origen.coordenadas,
    });

    updateDistanceAndDuration();
  };

  const validarEstadoAgendar = useCallback(() => {
    const fecha = form.getFieldValue("fecha");
    const horaRecogida = form.getFieldValue("hora_recogida");
    const horaLlegada = form.getFieldValue("hora_llegada");
    const horaRetornoRecogida = form.getFieldValue("hora_retorno_recogida");
    const horaRetornoLlegada = form.getFieldValue("hora_retorno_llegada");

    // Verificar si la fecha es válida usando encadenamiento opcional
    const esFechaValida = fecha?.isValid();

    // Verificar si las horas son válidas
    const horasValidas =
      (horaRecogida && horaLlegada) ||
      (horaRetornoRecogida && horaRetornoLlegada);

    // El botón "Agendar" debe estar deshabilitado si hay una fecha o no hay horas válidas
    setIsAgendarDisabled(esFechaValida || !horasValidas);
  }, [form]);

  // Llamar a validarEstadoAgendar cuando cambian los valores relevantes
  useEffect(() => {
    validarEstadoAgendar();
  }, [validarEstadoAgendar]);

  const handleTimeChange = () => {
    validarEstadoAgendar();
  };

  // Función de ayuda para verificar la diferencia de tiempo
  const isAtLeast15MinutesAfter = (earlier, later) => {
    if (!earlier || !later) return true; // Si alguno no está definido, no se valida aquí
    return later.diff(earlier, "minute") >= 15;
  };

  const onFinish = async (values) => {
    try {
      const usuario_id = user.id;

      const traslados = [];
      const origenCoords = form
        .getFieldValue("origen_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim())) || [0, 0];
      const destinoCoords = form
        .getFieldValue("destino_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim())) || [0, 0];

      const trasladoIda = {
        usuario_id,
        autorizacion_id: values.autorizacion_id,
        paciente_id: values.paciente_id,
        nombre_paciente: form.getFieldValue("nombre_paciente"),
        tipo_usuario: form.getFieldValue("tipo_usuario"),
        tipo_documento_paciente: form.getFieldValue("tipo_documento_paciente"),
        numero_documento_paciente: form.getFieldValue(
          "numero_documento_paciente"
        ),
        genero_paciente: form.getFieldValue("genero_paciente"),
        numero_contacto_paciente: form.getFieldValue(
          "numero_contacto_paciente"
        ),
        parentesco_responsable: form.getFieldValue("parentesco_responsable"),
        numero_contacto_responsable: form.getFieldValue(
          "numero_contacto_responsable"
        ),
        empresa: form.getFieldValue("empresa"),
        preautorizacion: form.getFieldValue("preautorizacion"),
        autorizacion: form.getFieldValue("autorizacion"),
        proposito: form.getFieldValue("proposito"),
        diagnostico_nombre: form.getFieldValue("diagnostico_nombre"),
        copago: form.getFieldValue("copago"),
        tipo_traslado: form.getFieldValue("tipo_traslado"),
        tipo_viaje: form.getFieldValue("tipo_viaje"),
        trayecto: "Ida",
        tipo_autorizacion: form.getFieldValue("tipo_autorizacion"),
        observaciones_autorizacion: form.getFieldValue(
          "observaciones_autorizacion"
        ),
        observaciones_traslado: values.observaciones_traslado || "",
        fecha: values.fecha.format("YYYY-MM-DD"),
        hora_recogida: values.hora_recogida.format("HH:mm"),
        hora_llegada: values.hora_llegada.format("HH:mm"),
        duracion_estimada: Math.round(duration),
        distancia_estimada: parseFloat(distance),
        vehiculo_id: null,
        conductor_id: null,
        status: "Programado",
        seguimiento: "Sin Validar",
        confirmacion: "Sin Confirmar",
        zona_paciente: form.getFieldValue("zona_paciente"),
        origen_direccion: form.getFieldValue("origen_direccion"),
        origen_departamento: form.getFieldValue("origen_departamento"),
        origen_ciudad: form.getFieldValue("origen_ciudad"),
        origen_localidad: form.getFieldValue("origen_localidad"),
        origen_barrio: form.getFieldValue("origen_barrio"),
        origen_indicaciones: form.getFieldValue("origen_indicaciones"),
        origen_coordenadas: {
          type: "Point",
          coordinates: [origenCoords[1], origenCoords[0]],
        },
        destino_direccion: form.getFieldValue("destino_direccion"),
        destino_departamento: form.getFieldValue("destino_departamento"),
        destino_ciudad: form.getFieldValue("destino_ciudad"),
        destino_localidad: form.getFieldValue("destino_localidad"),
        destino_barrio: form.getFieldValue("destino_barrio"),
        destino_indicaciones: form.getFieldValue("destino_indicaciones"),
        destino_coordenadas: {
          type: "Point",
          coordinates: [destinoCoords[1], destinoCoords[0]],
        },
      };
      traslados.push(trasladoIda);

      if (values.hora_retorno_recogida && values.hora_retorno_llegada) {
        const trasladoRetorno = {
          ...trasladoIda,
          trayecto: "Retorno",
          hora_recogida: values.hora_retorno_recogida.format("HH:mm"),
          hora_llegada: values.hora_retorno_llegada.format("HH:mm"),
          duracion_estimada: Math.round(durationReturn),
          distancia_estimada: parseFloat(distanceReturn),
          origen_direccion: form.getFieldValue("destino_direccion"),
          origen_departamento: form.getFieldValue("destino_departamento"),
          origen_ciudad: form.getFieldValue("destino_ciudad"),
          origen_localidad: form.getFieldValue("destino_localidad"),
          origen_barrio: form.getFieldValue("destino_barrio"),
          origen_indicaciones: form.getFieldValue("destino_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords[1], destinoCoords[0]],
          },
          destino_direccion: form.getFieldValue("origen_direccion"),
          destino_departamento: form.getFieldValue("origen_departamento"),
          destino_ciudad: form.getFieldValue("origen_ciudad"),
          destino_localidad: form.getFieldValue("origen_localidad"),
          destino_barrio: form.getFieldValue("origen_barrio"),
          destino_indicaciones: form.getFieldValue("origen_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [origenCoords[1], origenCoords[0]],
          },
        };
        traslados.push(trasladoRetorno);
      }

      for (const traslado of traslados) {
        await api.post("https://mogotaxsas.com/api/traslados", traslado);
      }

      message.success("Traslados guardados exitosamente.");
      onSubmit();
      onClose();
    } catch (error) {
      console.error("Error al guardar traslados:", error);
      message.error("Ocurrió un error al guardar los traslados.");
    }
  };

  const handleOpenMap = () => {
    if (origin && destination) {
      setMapVisible(true);
    } else {
      message.error("Coordenadas no válidas. Por favor verifique los datos.");
    }
  };

  const handleCloseMap = () => {
    setMapVisible(false);
  };

  const handleAgendarClick = () => {
    setIsAgendarVisible(true);
  };

  const handleAgendarClose = () => {
    setIsAgendarVisible(false);
    fetchTraslados();
    onSubmit();
  };

  // Funciones para deshabilitar horas y minutos en TimePicker usando 'disabledTime'
  const disabledTimeFunc = useCallback(
    (current) => {
      // Verificar si selectedDate está definido y es el mismo día que hoy usando encadenamiento opcional
      if (selectedDate?.isSame(dayjs(), "day")) {
        const now = dayjs();
        const currentHour = now.hour();
        const currentMinute = now.minute();

        return {
          disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === currentHour) {
              return Array.from({ length: currentMinute }, (_, i) => i);
            }
            return [];
          },
          disabledSeconds: () => [],
        };
      }
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    },
    [selectedDate]
  );

  // Función auxiliar para formatear la duración
  const formatDuration = (duration) => {
    if (!duration) return "";
    const totalMinutes = Math.round(parseFloat(duration));
    if (totalMinutes < 60) {
      return `${totalMinutes} minutos`;
    } else {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} h ${minutes} min`;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title={
          <div className="traslado-modal-header">
            Nueva Programación de Traslado
          </div>
        }
        footer={[
          <Button
            key="agendar"
            type="default"
            onClick={handleAgendarClick}
            disabled={isAgendarDisabled}
            className="traslado-button agendar-button"
          >
            Agendar
          </Button>,
          <Button
            key="cancel"
            onClick={onClose}
            className="traslado-button cancelar-button"
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            className="traslado-button programar-button"
          >
            Programar
          </Button>,
        ]}
        width="90%"
        centered
        destroyOnClose
        className="traslado-modal"
      >
        <div className="traslado-modal-content">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={(changedValues, allValues) => {
              if (changedValues.fecha !== undefined) {
                setSelectedDate(changedValues.fecha);
              }
            }}
          >
            {/* Selección de Paciente y Autorización */}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title level={4} className="traslado-section-title">
                  <UserOutlined className="traslado-section-icon" />
                  Seleccione Paciente
                </Title>

                <Form.Item
                  name="paciente_id"
                  label="Paciente"
                  rules={[
                    { required: true, message: "Seleccione un paciente" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seleccione un paciente"
                    onChange={handlePatientChange}
                    optionFilterProp="label"
                    className="traslado-select"
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      const label = option.label
                        ? option.label.toLowerCase()
                        : "";
                      const searchInput = input.toLowerCase();
                      return label.includes(searchInput);
                    }}
                  >
                    {patientOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Title level={4} className="traslado-section-title">
                  <FileTextOutlined className="traslado-section-icon" />
                  Seleccione Autorización
                </Title>

                {/* Campo oculto separado */}
                <Form.Item name="autorizacion_id" hidden>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item
                  name="autorizacion"
                  label="Número de Autorización"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione una autorización o preautorización",
                      validator: (_, value) => {
                        if (value || form.getFieldValue("preautorizacion")) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Seleccione una autorización o preautorización"
                          )
                        );
                      },
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Seleccione una autorización"
                    onChange={handleAutorizacionChange}
                    optionFilterProp="children"
                    className="traslado-select"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      typeof option.children === "string" &&
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {autorizaciones.map((a) => {
                      const tipoLabels = [];
                      if (a.numero_autorizacion) {
                        tipoLabels.push(`AUT: ${a.numero_autorizacion}`);
                      }
                      if (a.numero_pre_autorizacion) {
                        tipoLabels.push(`PRE: ${a.numero_pre_autorizacion}`);
                      }
                      const tipoLabel = tipoLabels.join(", ");
                      const fechaInicio = dayjs(
                        a.inicio_fecha_autorizacion
                      ).format("DD/MM/YY");
                      const fechaFin = dayjs(a.final_fecha_autorizacion).format(
                        "DD/MM/YY"
                      );

                      const key =
                        a.id ||
                        `${a.numero_autorizacion}-${a.numero_pre_autorizacion}`;

                      return (
                        <Option key={key} value={key}>
                          {`${tipoLabel} - ${fechaInicio} - ${fechaFin}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Información del Usuario */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="nombre_paciente"
                      label="Nombre del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="tipo_documento_paciente"
                      label="Tipo de Documento"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="numero_documento_paciente"
                      label="Número de Documento"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="tipo_usuario" label="Tipo de Usuario">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="edad_paciente" label="Edad del Paciente">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="genero_paciente"
                      label="Género del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item
                      name="numero_contacto_paciente"
                      label="Teléfono del Paciente"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Form.Item name="zona_paciente" label="Zona del Paciente">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Información de Autorización */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Title level={4} className="traslado-section-title">
                  <InfoCircleOutlined className="traslado-section-icon" />
                  Información de Autorización
                </Title>

                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="preautorizacion" label="Preautorización">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="tipo_autorizacion"
                      label="Tipo de Autorización"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="autorizacion"
                      label="Número de Autorización"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="observaciones_autorizacion"
                      label="Observaciones de la Autorización"
                    >
                      <TextArea disabled rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Información General */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Title level={4} className="traslado-section-title">
                  <CarOutlined className="traslado-section-icon" />
                  Información General
                </Title>

                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="tipo_traslado" label="Tipo de Traslado">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="tipo_viaje" label="Tipo de Viaje">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item
                      name="traslados_restantes"
                      label="Traslados Restantes"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="observaciones_traslado"
                      label="Observaciones del Traslado"
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Tarjetas de Distancia y Duración */}
            <Row gutter={[16, 16]} className="traslado-cards-row">
              <Col xs={24} sm={12}>
                <Card
                  title={
                    <span>
                      <EnvironmentOutlined className="traslado-card-icon" />
                      Distancia Aproximada
                    </span>
                  }
                  bordered={false}
                  className="traslado-card"
                >
                  <p className="traslado-card-text">
                    Ida: {distance ? `${distance} km` : "Esperando..."}
                  </p>
                  <p className="traslado-card-text">
                    Retorno:{" "}
                    {distanceReturn ? `${distanceReturn} km` : "Esperando..."}
                  </p>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card
                  title={
                    <span>
                      <ClockCircleOutlined className="traslado-card-icon" />
                      Tiempo Aproximado
                    </span>
                  }
                  bordered={false}
                  className="traslado-card"
                >
                  <p className="traslado-card-text">
                    Ida: {duration ? formatDuration(duration) : "Esperando..."}
                  </p>
                  <p className="traslado-card-text">
                    Retorno:{" "}
                    {durationReturn
                      ? formatDuration(durationReturn)
                      : "Esperando..."}
                  </p>
                </Card>
              </Col>
            </Row>

            {/* Información Médica */}
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Title level={4} className="traslado-section-title">
                  <MedicineBoxOutlined className="traslado-section-icon" />
                  Información Médica
                </Title>

                <Form.Item name="empresa" label="Empresa">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="proposito" label="Propósito">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="diagnostico_nombre" label="Diagnóstico">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="copago" label="Copago Total">
                  <InputNumber
                    min={0}
                    name="copago"
                    value={form.getFieldValue("copago") || 0}
                    onChange={(value) => form.setFieldsValue({ copago: value })}
                    style={{ width: "100%" }}
                    formatter={(value) => formatCurrencyCOP(value)}
                    parser={(value) =>
                      value
                        ? Number(
                            value
                              .replace(/[^0-9.,-]/g, "") // Elimina caracteres no numéricos
                              .replace(/\./g, "") // Elimina separadores de miles
                              .replace(/,/g, ".") // Reemplaza la coma decimal por punto
                          )
                        : 0
                    }
                    precision={2} // Asegura dos decimales
                    step={1000} // Incremento opcional de mil pesos
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  name="parentesco_responsable"
                  label="Parentesco del Responsable"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="numero_contacto_responsable"
                  label="Teléfono del Responsable"
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Información del Traslado
                </Title>
                <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                  <Col xs={24}>
                    {/* Título de la sección */}
                    <Typography level={5} style={{ marginBottom: "8px" }}>
                      Fechas de la Autorización
                    </Typography>

                    {/* Contenedor de fechas */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#f0f2f5",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                      }}
                    >
                      <Typography.Text
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        <strong>Inicio:</strong>{" "}
                        {minFecha
                          ? dayjs(minFecha).format("DD/MM/YYYY")
                          : "No definida"}
                      </Typography.Text>
                      <Typography.Text
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        <strong>Fin:</strong>{" "}
                        {maxFecha
                          ? dayjs(maxFecha).format("DD/MM/YYYY")
                          : "No definida"}
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>

                <Form.Item name="fecha" label="Fecha">
                  <DatePicker
                    onChange={() => {
                      validarEstadoAgendar(); // Llamada adicional aquí
                    }}
                    className="traslado-datepicker"
                    disabledDate={(currentDate) => {
                      return (
                        currentDate && currentDate < dayjs().startOf("day")
                      );
                    }}
                  />
                </Form.Item>

                {/* Hora de Recogida */}
                <Form.Item
                  name="hora_recogida"
                  label="Hora de Recogida"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la hora de recogida",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaLlegada = getFieldValue("hora_llegada");
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }

                        // Validar que hora_recogida <= hora_llegada
                        if (horaLlegada?.isBefore(value)) {
                          return Promise.reject(
                            new Error(
                              "La hora de recogida no puede ser después de la hora de llegada"
                            )
                          );
                        }

                        // Validar que hora_retorno_recogida >= hora_recogida + 15 minutos
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(value, horaRetornoRecogida)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de recogida"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc} // Función actualizada
                  />
                </Form.Item>

                {/* Hora de Llegada */}
                <Form.Item
                  name="hora_llegada"
                  label="Hora de Llegada"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la hora de llegada",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaRecogida = getFieldValue("hora_recogida");
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }

                        // Validar que hora_llegada >= hora_recogida + 15 minutos
                        if (
                          horaRecogida &&
                          !isAtLeast15MinutesAfter(horaRecogida, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de llegada debe ser al menos 15 minutos después de la hora de recogida"
                            )
                          );
                        }

                        // Validar que hora_retorno_recogida >= hora_llegada + 15 minutos
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(value, horaRetornoRecogida)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de llegada"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc} // Función actualizada
                  />
                </Form.Item>

                {/* Hora de Retorno (Recogida) */}
                <Form.Item
                  name="hora_retorno_recogida"
                  label="Hora de Retorno (Recogida)"
                  rules={[
                    {
                      required: false,
                      message: "Seleccione la hora de retorno de recogida",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaLlegada = getFieldValue("hora_llegada");
                        const horaRetornoLlegada = getFieldValue(
                          "hora_retorno_llegada"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }

                        // Validar que hora_retorno_recogida >= hora_llegada + 15 minutos
                        if (
                          horaLlegada &&
                          !isAtLeast15MinutesAfter(horaLlegada, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos después de la hora de llegada"
                            )
                          );
                        }

                        // Validar que hora_retorno_recogida <= hora_retorno_llegada - 15 minutos
                        if (
                          horaRetornoLlegada &&
                          !isAtLeast15MinutesAfter(value, horaRetornoLlegada)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de recogida debe ser al menos 15 minutos antes de la hora de llegada de retorno"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc} // Función actualizada
                  />
                </Form.Item>

                {/* Hora de Retorno (Llegada) */}
                <Form.Item
                  name="hora_retorno_llegada"
                  label="Hora de Retorno (Llegada)"
                  rules={[
                    {
                      required: false,
                      message: "Seleccione la hora de retorno de llegada",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const horaRetornoRecogida = getFieldValue(
                          "hora_retorno_recogida"
                        );
                        if (!value) {
                          return Promise.resolve();
                        }

                        // Validar que hora_retorno_llegada >= hora_retorno_recogida + 15 minutos
                        if (
                          horaRetornoRecogida &&
                          !isAtLeast15MinutesAfter(horaRetornoRecogida, value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "La hora de retorno de llegada debe ser al menos 15 minutos después de la hora de retorno de recogida"
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    className="traslado-timepicker"
                    format="HH:mm"
                    onChange={handleTimeChange}
                    disabledTime={disabledTimeFunc} // Función actualizada
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Información de Origen y Destino */}
            <Row gutter={[16, 16]}>
              {/* Información de Origen */}
              <Col xs={24} sm={11}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Origen
                </Title>

                <Form.Item name="origen_direccion" label="Dirección">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_departamento" label="Departamento">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_ciudad" label="Ciudad/Municipio">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_localidad" label="Localidad">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_barrio" label="Barrio">
                  <Input />
                </Form.Item>
                <Form.Item name="origen_indicaciones" label="Indicaciones">
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item name="origen_coordenadas" label="Coordenadas">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={2} className="traslado-swap-col">
                <Button
                  icon={<SwapOutlined />}
                  size="large"
                  shape="circle"
                  onClick={swapOrigenDestino}
                />
              </Col>

              {/* Información de Destino */}
              <Col xs={24} sm={11}>
                <Title level={4} className="traslado-section-title">
                  <AimOutlined className="traslado-section-icon" />
                  Destino
                </Title>

                <Form.Item name="destino_direccion" label="Dirección">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_departamento" label="Departamento">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_ciudad" label="Ciudad/Municipio">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_localidad" label="Localidad">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_barrio" label="Barrio">
                  <Input />
                </Form.Item>
                <Form.Item name="destino_indicaciones" label="Indicaciones">
                  <TextArea rows={2} />
                </Form.Item>
                <Form.Item name="destino_coordenadas" label="Coordenadas">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            {/* Botón para Ver Ruta en el Mapa */}
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Button
                  type="primary"
                  onClick={handleOpenMap}
                  className="traslado-map-button"
                  disabled={!origin || !destination}
                >
                  Ver ruta en el mapa
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <MapDialog
        open={mapVisible}
        onClose={handleCloseMap}
        origin={origin || { lat: 0, lng: 0 }} // Valor predeterminado si es null
        destination={destination || { lat: 0, lng: 0 }} // Valor predeterminado si es null
      />

      {/* Modal de Agendar */}
      <AgendarDialog
        open={isAgendarVisible}
        onClose={handleAgendarClose}
        onCloseParentDialog={onClose}
        form={form}
        minFecha={minFecha || dayjs().format("YYYY-MM-DD")} // Valor predeterminado
        maxFecha={maxFecha || dayjs().add(1, "year").format("YYYY-MM-DD")} // Valor predeterminado
        distance={distance || 0} // Valor predeterminado
        duration={duration || 0} // Valor predeterminado
        distanceReturn={distanceReturn || 0} // Valor predeterminado
        durationReturn={durationReturn || 0} // Valor predeterminado
      />
    </>
  );
}

// Validación de las props con prop-types
TrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired, // 'open' es un booleano requerido
  onClose: PropTypes.func.isRequired, // 'onClose' es una función requerida
  onSubmit: PropTypes.func.isRequired, // 'onSubmit' es una función requerida
};

export default TrasladoDialog;
