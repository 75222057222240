// src/hooks/useHandleDepartamentoChange.js

import { useState, useCallback } from "react";
import api from "../axiosConfig";
import { message } from "antd";

const useHandleDepartamentoChange = (form) => {
  const [ciudades, setCiudades] = useState([]);
  const [loadingCiudades, setLoadingCiudades] = useState(false);

  const handleDepartamentoChange = useCallback(
    async (value) => {
      if (!value) {
        setCiudades([]);
        form.setFieldsValue({ ciudad_id: null });
        return;
      }

      setLoadingCiudades(true);
      try {
        const response = await api.get(`/ciudades?departamento_id=${value}`);
        setCiudades(
          Array.isArray(response.data) ? response.data : response.data.data
        );
        form.setFieldsValue({ ciudad_id: null }); // Resetear ciudad
      } catch (error) {
        console.error("Error al obtener ciudades:", error);
        message.error("Error al obtener ciudades");
      } finally {
        setLoadingCiudades(false);
      }
    },
    [form]
  );

  return { ciudades, loadingCiudades, handleDepartamentoChange };
};

export default useHandleDepartamentoChange;
