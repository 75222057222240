import React from "react";
import { Typography, Box } from "@mui/material";

const MessageBox = ({ message, severity }) => (
  <Box mt={2}>
    <Typography variant="h6" color={severity === "error" ? "error" : "primary"}>
      {message}
    </Typography>
  </Box>
);

export default MessageBox;
