// src/Components/modules/Coordinacion/Coordinacion.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Layout } from "antd";
import api from "../../../axiosConfig";
import MovilesColumn from "./MovilesColumn";
import TrasladosColumn from "./TrasladosColumn";
import ZonasColumn from "./ZonasColumn";
import MenuContextualTraslado from "./MenuContextualTraslado";
import SnackbarMensaje from "./SnackbarMensaje";
import TrasladosFilter from "./TrasladosFilter";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import { useUser } from "../../../UserContext";
import { useSocket } from "../../../SocketContext"; // Importar el hook useSocket

const { Content } = Layout;

const Coordinacion = () => {
  // Estados para vehículos y traslados
  const [moviles, setMoviles] = useState([]);
  const [traslados, setTraslados] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [selectedMovil, setSelectedMovil] = useState(null);

  // Estados para menú contextual y traslado seleccionado
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedTraslado, setSelectedTraslado] = useState(null);

  // Estados para Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Estados para los filtros
  const [searchTermMoviles, setSearchTermMoviles] = useState("");
  const [selectedVehicleCode, setSelectedVehicleCode] = useState("");
  const [selectedConfirmacion, setSelectedConfirmacion] = useState("");
  const [selectedValidacion, setSelectedValidacion] = useState("");
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("daily");
  const [fechaInicio, setFechaInicio] = useState(dayjs().startOf("day"));
  const [fechaFin, setFechaFin] = useState(dayjs().endOf("day"));
  const [loadingTraslados, setLoadingTraslados] = useState(false);

  const { user } = useUser();
  const { socket } = useSocket(); // Obtener el socket desde el contexto

  const statuses = [
    "Programado",
    "Pre-Asignado",
    "Asignado",
    "Enterado",
    "Aceptado",
    "En Camino",
    "Esperando",
    "Iniciado",
    "Fallido",
    "Cancelado",
    "Finalizado",
  ];

  // Estado para los traslados seleccionados
  const [selectedTraslados, setSelectedTraslados] = useState([]);
  // Estado para el estado de los traslados seleccionados
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Función para obtener vehículos y conductores
  const fetchMovilesYConductores = useCallback(async () => {
    try {
      // Obtener los móviles
      const responseMoviles = await api.get(
        "https://mogotaxsas.com/api/vehiculos/codigo-placa"
      );
      const movilesData = responseMoviles.data;

      console.group("Datos de vehículos cargados");
      console.table(movilesData);
      console.groupEnd();

      // Obtener los conductores
      const responseConductores = await api.get(
        "https://mogotaxsas.com/api/vehiculo-conductores/basic-info"
      );
      const conductoresData = responseConductores.data;

      console.group("Datos de conductores cargados");
      console.table(conductoresData);
      console.groupEnd();

      // Asignar conductores a móviles
      const updatedMoviles = movilesData.map((movil) => {
        const conductorAsignado = conductoresData.find(
          (conductor) =>
            conductor.vehiculo_id === movil.id && conductor.es_principal
        );
        return {
          ...movil,
          Conductor: conductorAsignado ? conductorAsignado.Conductor : null,
        };
      });

      console.group("Vehículos con conductores asignados");
      console.table(updatedMoviles);
      console.groupEnd();

      setMoviles(updatedMoviles);
    } catch (error) {
      console.error("Error al cargar los vehículos y conductores:", error);
    }
  }, []);

  // Función para obtener traslados
  const fetchTraslados = useCallback(async () => {
    setLoadingTraslados(true);
    try {
      const fechaInicioISO = fechaInicio.format("YYYY-MM-DD");
      const fechaFinISO = fechaFin.format("YYYY-MM-DD");
      const { data } = await api.get("https://mogotaxsas.com/api/traslados", {
        params: {
          fechaInicio: fechaInicioISO,
          fechaFin: fechaFinISO,
        },
      });

      console.group("Datos de traslados cargados");
      console.table(data.rows);
      console.groupEnd();

      setTraslados(data.rows);
    } catch (error) {
      console.error("Error al cargar los traslados:", error);
    } finally {
      setLoadingTraslados(false);
    }
  }, [fechaInicio, fechaFin]);

  // Función para obtener las zonas
  useEffect(() => {
    const fetchZonas = async () => {
      try {
        const response = await api.get("https://mogotaxsas.com/api/zonas");
        setZonas(response.data);
      } catch (error) {
        console.error("Error al cargar las zonas:", error);
      }
    };
    fetchZonas();
  }, []);

  // Configurar debounce para fetchTraslados
  const debouncedFetchTraslados = useMemo(
    () => debounce(fetchTraslados, 300),
    [fetchTraslados]
  );

  // useEffect para obtener vehículos y traslados al montar el componente
  useEffect(() => {
    fetchMovilesYConductores();
    debouncedFetchTraslados();

    // Cancelar el debounce al desmontar el componente
    return () => {
      debouncedFetchTraslados.cancel();
    };
  }, [fetchMovilesYConductores, debouncedFetchTraslados]);

  // Filtrar traslados según los filtros aplicados
  const filteredTraslados = useMemo(() => {
    let filtered = traslados;

    // Filtrar por código de vehículo
    if (selectedVehicleCode) {
      filtered = filtered.filter(
        (traslado) => traslado.Vehiculo?.codigo === selectedVehicleCode
      );
    }

    // Filtrar por confirmación
    if (selectedConfirmacion) {
      filtered = filtered.filter(
        (traslado) => traslado.confirmacion === selectedConfirmacion
      );
    }

    // Filtrar por validación
    if (selectedValidacion) {
      filtered = filtered.filter(
        (traslado) => traslado.seguimiento === selectedValidacion
      );
    }

    // Filtrar por zona
    if (selectedZona) {
      filtered = filtered.filter(
        (traslado) => traslado.zona_paciente === selectedZona
      );
    }

    // Filtrar por estados
    if (selectedStatuses.length > 0) {
      filtered = filtered.filter((traslado) =>
        selectedStatuses.includes(traslado.status)
      );
    }

    return filtered;
  }, [
    traslados,
    selectedVehicleCode,
    selectedConfirmacion,
    selectedValidacion,
    selectedZona,
    selectedStatuses,
  ]);

  // Filtrar móviles según el término de búsqueda
  const filteredMoviles = useMemo(() => {
    if (!searchTermMoviles) return moviles;
    const term = searchTermMoviles.toLowerCase();
    return moviles.filter(
      (movil) =>
        movil.codigo.toLowerCase().includes(term) ||
        movil.placa.toLowerCase().includes(term)
    );
  }, [moviles, searchTermMoviles]);

  // Handler para cambiar el período de filtrado
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    let startDate;
    let endDate;
    const now = dayjs();

    switch (period) {
      case "currentWeek":
        startDate = now.startOf("week");
        endDate = now.endOf("week");
        break;
      case "previousWeek":
        startDate = now.subtract(1, "week").startOf("week");
        endDate = now.subtract(1, "week").endOf("week");
        break;
      case "currentMonth":
        startDate = now.startOf("month");
        endDate = now.endOf("month");
        break;
      case "previousMonth":
        startDate = now.subtract(1, "month").startOf("month");
        endDate = now.subtract(1, "month").endOf("month");
        break;
      case "daily":
      default:
        startDate = now.startOf("day");
        endDate = now.endOf("day");
        break;
    }

    setFechaInicio(startDate);
    setFechaFin(endDate);
  };

  // Handlers para cambiar la fecha (solo si el período es diario)
  const handlePrevDay = () => {
    if (selectedPeriod === "daily") {
      const newStart = fechaInicio.subtract(1, "day").startOf("day");
      const newEnd = fechaFin.subtract(1, "day").endOf("day");
      setFechaInicio(newStart);
      setFechaFin(newEnd);
    }
  };

  const handleNextDay = () => {
    if (selectedPeriod === "daily") {
      const newStart = fechaInicio.add(1, "day").startOf("day");
      const newEnd = fechaFin.add(1, "day").endOf("day");
      setFechaInicio(newStart);
      setFechaFin(newEnd);
    }
  };

  const handleDateChange = (date) => {
    if (date && selectedPeriod === "daily") {
      setFechaInicio(date.startOf("day"));
      setFechaFin(date.endOf("day"));
    }
  };

  // Funciones de manejo de menú contextual
  const handleContextMenu = (event, traslado) => {
    event.preventDefault();
    setSelectedTraslado(traslado);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Funciones para manejar opciones del menú contextual
  const handleOptionClick = (option) => {
    if (option === "copyPhone") {
      handleCopyToClipboard(selectedTraslado?.numero_contacto_paciente);
    } else if (option === "copyResponsiblePhone") {
      handleCopyToClipboard(selectedTraslado?.numero_contacto_responsable);
    }
  };

  // Función para copiar al portapapeles
  const handleCopyToClipboard = (phone) => {
    if (phone) {
      navigator.clipboard
        .writeText(phone)
        .then(() => {
          setSnackbarMessage(`Teléfono ${phone} copiado al portapapeles`);
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error("Error al copiar al portapapeles:", err);
          setSnackbarMessage("Error al copiar el teléfono.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      console.warn("No hay número de teléfono para copiar.");
      setSnackbarMessage("No hay número de teléfono para copiar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }
    handleCloseContextMenu();
  };

  // Función para cerrar el Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Función para actualizar un traslado en el estado
  const actualizarTraslado = (trasladoId, nuevosDatos) => {
    setTraslados((trasladosPrevios) =>
      trasladosPrevios.map((traslado) =>
        traslado.id === trasladoId ? { ...traslado, ...nuevosDatos } : traslado
      )
    );
  };

  // Función auxiliar para actualizar los traslados en el estado
  const actualizarTrasladosEnEstado = (
    prevTraslados,
    trasladosActualizados
  ) => {
    return prevTraslados.map((traslado) => {
      const actualizado = trasladosActualizados.find(
        (t) => t.id === traslado.id
      );
      if (actualizado) {
        // Asegurar que si el vehículo no viene en la respuesta, se mantenga el existente
        return {
          ...traslado,
          ...actualizado,
          nombre_vehiculo:
            actualizado.nombre_vehiculo || traslado.nombre_vehiculo,
          placa: actualizado.placa || traslado.placa,
          conductor_id: actualizado.conductor_id || traslado.conductor_id,
          nombre_conductor:
            actualizado.nombre_conductor || traslado.nombre_conductor,
        };
      }
      return traslado;
    });
  };

  // Función para asignar un móvil a un traslado individual
  const handleAsignarMovil = useCallback((trasladoId, nuevosDatos) => {
    setTraslados((prevTraslados) =>
      prevTraslados.map((traslado) =>
        traslado.id === trasladoId ? { ...traslado, ...nuevosDatos } : traslado
      )
    );
  }, []);

  // Función para manejar la asignación múltiple
  const handleAsignarMultiple = async (trasladosIds, status) => {
    let endpoint = "";
    let statusToAssign = "";

    if (status === "Programado") {
      if (!selectedMovil) {
        // No podemos proceder sin móvil para "Programado"
        setSnackbarMessage(
          "Debe seleccionar un móvil para pre-asignar traslados."
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }
      endpoint = "https://mogotaxsas.com/api/traslados/asignar-multiple";
      statusToAssign = "Pre-Asignado";
    } else if (status === "Pre-Asignado") {
      endpoint =
        "https://mogotaxsas.com/api/traslados/asignar-multiple-asignado";
      statusToAssign = "Asignado";
    } else {
      // Manejar otros estados o retornar
      return;
    }

    try {
      const dataToSend = {
        traslados_ids: trasladosIds,
        usuario_id: user.id,
        status: statusToAssign,
      };

      if (status === "Programado") {
        // Agregamos datos del móvil si estamos pre-asignando
        dataToSend.vehiculo_id = selectedMovil.id;
        dataToSend.nombre_vehiculo = selectedMovil.codigo;
        dataToSend.placa = selectedMovil.placa;
        dataToSend.conductor_id = selectedMovil.Conductor?.id ?? null;
        dataToSend.nombre_conductor = selectedMovil.Conductor?.nombre ?? null;
      }

      const response = await api.put(endpoint, dataToSend);

      // Actualizar el estado local de los traslados
      const trasladosActualizados = response.data.traslados;

      setTraslados((prevTraslados) =>
        actualizarTrasladosEnEstado(prevTraslados, trasladosActualizados)
      );

      // Mostrar mensaje de éxito
      setSnackbarMessage(`Traslados ${statusToAssign} correctamente.`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Limpiar los traslados seleccionados y el estado seleccionado
      setSelectedTraslados([]);
      setSelectedStatus(null);

      // Si deseleccionamos el móvil al seleccionar traslados "Pre-Asignado", podemos volver a seleccionar el móvil aquí
      if (status === "Programado") {
        setSelectedMovil(null);
      }
    } catch (error) {
      console.error(`Error al ${statusToAssign} los traslados:`, error);
      // Mostrar mensaje de error
      setSnackbarMessage(`Error al ${statusToAssign} los traslados.`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Función para manejar eventos de traslado_creado
  const handleTrasladoCreado = useCallback((data) => {
    const newTraslado = data.traslado;
    setTraslados((prevTraslados) => [newTraslado, ...prevTraslados]);
  }, []);

  // Función para manejar eventos de traslado_actualizado
  const handleTrasladoActualizado = useCallback((data) => {
    const updatedTraslado = data.traslado;
    setTraslados((prevTraslados) =>
      prevTraslados.map((t) =>
        t.id === updatedTraslado.id ? updatedTraslado : t
      )
    );
  }, []);

  // Función para manejar eventos de traslado_eliminado
  const handleTrasladoEliminado = useCallback((data) => {
    const trasladoId = data.trasladoId;
    setTraslados((prevTraslados) =>
      prevTraslados.filter((t) => t.id !== trasladoId)
    );
  }, []);
  // useEffect para escuchar eventos de Socket.IO
  useEffect(() => {
    if (!socket) return;

    // Registrar los listeners
    socket.on("traslado_creado", handleTrasladoCreado);
    socket.on("traslado_actualizado", handleTrasladoActualizado);
    socket.on("traslado_eliminado", handleTrasladoEliminado);

    // Cleanup en el desmontaje del componente
    return () => {
      socket.off("traslado_creado", handleTrasladoCreado);
      socket.off("traslado_actualizado", handleTrasladoActualizado);
      socket.off("traslado_eliminado", handleTrasladoEliminado);
    };
  }, [
    socket,
    handleTrasladoCreado,
    handleTrasladoActualizado,
    handleTrasladoEliminado,
  ]);

  return (
    <Layout>
      <Content style={{ padding: "24px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          {/* Componente de Filtro */}
          <Col span={24}>
            <TrasladosFilter
              onSearch={setSearchTermMoviles}
              onVehicleFilter={setSelectedVehicleCode}
              onConfirmacionFilter={setSelectedConfirmacion}
              onValidacionFilter={setSelectedValidacion}
              onZonaFilter={setSelectedZona}
              onStatusFilter={setSelectedStatuses}
              onDateChange={handleDateChange}
              onPrevDay={handlePrevDay}
              onNextDay={handleNextDay}
              onPeriodChange={handlePeriodChange}
              selectedPeriod={selectedPeriod}
              selectedDate={selectedPeriod === "daily" ? fechaInicio : null}
              moviles={moviles}
              zonas={zonas}
              statuses={statuses}
            />
          </Col>
        </Row>

        {/* Contenedor de las columnas */}
        <Row gutter={[16, 16]}>
          {/* Columna de Móviles */}
          <Col xs={24} sm={24} md={4}>
            <MovilesColumn
              moviles={filteredMoviles}
              searchTerm={searchTermMoviles}
              setSearchTerm={setSearchTermMoviles}
              selectedMovilId={selectedMovil?.id}
              onSelectMovil={setSelectedMovil}
            />
          </Col>

          {/* Columna de Traslados */}
          <Col xs={24} sm={24} md={16}>
            <TrasladosColumn
              traslados={filteredTraslados}
              handleContextMenu={handleContextMenu}
              onAsignarMovil={handleAsignarMovil}
              onAsignarMultiple={handleAsignarMultiple}
              selectedMovil={selectedMovil}
              setSelectedMovil={setSelectedMovil} // Pasamos setSelectedMovil
              loading={loadingTraslados}
              selectedTraslados={selectedTraslados}
              setSelectedTraslados={setSelectedTraslados}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
            />
          </Col>

          {/* Columna de Zonas */}
          <Col xs={24} sm={24} md={4}>
            <ZonasColumn traslados={filteredTraslados} />
          </Col>
        </Row>

        {/* Menú contextual y Snackbar */}
        {selectedTraslado && (
          <MenuContextualTraslado
            contextMenu={contextMenu}
            handleCloseContextMenu={handleCloseContextMenu}
            handleOptionClick={handleOptionClick}
            trasladoId={selectedTraslado.id}
            traslado={selectedTraslado}
            onEstadoCambiado={actualizarTraslado}
            origin={{
              lat: selectedTraslado?.origen_coordenadas?.coordinates?.[0],
              lng: selectedTraslado?.origen_coordenadas?.coordinates?.[1],
            }}
            destination={{
              lat: selectedTraslado?.destino_coordenadas?.coordinates?.[0],
              lng: selectedTraslado?.destino_coordenadas?.coordinates?.[1],
            }}
            selectedTraslados={selectedTraslados}
            onAsignarMultiple={handleAsignarMultiple}
            selectedMovil={selectedMovil}
            selectedStatus={selectedStatus}
          />
        )}

        <SnackbarMensaje
          snackbarOpen={snackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Content>
    </Layout>
  );
};

export default Coordinacion;
