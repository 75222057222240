// src/Components/modules/authorizations/Form/ResponsibleInfo.js

import React from "react";
import { Input, Form, Divider } from "antd";
import PropTypes from "prop-types";

const ResponsibleInfo = ({ form }) => (
  <>
    <Divider />

    <Form.Item label="Nombre del Responsable">
      <Input
        name="nombre_responsable"
        value={form.nombre_responsable || ""}
        disabled
      />
    </Form.Item>

    <Form.Item label="Parentesco del Responsable">
      <Input
        name="parentesco_responsable"
        value={form.parentesco_responsable || ""}
        disabled
      />
    </Form.Item>

    <Form.Item label="Teléfono del Responsable">
      <Input
        name="telefono_responsable"
        value={form.telefono_responsable || ""}
        disabled
      />
    </Form.Item>
  </>
);

ResponsibleInfo.propTypes = {
  form: PropTypes.object.isRequired,
};

export default ResponsibleInfo;
