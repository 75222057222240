// useFileUploadPatients.js
import { useState } from "react";
import api from "../../../axiosConfig";
import { useSocket } from "../../../SocketContext"; // Importar useSocket
import { message } from "antd";

export const useFileUploadPatients = () => {
  const [loading, setLoading] = useState({
    isLoading: false,
    progress: 0,
    status: "", // 'uploading', 'processing'
  });
  const [verificationStatus, setVerificationStatus] = useState({
    errors: [],
    correctEntries: 0,
    errorEntries: 0,
  });
  const [uploadStatus, setUploadStatus] = useState({
    totalUploaded: 0,
    totalErrors: 0,
    errorDetails: [],
  });

  const { socket } = useSocket(); // Usar el hook useSocket correctamente

  const verifyFile = async (file) => {
    if (!socket) {
      console.error("Socket no está conectado");
      return false;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading({ isLoading: true, progress: 0, status: "uploading" });

    const socketId = socket.id; // Obtener el socket ID

    let success = false;

    // Escuchar eventos de progreso
    const handleProgress = (data) => {
      setLoading((prev) => ({
        ...prev,
        progress: data.progress,
      }));
    };

    socket.on("verify_progress", handleProgress);

    try {
      const response = await api.post("/pacientes/verify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "socket-id": socketId, // Enviar el socket ID al servidor
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          if (totalLength !== null) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            setLoading((prev) => ({ ...prev, progress }));
          }
        },
      });

      // Cambiar el estado a 'processing' una vez que la carga termina
      setLoading((prev) => ({
        ...prev,
        status: "processing",
        progress: 100,
      }));

      setVerificationStatus({
        errors: response.data.errors,
        correctEntries: response.data.correctEntries,
        errorEntries: response.data.errorEntries,
      });

      success = response.data.errors.length === 0;
    } catch (error) {
      console.error("Error al verificar pacientes:", error);
      // Manejar error de versión
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Error al verificar pacientes.");
      }

      setVerificationStatus({
        errors: error.response?.data?.errors || [],
        correctEntries: 0,
        errorEntries: error.response?.data?.errors?.length || 0,
      });
      success = false;
    } finally {
      setLoading((prev) => ({ ...prev, isLoading: false }));
      socket.off("verify_progress", handleProgress);
    }

    return success;
  };

  const uploadFile = async (file, fetchPatients) => {
    if (!socket) {
      console.error("Socket no está conectado");
      return false;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading({ isLoading: true, progress: 0, status: "uploading" });

    try {
      const socketId = socket.id; // Obtener el socket ID

      const responsePromise = api.post("/pacientes/bulk", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "socket-id": socketId, // Enviar el socket ID al servidor
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          if (totalLength !== null) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            setLoading((prev) => ({ ...prev, progress }));
          }
        },
      });

      // Cambiar el estado a 'processing' una vez que la carga termina
      setLoading((prev) => ({ ...prev, status: "processing", progress: 100 }));

      // Escuchar eventos de progreso
      socket.on("bulk_upload_progress", (data) => {
        setLoading((prev) => ({
          ...prev,
          progress: data.progress,
        }));
      });

      const response = await responsePromise;

      fetchPatients();
      setUploadStatus({
        totalUploaded: response.data.totalUploaded,
        totalErrors: response.data.totalErrors,
        errorDetails: response.data.errorDetails,
      });

      return response.data.totalErrors === 0;
    } catch (error) {
      console.error("Error al cargar pacientes:", error);
      // Manejar error de versión
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Error al cargar pacientes.");
      }

      setUploadStatus({
        totalUploaded: 0,
        totalErrors: error.response?.data?.totalErrors || 0,
        errorDetails: error.response?.data?.errorDetails || [],
      });
      return false;
    } finally {
      setLoading({ isLoading: false, progress: 100, status: "" });
      socket.off("bulk_upload_progress");
    }
  };

  const resetUploadStatus = () => {
    setUploadStatus({
      totalUploaded: 0,
      totalErrors: 0,
      errorDetails: [],
    });
  };

  return {
    loading,
    verificationStatus,
    setVerificationStatus,
    verifyFile,
    uploadFile,
    uploadStatus,
    resetUploadStatus,
  };
};
