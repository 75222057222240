// CopagoFields.js
import React from "react";
import PropTypes from "prop-types";
import { Form, Select, InputNumber } from "antd";

const { Option } = Select;

const CopagoFields = ({ form, handleChange }) => {
  return (
    <>
      <Form.Item
        label="Porcentaje de Copago Total"
        name="porcentaje_copago_selected"
        rules={[
          { required: true, message: "Por favor selecciona un porcentaje" },
          {
            type: "number",
            min: 0,
            max: 100,
            message: "El porcentaje debe estar entre 0 y 100",
          },
        ]}
      >
        <Select
          placeholder="Seleccione un porcentaje"
          onChange={(value) =>
            handleChange({
              target: { name: "porcentaje_copago_selected", value },
            })
          }
          value={
            form.porcentaje_copago_selected !== null
              ? form.porcentaje_copago_selected
              : 0
          }
          allowClear={false}
        >
          <Option value={0}>0%</Option>
          <Option value={10}>10%</Option>
          <Option value={11.5}>11.5%</Option>
          <Option value={17.3}>17.3%</Option>
          <Option value={23}>23%</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Valor Máximo de Copago"
        name="valor_maximo_copago"
        rules={[
          {
            required: true,
            message: "Por favor ingrese el valor máximo de copago",
          },
          {
            validator: (_, value) => {
              if (value === null || value === undefined || value < 0) {
                return Promise.reject(
                  new Error(
                    "El valor máximo de copago debe ser mayor o igual a 0"
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber
          min={0}
          name="valor_maximo_copago"
          value={form.valor_maximo_copago ?? undefined}
          onChange={(value) =>
            handleChange({ target: { name: "valor_maximo_copago", value } })
          }
          style={{ width: "100%" }}
          step={1000}
          precision={2}
          placeholder="Ingrese el valor máximo de copago"
          onFocus={(e) => {
            e.target.select();
          }}
          formatter={(value) =>
            value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
          }
          parser={(value) =>
            parseFloat(
              value.replace(/\$\s?/g, "").replace(/\./g, "").replace(/,/g, ".")
            ) || 0
          }
        />
      </Form.Item>
    </>
  );
};

CopagoFields.propTypes = {
  form: PropTypes.shape({
    porcentaje_copago_selected: PropTypes.number,
    valor_maximo_copago: PropTypes.number,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CopagoFields;
