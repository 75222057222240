// src/components/Clients/Form/AdditionalInfo.js

import React from "react";
import { Form, Input, Typography, Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AdditionalInfo = () => (
  <div style={{ marginBottom: "24px" }}>
    <Title
      level={4}
      style={{
        marginBottom: 16,
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <InfoCircleOutlined style={{ marginRight: "8px" }} />
      Información Adicional
    </Title>

    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label="Nombre del Patrocinador" name="nombre_patrocinador">
          <Input placeholder="Nombre del Patrocinador" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Valoración General" name="valoracion_general">
          <Input placeholder="Valoración General" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Descripción del Producto/Servicio"
          name="descripcion_producto_servicio"
        >
          <Input.TextArea
            placeholder="Descripción del Producto/Servicio"
            rows={4}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Notas" name="notas">
          <Input.TextArea placeholder="Notas" rows={4} />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default AdditionalInfo;
