// src/components/common/LoadingProgress.jsx
import React from "react";
import { Progress } from "antd";
import PropTypes from "prop-types";

const LoadingProgress = ({ progress, totalTraslados }) => (
  <div style={{ textAlign: "center", marginBottom: "20px" }}>
    <Progress
      type="circle"
      percent={Math.round(progress)}
      format={() => `${Math.round(progress)}%`}
      style={{ marginBottom: "20px" }}
    />

    <div>
      <p>
        Enviando {Math.round(progress)}% de {totalTraslados} traslados
      </p>
    </div>
  </div>
);

LoadingProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  totalTraslados: PropTypes.number.isRequired,
};

export default LoadingProgress;
