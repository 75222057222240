// src/components/Clients/Form/AddressInfo.js

import React from "react";
import PropTypes from "prop-types";
import { Form, Select, Input, Button, Row, Col, Typography } from "antd";
import { EnvironmentOutlined, HomeOutlined } from "@ant-design/icons";
import CountrySelect from "./CountrySelect";

const { Option } = Select;
const { Title } = Typography;

const AddressInfo = ({
  zonas,
  departamentos,
  ciudades,
  loadingZonas,
  loadingDepartamentos,
  loadingCiudades,
  handleMapDialogOpen,
  onDepartamentoChange,
  onZonaChange,
}) => {
  return (
    <div style={{ marginBottom: "24px" }}>
      <Title
        level={4}
        style={{
          marginBottom: 16,
          backgroundColor: "#0a2e5c",
          color: "white",
          padding: "8px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <EnvironmentOutlined style={{ marginRight: "8px" }} />
        Información de Dirección
      </Title>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Zona"
            name="zona_id"
            rules={[
              { required: true, message: "Por favor selecciona una zona" },
            ]}
          >
            <Select
              placeholder="Selecciona una zona"
              allowClear
              suffixIcon={<EnvironmentOutlined />}
              onChange={onZonaChange}
              loading={loadingZonas}
              disabled={loadingZonas || zonas.length === 0}
            >
              {zonas.map((zonaItem) => (
                <Option key={zonaItem.id} value={zonaItem.id}>
                  {zonaItem.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="País"
            name="pais"
            rules={[
              { required: true, message: "Por favor selecciona un país" },
              { min: 2, message: "El país debe tener al menos 2 caracteres" },
              {
                max: 100,
                message: "El país no puede exceder los 100 caracteres",
              },
            ]}
            initialValue="Colombia"
          >
            <CountrySelect placeholder="Selecciona un país" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Departamento"
            name="departamento_id"
            rules={[
              {
                required: true,
                message: "Por favor selecciona un departamento",
              },
            ]}
          >
            <Select
              placeholder="Selecciona un departamento"
              allowClear
              onChange={onDepartamentoChange}
              suffixIcon={<EnvironmentOutlined />}
              loading={loadingDepartamentos}
              disabled={loadingDepartamentos || departamentos.length === 0}
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Ciudad/Municipio"
            name="ciudad_id"
            rules={[
              { required: true, message: "Por favor selecciona una ciudad" },
            ]}
          >
            <Select
              placeholder="Selecciona una ciudad"
              allowClear
              suffixIcon={<EnvironmentOutlined />}
              loading={loadingCiudades}
              disabled={loadingCiudades || ciudades.length === 0}
            >
              {ciudades.map((ciudadItem) => (
                <Option key={ciudadItem.id} value={ciudadItem.id}>
                  {ciudadItem.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Button
            icon={<EnvironmentOutlined />}
            onClick={handleMapDialogOpen}
            style={{ width: "100%", marginBottom: "16px" }}
          >
            Buscar en el Mapa
          </Button>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Dirección"
            name="direccion"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la dirección",
              },
            ]}
          >
            <Input
              prefix={<HomeOutlined />}
              placeholder="Escribe la dirección"
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

AddressInfo.propTypes = {
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
  departamentos: PropTypes.arrayOf(PropTypes.object).isRequired,
  ciudades: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingZonas: PropTypes.bool.isRequired,
  loadingDepartamentos: PropTypes.bool.isRequired,
  loadingCiudades: PropTypes.bool.isRequired,
  handleMapDialogOpen: PropTypes.func.isRequired,
  onDepartamentoChange: PropTypes.func.isRequired,
  onZonaChange: PropTypes.func,
};

export default AddressInfo;
