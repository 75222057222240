import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Space, Divider, Button, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

function ValidarTrasladoDialog({ open, onClose, onSubmit, trasladoId }) {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} type="default">
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          icon={<CheckCircleOutlined />}
          danger
        >
          Validar
        </Button>,
      ]}
      title={
        <Space align="center">
          <CheckCircleOutlined style={{ fontSize: "24px", color: "#52c41a" }} />
          <Title level={5} style={{ margin: 0 }}>
            Validar Traslado
          </Title>
        </Space>
      }
      width={500}
      centered
      destroyOnClose
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          padding: "24px",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div>
            <Text type="secondary">
              Vas a validar el traslado con el siguiente <strong>CS-ID:</strong>
            </Text>
            <Title
              level={3}
              style={{
                backgroundColor: "#e6f7ff",
                padding: "12px",
                borderRadius: "8px",
                textAlign: "center",
                color: "#1890ff",
                marginTop: "8px",
              }}
            >
              {trasladoId || "CS-ID no disponible"}
            </Title>
          </div>
          <Divider />
          <Space align="center">
            <CheckCircleOutlined
              style={{ fontSize: "20px", color: "#52c41a" }}
            />
            <Text>
              ¿Estás seguro de que deseas validar este traslado? Esta acción
              cambiará su seguimiento a "Validado".
            </Text>
          </Space>
        </Space>
      </Card>
    </Modal>
  );
}

ValidarTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ValidarTrasladoDialog;
