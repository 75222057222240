import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Descriptions, Tag, Spin, Button } from "antd";
import api from "../../../axiosConfig";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const ViewUserDialog = ({ user, open, handleClose }) => {
  const [tipoDocumentoDescripcion, setTipoDocumentoDescripcion] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fetchTipoDocumentoDescripcion(user.tipo_documento_id);
    }
  }, [user]);

  const fetchTipoDocumentoDescripcion = async (tipoDocumentoId) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/tipos-documento/${tipoDocumentoId}`
      );
      setTipoDocumentoDescripcion(response.data.descripcion);
      setLoading(false);
    } catch (error) {
      console.error(
        "Error al obtener la descripción del tipo de documento:",
        error
      );
      setLoading(false);
    }
  };

  const getEstadoTag = (estado) =>
    estado ? (
      <Tag color="green">Activo</Tag>
    ) : (
      <Tag color="red">Suspendido</Tag>
    );

  return (
    <Modal
      title="Ver Usuario"
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="close" type="primary" onClick={handleClose}>
          Cerrar
        </Button>,
      ]}
      width={800}
    >
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : (
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Nombre">{user.nombre}</Descriptions.Item>
          <Descriptions.Item label="Tipo de Documento">
            {tipoDocumentoDescripcion}
          </Descriptions.Item>
          <Descriptions.Item label="Número de Documento">
            {user.numero_documento}
          </Descriptions.Item>
          <Descriptions.Item label="Correo Electrónico">
            {user.correo_electronico}
          </Descriptions.Item>
          <Descriptions.Item label="Teléfono">
            {user.telefono || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Cargo">
            {user.cargo || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Área">
            {user.area || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Estado">
            {getEstadoTag(user.estado)}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha de Creación">
            {format(new Date(user.fecha_creacion), "PPP", { locale: es })}{" "}
            {format(new Date(user.fecha_creacion), "p", { locale: es })}
          </Descriptions.Item>
          {user.fecha_ultima_modificacion && (
            <Descriptions.Item label="Última Modificación">
              {format(new Date(user.fecha_ultima_modificacion), "PPP", {
                locale: es,
              })}{" "}
              {format(new Date(user.fecha_ultima_modificacion), "p", {
                locale: es,
              })}
            </Descriptions.Item>
          )}
          {user.ultimo_acceso && (
            <Descriptions.Item label="Último Acceso">
              {format(new Date(user.ultimo_acceso), "PPP", { locale: es })}{" "}
              {format(new Date(user.ultimo_acceso), "p", { locale: es })}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </Modal>
  );
};

// Validar las props
ViewUserDialog.propTypes = {
  user: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    tipo_documento_id: PropTypes.number.isRequired,
    numero_documento: PropTypes.string.isRequired,
    correo_electronico: PropTypes.string.isRequired,
    telefono: PropTypes.string,
    cargo: PropTypes.string,
    area: PropTypes.string,
    estado: PropTypes.bool.isRequired,
    fecha_creacion: PropTypes.string.isRequired,
    fecha_ultima_modificacion: PropTypes.string,
    ultimo_acceso: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ViewUserDialog;
