// src/components/Sidebar.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Layout, Menu, Typography, Divider, Drawer } from "antd";
import {
  UserAddOutlined,
  UserOutlined,
  CarOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  PullRequestOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../Assets/img/logo.webp";
import "./Sidebar.css"; // Importa el CSS personalizado

const { Sider } = Layout;
const { Title } = Typography;

function Sidebar({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  // Detectar si el dispositivo es móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define móvil como <= 768px
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleListItemClick = (route) => {
    navigate(`/modules/${route}`);
    if (isMobile) {
      toggleDrawer();
    }
  };

  const menuItems = [
    {
      label: "Usuarios",
      key: "user",
      icon: <TeamOutlined />,
      children: [
        {
          label: "Lista de Usuarios",
          key: "UserList",
          onClick: () => handleListItemClick("UserList"),
        },
        {
          label: "Tarjetas de Usuarios",
          key: "UserCards",
          onClick: () => handleListItemClick("UserCards"),
        },
      ],
    },
    {
      label: "Pacientes",
      key: "Patients",
      icon: <UserAddOutlined />,
      onClick: () => handleListItemClick("Patients"),
    },
    {
      label: "Autorizaciones",
      key: "Authorizations",
      icon: <TeamOutlined />,
      onClick: () => handleListItemClick("Authorizations"),
    },
    {
      label: "Clientes EPS",
      key: "Clients",
      icon: <ContactsOutlined />,
      onClick: () => handleListItemClick("Clients"),
    },
    {
      label: "Conductores",
      key: "Drivers",
      icon: <TeamOutlined />,
      onClick: () => handleListItemClick("Drivers"),
    },
    {
      label: "Vehículos",
      key: "Vehicles",
      icon: <CarOutlined />,
      onClick: () => handleListItemClick("Vehicles"),
    },
    {
      label: "Asignaciones",
      key: "VehiculoConductores",
      icon: <CarOutlined />,
      onClick: () => handleListItemClick("VehiculoConductores"),
    },
    {
      label: "Instituciones",
      key: "Institutions",
      icon: <UserOutlined />,
      onClick: () => handleListItemClick("Institutions"),
    },
    {
      label: "Centro de Control",
      key: "CenterControl",
      icon: <PullRequestOutlined />,
      onClick: () => handleListItemClick("CenterControl"),
    },
    {
      label: "Coordinación",
      key: "Coordinacion",
      icon: <TeamOutlined />,
      onClick: () => handleListItemClick("Coordinacion"),
    },
  ];

  const configItems = [
    {
      label: "Ajustes Generales",
      key: "GeneralSettings",
      icon: <SettingOutlined />,
      onClick: () => handleListItemClick("GeneralSettings"),
    },
    {
      label: "Ajustes Avanzados",
      key: "AdvancedSettings",
      icon: <SettingOutlined />,
      onClick: () => handleListItemClick("AdvancedSettings"),
    },
    {
      label: "Verificación de Hora",
      key: "TimeCheck",
      icon: <ClockCircleOutlined />,
      onClick: () => handleListItemClick("TimeCheck"),
    },
  ];

  const sidebarContent = (
    <>
      <div style={{ padding: "10px" }}>
        <img src={logo} alt="Logo" style={{ width: "100%" }} />
      </div>
      {open && (
        <>
          <Divider style={{ background: "white", margin: "0" }} />
          <Title level={5} style={{ color: "white", textAlign: "center" }}>
            Módulos
          </Title>
          <Divider style={{ background: "white", margin: "0" }} />
        </>
      )}
      <div
        className="sidebar-custom"
        style={{
          overflowY: "auto",
          height: "calc(100vh - 160px)",
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname.split("/")[2]]}
          defaultOpenKeys={["user"]}
          items={menuItems}
        />
        <Divider style={{ background: "white", margin: "0" }} />
        {open && (
          <Title level={5} style={{ color: "white", textAlign: "center" }}>
            Configuración
          </Title>
        )}
        <Divider style={{ background: "white", margin: "0" }} />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname.split("/")[2]]}
          items={configItems}
        />
      </div>
    </>
  );

  return isMobile ? (
    <Drawer
      title={<span style={{ color: "white" }}>Menú</span>}
      placement="left"
      onClose={toggleDrawer}
      open={open}
      className="custom-drawer custom-drawer-content-wrapper" // Añade las clases personalizadas
      closeIcon={<span style={{ color: "white" }}>X</span>}
    >
      {sidebarContent}
    </Drawer>
  ) : (
    <Sider
      width={250}
      collapsible
      trigger={null}
      collapsed={!open}
      theme="dark"
      breakpoint="lg"
      collapsedWidth="80"
      style={{
        color: "white",
        backgroundColor: "#001529",
        overflow: "hidden",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      {sidebarContent}
    </Sider>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
