// src/Components/common/ColoredCard.js
import React from "react";
import PropTypes from "prop-types";

const ColoredCard = ({ color, children }) => {
  return (
    <div
      style={{
        display: "flex",
        borderRadius: "8px",
        overflow: "hidden",
        marginBottom: "16px", // Agrega un margen inferior para separar las tarjetas
      }}
    >
      {/* Borde lateral */}
      <div
        style={{
          width: "5px",
          backgroundColor: color,
        }}
      ></div>
      {/* Contenido de la tarjeta */}
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
};

ColoredCard.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ColoredCard;
