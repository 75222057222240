// statsUtils.js
export const calcularStats = (asignaciones, vehiculos, conductores) => {
  const vehiculosConConductorSet = new Set(
    asignaciones.map((a) => a.vehiculo_id)
  );
  const conductoresAsignadosSet = new Set(
    asignaciones.map((a) => a.conductor_id)
  );
  const vehiculosConConductorPrincipalSet = new Set(
    asignaciones.filter((a) => a.es_principal).map((a) => a.vehiculo_id)
  );
  const conductoresPrincipalesAsignados = asignaciones.filter(
    (a) => a.es_principal
  ).length;

  return {
    totalVehiculos: vehiculos.length,
    vehiculosConConductor: vehiculosConConductorSet.size,
    vehiculosSinConductor: vehiculos.length - vehiculosConConductorSet.size,
    vehiculosConConductorPrincipal: vehiculosConConductorPrincipalSet.size,
    totalConductores: conductores.length,
    conductoresAsignados: conductoresAsignadosSet.size,
    conductoresPrincipalesAsignados: conductoresPrincipalesAsignados,
    conductoresSinVehiculo: conductores.length - conductoresAsignadosSet.size,
  };
};
