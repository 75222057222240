// AuthorizationData.js
import React from "react";
import PropTypes from "prop-types";
import {
  Input,
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  AutoComplete,
  Divider,
} from "antd";
import CopagoFields from "./CopagoFields";
import MipresFields from "./MipresFields";

const { Option } = Select;

const AuthorizationData = ({
  form,
  handleChange,
  cups = [],
  handleCupsChange,
  errors = {},
  isMipresExisting = false,
  handleNoPbsChange,
  propositos = [],
  handlePropositoChange,
  copagoFieldsDisabled = false,
}) => {
  return (
    <>
      <Divider />

      <MipresFields
        form={form}
        handleChange={handleChange}
        handleNoPbsChange={handleNoPbsChange}
        errors={errors}
      />

      <Form.Item
        label="CUPS Código"
        required
        validateStatus={errors.cups_codigo ? "error" : ""}
        help={errors.cups_codigo}
      >
        <AutoComplete
          allowClear
          options={cups.map((cup) => ({
            value: cup.codigo,
            label: `${cup.codigo} - ${cup.descripcion}`,
          }))}
          value={form.cups_codigo || ""}
          onSelect={handleCupsChange}
          onChange={(value) =>
            handleChange({ target: { name: "cups_codigo", value } })
          }
          placeholder="Seleccione un código CUPS"
          filterOption={(inputValue, option) =>
            option.label.toUpperCase().includes(inputValue.toUpperCase())
          }
        />
      </Form.Item>

      {copagoFieldsDisabled && (
        <CopagoFields form={form} handleChange={handleChange} />
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Número de Entrega">
            <InputNumber
              min={1}
              max={12}
              name="numero_entrega"
              value={form.numero_entrega || ""}
              onChange={(value) =>
                handleChange({ target: { name: "numero_entrega", value } })
              }
              disabled={isMipresExisting || form.no_pbs}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Entregas Autorizadas">
            <InputNumber
              min={1}
              max={12}
              name="entregas_autorizadas"
              value={form.entregas_autorizadas || ""}
              onChange={(value) =>
                handleChange({ target: { name: "entregas_autorizadas", value } })
              }
              disabled={isMipresExisting || form.no_pbs}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="CUPS Descripción">
        <Input
          name="cups_descripcion"
          value={form.cups_descripcion || ""}
          disabled
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Tipo de Viaje">
            <Input name="tipo_viaje" value={form.tipo_viaje || ""} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tipo de Traslado">
            <Input
              name="tipo_traslado"
              value={form.tipo_traslado || ""}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Categoría de Autorización"
        required
        validateStatus={errors.categoria_autorizacion ? "error" : ""}
        help={errors.categoria_autorizacion}
      >
        <Select
          name="categoria_autorizacion"
          value={form.categoria_autorizacion || ""}
          onChange={(value) =>
            handleChange({ target: { name: "categoria_autorizacion", value } })
          }
          placeholder="Seleccione una categoría"
        >
          <Option value="reemplazada">Reemplazada</Option>
          <Option value="autorizacion">Autorización</Option>
          <Option value="preautorizacion">Pre-autorización</Option>
        </Select>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="N.° Aut"
            required={!form.numero_pre_autorizacion}
            validateStatus={errors.numero_autorizacion ? "error" : ""}
            help={errors.numero_autorizacion}
          >
            <Input
              name="numero_autorizacion"
              value={form.numero_autorizacion || ""}
              onChange={handleChange}
              placeholder="Ingrese el número de autorización"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="N.° Pre-Aut"
            required={!form.numero_autorizacion}
            validateStatus={errors.numero_pre_autorizacion ? "error" : ""}
            help={errors.numero_pre_autorizacion}
          >
            <Input
              name="numero_pre_autorizacion"
              value={form.numero_pre_autorizacion || ""}
              onChange={handleChange}
              placeholder="Ingrese el número de pre-autorización"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Tipo de Autorización"
        required
        validateStatus={errors.tipo_autorizacion ? "error" : ""}
        help={errors.tipo_autorizacion}
      >
        <Select
          name="tipo_autorizacion"
          value={form.tipo_autorizacion || ""}
          onChange={(value) =>
            handleChange({ target: { name: "tipo_autorizacion", value } })
          }
          placeholder="Seleccione un tipo"
        >
          <Option value="abierta">Abierta</Option>
          <Option value="cerrada">Cerrada</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Propósito"
        validateStatus={errors.proposito ? "error" : ""}
        help={errors.proposito}
      >
        <AutoComplete
          options={propositos.map((proposito) => ({
            value: proposito.descripcion,
            label: proposito.descripcion,
          }))}
          value={form.proposito || ""}
          onSelect={(value) => handlePropositoChange(value)}
          onChange={(value) =>
            handleChange({ target: { name: "proposito", value } })
          }
          placeholder="Seleccione un propósito"
          filterOption={(inputValue, option) =>
            option.label.toUpperCase().includes(inputValue.toUpperCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Estado de Autorización"
        required
        validateStatus={errors.estado_autorizacion ? "error" : ""}
        help={errors.estado_autorizacion}
      >
        <Select
          name="estado_autorizacion"
          value={form.estado_autorizacion || ""}
          onChange={(value) =>
            handleChange({ target: { name: "estado_autorizacion", value } })
          }
          placeholder="Seleccione un estado"
        >
          <Option value="Activa">Activa</Option>
          <Option value="Suspendida">Suspendida</Option>
          <Option value="Completada">Completada</Option>
        </Select>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Traslados Autorizados"
            required
            validateStatus={errors.total_traslados_asignados ? "error" : ""}
            help={errors.total_traslados_asignados}
          >
            <InputNumber
              min={1}
              name="total_traslados_asignados"
              value={form.total_traslados_asignados || ""}
              onChange={(value) =>
                handleChange({
                  target: { name: "total_traslados_asignados", value },
                })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Kilómetros Autorizados"
            validateStatus={errors.kilometros_autorizados ? "error" : ""}
            help={errors.kilometros_autorizados}
          >
            <InputNumber
              min={0}
              name="kilometros_autorizados"
              value={form.kilometros_autorizados || ""}
              onChange={(value) =>
                handleChange({
                  target: { name: "kilometros_autorizados", value },
                })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Kilómetros por Servicios"
        validateStatus={errors.kilometros_por_servicios ? "error" : ""}
        help={errors.kilometros_por_servicios}
      >
        <InputNumber
          min={0}
          name="kilometros_por_servicios"
          value={form.kilometros_por_servicios || ""}
          disabled
          onChange={(value) =>
            handleChange({
              target: { name: "kilometros_por_servicios", value },
            })
          }
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        label="Copago Total"
        validateStatus={errors.copago ? "error" : ""}
        help={errors.copago}
      >
        <InputNumber
          min={0}
          name="copago"
          value={form.copago || 0}
          onChange={(value) =>
            handleChange({ target: { name: "copago", value } })
          }
          style={{ width: "100%" }}
          step={1000}
          precision={2}
          placeholder="Ingrese el copago total"
          onFocus={(e) => {
            e.target.select();
          }}
          formatter={(value) =>
            value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
          }
          parser={(value) =>
            parseFloat(
              value.replace(/\$\s?/g, "")
                .replace(/\./g, "")
                .replace(/,/g, ".")
            ) || 0
          }
          disabled={copagoFieldsDisabled}
        />
      </Form.Item>

      <Form.Item
        label="Copago por Servicios"
        validateStatus={errors.copago_diario ? "error" : ""}
        help={errors.copago_diario}
      >
        <InputNumber
          min={0}
          name="copago_diario"
          value={form.copago_diario || 0}
          onChange={(value) =>
            handleChange({ target: { name: "copago_diario", value } })
          }
          style={{ width: "100%" }}
          step={1000}
          precision={2}
          placeholder="Ingrese el copago por servicios"
          onFocus={(e) => {
            e.target.select();
          }}
          formatter={(value) =>
            value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
          }
          parser={(value) =>
            parseFloat(
              value.replace(/\$\s?/g, "")
                .replace(/\./g, "")
                .replace(/,/g, ".")
            ) || 0
          }
          disabled={copagoFieldsDisabled}
        />
      </Form.Item>
    </>
  );
};

AuthorizationData.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  cups: PropTypes.array.isRequired,
  handleCupsChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isMipresExisting: PropTypes.bool.isRequired,
  handleNoPbsChange: PropTypes.func.isRequired,
  propositos: PropTypes.array.isRequired,
  handlePropositoChange: PropTypes.func.isRequired,
  copagoFieldsDisabled: PropTypes.bool,
};

export default AuthorizationData;
