import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Button,
  Popover,
  Checkbox,
  Form,
  Space,
} from "antd";
import {
  FilterOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import locale from "antd/es/date-picker/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importar el locale de Day.js

const { Option } = Select;

// Función de utilidad para convertir fechas de forma segura a ISO
const safeToISOString = (date) => {
  return date ? date.toISOString() : null;
};

const FiltrosTraslados = ({
  dateFilterType,
  setDateFilterType,
  selectedDate,
  setSelectedDate,
  handlePrevDay,
  handleNextDay,
  singleHourFilter,
  setSingleHourFilter,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  searchQuery,
  setSearchQuery,
  zonas = [],
  selectedZona,
  setSelectedZona,
  selectedStatuses = [],
  setSelectedStatuses,
  selectedSeguimientoStatus,
  setSelectedSeguimientoStatus,
  selectedConfirmacionStatus,
  setSelectedConfirmacionStatus,
  handleOpenDialog,
  handleFilterChange,
  selectedMovil,
  setSelectedMovil,
}) => {
  const [visiblePopover, setVisiblePopover] = useState(false);

  // Estado para almacenar los vehículos obtenidos desde la API
  const [vehiculos, setVehiculos] = useState([]);
  const [vehiculosCargados, setVehiculosCargados] = useState(false);

  // Función para cargar los vehículos desde la API
  const fetchVehiculos = async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/vehiculos/codigo-placa"
      );
      setVehiculos(response.data);
    } catch (error) {
      console.error("Error al obtener los vehículos:", error);
    }
  };

  // Cargar los vehículos solo cuando se abre el filtro de vehículos
  const handleMovilFilterOpen = () => {
    if (!vehiculosCargados) {
      fetchVehiculos();
      setVehiculosCargados(true);
    }
  };

  const statuses = [
    "Programado",
    "Pre-Asignado",
    "Asignado",
    "Enterado",
    "Aceptado",
    "En Camino",
    "Esperando",
    "Iniciado",
    "Fallido",
    "Cancelado",
    "Finalizado",
  ];

  const handleStatusChange = (checkedValues) => {
    setSelectedStatuses(checkedValues);
  };

  // Llamar a handleFilterChange cada vez que se cambian los filtros
  useEffect(() => {
    if (typeof handleFilterChange === "function") {
      handleFilterChange({
        selectedZona,
        selectedStatuses,
        startDate: safeToISOString(startDate),
        endDate: safeToISOString(endDate),
        startTime: safeToISOString(startTime),
        endTime: safeToISOString(endTime),
        selectedSeguimientoStatus,
        selectedConfirmacionStatus,
        selectedMovil,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedZona,
    selectedStatuses,
    startDate,
    endDate,
    startTime,
    endTime,
    selectedSeguimientoStatus,
    selectedConfirmacionStatus,
    selectedMovil,
  ]);

  // Efecto para restablecer el rango personalizado al cambiar el tipo de filtro de fecha
  useEffect(() => {
    if (dateFilterType !== "custom") {
      setStartDate(new Date());
      setEndDate(new Date());
      setStartTime(null);
      setEndTime(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterType]);

  const popoverContent = (
    <Checkbox.Group
      options={statuses}
      value={selectedStatuses}
      onChange={handleStatusChange}
      style={{ display: "flex", flexDirection: "column" }}
    />
  );

  return (
    <Form layout="vertical">
      {/* Primera Línea: Filtros Principales */}
      <Row gutter={[16, 16]} align="middle">
        {/* Buscador */}
        <Col xs={24} sm={12} md={6}>
          <Input
            placeholder="Buscar por CS, Nombre, Documento, PRE, AUT"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            allowClear
          />
        </Col>

        {/* Autocomplete de Móvil */}
        <Col xs={24} sm={12} md={3}>
          <Select
            showSearch
            placeholder="Móvil (Código - Placa)"
            value={
              selectedMovil
                ? `${selectedMovil.codigo} - ${selectedMovil.placa}`
                : undefined
            }
            onChange={(value) => {
              const movil = vehiculos.find(
                (v) => `${v.codigo} - ${v.placa}` === value
              );
              setSelectedMovil(movil || null);
            }}
            onDropdownVisibleChange={(open) => {
              if (open) handleMovilFilterOpen();
            }}
            options={vehiculos.map((vehiculo) => ({
              label: `${vehiculo.codigo} - ${vehiculo.placa}`,
              value: `${vehiculo.codigo} - ${vehiculo.placa}`,
            }))}
            allowClear
          />
        </Col>

        {/* Confirmación */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Confirmación"
            value={selectedConfirmacionStatus}
            onChange={(value) => setSelectedConfirmacionStatus(value)}
            style={{ width: "100%" }}
          >
            <Option value="Ambos">Ambos</Option>
            <Option value="Confirmado">Confirmado</Option>
            <Option value="Sin Confirmar">Sin Confirmar</Option>
          </Select>
        </Col>

        {/* Seguimiento */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Seguimiento"
            value={selectedSeguimientoStatus}
            onChange={(value) => setSelectedSeguimientoStatus(value)}
            style={{ width: "100%" }}
          >
            <Option value="Ambos">Ambos</Option>
            <Option value="Validado">Validado</Option>
            <Option value="Sin Validar">Sin Validar</Option>
          </Select>
        </Col>

        {/* Zona */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Zona"
            value={selectedZona}
            onChange={(value) => setSelectedZona(value)}
            style={{ width: "100%" }}
          >
            <Option value="">Todas las Zonas</Option>
            {zonas.length > 0 ? (
              zonas.map((zona) => (
                <Option key={zona.id} value={zona.nombre}>
                  {zona.nombre}
                </Option>
              ))
            ) : (
              <Option value="" disabled>
                No hay zonas disponibles
              </Option>
            )}
          </Select>
        </Col>

        {/* Filtro de Fechas */}
        <Col xs={24} sm={12} md={3}>
          <Select
            placeholder="Filtro de Fechas"
            value={dateFilterType}
            onChange={(value) => setDateFilterType(value)}
            allowClear={false}
            style={{ width: "100%" }}
          >
            <Option value="none">Sin Rango</Option>
            <Option value="week">Semana Actual</Option>
            <Option value="previousWeek">Semana Anterior</Option>
            <Option value="month">Mes Actual</Option>
            <Option value="previousMonth">Mes Anterior</Option>
            <Option value="custom">Rango Personalizado</Option>
          </Select>
        </Col>

        {/* Ícono de Filtro */}
        <Col xs={24} sm={12} md={1}>
          <Popover
            content={popoverContent}
            title="Estados"
            trigger="click"
            open={visiblePopover}
            onOpenChange={(visible) => setVisiblePopover(visible)}
          >
            <Button icon={<FilterOutlined />} />
          </Popover>
        </Col>

        {/* Botón Programar */}
        <Col xs={24} sm={12} md={1} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenDialog}
            size="middle"
          >
            Programar
          </Button>
        </Col>
      </Row>

      {/* Segunda Línea: Filtros de Fecha y Hora */}
      <Row gutter={[16, 16]} align="middle">
        {dateFilterType !== "custom" ? (
          // Cuando no es "Personalizado"
          <>
            {/* Hora Exacta */}
            <Col xs={24} sm={12} md={2}>
              <TimePicker
                placeholder="Hora Exacta"
                value={singleHourFilter ? dayjs(singleHourFilter) : null}
                onChange={(time) =>
                  setSingleHourFilter(time ? time.toDate() : null)
                }
                format="HH:mm"
                style={{ width: "100%" }}
                allowClear={false}
              />
            </Col>

            {/* Fecha con Flechas */}
            <Col
              xs={24}
              sm={12}
              md={18}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Space size="large">
                <Button icon={<LeftOutlined />} onClick={handlePrevDay} />
                <DatePicker
                  value={selectedDate ? dayjs(selectedDate) : null}
                  onChange={(date) =>
                    setSelectedDate(date ? date.toDate() : null)
                  }
                  format="DD/MM"
                  locale={locale}
                  allowClear={false}
                  style={{ width: "120px", textAlign: "center" }}
                />
                <Button icon={<RightOutlined />} onClick={handleNextDay} />
              </Space>
            </Col>
          </>
        ) : (
          // Cuando es "Personalizado"
          <>
            {/* Fecha Inicio */}
            <Col xs={24} sm={12} md={4}>
              <DatePicker
                placeholder="Fecha Inicio"
                value={startDate ? dayjs(startDate) : null}
                onChange={(date) => setStartDate(date ? date.toDate() : null)}
                format="DD/MM/YYYY"
                locale={locale}
                style={{ width: "100%" }}
                allowClear={false}
              />
            </Col>

            {/* Fecha Fin */}
            <Col xs={24} sm={12} md={4}>
              <DatePicker
                placeholder="Fecha Fin"
                value={endDate ? dayjs(endDate) : null}
                onChange={(date) => setEndDate(date ? date.toDate() : null)}
                format="DD/MM/YYYY"
                locale={locale}
                style={{ width: "100%" }}
                allowClear={false}
              />
            </Col>

            {/* Hora Desde */}
            <Col xs={24} sm={12} md={4}>
              <TimePicker
                placeholder="Desde"
                value={startTime ? dayjs(startTime) : null}
                onChange={(time) => setStartTime(time ? time.toDate() : null)}
                format="HH:mm"
                style={{ width: "100%" }}
                allowClear={true}
              />
            </Col>

            {/* Hora Hasta */}
            <Col xs={24} sm={12} md={4}>
              <TimePicker
                placeholder="Hasta"
                value={endTime ? dayjs(endTime) : null}
                onChange={(time) => setEndTime(time ? time.toDate() : null)}
                format="HH:mm"
                style={{ width: "100%" }}
                allowClear={true}
              />
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

// Añadir validación de PropTypes para el componente
FiltrosTraslados.propTypes = {
  dateFilterType: PropTypes.string.isRequired,
  setDateFilterType: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  handlePrevDay: PropTypes.func.isRequired,
  handleNextDay: PropTypes.func.isRequired,
  singleHourFilter: PropTypes.instanceOf(Date),
  setSingleHourFilter: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date),
  setStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.instanceOf(Date),
  setEndTime: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedZona: PropTypes.string.isRequired,
  setSelectedZona: PropTypes.func.isRequired,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedStatuses: PropTypes.func.isRequired,
  selectedSeguimientoStatus: PropTypes.string.isRequired,
  setSelectedSeguimientoStatus: PropTypes.func.isRequired,
  selectedConfirmacionStatus: PropTypes.string.isRequired,
  setSelectedConfirmacionStatus: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func,
  selectedMovil: PropTypes.object,
  setSelectedMovil: PropTypes.func.isRequired,
};

export default FiltrosTraslados;
