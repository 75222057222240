// src/components/common/WeekdaySelector.jsx
import React from "react";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

const WeekdaySelector = ({ selectedWeekdays, handleWeekdaySelect }) => {
  const weekdays = [
    { label: "Domingo", key: "domingo" },
    { label: "Lunes", key: "lunes" },
    { label: "Martes", key: "martes" },
    { label: "Miércoles", key: "miercoles" },
    { label: "Jueves", key: "jueves" },
    { label: "Viernes", key: "viernes" },
    { label: "Sábado", key: "sabado" },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
      }}
    >
      {weekdays.map((day) => (
        <Checkbox
          key={day.key}
          checked={selectedWeekdays[day.key]}
          onChange={() => handleWeekdaySelect(day.key)}
        >
          {day.label}
        </Checkbox>
      ))}
    </div>
  );
};

WeekdaySelector.propTypes = {
  selectedWeekdays: PropTypes.object.isRequired,
  handleWeekdaySelect: PropTypes.func.isRequired,
};

export default WeekdaySelector;
