// src/components/AgendarDialog/CustomCellRender.jsx
import React from "react";
import PropTypes from "prop-types";
import CalendarCell from "./CalendarCell";

const CustomCellRender = ({
  date,
  selectedDays,
  handleDragStart,
  handleDrop,
  handleEditClick,
  editingDay,
  renderEditPopoverContent,
  currentMonth,
}) => {
  const formattedDate = date.format("YYYY-MM-DD");
  const selectedDay = selectedDays.find(
    (day) => day.ida === formattedDate || day.retorno === formattedDate
  );

  if (date.month() !== currentMonth) {
    return <div style={{ visibility: "hidden" }}>{date.date()}</div>;
  }

  return (
    <CalendarCell
      date={date}
      selectedDay={selectedDay}
      handleDragStart={handleDragStart}
      handleDrop={handleDrop}
      handleEditClick={handleEditClick}
      editingDay={editingDay}
      renderEditPopoverContent={renderEditPopoverContent}
    />
  );
};

CustomCellRender.propTypes = {
  date: PropTypes.object.isRequired,
  selectedDays: PropTypes.array.isRequired,
  handleDragStart: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  editingDay: PropTypes.string,
  renderEditPopoverContent: PropTypes.func.isRequired,
  currentMonth: PropTypes.number.isRequired,
};

export default CustomCellRender;
