// src/Components/modules/PatientList/ObservationsInfo.js

import React from "react";
import { Form, Input } from "antd";

const { TextArea } = Input;

/**
 * Componente para la sección de Observaciones en el formulario de pacientes.
 */
const ObservationsInfo = () => (
  <Form.Item
    name="observaciones"
    rules={[
      { required: false, message: "Por favor ingrese las observaciones" },
    ]}
  >
    <TextArea rows={4} placeholder="Escribe las observaciones" />
  </Form.Item>
);

export default ObservationsInfo;
