// src/hooks/useTraslados.js
import { useState } from "react";
import { crearTraslado } from "../services/trasladoService";
import { message } from "antd";

const useTraslados = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalTraslados, setTotalTraslados] = useState(0);

  const confirmarAgendar = async (traslados, onClose, onCloseParentDialog) => {
    if (traslados.length === 0) {
      message.error("No se generaron traslados. Revisa la selección de días.");
      return;
    }

    setLoading(true);
    setTotalTraslados(traslados.length);
    setProgress(0);

    try {
      for (let i = 0; i < traslados.length; i++) {
        await crearTraslado(traslados[i]);

        const currentProgress = ((i + 1) / traslados.length) * 100;
        setProgress(currentProgress);
      }

      message.success("Traslados agendados exitosamente.");
      onClose();
      onCloseParentDialog();
    } catch (error) {
      console.error("Error al agendar traslados:", error);
      message.error("Ocurrió un error al agendar los traslados.");
    } finally {
      setLoading(false);
    }
  };

  return { loading, progress, totalTraslados, confirmarAgendar };
};

export default useTraslados;
