import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Input, Select, Form, Spin, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import AutocompleteAddress from "../../../Resources/GoogleApi/AutocompleteAddress";
import MapDialog from "../../../Resources/GoogleApi/MapDialog";
import api from "../../../axiosConfig";
import { fetchDepartamentos, fetchCiudades } from "./api";
import { useUser } from "../../../UserContext"; // Importa el contexto de usuario
import PropTypes from "prop-types"; // Importamos PropTypes

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const InstitutionsForm = ({
  open,
  handleClose,
  fetchInstitutions,
  currentInstitution,
}) => {
  const { user } = useUser(); // Usa el contexto de usuario
  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [newInstitution, setNewInstitution] = useState({
    nombre: "",
    departamento_id: "",
    ciudad_id: "",
    localidad: "",
    barrio: "",
    direccion: "",
    coordenadas: "",
    indicaciones: "",
  });

  const fetchData = useCallback(async () => {
    try {
      const [departamentosData] = await Promise.all([fetchDepartamentos()]);
      setDepartamentos(departamentosData);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchData();
      if (currentInstitution) {
        setNewInstitution({
          ...currentInstitution,
          coordenadas: currentInstitution.coordenadas
            ? currentInstitution.coordenadas.coordinates.join(", ")
            : "",
        });
        fetchCiudades(currentInstitution.departamento_id).then(setCiudades);
      } else {
        resetForm();
      }
    }
  }, [open, fetchData, currentInstitution]);

  useEffect(() => {
    if (newInstitution.departamento_id) {
      fetchCiudades(newInstitution.departamento_id).then(setCiudades);
    } else {
      setCiudades([]);
    }
  }, [newInstitution.departamento_id]);

  const resetForm = () => {
    setNewInstitution({
      nombre: "",
      departamento_id: "",
      ciudad_id: "",
      localidad: "",
      barrio: "",
      direccion: "",
      coordenadas: "",
      indicaciones: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewInstitution({ ...newInstitution, [name]: value });
  };

  const handleSave = async () => {
    try {
      const institutionToSend = { ...newInstitution };

      if (newInstitution.coordenadas) {
        const coords = newInstitution.coordenadas
          .split(",")
          .map((coord) => parseFloat(coord.trim()));
        institutionToSend.coordenadas = {
          type: "Point",
          coordinates: coords,
        };
      } else {
        institutionToSend.coordenadas = null;
      }

      institutionToSend.usuario_id = user.id; // Incluye el ID del usuario

      if (currentInstitution) {
        await api.put(
          `https://mogotaxsas.com/api/instituciones/${currentInstitution.id}`,
          institutionToSend
        );
      } else {
        await api.post(
          "https://mogotaxsas.com/api/instituciones",
          institutionToSend
        );
      }
      fetchInstitutions();
      handleClose();
    } catch (error) {
      console.error("Error al guardar la institución:", error);
    }
  };

  const handleMapDialogOpen = () => {
    setIsMapDialogOpen(true);
  };

  const handleMapDialogClose = () => {
    setIsMapDialogOpen(false);
  };

  const handleMapSelect = (direccion, localidad, coordenadas) => {
    setNewInstitution((prev) => ({
      ...prev,
      direccion,
      localidad,
      coordenadas,
    }));
    setIsMapDialogOpen(false);
  };

  return (
    <Modal
      title={currentInstitution ? "Editar Institución" : "Agregar Institución"}
      open={open}
      onCancel={() => {
        handleClose();
        resetForm();
      }}
      onOk={handleSave}
      okText="Guardar"
      cancelText="Cancelar"
      width={800}
    >
      {loading ? (
        <Spin />
      ) : (
        <Form layout="vertical">
          <Title level={4}>Información de la Institución</Title>
          <Form.Item label="Nombre" required>
            <Input
              name="nombre"
              value={newInstitution.nombre}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Departamento" required>
            <Select
              name="departamento_id"
              value={newInstitution.departamento_id}
              onChange={(value) =>
                setNewInstitution({ ...newInstitution, departamento_id: value })
              }
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Ciudad/Municipio" required>
            <Select
              name="ciudad_id"
              value={newInstitution.ciudad_id}
              onChange={(value) =>
                setNewInstitution({ ...newInstitution, ciudad_id: value })
              }
            >
              {ciudades.map((ciudad) => (
                <Option key={ciudad.id} value={ciudad.id}>
                  {ciudad.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Dirección">
            <AutocompleteAddress
              setDireccion={(direccion) =>
                setNewInstitution((prev) => ({ ...prev, direccion }))
              }
              setLocalidad={(localidad) =>
                setNewInstitution((prev) => ({ ...prev, localidad }))
              }
              setCoordenadas={(coordenadas) =>
                setNewInstitution((prev) => ({ ...prev, coordenadas }))
              }
            />
            <Button
              type="primary"
              icon={<EnvironmentOutlined />}
              onClick={handleMapDialogOpen}
            >
              Buscar en el Mapa
            </Button>
            <Input
              name="direccion"
              value={newInstitution.direccion}
              onChange={handleInputChange}
              placeholder="Dirección"
              style={{ marginTop: 8 }}
            />
          </Form.Item>
          <Form.Item label="Localidad">
            <Input
              name="localidad"
              value={newInstitution.localidad}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Barrio">
            <Input
              name="barrio"
              value={newInstitution.barrio}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Coordenadas (lat, lon)">
            <Input
              name="coordenadas"
              value={newInstitution.coordenadas}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Indicaciones">
            <TextArea
              name="indicaciones"
              value={newInstitution.indicaciones}
              onChange={handleInputChange}
              rows={3}
            />
          </Form.Item>
        </Form>
      )}
      <MapDialog
        open={isMapDialogOpen}
        onClose={handleMapDialogClose}
        onSelect={handleMapSelect}
        initialCoords={newInstitution.coordenadas}
      />
    </Modal>
  );
};

InstitutionsForm.propTypes = {
  open: PropTypes.bool.isRequired, // Asegura que 'open' sea un booleano y sea requerido
  handleClose: PropTypes.func.isRequired, // Asegura que 'handleClose' sea una función y sea requerido
  fetchInstitutions: PropTypes.func.isRequired, // Asegura que 'fetchInstitutions' sea una función y sea requerido
  currentInstitution: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    departamento_id: PropTypes.number,
    ciudad_id: PropTypes.number,
    coordenadas: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
  }), // Valida que 'currentInstitution' tenga esta estructura, pero no es requerido
};

export default InstitutionsForm;
