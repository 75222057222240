// src/utils/formatCurrency.js

/**
 * Formatea un valor numérico como moneda COP (Pesos Colombianos).
 *
 * @param {number|string} value - El valor a formatear.
 * @returns {string} - El valor formateado como moneda COP.
 */
export const formatCurrencyCOP = (value) => {
  if (typeof value !== "number") {
    // Intenta convertir a número si no lo es
    value = Number(value);
    if (isNaN(value)) return "$ 0,00";
  }
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};
