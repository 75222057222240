// TablaAsignaciones.js
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Table, Tag } from "antd";

const TablaAsignaciones = ({ asignaciones, zonaFiltro, searchTerm }) => {
  const filteredAsignaciones = useMemo(() => {
    return asignaciones.filter((asignacion) => {
      const vehiculo = asignacion.Vehiculo;
      const conductor = asignacion.Conductor;

      const matchesZona =
        zonaFiltro == null || (vehiculo && vehiculo.zona_id === zonaFiltro);

      const matchesSearchTerm =
        !searchTerm ||
        (vehiculo &&
          (vehiculo.placa.toLowerCase().includes(searchTerm.toLowerCase()) ||
            vehiculo.codigo
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))) ||
        (conductor &&
          (conductor.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
            conductor.numero_documento
              .toLowerCase()
              .includes(searchTerm.toLowerCase())));

      return matchesZona && matchesSearchTerm;
    });
  }, [asignaciones, zonaFiltro, searchTerm]);

  const columns = [
    {
      title: "Vehículo",
      dataIndex: "Vehiculo",
      key: "vehiculo",
      sorter: (a, b) => a.Vehiculo.placa.localeCompare(b.Vehiculo.placa),
      render: (vehiculo) => (
        <>
          <strong>{vehiculo?.placa || "N/A"}</strong>
          <br />
          {vehiculo?.codigo || "N/A"}
          <br />
          {vehiculo?.zona?.nombre || "N/A"}
        </>
      ),
    },
    {
      title: "Conductor",
      dataIndex: "Conductor",
      key: "conductor",
      sorter: (a, b) => a.Conductor.nombre.localeCompare(b.Conductor.nombre),
      render: (conductor) => (
        <>
          <strong>{conductor?.nombre || "N/A"}</strong>
          <br />
          {conductor?.numero_documento || "N/A"}
          <br />
          {conductor?.zona?.nombre || "N/A"}
        </>
      ),
    },
    {
      title: "Es Principal",
      dataIndex: "es_principal",
      key: "es_principal",
      filters: [
        { text: "Sí", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.es_principal === value,
      render: (text) =>
        text ? <Tag color="green">Sí</Tag> : <Tag color="volcano">No</Tag>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={filteredAsignaciones.map((asignacion) => ({
        key: `${asignacion.vehiculo_id}-${asignacion.conductor_id}`,
        ...asignacion,
      }))}
      pagination={{ pageSize: 10 }}
    />
  );
};

TablaAsignaciones.propTypes = {
  asignaciones: PropTypes.array.isRequired,
  zonaFiltro: PropTypes.number, // Cambiado a number
  searchTerm: PropTypes.string.isRequired,
};

export default TablaAsignaciones;
