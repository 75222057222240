import { useEffect, useState, useMemo } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const useGoogleMaps = (apiKey) => {
  const [google, setGoogle] = useState(null);

  // Usamos useMemo para asegurar que loader sea consistentes
  const loader = useMemo(() => {
    return new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places", "marker"],
    });
  }, [apiKey]);

  useEffect(() => {
    const loadGoogleMaps = async () => {
      try {
        await loader.importLibrary("maps"); // Ya no se necesita asignar a googleMaps
        setGoogle(window.google);
      } catch (error) {
        console.error("Failed to load Google Maps script:", error);
      }
    };

    loadGoogleMaps();
  }, [loader]);

  return google;
};

export default useGoogleMaps;
