import React from "react";
import { Card, Typography, List, Tag } from "antd";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import PropTypes from "prop-types";

const { Title, Text } = Typography;

// Mapa de colores para los estados
const statusColors = {
  Cancelado: "#b10e1a",
  Finalizado: "#4d4d4d",
  Fallido: "#4a2c04",
  Asignado: "#1f7362",
  "Pre-Asignado": "#0c27a0",
  Programado: "#6c06b5",
  Enterado: "#0c5b07",
  Iniciado: "#fa7114",
  Aceptado: "#088A08",
  "En Camino": "#FFBF00",
  Esperando: "#a44706",
  default: "#b0b500",
};

const ZonasColumn = ({ traslados = [] }) => {
  // Agrupar traslados por zona_paciente
  const zonas = traslados.reduce((acc, traslado) => {
    const zona = traslado.zona_paciente || "Sin Zona";
    if (!acc[zona]) {
      acc[zona] = { total: 0, statuses: {} };
    }
    acc[zona].total += 1;
    const status = traslado.status;
    if (!acc[zona].statuses[status]) {
      acc[zona].statuses[status] = 0;
    }
    acc[zona].statuses[status] += 1;
    return acc;
  }, {});

  // Convertir el objeto de zonas en un array
  const zonasArray = Object.keys(zonas).map((zona) => ({
    nombre: zona,
    total: zonas[zona].total,
    statuses: zonas[zona].statuses,
  }));

  return (
    <Card
      title={
        <Title level={4} style={{ color: "#00509E" }}>
          Zonas
        </Title>
      }
      bordered={false}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      {/* Contenido con scroll usando SimpleBarReact */}
      <SimpleBarReact style={{ maxHeight: "60vh", overflowY: "auto" }}>
        <List
          itemLayout="vertical"
          dataSource={zonasArray}
          renderItem={(zona) => (
            <Card
              key={zona.nombre}
              type="inner"
              style={{
                marginBottom: "16px",
                borderRadius: "8px",
              }}
            >
              {/* Nombre de la zona */}
              <Text strong style={{ display: "block", marginBottom: "8px" }}>
                {zona.nombre}
              </Text>
              {/* Total debajo del nombre */}
              <Tag color="blue" style={{ marginBottom: "16px" }}>
                Total: {zona.total}
              </Tag>
              {/* Lista de estados */}
              <List
                dataSource={Object.entries(zona.statuses)}
                renderItem={([status, count]) => (
                  <List.Item style={{ padding: "4px 0" }}>
                    <Tag
                      color={statusColors[status] || statusColors.default}
                      style={{ marginRight: "8px" }}
                    >
                      {status}
                    </Tag>
                    <Text>{count}</Text>
                  </List.Item>
                )}
              />
            </Card>
          )}
        />
      </SimpleBarReact>
    </Card>
  );
};

ZonasColumn.propTypes = {
  traslados: PropTypes.array.isRequired,
};

export default ZonasColumn;
