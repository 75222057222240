import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Typography,
} from "antd";
import {
  UserAddOutlined,
  MailOutlined,
  PhoneOutlined,
  IdcardOutlined,
  ApartmentOutlined,
  TeamOutlined,
  SafetyOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import api from "../../../axiosConfig";

const { Option } = Select;
const { Title } = Typography;

const AddUserForm = ({ open, handleClose, fetchUsuarios }) => {
  const [form] = Form.useForm();
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");

  const getEmailSuffixIcon = () => {
    switch (emailStatus) {
      case "validating":
        return <CheckCircleTwoTone twoToneColor="#1890ff" />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  };

  const getDocumentSuffixIcon = () => {
    switch (documentStatus) {
      case "validating":
        return <CheckCircleTwoTone twoToneColor="#1890ff" />;
      case "success":
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case "error":
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchTipoDocumentos = async () => {
      try {
        const response = await api.get(
          "https://mogotaxsas.com/api/tipos-documento"
        );
        setTipoDocumentos(response.data);
      } catch (error) {
        console.error("Error al obtener los tipos de documento:", error);
        message.error("Error al cargar los tipos de documento.");
      }
    };
    fetchTipoDocumentos();
  }, []);

  const capitalizeWords = (value) => {
    return value
      .split(" ")
      .map((word) => {
        if (word.length === 0) return "";
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = capitalizeWords(value);
    form.setFieldsValue({ nombre: capitalizedValue });
  };

  const validateNumeroDocumento = async (_, numeroDocumento) => {
    if (!numeroDocumento) {
      setDocumentStatus("error");
      return Promise.reject(new Error("El número de documento es requerido."));
    }
    try {
      setDocumentStatus("validating");
      const response = await api.get(
        `https://mogotaxsas.com/api/auth/check_documento?numero_documento=${numeroDocumento}`
      );
      if (response.data.exists) {
        setDocumentStatus("error");
        return Promise.reject(
          new Error("El número de documento ya está registrado.")
        );
      }
      setDocumentStatus("success");
      return Promise.resolve();
    } catch (error) {
      console.error("Error al verificar el número de documento:", error);
      setDocumentStatus("error");
      return Promise.reject(
        new Error("Error al validar el número de documento.")
      );
    }
  };

  const validateCorreoElectronico = async (_, correoElectronico) => {
    if (!correoElectronico) {
      setEmailStatus("error");
      return Promise.reject(new Error("El correo electrónico es requerido."));
    }
    try {
      setEmailStatus("validating");
      const response = await api.get(
        `https://mogotaxsas.com/api/auth/check_correo?correo_electronico=${encodeURIComponent(
          correoElectronico
        )}`
      );
      if (response.data.exists) {
        setEmailStatus("error");
        return Promise.reject(
          new Error("El correo electrónico ya está registrado.")
        );
      }
      setEmailStatus("success");
      return Promise.resolve();
    } catch (error) {
      console.error("Error al verificar el correo electrónico:", error);
      setEmailStatus("error");
      return Promise.reject(
        new Error("Error al validar el correo electrónico.")
      );
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await api.post("https://mogotaxsas.com/api/auth/register", values);
      fetchUsuarios();
      handleClose();
      message.success("Usuario agregado correctamente.");
      form.resetFields();
      setEmailStatus("");
      setDocumentStatus("");
    } catch (error) {
      console.error("Error al agregar el usuario:", error);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Ocurrió un error al agregar el usuario.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose();
        form.resetFields();
        setEmailStatus("");
        setDocumentStatus("");
      }}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "white",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <UserAddOutlined style={{ marginRight: "8px" }} />
          Agregar Usuario
        </div>
      }
      footer={null}
      width={1000}
      destroyOnClose={true}
    >
      <div
        style={{
          padding: "16px",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            estado: true,
            es_administrador: false,
          }}
        >
          <Row gutter={24}>
            {/* Columna Izquierda */}
            <Col xs={24} sm={24} md={12}>
              {/* Información Básica */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MailOutlined style={{ marginRight: "8px" }} />
                  Información Básica
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Nombre"
                      name="nombre"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el nombre",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El nombre solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        onChange={handleNameChange}
                        placeholder="Ingrese el nombre"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Correo Electrónico"
                      name="correo_electronico"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          type: "email",
                          message: "Ingrese un correo electrónico válido",
                        },
                        {
                          required: true,
                          message: "Por favor ingrese el correo",
                        },
                        {
                          validator: validateCorreoElectronico,
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        placeholder="Ingrese el correo electrónico"
                        suffix={getEmailSuffixIcon()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Información Laboral */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TeamOutlined style={{ marginRight: "8px" }} />
                  Información Laboral
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Cargo"
                      name="cargo"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el cargo",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El cargo solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        prefix={<ApartmentOutlined />}
                        placeholder="Ingrese el cargo"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Área"
                      name="area"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el área",
                        },
                        {
                          pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          message:
                            "El área solo debe contener letras y espacios",
                        },
                      ]}
                    >
                      <Input
                        prefix={<ApartmentOutlined />}
                        placeholder="Ingrese el área"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Estado y Rol */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SafetyOutlined style={{ marginRight: "8px" }} />
                  Estado y Rol
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Estado"
                      name="estado"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione el estado",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione el estado">
                        <Option value={true}>Activo</Option>
                        <Option value={false}>Suspendido</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Es Administrador"
                      name="es_administrador"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione el rol",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione el rol">
                        <Option value={true}>Sí</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Columna Derecha */}
            <Col xs={24} sm={24} md={12}>
              {/* Información de Seguridad */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SafetyOutlined style={{ marginRight: "8px" }} />
                  Información de Seguridad
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contraseña"
                      name="contraseña"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese la contraseña",
                        },
                        {
                          min: 6,
                          message:
                            "La contraseña debe tener al menos 6 caracteres",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<SafetyOutlined />}
                        placeholder="Ingrese la contraseña"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Teléfono"
                      name="telefono"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el teléfono",
                        },
                        {
                          len: 10,
                          message: "El teléfono debe tener 10 dígitos",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "El teléfono solo debe contener números",
                        },
                      ]}
                    >
                      <Input
                        prefix={<PhoneOutlined />}
                        maxLength={10}
                        placeholder="Ingrese el teléfono"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {/* Información Adicional */}
              <div style={{ marginBottom: "24px" }}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IdcardOutlined style={{ marginRight: "8px" }} />
                  Información Adicional
                </Title>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Tipo de Documento"
                      name="tipo_documento_id"
                      rules={[
                        {
                          required: true,
                          message: "Por favor seleccione un tipo de documento",
                        },
                      ]}
                    >
                      {tipoDocumentos.length > 0 ? (
                        <Select placeholder="Seleccione un tipo de documento">
                          {tipoDocumentos.map((tipo) => (
                            <Option key={tipo.id} value={tipo.id}>
                              {tipo.descripcion}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          disabled
                          placeholder="Cargando tipos de documento..."
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Número de Documento"
                      name="numero_documento"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el número de documento",
                        },
                        {
                          pattern: /^\d+$/,
                          message:
                            "El número de documento solo debe contener números",
                        },
                        {
                          validator: validateNumeroDocumento,
                        },
                      ]}
                    >
                      <Input
                        prefix={<IdcardOutlined />}
                        placeholder="Ingrese el número de documento"
                        suffix={getDocumentSuffixIcon()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Botones de Acción */}
          <Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  onClick={() => {
                    handleClose();
                    form.resetFields();
                    setEmailStatus("");
                    setDocumentStatus("");
                  }}
                  style={{
                    backgroundColor: "#d32f2f",
                    color: "white",
                    borderColor: "#d32f2f",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#b71c1c")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#d32f2f")
                  }
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    borderColor: "#0a2e5c",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    marginRight: "8px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#004080")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0a2e5c")
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

// Validación de props con PropTypes
AddUserForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchUsuarios: PropTypes.func.isRequired,
};

export default AddUserForm;
