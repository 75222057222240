// src/components/Clients/Form/ClientForm.js

import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, message, Spin } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import GeneralInfo from "./GeneralInfo";
import AddressInfo from "./AddressInfo";
import AdditionalInfo from "./AdditionalInfo";
import ImportantDates from "./ImportantDates";
import MapDialog from "../../../../Resources/GoogleApi/MapDialog";
import useFetchDepartamentosYZonas from "../../../../hooks/useFetchDepartamentosYZonas";
import useHandleDepartamentoChange from "../../../../hooks/useHandleDepartamentoChange";
import { formatClientDates } from "./utils/formatDates";
import {
  submitClientData,
  ValidationError,
  GeneralError,
} from "./utils/apiClient";
import {
  UserAddOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const ClientForm = ({
  open,
  handleClose,
  fetchClients,
  initialData = null,
  isEdit = false,
}) => {
  const [form] = Form.useForm();
  const { departamentos, zonas, loadingDepartamentos, loadingZonas } =
    useFetchDepartamentosYZonas();
  const { ciudades, loadingCiudades, handleDepartamentoChange } =
    useHandleDepartamentoChange(form);

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Función para formatear datos iniciales
  const formatInitialData = (data) => ({
    ...data,
    fecha_ultima_revision: data.fecha_ultima_revision
      ? dayjs(data.fecha_ultima_revision)
      : null,
    fecha_proxima_revision: data.fecha_proxima_revision
      ? dayjs(data.fecha_proxima_revision)
      : null,
    fecha_contrato_firmado: data.fecha_contrato_firmado
      ? dayjs(data.fecha_contrato_firmado)
      : null,
    fecha_vencimiento_contrato: data.fecha_vencimiento_contrato
      ? dayjs(data.fecha_vencimiento_contrato)
      : null,
    fecha_creacion: data.fecha_creacion ? dayjs(data.fecha_creacion) : null,
    fecha_revision_contrato: data.fecha_revision_contrato
      ? dayjs(data.fecha_revision_contrato)
      : null,
    fecha_aprobacion: data.fecha_aprobacion
      ? dayjs(data.fecha_aprobacion)
      : null,
  });

  useEffect(() => {
    const initializeForm = async () => {
      if (initialData) {
        try {
          setFormLoading(true);
          await handleDepartamentoChange(initialData.departamento_id);
          const formattedData = formatInitialData(initialData);
          form.setFieldsValue(formattedData);
        } catch (error) {
          console.error("Error al inicializar el formulario:", error);
          message.error("Error al inicializar el formulario");
        } finally {
          setFormLoading(false);
        }
      } else {
        form.resetFields();
        form.setFieldsValue({ pais: "Colombia" });
      }
    };

    initializeForm();
  }, [initialData, form, handleDepartamentoChange]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await form.validateFields();
      const formattedValues = formatClientDates(values);
      await submitClientData(isEdit, initialData, formattedValues);

      fetchClients();
      handleClose();
    } catch (error) {
      console.log("Error capturado:", error);
      if (error instanceof ValidationError) {
        form.setFields(
          Object.keys(error.fieldErrors).map((field) => ({
            name: field,
            errors: [error.fieldErrors[field]],
          }))
        );

        Object.values(error.fieldErrors).forEach((errorMsg) => {
          message.error(errorMsg);
        });
      } else if (error instanceof GeneralError) {
        message.error(error.message);
      } else {
        message.error("Error al guardar el cliente");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMapDialogOpen = () => {
    setIsMapOpen(true);
  };

  const handleMapDialogClose = () => {
    setIsMapOpen(false);
  };

  const handleSelect = (direccion, localidad, coordenadas) => {
    form.setFieldsValue({ direccion, localidad, coordenadas });
    setIsMapOpen(false);
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "white",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isEdit ? (
            <>
              <EditOutlined style={{ marginRight: "8px" }} />
              Editar Cliente
            </>
          ) : (
            <>
              <UserAddOutlined style={{ marginRight: "8px" }} />
              Agregar Cliente
            </>
          )}
        </div>
      }
      footer={null}
      width={1000}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Spin spinning={formLoading} tip="Cargando formulario...">
          <Row gutter={24}>
            {/* Columna Izquierda */}
            <Col xs={24} sm={24} md={12}>
              <GeneralInfo />
              <AdditionalInfo />
            </Col>

            {/* Columna Derecha */}
            <Col xs={24} sm={24} md={12}>
              <AddressInfo
                zonas={zonas}
                departamentos={departamentos}
                ciudades={ciudades}
                loadingZonas={loadingZonas}
                loadingDepartamentos={loadingDepartamentos}
                loadingCiudades={loadingCiudades}
                handleMapDialogOpen={handleMapDialogOpen}
                onDepartamentoChange={handleDepartamentoChange}
                onZonaChange={(value) => {
                  console.log("Zona seleccionada:", value);
                }}
              />
              <ImportantDates />
            </Col>
          </Row>

          {/* Botones de Acción */}
          <Form.Item>
            <Row justify="end">
              <Col>
                <Button
                  onClick={() => {
                    handleClose();
                    form.resetFields();
                  }}
                  style={{
                    backgroundColor: "#d32f2f",
                    color: "white",
                    borderColor: "#d32f2f",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                    marginRight: "8px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#b71c1c")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#d32f2f")
                  }
                  icon={<CloseOutlined />}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  style={{
                    backgroundColor: "#0a2e5c",
                    color: "white",
                    borderColor: "#0a2e5c",
                    borderRadius: "4px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#004080")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0a2e5c")
                  }
                  icon={<SaveOutlined />}
                >
                  {isEdit ? "Guardar Cambios" : "Guardar"}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Spin>
      </Form>

      {/* Integrar el MapDialog */}
      <MapDialog
        open={isMapOpen}
        onClose={handleMapDialogClose}
        onSelect={handleSelect}
        initialCoords={form.getFieldValue("coordenadas")}
      />
    </Modal>
  );
};

ClientForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchClients: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default ClientForm;
