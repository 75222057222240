// src/Components/modules/authorizations/Form/PatientInfo.js

import React, { useEffect, useState } from "react";
import { Input, Select, Form, Divider, Row, Col } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

const PatientInfo = ({
  form,
  handleChange,
  patients,
  handlePatientChange,
  isEditMode,
}) => {
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [edad, setEdad] = useState("");

  // Función para calcular la edad
  const calculateAge = (fechaNacimiento) => {
    const birthDate = new Date(fechaNacimiento);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    if (form.paciente_id) {
      const selectedPatient = patients.find(
        (patient) => patient.id === form.paciente_id
      );
      if (selectedPatient?.fecha_nacimiento) {
        setFechaNacimiento(selectedPatient.fecha_nacimiento);
        setEdad(calculateAge(selectedPatient.fecha_nacimiento));
      } else {
        setFechaNacimiento("");
        setEdad("");
      }
    } else {
      setFechaNacimiento("");
      setEdad("");
    }
  }, [form.paciente_id, patients]);

  return (
    <>
      <Divider />

      <Form.Item
        label="Paciente"
        required
        tooltip="Seleccione un paciente de la lista"
      >
        <Select
          showSearch
          placeholder="Seleccione un paciente"
          optionFilterProp="children"
          value={form.paciente_id || undefined}
          onChange={handlePatientChange}
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          disabled={isEditMode}
        >
          {patients.map((patient) => (
            <Option key={patient.id} value={patient.id}>
              {`${patient.nombre} - ${patient.numero_documento}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Nombre del Paciente">
            <Input
              name="nombre_paciente"
              value={form.nombre_paciente || ""}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tipo de Documento">
            <Input
              name="tipo_documento_paciente"
              value={form.tipo_documento_paciente || ""}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Número de Documento">
            <Input
              name="numero_documento_paciente"
              value={form.numero_documento_paciente || ""}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Género">
            <Input name="genero" value={form.genero || ""} disabled />
          </Form.Item>
        </Col>
      </Row>

      {/* Información adicional */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Fecha de Nacimiento">
            <Input value={fechaNacimiento || ""} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Edad">
            <Input value={edad ? `${edad} años` : ""} disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Empresa">
            <Input name="empresa" value={form.empresa || ""} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Régimen">
            <Input name="regimen" value={form.regimen || ""} disabled />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Diagnóstico">
        <Input
          name="diagnostico_nombre"
          value={form.diagnostico_nombre || ""}
          disabled
        />
      </Form.Item>
    </>
  );
};

PatientInfo.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  patients: PropTypes.array.isRequired,
  handlePatientChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default PatientInfo;
