// EmergencyContactInfo.jsx
import React from "react";
import { Form, Input, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const { Title } = Typography;

const EmergencyContactInfo = ({ driver, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información de Contacto de Emergencia
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Nombre de Contacto de Emergencia"
          required
          validateStatus={errors.nombre_emergencia ? "error" : ""}
          help={errors.nombre_emergencia}
        >
          <Input
            name="nombre_emergencia"
            value={driver.nombre_emergencia}
            onChange={handleInputChange}
            placeholder="Nombre completo"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Número de Contacto de Emergencia"
          validateStatus={errors.numero_emergencia ? "error" : ""}
          help={errors.numero_emergencia}
        >
          <Input
            name="numero_emergencia"
            value={driver.numero_emergencia}
            onChange={handleInputChange}
            maxLength={10}
            placeholder="10 dígitos"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Parentesco de Contacto de Emergencia"
          required
          validateStatus={errors.parentesco_emergencia ? "error" : ""}
          help={errors.parentesco_emergencia}
        >
          <Input
            name="parentesco_emergencia"
            value={driver.parentesco_emergencia}
            onChange={handleInputChange}
            placeholder="Ej: Padre, Madre, Cónyuge, etc."
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

EmergencyContactInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default EmergencyContactInfo;
