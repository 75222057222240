import React from "react";
import PropTypes from "prop-types"; // Importar prop-types para la validación
import { TablePagination } from "@mui/material";

function PaginacionTraslados({
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <div
      style={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 1,
      }}
    >
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Filas por página"
      />
    </div>
  );
}

// Validación de las props con prop-types
PaginacionTraslados.propTypes = {
  count: PropTypes.number.isRequired, // 'count' debe ser un número y es requerido
  page: PropTypes.number.isRequired, // 'page' debe ser un número y es requerido
  rowsPerPage: PropTypes.number.isRequired, // 'rowsPerPage' debe ser un número y es requerido
  handleChangePage: PropTypes.func.isRequired, // 'handleChangePage' debe ser una función y es requerido
  handleChangeRowsPerPage: PropTypes.func.isRequired, // 'handleChangeRowsPerPage' debe ser una función y es requerido
};

export default PaginacionTraslados;
