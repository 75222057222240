// src/UserContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    const savedAccessToken = localStorage.getItem("accessToken");
    const savedRefreshToken = localStorage.getItem("refreshToken");
    if (savedUser) {
      setUser(JSON.parse(savedUser)); // Cargar usuario desde localStorage
    }
    if (savedAccessToken) {
      setAccessToken(savedAccessToken);
    }
    if (savedRefreshToken) {
      setRefreshToken(savedRefreshToken);
    }

    // Escuchar el evento 'tokens_updated' para actualizar los tokens en el estado
    const handleTokensUpdated = () => {
      const updatedAccessToken = localStorage.getItem("accessToken");
      const updatedRefreshToken = localStorage.getItem("refreshToken");
      setAccessToken(updatedAccessToken);
      setRefreshToken(updatedRefreshToken);
    };

    window.addEventListener("tokens_updated", handleTokensUpdated);

    return () => {
      window.removeEventListener("tokens_updated", handleTokensUpdated);
    };
  }, []);

  /**
   * Función para iniciar sesión.
   * Almacena el usuario y los tokens en el estado y en localStorage.
   * @param {object} userData - Datos del usuario.
   * @param {string} newAccessToken - Nuevo Access Token.
   * @param {string} newRefreshToken - Nuevo Refresh Token.
   */
  const loginUser = (userData, newAccessToken, newRefreshToken) => {
    localStorage.setItem("user", JSON.stringify(userData)); // Almacenar usuario en localStorage
    localStorage.setItem("accessToken", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    setUser(userData);
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
  };

  /**
   * Función para cerrar sesión.
   * Elimina el usuario y los tokens del estado y de localStorage.
   */
  const logoutUser = () => {
    localStorage.removeItem("user"); // Eliminar usuario de localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
  };

  /**
   * Función para actualizar los tokens.
   * Actualiza el Access Token y el Refresh Token en el estado y en localStorage.
   * @param {string} newAccessToken - Nuevo Access Token.
   * @param {string} newRefreshToken - Nuevo Refresh Token.
   */
  const updateTokens = (newAccessToken, newRefreshToken) => {
    localStorage.setItem("accessToken", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
  };

  const contextValue = useMemo(
    () => ({
      user,
      accessToken,
      refreshToken,
      setUser,
      loginUser,
      logoutUser,
      updateTokens,
    }),
    [user, accessToken, refreshToken]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
