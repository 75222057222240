// AsignarBuscadorFiltro.js
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Input, Select } from "antd";

const { Option } = Select;

const AsignarBuscadorFiltro = ({
  onAsignarClick,
  searchTerm,
  handleSearchTermChange,
  zonaFiltro,
  handleZonaFiltroChange,
  zonas,
}) => {
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
      <Col xs={24} sm={12} md={6}>
        <Button type="primary" onClick={onAsignarClick}>
          Asignar Conductor
        </Button>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Input.Search
          placeholder="Buscar..."
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Select
          placeholder="Filtrar por Zona"
          value={zonaFiltro ?? -1} // Usar -1 para "Todas las Zonas"
          onChange={handleZonaFiltroChange}
          style={{ width: "100%" }}
          allowClear
        >
          <Option value={-1}>Todas las Zonas</Option>
          {zonas.map((zona) => (
            <Option key={zona.id} value={zona.id}>
              {zona.nombre}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

AsignarBuscadorFiltro.propTypes = {
  onAsignarClick: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTermChange: PropTypes.func.isRequired,
  zonaFiltro: PropTypes.number, // Cambiado a number
  handleZonaFiltroChange: PropTypes.func.isRequired,
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AsignarBuscadorFiltro;
