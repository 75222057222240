import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal, Input, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import useGoogleMaps from "./useGoogleMaps";

const MapDialog = ({ open, onClose, onSelect, initialCoords }) => {
  const mapRef = useRef(null);
  const panoramaRef = useRef(null);
  const markerRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [direccion, setDireccion] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [coordenadas, setCoordenadas] = useState("");

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const google = useGoogleMaps(googleApiKey);

  const geocodePosition = useCallback(
    (lat, lng, sessionToken) => {
      const geocoder = new google.maps.Geocoder();
      const position = { lat, lng };
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === "OK" && results[0]) {
          const formattedAddress = results[0].formatted_address;
          const addressParts = formattedAddress.split(",");
          const direccionCorta = addressParts[0].trim();
          const coords = `${lat},${lng}`;

          let loc = "";
          results[0].address_components.forEach((component) => {
            if (
              component.types.includes("sublocality") ||
              component.types.includes("sublocality_level_1")
            ) {
              loc = component.long_name;
            }
          });

          setDireccion(direccionCorta);
          setLocalidad(loc);
          setCoordenadas(coords);
        } else {
          console.error("Geocoder failed due to:", status);
          setDireccion("");
          setLocalidad("");
          setCoordenadas("");
        }
      });
    },
    [google]
  );

  const initializeMap = useCallback(async () => {
    if (!google || !mapRef.current) return;
    const sessionToken = new google.maps.places.AutocompleteSessionToken();
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // Si tenemos initialCoords, las utilizamos, si no, usamos las coordenadas por defecto.
    let startLat = 4.6151959;
    let startLng = -74.1335376;

    if (initialCoords) {
      const [latStr, lngStr] = initialCoords.split(",");
      // Si las coords están en formato "lat,lng", debemos parsear adecuadamente:
      // Verifica el orden en el que las guardaste. Si las guardaste como `${lat},${lng}`
      // asegúrate de asignar correctamente
      startLat = parseFloat(latStr);
      startLng = parseFloat(lngStr);
    }

    const mapInstance = new google.maps.Map(mapRef.current, {
      center: { lat: startLat, lng: startLng },
      zoom: 12,
      mapId: "DEMO_MAP_ID",
    });

    const marker = new AdvancedMarkerElement({
      map: mapInstance,
      position: { lat: startLat, lng: startLng },
      title: "Ubicación Seleccionada",
      draggable: true,
    });

    const panorama = new google.maps.StreetViewPanorama(panoramaRef.current, {
      position: { lat: startLat, lng: startLng },
      pov: { heading: 165, pitch: 0 },
      zoom: 1,
      visible: true,
    });

    mapInstance.setStreetView(panorama);

    const autocomplete = new google.maps.places.Autocomplete(
      autocompleteRef.current.input,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "co" },
        sessionToken: sessionToken,
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      mapInstance.setCenter(place.geometry.location);
      marker.position = place.geometry.location;
      panorama.setPosition(place.geometry.location);

      const addressParts = place.formatted_address.split(",");
      const direccionCorta = addressParts[0].trim();

      let loc = "";
      place.address_components.forEach((component) => {
        if (
          component.types.includes("sublocality") ||
          component.types.includes("sublocality_level_1")
        ) {
          loc = component.long_name;
        }
      });

      setDireccion(direccionCorta);
      setLocalidad(loc);
      setCoordenadas(`${lat},${lng}`);
    });

    google.maps.event.addListener(mapInstance, "click", (event) => {
      marker.position = event.latLng;
      panorama.setPosition(event.latLng);
      panorama.setVisible(true);
      const position = marker.position.toJSON();
      const lat = position.lat;
      const lng = position.lng;
      geocodePosition(lat, lng, sessionToken);
    });

    // Si se pasaron coords iniciales, llamamos a geocodePosition para cargar la dirección
    if (initialCoords) {
      const [latStr, lngStr] = initialCoords.split(",");
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);
      geocodePosition(lat, lng, sessionToken);
    }

    markerRef.current = marker;
  }, [google, geocodePosition, initialCoords]);

  const handleSelect = () => {
    if (markerRef.current) {
      const position = markerRef.current.position.toJSON();
      const lat = position.lat;
      const lng = position.lng;
      if (lat !== undefined && lng !== undefined) {
        geocodePosition(lat, lng);
        onSelect(direccion, localidad, coordenadas);
        onClose();
      } else {
        console.error("Marker position is not valid:", position);
      }
    }
  };

  // Eliminamos el resetState() automático al abrir.
  // Solo resetea si NO tienes initialCoords (siempre y cuando así lo desees).
  useEffect(() => {
    if (open) {
      // Si no hay coords iniciales, reseteamos a valores vacíos
      if (!initialCoords) {
        setDireccion("");
        setLocalidad("");
        setCoordenadas("");
        markerRef.current = null;
      }
      if (mapRef.current) {
        mapRef.current.innerHTML = "";
      }
      setTimeout(() => {
        initializeMap();
      }, 100);
    }
  }, [open, initializeMap, initialCoords]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Seleccionar Dirección en el Mapa"
      footer={[
        <Button key="select" type="primary" onClick={handleSelect}>
          Seleccionar
        </Button>,
        <Button key="close" onClick={onClose}>
          Cerrar
        </Button>,
      ]}
      width={900}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Input
            ref={autocompleteRef}
            placeholder="Buscar dirección o lugar"
            allowClear
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "16px" }}>
        <Col span={24}>
          <div ref={mapRef} style={{ height: "300px" }} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "16px" }}>
        <Col span={24}>
          <div ref={panoramaRef} style={{ height: "300px" }} />
        </Col>
      </Row>
    </Modal>
  );
};

MapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  initialCoords: PropTypes.string,
};

export default MapDialog;
