// src/Components/modules/Coordinacion/MovilesColumn.js

import React from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Typography,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import PersonIcon from "@mui/icons-material/Person";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const MovilesColumn = ({
  moviles,
  searchTerm,
  setSearchTerm,
  selectedMovilId,
  onSelectMovil,
}) => {
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  // Función para obtener el color de fondo
  const getBackgroundColor = (movil) => {
    if (selectedMovilId === movil.id) return "#affd94";
    if (movil.Conductor) return "#e6f7ff";
    return "#fff1f0";
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 0,
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#00509E",
          backgroundColor: "#ffffff",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          padding: "10px",
          margin: 0,
          boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.1)",
          minHeight: "50px",
        }}
      >
        Móviles
      </Typography>

      <Box sx={{ padding: "10px", backgroundColor: "#f9f9f9" }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Buscar por código o placa"
          value={searchTerm}
          onChange={handleSearch}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "5px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ccc",
              },
              "&:hover fieldset": {
                borderColor: "#00509E",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00509E",
              },
            },
          }}
        />
      </Box>

      <SimpleBarReact style={{ maxHeight: "60vh", overflowY: "auto" }}>
        <List>
          {moviles.map((movil) => (
            <ListItem
              key={movil.id || movil.codigo}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("movil", JSON.stringify(movil));
              }}
              onClick={() => onSelectMovil(movil)}
              sx={{
                backgroundColor: getBackgroundColor(movil),
                marginBottom: "10px",
                borderRadius: "10px",
                padding: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
                  cursor: "pointer",
                },
              }}
            >
              <ListItemText
                primary={
                  <Box
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      color: "#00509E",
                      wordBreak: "break-word",
                    }}
                  >
                    {`${movil.codigo} (${movil.placa})`}
                  </Box>
                }
                secondary={
                  <Box component="div">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        sx={{
                          backgroundColor: movil.Conductor
                            ? "#007bff"
                            : "#ff4d4f",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                        }}
                      >
                        {movil.Conductor ? (
                          <PersonIcon fontSize="small" />
                        ) : (
                          <ErrorOutlineIcon fontSize="small" />
                        )}
                      </Avatar>
                      <Box component="span" sx={{ color: "#000" }}>
                        {movil.Conductor?.nombre ?? "Sin conductor asignado"}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: movil.zona ? "#007bff" : "#ff4d4f",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                        }}
                      >
                        <LocationOnIcon fontSize="small" />
                      </Avatar>
                      <Box component="span" sx={{ color: "#000" }}>
                        {movil.zona?.nombre ?? "Sin zona asignada"}
                      </Box>
                    </Box>
                  </Box>
                }
                primaryTypographyProps={{ component: "div" }}
                secondaryTypographyProps={{ component: "div" }}
              />
            </ListItem>
          ))}
        </List>
      </SimpleBarReact>
    </Paper>
  );
};

MovilesColumn.propTypes = {
  moviles: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  selectedMovilId: PropTypes.number,
  onSelectMovil: PropTypes.func.isRequired,
};

export default MovilesColumn;
