// MedicalInfo.jsx
import React from "react";
import { Form, Select, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;
const { Title } = Typography;

// Opciones para Grupo Sanguíneo
const gruposSanguineos = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];

const MedicalInfo = ({ driver, handleInputChange, errors }) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información Médica
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Grupo Sanguíneo"
          required
          validateStatus={errors.grupo_sanguineo ? "error" : ""}
          help={errors.grupo_sanguineo}
        >
          <Select
            value={driver.grupo_sanguineo}
            onChange={(value) =>
              handleInputChange({
                target: { name: "grupo_sanguineo", value },
              })
            }
            placeholder="Seleccione Grupo Sanguíneo"
            allowClear
          >
            {gruposSanguineos.map((grupo) => (
              <Option key={grupo.value} value={grupo.value}>
                {grupo.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

MedicalInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default MedicalInfo;
