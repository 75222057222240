import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Input,
  Card,
  Row,
  Col,
  Tooltip,
  Modal,
  Typography,
  Space,
} from "antd";
import {
  EnvironmentOutlined,
  HomeOutlined,
  BankOutlined,
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import InstitutionsForm from "./InstitutionsForm";
import { useUser } from "../../../UserContext"; // Importa el contexto de usuario
import "./Institutions.css";

const { Search } = Input;
const { Text } = Typography;

const Institutions = () => {
  const { user } = useUser(); // Usa el contexto de usuario
  const [institutions, setInstitutions] = useState([]);
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentInstitution, setCurrentInstitution] = useState(null);
  const [institutionToDelete, setInstitutionToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchInstitutions();
  }, []);

  const fetchInstitutions = async () => {
    try {
      const response = await api.get(
        "https://mogotaxsas.com/api/instituciones"
      );
      setInstitutions(response.data);
      setFilteredInstitutions(response.data);
    } catch (error) {
      console.error("Error al obtener las instituciones:", error);
    }
  };

  const filterInstitutions = useCallback(() => {
    const filtered = institutions.filter((institution) =>
      institution.nombre.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredInstitutions(filtered);
  }, [institutions, searchQuery]);

  useEffect(() => {
    filterInstitutions();
  }, [searchQuery, institutions, filterInstitutions]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClickOpen = (institution = null) => {
    setCurrentInstitution(institution);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentInstitution(null);
  };

  const handleDeleteDialogOpen = (institution) => {
    setInstitutionToDelete(institution);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setInstitutionToDelete(null);
  };

  const handleDelete = async () => {
    if (institutionToDelete) {
      try {
        await api.delete(
          `https://mogotaxsas.com/api/instituciones/${institutionToDelete.id}`,
          {
            data: { usuario_id: user.id }, // Incluye el ID del usuario en la solicitud
          }
        );
        fetchInstitutions();
        handleDeleteDialogClose();
      } catch (error) {
        console.error("Error al eliminar la institución:", error);
      }
    }
  };

  const openInGoogleMaps = (coordinates) => {
    const [lat, lon] = coordinates;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`,
      "_blank"
    );
  };

  return (
    <div style={{ padding: 16 }}>
      {/* Usamos Space para el buscador y el botón en la misma línea */}
      <Space
        style={{
          marginBottom: 20,
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Search
          placeholder="Buscar institución"
          value={searchQuery}
          onChange={handleSearchChange}
          enterButton={<SearchOutlined />}
          style={{ width: "200%" }} // Ajusta el tamaño según necesites
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleClickOpen()}
          style={{ marginRight: 10 }}
        >
          Agregar Nueva Institución
        </Button>
      </Space>

      <Row gutter={[16, 16]}>
        {filteredInstitutions.map((institution) => (
          <Col xs={24} sm={12} md={8} key={institution.id}>
            <Card
              title={institution.nombre}
              bordered={false}
              actions={[
                <Tooltip title="Ver en Google Maps" key="google-maps">
                  <EnvironmentOutlined
                    onClick={() =>
                      openInGoogleMaps(institution.coordenadas.coordinates)
                    }
                  />
                </Tooltip>,
                <Tooltip title="Editar" key="edit">
                  <EditOutlined onClick={() => handleClickOpen(institution)} />
                </Tooltip>,
                <Tooltip title="Borrar" key="delete">
                  <DeleteOutlined
                    onClick={() => handleDeleteDialogOpen(institution)}
                  />
                </Tooltip>,
              ]}
            >
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <BankOutlined />{" "}
                  <Text>
                    {institution.Departamento?.nombre || "Sin departamento"}
                  </Text>
                </Col>
                <Col span={12}>
                  <HomeOutlined /> <Text>{institution.direccion}</Text>
                </Col>
                <Col span={12}>
                  <EnvironmentOutlined />{" "}
                  <Text>{institution.Ciudad?.nombre || "Sin ciudad"}</Text>
                </Col>
                <Col span={12}>
                  <EnvironmentOutlined /> <Text>{institution.barrio}</Text>
                </Col>
                {institution.indicaciones && (
                  <Col span={24}>
                    <InfoCircleOutlined />{" "}
                    <Text>{institution.indicaciones}</Text>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>

      <InstitutionsForm
        open={open}
        handleClose={handleClose}
        fetchInstitutions={fetchInstitutions}
        currentInstitution={currentInstitution}
      />

      <Modal
        title="Confirmar Eliminación"
        open={deleteDialogOpen}
        onOk={handleDelete}
        onCancel={handleDeleteDialogClose}
        okText="Borrar"
        cancelText="Cancelar"
      >
        <p>¿Estás seguro de que deseas eliminar esta institución?</p>
      </Modal>
    </div>
  );
};

export default Institutions;
