// MipresFields.js
import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Input, Switch } from "antd";

const MipresFields = ({ form, handleChange, handleNoPbsChange, errors }) => {
  const handleCodigoMipresChange = (e) => {
    const { value } = e.target;
    const regex = /^\d*$/;
    if (regex.test(value)) {
      handleChange(e);
    }
  };

  const isCodigoMipresInvalid =
    !form.no_pbs && form.codigo_mipres && form.codigo_mipres.length !== 20;

  const codigoMipresValidateStatus =
    isCodigoMipresInvalid || errors.codigo_mipres ? "error" : "";

  const codigoMipresHelp = isCodigoMipresInvalid
    ? "Debe ingresar exactamente 20 dígitos."
    : errors.codigo_mipres || "";

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="Código MIPRES"
          required={!form.no_pbs}
          validateStatus={codigoMipresValidateStatus}
          help={codigoMipresHelp}
        >
          <Input
            name="codigo_mipres"
            value={form.codigo_mipres || ""}
            onChange={handleCodigoMipresChange}
            maxLength={20}
            disabled={form.no_pbs}
            placeholder="Ingrese 20 dígitos"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item>
          <Switch
            checked={form.no_pbs}
            onChange={handleNoPbsChange}
            checkedChildren="NO PBS"
            unCheckedChildren="PBS"
            name="no_pbs"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

MipresFields.propTypes = {
  form: PropTypes.shape({
    no_pbs: PropTypes.bool,
    codigo_mipres: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleNoPbsChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    codigo_mipres: PropTypes.string,
  }),
};

export default MipresFields;
