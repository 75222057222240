// src/components/Clients/Form/GeneralInfo.js

import React from "react";
import { Form, Input, Typography, Row, Col } from "antd";
import { PhoneOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

const { Title } = Typography;

const GeneralInfo = () => (
  <div style={{ marginBottom: "24px" }}>
    <Title
      level={4}
      style={{
        marginBottom: 16,
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <UserOutlined style={{ marginRight: "8px" }} />
      Información General
    </Title>

    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Nombre del Cliente"
          name="nombre_cliente"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el nombre del cliente",
            },
            {
              min: 2,
              message: "El nombre del cliente debe tener al menos 2 caracteres",
            },
            {
              max: 100,
              message:
                "El nombre del cliente no puede exceder los 100 caracteres",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Nombre del Cliente" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Nombre del Contacto"
          name="nombre_contacto"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el nombre del contacto",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Nombre del Contacto" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Teléfono"
          name="telefono"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un número de teléfono",
            },
            {
              pattern: /^[0-9\b]+$/,
              message: "El teléfono solo debe contener números",
            },
            {
              pattern: /^\d{10}$/, // Exactamente 10 dígitos
              message: "El teléfono debe tener 10 dígitos",
            },
          ]}
        >
          <Input
            maxLength={10}
            prefix={<PhoneOutlined />}
            placeholder="Ingrese el teléfono"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Correo Electrónico"
          name="correo_electronico"
          rules={[
            {
              required: true,
              type: "email",
              message: "Por favor ingrese un correo electrónico válido",
            },
            {
              max: 100,
              message:
                "El correo electrónico no puede exceder los 100 caracteres",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Ingrese el correo" />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default GeneralInfo;
