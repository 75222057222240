// src/components/AgendarDialog/AgendarDialog.jsx
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Calendar, message, ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/es";
import es_ES from "antd/es/locale/es_ES";
import { useUser } from "../../../UserContext";

import CustomCellRender from "./AgendarDialog/CustomCellRender"; // Importamos el nuevo componente
import EditPopoverContent from "./AgendarDialog/EditPopoverContent";
import LoadingProgress from "./AgendarDialog/common/LoadingProgress";
import WeekdaySelector from "./AgendarDialog/common/WeekdaySelector";
import useTraslados from "./AgendarDialog/hooks/useTraslados";

moment.locale("es");

const AgendarDialog = ({
  open,
  onClose,
  form,
  minFecha,
  maxFecha,
  distance,
  duration,
  distanceReturn,
  durationReturn,
  onCloseParentDialog,
}) => {
  const { user } = useUser();
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState({
    domingo: false,
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
  });
  const [trasladosRestantes, setTrasladosRestantes] = useState(0);
  const [trasladosNecesarios, setTrasladosNecesarios] = useState(0);
  const [draggingDay, setDraggingDay] = useState(null);
  const [draggingType, setDraggingType] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [editingDay, setEditingDay] = useState(null);
  const [idaTime, setIdaTime] = useState(null);
  const [llegadaTime, setLlegadaTime] = useState(null);
  const [retornoRecogidaTime, setRetornoRecogidaTime] = useState(null);
  const [retornoLlegadaTime, setRetornoLlegadaTime] = useState(null);

  const { loading, progress, totalTraslados, confirmarAgendar } =
    useTraslados();

  const setTimesFromForm = useCallback(() => {
    setIdaTime(
      form.getFieldValue("hora_recogida")
        ? moment(form.getFieldValue("hora_recogida"), "HH:mm")
        : null
    );
    setLlegadaTime(
      form.getFieldValue("hora_llegada")
        ? moment(form.getFieldValue("hora_llegada"), "HH:mm")
        : null
    );
    setRetornoRecogidaTime(
      form.getFieldValue("hora_retorno_recogida")
        ? moment(form.getFieldValue("hora_retorno_recogida"), "HH:mm")
        : null
    );
    setRetornoLlegadaTime(
      form.getFieldValue("hora_retorno_llegada")
        ? moment(form.getFieldValue("hora_retorno_llegada"), "HH:mm")
        : null
    );
  }, [form]);

  useEffect(() => {
    if (open) {
      setSelectedDays([]);
      setSelectedWeekdays({
        domingo: false,
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
      });

      const remainingTransfers = form.getFieldValue("traslados_restantes") || 0;
      console.log("Traslados Restantes Iniciales:", remainingTransfers);
      setTrasladosRestantes(remainingTransfers);

      const isRoundTrip =
        form.getFieldValue("hora_retorno_recogida") &&
        form.getFieldValue("hora_retorno_llegada");
      setTrasladosNecesarios(isRoundTrip ? 2 : 1);

      setTimesFromForm();

      const today = moment();
      setCurrentMonth(today.month());
      setCurrentYear(today.year());
    }
  }, [open, form, setTimesFromForm]);

  const validDate = (date) => {
    return (
      !date.isBefore(moment(minFecha)) &&
      !date.isAfter(moment(maxFecha).endOf("day"))
    );
  };

  const handleConfirmarAgendar = async () => {
    console.log("Confirmar Agendar - Días Seleccionados:", selectedDays);
    if (selectedDays.length === 0) {
      message.error("No se han seleccionado días para agendar.");
      return;
    }

    const traslados = selectedDays.flatMap((day) => {
      const trasladosDia = [];
      const origenCoords = form
        .getFieldValue("origen_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim()));
      const destinoCoords = form
        .getFieldValue("destino_coordenadas")
        ?.split(",")
        .map((coord) => parseFloat(coord.trim()));

      if (day.ida) {
        trasladosDia.push({
          usuario_id: user.id,
          autorizacion_id: form.getFieldValue("autorizacion_id"), // Agrega esta línea
          paciente_id: form.getFieldValue("paciente_id"),
          nombre_paciente: form.getFieldValue("nombre_paciente"),
          tipo_usuario: form.getFieldValue("tipo_usuario"),
          tipo_documento_paciente: form.getFieldValue(
            "tipo_documento_paciente"
          ),
          numero_documento_paciente: form.getFieldValue(
            "numero_documento_paciente"
          ),
          genero_paciente: form.getFieldValue("genero_paciente"),
          numero_contacto_paciente: form.getFieldValue(
            "numero_contacto_paciente"
          ),
          parentesco_responsable: form.getFieldValue("parentesco_responsable"),
          numero_contacto_responsable: form.getFieldValue(
            "numero_contacto_responsable"
          ),
          empresa: form.getFieldValue("empresa"),
          preautorizacion: form.getFieldValue("preautorizacion"),
          autorizacion: form.getFieldValue("autorizacion"),
          proposito: form.getFieldValue("proposito"),
          diagnostico_nombre: form.getFieldValue("diagnostico_nombre"),
          copago: form.getFieldValue("copago"),
          tipo_traslado: form.getFieldValue("tipo_traslado"),
          tipo_viaje: form.getFieldValue("tipo_viaje"),
          trayecto: "Ida",
          tipo_autorizacion: form.getFieldValue("tipo_autorizacion"),
          observaciones_autorizacion: form.getFieldValue(
            "observaciones_autorizacion"
          ),
          observaciones_traslado: form.getFieldValue("observaciones_traslado"),
          zona_paciente: form.getFieldValue("zona_paciente"),
          fecha: day.ida,
          hora_recogida: day.idaTime
            ? day.idaTime.format("HH:mm")
            : form.getFieldValue("hora_recogida")?.format("HH:mm"),
          hora_llegada: day.llegadaTime
            ? day.llegadaTime.format("HH:mm")
            : form.getFieldValue("hora_llegada")?.format("HH:mm"),
          duracion_estimada: Math.round(duration),
          distancia_estimada: parseFloat(distance),
          vehiculo_id: null,
          conductor_id: null,
          status: "Programado",
          seguimiento: "Sin Validar",
          confirmacion: "Sin Confirmar",
          origen_direccion: form.getFieldValue("origen_direccion"),
          origen_departamento: form.getFieldValue("origen_departamento"),
          origen_ciudad: form.getFieldValue("origen_ciudad"),
          origen_localidad: form.getFieldValue("origen_localidad"),
          origen_barrio: form.getFieldValue("origen_barrio"),
          origen_indicaciones: form.getFieldValue("origen_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [origenCoords[1], origenCoords[0]], // Invertimos el orden
          },
          destino_direccion: form.getFieldValue("destino_direccion"),
          destino_departamento: form.getFieldValue("destino_departamento"),
          destino_ciudad: form.getFieldValue("destino_ciudad"),
          destino_localidad: form.getFieldValue("destino_localidad"),
          destino_barrio: form.getFieldValue("destino_barrio"),
          destino_indicaciones: form.getFieldValue("destino_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords[1], destinoCoords[0]], // [longitud, latitud]
          },
        });
      }

      if (day.retorno) {
        trasladosDia.push({
          ...trasladosDia[0], // Reutilizar los mismos campos
          trayecto: "Retorno",
          fecha: day.retorno,
          autorizacion_id: form.getFieldValue("autorizacion_id"), // Asegúrate de incluir esta línea
          hora_recogida: day.retornoRecogidaTime
            ? day.retornoRecogidaTime.format("HH:mm")
            : form.getFieldValue("hora_retorno_recogida")?.format("HH:mm"),
          hora_llegada: day.retornoLlegadaTime
            ? day.retornoLlegadaTime.format("HH:mm")
            : form.getFieldValue("hora_retorno_llegada")?.format("HH:mm"),
          duracion_estimada: Math.round(durationReturn),
          distancia_estimada: parseFloat(distanceReturn),
          origen_direccion: form.getFieldValue("destino_direccion"),
          origen_departamento: form.getFieldValue("destino_departamento"),
          origen_ciudad: form.getFieldValue("destino_ciudad"),
          origen_localidad: form.getFieldValue("destino_localidad"),
          origen_barrio: form.getFieldValue("destino_barrio"),
          origen_indicaciones: form.getFieldValue("destino_indicaciones"),
          origen_coordenadas: {
            type: "Point",
            coordinates: [destinoCoords[1], destinoCoords[0]], // [longitud, latitud]
          },
          destino_direccion: form.getFieldValue("origen_direccion"),
          destino_departamento: form.getFieldValue("origen_departamento"),
          destino_ciudad: form.getFieldValue("origen_ciudad"),
          destino_localidad: form.getFieldValue("origen_localidad"),
          destino_barrio: form.getFieldValue("origen_barrio"),
          destino_indicaciones: form.getFieldValue("origen_indicaciones"),
          destino_coordenadas: {
            type: "Point",
            coordinates: [origenCoords[1], origenCoords[0]], // [longitud, latitud]
          },
        });
      } else {
        console.log("Error: La fecha de retorno es null.");
      }

      return trasladosDia;
    });

    confirmarAgendar(traslados, onClose, onCloseParentDialog);
  };

  const handleDrop = (date) => {
    if (!draggingDay) return;

    const formattedDate = date.format("YYYY-MM-DD");
    console.log("Drop - Fecha seleccionada:", formattedDate);

    setSelectedDays((prevSelectedDays) => {
      const updatedDays = prevSelectedDays.map((day) => {
        if (draggingType === "Ida" && day.ida === draggingDay) {
          return { ...day, ida: null };
        } else if (draggingType === "Retorno" && day.retorno === draggingDay) {
          return { ...day, retorno: null };
        }
        return day;
      });

      const existingDay = updatedDays.find(
        (day) => day.ida === formattedDate || day.retorno === formattedDate
      );

      if (existingDay) {
        if (draggingType === "Ida") {
          existingDay.ida = formattedDate;
        } else {
          existingDay.retorno = formattedDate;
        }
      } else {
        updatedDays.push({
          ida: draggingType === "Ida" ? formattedDate : null,
          retorno: draggingType === "Retorno" ? formattedDate : null,
          idaTime: form.getFieldValue("hora_recogida"),
          llegadaTime: form.getFieldValue("hora_llegada"),
          retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
          retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
        });
      }

      console.log("Días seleccionados después del drop:", updatedDays);
      return updatedDays.filter((day) => day.ida || day.retorno);
    });

    setDraggingDay(null);
    setDraggingType(null);
  };

  const handleDragStart = (e, trayecto, date) => {
    e.dataTransfer.setData("trayecto", trayecto);
    setDraggingDay(date.format("YYYY-MM-DD"));
    setDraggingType(trayecto);
    console.log(
      "Drag Start - Trayecto:",
      trayecto,
      "Fecha:",
      date.format("YYYY-MM-DD")
    );
  };

  const handleWeekdaySelect = (weekday) => {
    const daysOfWeek = {
      domingo: 0,
      lunes: 1,
      martes: 2,
      miercoles: 3,
      jueves: 4,
      viernes: 5,
      sabado: 6,
    };

    const selectedDayIndex = daysOfWeek[weekday];
    const newSelectedDays = [];
    const datesToDeselect = [];

    let currentDate = moment(minFecha);
    while (currentDate.isSameOrBefore(maxFecha, "day")) {
      if (currentDate.day() === selectedDayIndex) {
        const formattedDate = currentDate.format("YYYY-MM-DD");
        if (selectedDays.some((day) => day.ida === formattedDate)) {
          datesToDeselect.push(formattedDate);
        } else {
          newSelectedDays.push({
            ida: formattedDate,
            retorno: trasladosNecesarios === 2 ? formattedDate : null,
            idaTime: form.getFieldValue("hora_recogida"),
            llegadaTime: form.getFieldValue("hora_llegada"),
            retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
            retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
          });
        }
      }
      currentDate.add(1, "day");
    }

    const totalTrasladosSeleccionados =
      (selectedDays.length - datesToDeselect.length + newSelectedDays.length) *
      trasladosNecesarios;
    if (totalTrasladosSeleccionados > trasladosRestantes) {
      message.error(
        `No puedes seleccionar más de ${Math.floor(
          trasladosRestantes / trasladosNecesarios
        )} días.`
      );
      return;
    }

    setSelectedDays((prevSelectedDays) => [
      ...prevSelectedDays.filter((day) => !datesToDeselect.includes(day.ida)),
      ...newSelectedDays,
    ]);

    setSelectedWeekdays((prevSelectedWeekdays) => ({
      ...prevSelectedWeekdays,
      [weekday]: !prevSelectedWeekdays[weekday],
    }));

    console.log(
      "Días seleccionados después de seleccionar un día de la semana:",
      selectedDays
    );
  };

  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    console.log("Fecha seleccionada:", formattedDate);
    console.log("Fecha mínima:", minFecha);
    console.log("Fecha máxima:", maxFecha);

    const selectedMonth = date.month();
    const selectedYear = date.year();

    if (selectedMonth !== currentMonth || selectedYear !== currentYear) {
      setCurrentMonth(selectedMonth);
      setCurrentYear(selectedYear);
      return;
    }

    if (!validDate(date)) {
      return;
    }

    if (selectedDays.some((day) => day.ida === formattedDate)) {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day.ida !== formattedDate)
      );
    } else {
      const totalTrasladosSeleccionados =
        (selectedDays.length + 1) * trasladosNecesarios;

      if (totalTrasladosSeleccionados > trasladosRestantes) {
        message.error(
          `No puedes seleccionar más de ${Math.floor(
            trasladosRestantes / trasladosNecesarios
          )} días.`
        );
        return;
      }

      setSelectedDays((prevSelectedDays) => [
        ...prevSelectedDays,
        {
          ida: formattedDate,
          retorno: trasladosNecesarios === 2 ? formattedDate : null,
          idaTime: form.getFieldValue("hora_recogida"),
          llegadaTime: form.getFieldValue("hora_llegada"),
          retornoRecogidaTime: form.getFieldValue("hora_retorno_recogida"),
          retornoLlegadaTime: form.getFieldValue("hora_retorno_llegada"),
        },
      ]);
    }

    console.log(
      "Días seleccionados después de seleccionar una fecha:",
      selectedDays
    );
  };

  const handleSaveTimes = () => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.map((day) => {
        if (day.ida === editingDay || day.retorno === editingDay) {
          return {
            ...day,
            idaTime,
            llegadaTime,
            retornoRecogidaTime,
            retornoLlegadaTime,
          };
        }
        return day;
      })
    );
    setEditingDay(null);
    console.log("Horas guardadas para el día:", editingDay, selectedDays);
  };

  const renderEditPopoverContentFunc = () => {
    const selectedDay = selectedDays.find(
      (day) => day.ida === editingDay || day.retorno === editingDay
    );
    return (
      <EditPopoverContent
        idaTime={idaTime}
        setIdaTime={setIdaTime}
        llegadaTime={llegadaTime}
        setLlegadaTime={setLlegadaTime}
        retornoRecogidaTime={retornoRecogidaTime}
        setRetornoRecogidaTime={setRetornoRecogidaTime}
        retornoLlegadaTime={retornoLlegadaTime}
        setRetornoLlegadaTime={setRetornoLlegadaTime}
        handleSaveTimes={handleSaveTimes}
        form={form}
        selectedDay={selectedDay}
      />
    );
  };

  const handleEditClick = (formattedDate, selectedDay) => {
    setEditingDay(formattedDate);

    if (selectedDay) {
      setIdaTime(
        selectedDay.idaTime
          ? moment(selectedDay.idaTime, "HH:mm")
          : moment(form.getFieldValue("hora_recogida"), "HH:mm")
      );
      setLlegadaTime(
        selectedDay.llegadaTime
          ? moment(selectedDay.llegadaTime, "HH:mm")
          : moment(form.getFieldValue("hora_llegada"), "HH:mm")
      );
      setRetornoRecogidaTime(
        selectedDay.retornoRecogidaTime
          ? moment(selectedDay.retornoRecogidaTime, "HH:mm")
          : moment(form.getFieldValue("hora_retorno_recogida"), "HH:mm")
      );
      setRetornoLlegadaTime(
        selectedDay.retornoLlegadaTime
          ? moment(selectedDay.retornoLlegadaTime, "HH:mm")
          : moment(form.getFieldValue("hora_retorno_llegada"), "HH:mm")
      );
    }
    console.log("Editando horas para la fecha:", formattedDate, selectedDay);
  };

  return (
    <ConfigProvider locale={es_ES}>
      <Modal
        open={open}
        onCancel={onClose}
        title="Agendamiento de Traslados En Proceso"
        footer={[
          <Button key="cancelar" onClick={onClose} disabled={loading}>
            Cancelar
          </Button>,
          <Button
            key="confirmar"
            type="primary"
            onClick={handleConfirmarAgendar}
            loading={loading}
            disabled={loading}
          >
            Confirmar
          </Button>,
        ]}
        width={1000}
      >
        {loading ? (
          <LoadingProgress
            progress={progress}
            totalTraslados={totalTraslados}
          />
        ) : (
          <>
            <WeekdaySelector
              selectedWeekdays={selectedWeekdays}
              handleWeekdaySelect={handleWeekdaySelect}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Traslados restantes:</strong>
                <div>{trasladosRestantes}</div>
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Traslados a utilizar por día:</strong>
                <div>{trasladosNecesarios}</div>
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <strong>Total días seleccionados:</strong>
                <div>{selectedDays.length}</div>
              </div>
            </div>

            <Calendar
              style={{
                fontSize: "14px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #dfe1e5",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              cellRender={(date) => (
                <CustomCellRender
                  date={date}
                  selectedDays={selectedDays}
                  handleDragStart={handleDragStart}
                  handleDrop={handleDrop}
                  handleEditClick={handleEditClick}
                  editingDay={editingDay}
                  renderEditPopoverContent={renderEditPopoverContentFunc}
                  currentMonth={currentMonth}
                />
              )}
              onSelect={handleDateSelect}
              onPanelChange={(date) => {
                setCurrentMonth(date.month());
                setCurrentYear(date.year());
              }}
              disabledDate={(date) =>
                date?.isBefore(moment(minFecha)) ||
                date?.isAfter(moment(maxFecha).endOf("day"))
              }
            />
          </>
        )}
      </Modal>
    </ConfigProvider>
  );
};

AgendarDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  minFecha: PropTypes.string.isRequired,
  maxFecha: PropTypes.string.isRequired,
  distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  distanceReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  durationReturn: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCloseParentDialog: PropTypes.func,
};

export default AgendarDialog;
