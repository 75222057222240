import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Row, Col, Button, Card, Space } from "antd";
import {
  UserOutlined,
  CarOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { formatCurrencyCOP } from "../../../utils/formatCurrency"; // Importa la función de formateo

const { Title, Text } = Typography;

// Componente InfoCard para mostrar la información de cada sección
const InfoCard = ({ title, icon, children }) => (
  <Card
    bordered={false}
    style={{
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
      backgroundColor: "#fff",
      marginBottom: "24px",
    }}
    title={
      <Space align="center">
        {icon}
        <Title level={5} style={{ margin: 0, fontWeight: 500 }}>
          {title}
        </Title>
      </Space>
    }
  >
    {children}
  </Card>
);

function VerTrasladoDialog({ open, onClose, traslado }) {
  if (!traslado) return null;

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Cerrar
        </Button>,
      ]}
      title={
        <Space align="center">
          <InfoCircleOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          <Title level={4} style={{ margin: 0 }}>
            Detalles del Traslado CS - {traslado.id}
          </Title>
        </Space>
      }
      width={1000}
      style={{ padding: "24px" }}
      centered
      destroyOnClose
    >
      <Row gutter={[24, 24]}>
        {/* Información del Usuario y Médica */}
        <Col xs={24} md={12} lg={8}>
          <InfoCard
            title="Información del Usuario"
            icon={
              <UserOutlined style={{ fontSize: "20px", color: "#52c41a" }} />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>Nombre:</Text> {traslado.nombre_paciente}
              </Text>
              <Text>
                <Text strong>Tipo de Usuario:</Text> {traslado.tipo_usuario}
              </Text>
              <Text>
                <Text strong>Tipo Documento:</Text>{" "}
                {traslado.tipo_documento_paciente}
              </Text>
              <Text>
                <Text strong>Núm. Documento:</Text>{" "}
                {traslado.numero_documento_paciente}
              </Text>
            </Space>
          </InfoCard>

          <InfoCard
            title="Información Médica"
            icon={
              <FileTextOutlined
                style={{ fontSize: "20px", color: "#faad14" }}
              />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>EPS:</Text> {traslado.empresa}
              </Text>
              <Text>
                <Text strong>Preautorización:</Text>{" "}
                {traslado.preautorizacion || "N/A"}
              </Text>
              <Text>
                <Text strong>Autorización:</Text> {traslado.autorizacion}
              </Text>
              <Text>
                <Text strong>Procedimiento:</Text> {traslado.proposito}
              </Text>
              <Text>
                <Text strong>Diagnóstico:</Text> {traslado.diagnostico_nombre}
              </Text>
              <Text>
                <Text strong>Copago:</Text>{" "}
                {formatCurrencyCOP(traslado.copago) || "$ 0,00"}
              </Text>
            </Space>
          </InfoCard>
        </Col>

        {/* Vehículo, Conductor y Estado */}
        <Col xs={24} md={12} lg={8}>
          <InfoCard
            title="Vehículo y Conductor"
            icon={
              <CarOutlined style={{ fontSize: "20px", color: "#eb2f96" }} />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>Vehículo:</Text>{" "}
                {traslado.nombre_vehiculo || "Sin Asignar"}
              </Text>
              <Text>
                <Text strong>Placa:</Text> {traslado.placa || "Sin Asignar"}
              </Text>
              <Text>
                <Text strong>Conductor:</Text>{" "}
                {traslado.nombre_conductor || "Sin Asignar"}
              </Text>
            </Space>
          </InfoCard>

          <InfoCard
            title="Estado del Traslado"
            icon={
              <FileTextOutlined
                style={{ fontSize: "20px", color: "#13c2c2" }}
              />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>Status:</Text> {traslado.status}
              </Text>
              <Text>
                <Text strong>Seguimiento:</Text> {traslado.seguimiento}
              </Text>
              <Text>
                <Text strong>Confirmación:</Text> {traslado.confirmacion}
              </Text>
            </Space>
          </InfoCard>
        </Col>

        {/* Origen, Destino y Observaciones */}
        <Col xs={24} md={24} lg={8}>
          <InfoCard
            title="Información de Origen"
            icon={
              <EnvironmentOutlined
                style={{ fontSize: "20px", color: "#2f54eb" }}
              />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>Lugar:</Text>{" "}
                {traslado.origen_lugar || "Domicilio"}
              </Text>
              <Text>
                <Text strong>Departamento:</Text> {traslado.origen_departamento}
              </Text>
              <Text>
                <Text strong>Ciudad:</Text> {traslado.origen_ciudad}
              </Text>
              <Text>
                <Text strong>Dirección:</Text> {traslado.origen_direccion}
              </Text>
            </Space>
          </InfoCard>

          <InfoCard
            title="Información de Destino"
            icon={
              <EnvironmentOutlined
                style={{ fontSize: "20px", color: "#722ed1" }}
              />
            }
          >
            <Space direction="vertical">
              <Text>
                <Text strong>Lugar:</Text>{" "}
                {traslado.destino_lugar || "Domicilio"}
              </Text>
              <Text>
                <Text strong>Departamento:</Text>{" "}
                {traslado.destino_departamento}
              </Text>
              <Text>
                <Text strong>Ciudad:</Text> {traslado.destino_ciudad}
              </Text>
              <Text>
                <Text strong>Dirección:</Text> {traslado.destino_direccion}
              </Text>
            </Space>
          </InfoCard>

          <InfoCard
            title="Observaciones"
            icon={
              <CommentOutlined style={{ fontSize: "20px", color: "#fa541c" }} />
            }
          >
            <Text>
              {traslado.observaciones_traslado || "Sin observaciones"}
            </Text>
          </InfoCard>
        </Col>
      </Row>
    </Modal>
  );
}

// **Validación de las props con PropTypes**
InfoCard.propTypes = {
  title: PropTypes.string.isRequired, // 'title' debe ser un string y es requerido
  icon: PropTypes.element, // 'icon' es opcional, pero debe ser un elemento de React si se pasa
  children: PropTypes.node, // 'children' es opcional y puede ser cualquier nodo renderizable
};

// Validación de las props con prop-types
VerTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  traslado: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    nombre_paciente: PropTypes.string,
    tipo_usuario: PropTypes.string,
    tipo_documento_paciente: PropTypes.string,
    numero_documento_paciente: PropTypes.string,
    empresa: PropTypes.string,
    preautorizacion: PropTypes.string,
    autorizacion: PropTypes.string,
    proposito: PropTypes.string,
    diagnostico_nombre: PropTypes.string,
    copago: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nombre_vehiculo: PropTypes.string,
    placa: PropTypes.string,
    nombre_conductor: PropTypes.string,
    status: PropTypes.string,
    seguimiento: PropTypes.string,
    confirmacion: PropTypes.string,
    origen_lugar: PropTypes.string,
    origen_departamento: PropTypes.string,
    origen_ciudad: PropTypes.string,
    origen_direccion: PropTypes.string,
    destino_lugar: PropTypes.string,
    destino_departamento: PropTypes.string,
    destino_ciudad: PropTypes.string,
    destino_direccion: PropTypes.string,
    observaciones_traslado: PropTypes.string,
  }),
};

export default VerTrasladoDialog;
