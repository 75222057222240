// AutocompleteAddress.js
import React, { useEffect, useRef, useCallback } from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import useGoogleMaps from "./useGoogleMaps";

const AutocompleteAddress = ({
  setDireccion,
  setLocalidad,
  setCoordenadas,
}) => {
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const google = useGoogleMaps(googleApiKey);

  const extractAddressComponents = (place) => {
    const addressComponents = place?.address_components;
    let streetNumber = "";
    let route = "";
    let localidad = "";

    if (addressComponents) {
      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        } else if (types.includes("route")) {
          route = component.long_name;
        } else if (
          types.includes("sublocality") ||
          types.includes("sublocality_level_1")
        ) {
          localidad = component.long_name;
        }
      });
    }

    const direccion = streetNumber ? `${route} #${streetNumber}` : route;

    return { direccion, localidad };
  };

  const handlePlaceChanged = useCallback(() => {
    const place = autoCompleteRef.current.getPlace();
    if (!place?.address_components) {
      console.error("No se pudo obtener la dirección completa.");
      return;
    }

    const { direccion, localidad } = extractAddressComponents(place);
    const location = place.geometry.location;
    const coordenadas = `${location.lat()},${location.lng()}`;

    setCoordenadas(coordenadas);
    setDireccion(direccion);
    setLocalidad(localidad);
  }, [setDireccion, setLocalidad, setCoordenadas]);

  useEffect(() => {
    if (!google) return;

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current.input,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "co" },
      }
    );

    autoCompleteRef.current.addListener("place_changed", handlePlaceChanged);
  }, [google, handlePlaceChanged]);

  return (
    <Input
      ref={inputRef}
      placeholder="Buscar Dirección o Lugar"
      allowClear
      autoComplete="off"
    />
  );
};

AutocompleteAddress.propTypes = {
  setDireccion: PropTypes.func.isRequired,
  setLocalidad: PropTypes.func.isRequired,
  setCoordenadas: PropTypes.func.isRequired,
};

export default AutocompleteAddress;
