// src/components/modules/patients/Patients.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import api from "../../../axiosConfig";
import {
  Table,
  Button,
  Input,
  Tag,
  Modal,
  Row,
  Col,
  Pagination,
  Space,
  Spin,
  message,
  Select,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
  UploadOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import EditPatientForm from "./Form/EditPatientForm";
import DeletePatientDialog from "./DeletePatientDialog";
import ViewPatientDialog from "./ViewPatientDialog";
import AddPatientForm from "./Form/AddPatientForm";
import BulkUploadPatients from "./BulkUploadPatients";
import HistorialPacienteDialog from "./HistorialPacienteDialog";

const { Option } = Select;

const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loadingZonas, setLoadingZonas] = useState(false);
  const [selectedZona, setSelectedZona] = useState(null);

  const [filter, setFilter] = useState("");
  const [searchInput, setSearchInput] = useState(filter); // Estado local para el input de búsqueda

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [patientNameToDelete, setPatientNameToDelete] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [historialDialogOpen, setHistorialDialogOpen] = useState(false);
  const [selectedPacienteId, setSelectedPacienteId] = useState(null);

  // Para estados
  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [selectedEstado, setSelectedEstado] = useState(null);

  // Departamentos y Ciudades
  const [departamentos, setDepartamentos] = useState([]);
  const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);

  const [ciudades, setCiudades] = useState([]);
  const [loadingCiudades, setLoadingCiudades] = useState(false);
  const [selectedCiudad, setSelectedCiudad] = useState(null);

  // Función para obtener pacientes con filtros
  const fetchPatients = useCallback(async () => {
    setListLoading(true);
    try {
      const params = {};
      if (filter) params.search = filter;
      if (selectedZona) params.zona = selectedZona;
      if (selectedEstado) params.estado = selectedEstado;
      if (selectedDepartamento) params.departamento_id = selectedDepartamento;
      if (selectedCiudad) params.ciudad_id = selectedCiudad;

      const response = await api.get("/pacientes/listado", { params });
      setPatients(response.data);
    } catch (error) {
      console.error("Error al obtener los pacientes:", error);
      message.error("Error al obtener los pacientes");
    } finally {
      setListLoading(false);
    }
  }, [
    filter,
    selectedZona,
    selectedEstado,
    selectedDepartamento,
    selectedCiudad,
  ]);

  // Zonas
  const fetchZonas = useCallback(async () => {
    setLoadingZonas(true);
    try {
      const response = await api.get("/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener las zonas:", error);
      message.error("Error al obtener las zonas");
    } finally {
      setLoadingZonas(false);
    }
  }, []);

  // Estados
  const fetchEstados = useCallback(async () => {
    setLoadingEstados(true);
    try {
      const response = await api.get("/estados");
      setEstados(response.data);
    } catch (error) {
      console.error("Error al obtener los estados:", error);
      message.error("Error al obtener los estados");
    } finally {
      setLoadingEstados(false);
    }
  }, []);

  // Departamentos
  const fetchDepartamentos = useCallback(async () => {
    setLoadingDepartamentos(true);
    try {
      const response = await api.get("/departamentos");
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
      message.error("Error al obtener los departamentos");
    } finally {
      setLoadingDepartamentos(false);
    }
  }, []);

  // Ciudades (al seleccionar un departamento)
  const fetchCiudades = useCallback(async (departamento_id) => {
    if (!departamento_id) {
      setCiudades([]);
      return;
    }
    setLoadingCiudades(true);
    try {
      const response = await api.get("/ciudades", {
        params: { departamento_id },
      });
      setCiudades(response.data);
    } catch (error) {
      console.error("Error al obtener las ciudades:", error);
      message.error("Error al obtener las ciudades");
    } finally {
      setLoadingCiudades(false);
    }
  }, []);

  useEffect(() => {
    fetchPatients();
    fetchZonas();
    fetchEstados();
    fetchDepartamentos();
  }, [fetchPatients, fetchZonas, fetchEstados, fetchDepartamentos]);

  // Resetear la página al cambiar filtros
  useEffect(() => {
    setPage(1);
  }, [
    filter,
    selectedZona,
    selectedEstado,
    selectedDepartamento,
    selectedCiudad,
  ]);

  // Cargar ciudades cuando se selecciona un departamento
  useEffect(() => {
    if (selectedDepartamento) {
      fetchCiudades(selectedDepartamento);
      setSelectedCiudad(null);
    } else {
      setCiudades([]);
      setSelectedCiudad(null);
    }
  }, [selectedDepartamento, fetchCiudades]);

  /**
   * Implementa el debounce para el campo de búsqueda.
   * Espera 800 ms después de la última escritura antes de actualizar el filtro.
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter(searchInput);
    }, 800); // 800 ms de retraso

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  /**
   * Maneja el cambio en el campo de búsqueda.
   * Actualiza el estado local `searchInput`.
   */
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setPage(1);
  };

  const handleEditClick = useCallback(async (patient) => {
    try {
      setLoadingPatient(true);
      const response = await api.get(`/pacientes/${patient.id}`);
      setSelectedPatient(response.data);
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el paciente:", error);
      message.error("Error al obtener los datos del paciente");
    } finally {
      setLoadingPatient(false);
    }
  }, []);

  const handleViewClick = useCallback(async (patient) => {
    try {
      setLoadingPatient(true);
      const response = await api.get(`/pacientes/${patient.id}`);
      setSelectedPatient(response.data);
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el paciente:", error);
      message.error("Error al obtener los datos del paciente");
    } finally {
      setLoadingPatient(false);
    }
  }, []);

  const handleOpenDeleteDialog = useCallback((patient) => {
    setPatientToDelete(patient.id);
    setPatientNameToDelete(patient.nombre);
    setDeleteDialogOpen(true);
  }, []);

  const handleHistorialClick = useCallback((patient) => {
    setSelectedPacienteId(patient.id);
    setHistorialDialogOpen(true);
  }, []);

  const getEstadoColor = (estado) => {
    if (!estado) {
      return "gray";
    }

    switch (estado.toLowerCase()) {
      case "activo":
        return "green";
      case "suspendido":
        return "red";
      case "fallecido":
        return "black";
      default:
        return "gray";
    }
  };

  // Filtrado local (opcional, ya que el backend filtra)
  const filteredPatients = useMemo(() => {
    return patients.filter((patient) => {
      const matchesFilter =
        patient.nombre.toLowerCase().includes(filter.toLowerCase()) ||
        patient.numero_documento.toLowerCase().includes(filter.toLowerCase());

      const matchesZona = selectedZona
        ? patient.Zona?.nombre === selectedZona
        : true;

      const matchesEstado = selectedEstado
        ? patient.Estado?.descripcion === selectedEstado
        : true;

      const matchesDepartamento = selectedDepartamento
        ? patient.Departamento?.id === selectedDepartamento
        : true;

      const matchesCiudad = selectedCiudad
        ? patient.Ciudad?.id === selectedCiudad
        : true;

      return (
        matchesFilter &&
        matchesZona &&
        matchesEstado &&
        matchesDepartamento &&
        matchesCiudad
      );
    });
  }, [
    patients,
    filter,
    selectedZona,
    selectedEstado,
    selectedDepartamento,
    selectedCiudad,
  ]);

  const paginatedPatients = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return filteredPatients.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredPatients, page, rowsPerPage]);

  const CiudadToString = (Ciudad) => {
    if (!Ciudad) return "";
    const departamento = Ciudad.Departamento?.nombre || "Desconocido";
    return `${Ciudad.nombre}, ${departamento}`;
  };

  const columns = useMemo(
    () => [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Tipo de Documento",
        dataIndex: "TipoDocumento",
        key: "TipoDocumento",
        render: (TipoDocumento) => TipoDocumento?.descripcion || "Desconocido",
        sorter: (a, b) =>
          (a.TipoDocumento?.descripcion || "").localeCompare(
            b.TipoDocumento?.descripcion || ""
          ),
      },
      {
        title: "Número de Documento",
        dataIndex: "numero_documento",
        key: "numero_documento",
        sorter: (a, b) =>
          (a.numero_documento || "").localeCompare(b.numero_documento || ""),
      },
      {
        title: "Zona",
        dataIndex: "Zona",
        key: "zona",
        render: (Zona) => Zona?.nombre || "Sin Zona",
        sorter: (a, b) =>
          (a.Zona?.nombre || "").localeCompare(b.Zona?.nombre || ""),
      },
      {
        title: "Ciudad, Departamento",
        dataIndex: "Ciudad",
        key: "ciudad_departamento",
        render: (Ciudad) =>
          Ciudad
            ? `${Ciudad.nombre}, ${
                Ciudad.Departamento?.nombre || "Desconocido"
              }`
            : "Desconocido",
        sorter: (a, b) => {
          const aVal = CiudadToString(a.Ciudad);
          const bVal = CiudadToString(b.Ciudad);
          return aVal.localeCompare(bVal);
        },
      },
      {
        title: "Estado",
        dataIndex: "Estado",
        key: "estado",
        render: (Estado) => (
          <Tag color={getEstadoColor(Estado?.descripcion)}>
            {Estado?.descripcion || "Desconocido"}
          </Tag>
        ),
        sorter: (a, b) =>
          (a.Estado?.descripcion || "").localeCompare(
            b.Estado?.descripcion || ""
          ),
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, paciente) => (
          <Space size="middle">
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleViewClick(paciente)}
              type="default"
            />
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditClick(paciente)}
              type="default"
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleOpenDeleteDialog(paciente)}
              type="default"
              danger
            />
            <Button
              icon={<HistoryOutlined />}
              onClick={() => handleHistorialClick(paciente)}
              type="default"
            />
          </Space>
        ),
        width: 200,
      },
    ],
    [
      handleViewClick,
      handleEditClick,
      handleOpenDeleteDialog,
      handleHistorialClick,
    ]
  );

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 1,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
  };

  return (
    <div className="patients-container">
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            {/* Campo de Búsqueda con Debounce Implementado */}
            <Input
              placeholder="Buscar"
              value={searchInput}
              onChange={handleSearchChange}
              suffix={<SearchOutlined />}
              style={{ width: 200 }}
              allowClear
            />
            <Select
              placeholder="Departamento"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => setSelectedDepartamento(value)}
              loading={loadingDepartamentos}
              value={selectedDepartamento}
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Ciudad"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => setSelectedCiudad(value)}
              loading={loadingCiudades}
              value={selectedCiudad}
              disabled={!selectedDepartamento}
            >
              {ciudades.map((ciud) => (
                <Option key={ciud.id} value={ciud.id}>
                  {ciud.nombre}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Zona"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => setSelectedZona(value)}
              loading={loadingZonas}
              value={selectedZona}
            >
              {zonas.map((zona) => (
                <Option key={zona.id} value={zona.nombre}>
                  {zona.nombre}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Estado"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => setSelectedEstado(value)}
              loading={loadingEstados}
              value={selectedEstado}
            >
              {estados.map((estado) => (
                <Option key={estado.id} value={estado.descripcion}>
                  {estado.descripcion}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDialogOpen(true)}
            >
              Agregar Paciente
            </Button>
            <Button
              icon={<UploadOutlined />}
              onClick={() => setBulkUploadDialogOpen(true)}
            >
              Carga Masiva
            </Button>
          </Space>
        </Col>
      </Row>

      <Spin spinning={listLoading} tip="Cargando pacientes...">
        <Table
          columns={columns.map((col) => ({
            ...col,
            onHeaderCell: () => ({
              style: tableHeaderStyle,
            }),
          }))}
          dataSource={paginatedPatients}
          pagination={false}
          rowKey="id"
          bordered
          scroll={{ y: 600 }}
        />
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
        >
          <Pagination
            current={page}
            pageSize={rowsPerPage}
            total={filteredPatients.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={handleRowsPerPageChange}
            pageSizeOptions={["5", "10", "25", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} pacientes`
            }
          />
        </div>
      </Spin>

      {selectedPatient && (
        <EditPatientForm
          patient={selectedPatient}
          open={editDialogOpen}
          handleClose={() => setEditDialogOpen(false)}
          fetchPatients={fetchPatients}
          loading={loadingPatient}
        />
      )}

      {viewDialogOpen && (
        <ViewPatientDialog
          patient={selectedPatient}
          open={viewDialogOpen}
          handleClose={() => setViewDialogOpen(false)}
          loading={loadingPatient}
        />
      )}

      {deleteDialogOpen && (
        <DeletePatientDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          patientId={patientToDelete}
          patientName={patientNameToDelete}
          fetchPatients={fetchPatients}
        />
      )}

      <AddPatientForm
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        fetchPatients={fetchPatients}
      />

      <Modal
        open={bulkUploadDialogOpen}
        onCancel={() => setBulkUploadDialogOpen(false)}
        footer={[
          <Button key="close" onClick={() => setBulkUploadDialogOpen(false)}>
            Cerrar
          </Button>,
        ]}
        width="60%"
      >
        <BulkUploadPatients fetchPatients={fetchPatients} />
      </Modal>

      {historialDialogOpen && (
        <HistorialPacienteDialog
          open={historialDialogOpen}
          onClose={() => setHistorialDialogOpen(false)}
          pacienteId={selectedPacienteId}
        />
      )}
    </div>
  );
};

export default Patients;
