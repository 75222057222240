// src/components/Clients/Form/utils/apiClient.js

import api from "../../../../../axiosConfig";
import { message } from "antd";

// Definir clases de error personalizadas
export class ValidationError extends Error {
  constructor(fieldErrors) {
    super("Validation Error");
    this.name = "ValidationError";
    this.fieldErrors = fieldErrors;
  }
}

export class GeneralError extends Error {
  constructor(message) {
    super(message);
    this.name = "GeneralError";
  }
}

export const submitClientData = async (
  isEdit,
  initialData,
  formattedValues
) => {
  try {
    if (isEdit && initialData) {
      await api.put(`/clientes/${initialData.id}`, formattedValues);
      message.success("Cliente actualizado exitosamente");
    } else {
      await api.post("/clientes", formattedValues);
      message.success("Cliente agregado exitosamente");
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.errors
    ) {
      console.log("Errores enviados al frontend:", error.response.data.errors);
      throw new ValidationError(error.response.data.errors);
    } else {
      console.error("Error inesperado:", error);
      message.error("Error al guardar el cliente");
      throw new GeneralError(
        "Error al guardar el cliente. Por favor intenta de nuevo."
      );
    }
  }
};
