// src/Components/modules/Coordinacion/CerrarTrasladoDialog.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Radio, Typography, Space } from "antd";

const { Text } = Typography;

/**
 * Componente CerrarTrasladoDialog para cerrar un traslado seleccionando su estado.
 */
function CerrarTrasladoDialog({ open, onClose, onSubmit }) {
  const [estado, setEstado] = useState("Finalizado"); // Estado inicial "Finalizado"

  const handleChange = (e) => {
    setEstado(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(estado); // Enviar el estado seleccionado al servidor
    onClose(); // Cerrar el diálogo
  };

  return (
    <Modal
      open={open}
      title={
        <Text strong style={{ color: "#2E3B4E" }}>
          Cerrar Traslado
        </Text>
      }
      onCancel={onClose}
      footer={null}
      centered
      width={600}
      style={{ borderRadius: "15px", padding: "20px" }}
      styles={{ padding: "20px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text
          type="secondary"
          style={{ color: "#7D879C", marginBottom: "20px" }}
        >
          Selecciona el estado con el que deseas cerrar el traslado:
        </Text>
        <Radio.Group onChange={handleChange} value={estado}>
          <Space direction="vertical">
            <Radio value="Finalizado">Finalizado</Radio>
            <Radio value="Cancelado">Cancelado</Radio>
            <Radio value="Fallido">Fallido</Radio>
          </Space>
        </Radio.Group>
        <Space
          style={{
            justifyContent: "space-between",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Button
            onClick={onClose}
            style={{
              backgroundColor: "#F5F5F5",
              color: "#7D879C",
              padding: "10px 20px",
              borderRadius: "8px",
              boxShadow: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              backgroundColor: "#1976D2",
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Cerrar Traslado
          </Button>
        </Space>
      </Space>
    </Modal>
  );
}

// Validación de las props con PropTypes
CerrarTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Indica si el diálogo está abierto
  onClose: PropTypes.func.isRequired, // Función para cerrar el diálogo
  onSubmit: PropTypes.func.isRequired, // Función para enviar el estado seleccionado
};

export default CerrarTrasladoDialog;
