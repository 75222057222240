// src/components/Modules/Vehicles/AddVehicleForm.js

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Spin, notification } from "antd";
import api from "../../../axiosConfig";
import moment from "moment-timezone";
import { useUser } from "../../../UserContext";

// Importar los componentes de sección
import VehicleInfo from "./VehicleInfo";
import OwnerInfo from "./OwnerInfo";
import AdminInfo from "./AdminInfo";
import ContractInfo from "./ContractInfo";
import ExpirationDatesInfo from "./ExpirationDatesInfo";

// Definir valores predeterminados
const defaultVehicle = {
  codigo: "",
  placa: "",
  marca: "",
  modelo: "",
  color: "",
  estado: "",
  capacidad_pasajeros: "",
  kilometraje: "",
  numero_motor: "",
  numero_chasis: "",
  propietario_numero_documento: "",
  propietario_tipo_documento_id: "",
  propietario_nombre: "",
  propietario_telefono: "",
  propietario_correo: "",
  propietario_ciudad: "",
  propietario_departamento: "",
  administrador_numero_documento: "",
  administrador_tipo_documento_id: "",
  administrador_nombre: "",
  administrador_telefono: "",
  administrador_correo: "",
  administrador_direccion: "",
  administrador_barrio: "",
  administrador_localidad: "",
  administrador_ciudad: "",
  administrador_departamento: "",
  zona_id: "",
  tipo_vinculacion: "",
  fecha_vinculacion: "",
  fecha_matricula: "",
  combustible: "",
  cilindraje: "",
  gps: "",
  empresa_vinculadora: "",
  nit: "",
  fecha_vencimiento_preventiva: "",
  empresa_preventiva_id: "",
  fecha_vencimiento_fuec: "",
  empresa_fuec_id: "",
  fecha_vencimiento_soat: "",
  empresa_soat_id: "",
  fecha_vencimiento_tecnomecanica: "",
  empresa_tecnomecanica_id: "",
  fecha_vencimiento_contractual: "",
  empresa_contractual_id: "",
  fecha_vencimiento_extracontractual: "",
  empresa_extracontractual_id: "",
  fecha_vencimiento_tarjeta_operacion: "",
  empresa_tarjeta_operacion_id: "",
  fecha_vencimiento_todo_riesgo: "",
  empresa_todo_riesgo_id: "",
};

const AddVehicleForm = ({
  open,
  handleClose,
  fetchVehicles,
  initialData = null, // Datos iniciales para edición
  isEdit = false, // Modo edición
}) => {
  const { user } = useUser();
  const [vehicle, setVehicle] = useState(defaultVehicle);
  const [errors, setErrors] = useState({});
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [aseguradoras, setAseguradoras] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTiposDocumento = useCallback(async () => {
    try {
      const response = await api.get("/tipos-documento");
      setTiposDocumento(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de documento:", error);
    }
  }, []);

  const fetchAseguradoras = useCallback(async () => {
    try {
      const response = await api.get("/aseguradoras");
      setAseguradoras(response.data);
    } catch (error) {
      console.error("Error al obtener aseguradoras:", error);
    }
  }, []);

  const fetchZonas = useCallback(async () => {
    try {
      const response = await api.get("/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener zonas:", error);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      Promise.all([
        fetchTiposDocumento(),
        fetchAseguradoras(),
        fetchZonas(),
      ]).finally(() => setLoading(false));

      if (isEdit && initialData) {
        populateForm(initialData);
      } else {
        resetForm();
      }
    }
  }, [
    open,
    fetchTiposDocumento,
    fetchAseguradoras,
    fetchZonas,
    isEdit,
    initialData,
  ]);

  const populateForm = (data) => {
    setVehicle({
      ...defaultVehicle,
      ...data,
    });
  };

  const resetForm = () => {
    setVehicle(defaultVehicle);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Limpiar el error del campo que se está modificando
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "propietario_telefono" || name === "administrador_telefono") {
      const regex = /^[0-9\b]+$/; // Solo permite números
      if (value === "" || regex.test(value)) {
        if (value.length <= 10) {
          setVehicle((prev) => ({
            ...prev,
            [name]: value,
          }));
        }
      }
    } else {
      setVehicle((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validar campos requeridos
    if (!vehicle.codigo.trim()) {
      newErrors.codigo = "El móvil es requerido.";
      valid = false;
    }

    if (!vehicle.placa.trim()) {
      newErrors.placa = "La placa es requerida.";
      valid = false;
    }

    // Validar teléfono propietario
    if (!vehicle.propietario_telefono.trim()) {
      newErrors.propietario_telefono =
        "El teléfono del propietario es requerido.";
      valid = false;
    } else if (vehicle.propietario_telefono.length !== 10) {
      newErrors.propietario_telefono = "El teléfono debe tener 10 dígitos.";
      valid = false;
    }

    // Validar teléfono administrador
    if (!vehicle.administrador_telefono.trim()) {
      newErrors.administrador_telefono =
        "El teléfono del administrador es requerido.";
      valid = false;
    } else if (vehicle.administrador_telefono.length !== 10) {
      newErrors.administrador_telefono = "El teléfono debe tener 10 dígitos.";
      valid = false;
    }

    // Validar otros campos según sea necesario...

    setErrors(newErrors);
    return valid;
  };

  const prepareVehicleData = () => {
    return {
      ...vehicle,
      usuario_id: user.id,
      fecha_vinculacion: vehicle.fecha_vinculacion
        ? moment.tz(vehicle.fecha_vinculacion, "America/Bogota").toISOString()
        : null,
      fecha_matricula: vehicle.fecha_matricula
        ? moment.tz(vehicle.fecha_matricula, "America/Bogota").toISOString()
        : null,
      fecha_vencimiento_preventiva: vehicle.fecha_vencimiento_preventiva
        ? moment
            .tz(vehicle.fecha_vencimiento_preventiva, "America/Bogota")
            .toISOString()
        : null,
      // Repetir para los demás campos de fecha...
    };
  };

  const sendAddVehicle = async (vehicleData) => {
    await api.post("/vehiculos", vehicleData);
  };

  const sendEditVehicle = async (vehicleData) => {
    if (!initialData?.id) {
      throw new Error("Datos iniciales incompletos para la edición.");
    }
    await api.put(`/vehiculos/${initialData.id}`, vehicleData);
  };

  const sendVehicleData = async (vehicleData) => {
    return isEdit ? sendEditVehicle(vehicleData) : sendAddVehicle(vehicleData);
  };

  const showSuccessNotification = () => {
    notification.success({
      message: isEdit ? "Vehículo actualizado" : "Vehículo agregado",
      description: isEdit
        ? "El vehículo se ha actualizado correctamente."
        : "El vehículo se ha agregado correctamente.",
    });
  };

  const showErrorNotification = () => {
    notification.error({
      message: isEdit
        ? "Error al editar vehículo"
        : "Error al agregar vehículo",
      description:
        "Ha ocurrido un error al guardar los datos. Por favor, intenta nuevamente.",
    });
  };

  const handleError = (error) => {
    console.error(
      isEdit ? "Error al editar vehículo:" : "Error al agregar vehículo:",
      error
    );

    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.errors
    ) {
      const fieldErrors = error.response.data.errors;
      setErrors(fieldErrors);

      // Definir los campos que tienen restricciones únicas
      const uniqueFields = ["placa"];

      uniqueFields.forEach((field) => {
        if (fieldErrors[field]) {
          let fieldName = "";
          if (field === "placa") {
            fieldName = "Placa";
          }
          // Agrega más condiciones if-else si tienes otros campos únicos
          else {
            fieldName = field;
          }
          notification.error({
            message: `Error en ${fieldName}`,
            description: fieldErrors[field],
          });
        }
      });
    } else {
      showErrorNotification();
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const vehicleData = prepareVehicleData();
      await sendVehicleData(vehicleData);

      // Refrescar la lista de vehículos y cerrar el modal
      fetchVehicles();
      handleClose();

      // Mostrar notificación de éxito
      showSuccessNotification();

      // Resetear el formulario después de enviar si no es edición
      if (!isEdit) {
        resetForm();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      title={isEdit ? "Editar Vehículo" : "Agregar Vehículo"}
      open={open}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {isEdit ? "Guardar Cambios" : "Agregar"}
        </Button>,
      ]}
      width={900}
      style={{ maxHeight: "80vh", overflowY: "auto" }}
      destroyOnClose={true}
    >
      <Spin spinning={loading} tip="Cargando información...">
        <Form layout="vertical">
          {/* Información del Vehículo */}
          <VehicleInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            zonas={zonas}
            errors={errors}
          />

          {/* Datos del Propietario */}
          <OwnerInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            tiposDocumento={tiposDocumento}
            errors={errors}
          />

          {/* Datos del Administrador */}
          <AdminInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            tiposDocumento={tiposDocumento}
            errors={errors}
          />

          {/* Convenio */}
          <ContractInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            errors={errors}
          />

          {/* Fechas de Vencimiento */}
          <ExpirationDatesInfo
            vehicle={vehicle}
            handleInputChange={handleInputChange}
            aseguradoras={aseguradoras}
            errors={errors}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

AddVehicleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default AddVehicleForm;
