// src/Components/modules/Coordinacion/TrasladosColumn.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, List, Typography, Spin, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import ItemTraslado from "./ItemTraslado";
import PaginationControl from "./PaginationControl";

const { Text } = Typography;

const TrasladosColumn = ({
  traslados,
  handleContextMenu,
  onAsignarMovil,
  selectedMovil,
  setSelectedMovil,
  loading = false,
  selectedTraslados,
  setSelectedTraslados,
  selectedStatus,
  setSelectedStatus,
}) => {
  // Estado para la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Estado para controlar el checkbox "Seleccionar Todos"
  const [selectAll, setSelectAll] = useState(false);

  // Función para manejar la selección de traslados
  const handleSelectTraslado = (trasladoId, trasladoStatus) => {
    setSelectedTraslados((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(trasladoId);

      if (isAlreadySelected) {
        // Deseleccionar
        const newSelected = prevSelected.filter((id) => id !== trasladoId);
        if (newSelected.length === 0) {
          setSelectedStatus(null);
        }
        return newSelected;
      }

      if (!["Programado", "Pre-Asignado"].includes(trasladoStatus)) {
        return prevSelected;
      }

      if (trasladoStatus === "Programado" && !selectedMovil) {
        return prevSelected;
      }

      if (prevSelected.length > 0 && trasladoStatus !== selectedStatus) {
        return prevSelected;
      }

      if (prevSelected.length === 0) {
        setSelectedStatus(trasladoStatus);
        if (trasladoStatus === "Pre-Asignado" && selectedMovil) {
          setSelectedMovil(null);
        }
      }

      return [...prevSelected, trasladoId];
    });
  };

  // Función para manejar el cambio del checkbox "Seleccionar Todos"
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);

    if (checked) {
      let statusToSelect = selectedStatus;

      if (!statusToSelect) {
        if (selectedMovil) {
          statusToSelect = "Programado";
          setSelectedStatus("Programado");
        } else {
          statusToSelect = "Pre-Asignado";
          setSelectedStatus("Pre-Asignado");
        }
      }

      if (statusToSelect === "Programado" && !selectedMovil) {
        // No podemos seleccionar traslados "Programado" sin un móvil seleccionado
        return;
      }

      const idsToSelect = traslados
        .filter(
          (traslado) =>
            traslado.status === statusToSelect &&
            ["Programado", "Pre-Asignado"].includes(traslado.status)
        )
        .map((traslado) => traslado.id);

      setSelectedTraslados(idsToSelect);
    } else {
      setSelectedTraslados([]);
      setSelectedStatus(null);
    }
  };

  // Sincronizar el estado de selectAll con selectedTraslados
  useEffect(() => {
    const allSelectableTraslados = traslados.filter(
      (traslado) =>
        ["Programado", "Pre-Asignado"].includes(traslado.status) &&
        traslado.status === selectedStatus
    );
    if (
      selectedTraslados.length > 0 &&
      selectedTraslados.length === allSelectableTraslados.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTraslados, traslados, selectedStatus]);

  // Calcular el índice de los elementos a mostrar en la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTraslados = traslados.slice(indexOfFirstItem, indexOfLastItem);

  // Manejar el cambio de página
  const handleChangePage = (page) => setCurrentPage(page);

  const handleItemsPerPageChange = (size) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [traslados]);

  // Determinar qué contenido renderizar
  let content;

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "20px",
        }}
      >
        <Spin indicator={antIcon} />
        <Text style={{ marginLeft: 10 }}>Cargando traslados...</Text>
      </div>
    );
  } else if (traslados.length > 0) {
    content = (
      <List
        dataSource={currentTraslados}
        renderItem={(traslado) => (
          <ItemTraslado
            key={traslado.id}
            traslado={{
              ...traslado,
              distancia_estimada: Number(traslado.distancia_estimada),
              copago: traslado.copago ? Number(traslado.copago) : 0,
            }}
            handleContextMenu={handleContextMenu}
            onAsignarMovil={onAsignarMovil}
            isSelected={selectedTraslados.includes(traslado.id)}
            onSelect={() => handleSelectTraslado(traslado.id, traslado.status)}
            selectedMovil={selectedMovil}
            selectedStatus={selectedStatus}
          />
        )}
      />
    );
  } else {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "20px",
        }}
      >
        <Text>No hay traslados disponibles.</Text>
      </div>
    );
  }

  return (
    <Card
      style={{
        padding: 0,
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Alineamos el contenido al inicio
        height: "100%",
        position: "relative", // Necesario para posicionar elementos hijos absolutamente
      }}
    >
      {/* Mostrar el checkbox solo si hay al menos un traslado seleccionado en Programado o Pre-Asignado */}
      {selectedTraslados.length > 0 &&
        ["Programado", "Pre-Asignado"].includes(selectedStatus) && (
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              zIndex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "5px",
              borderRadius: "4px",
            }}
          >
            <Checkbox
              indeterminate={
                selectedTraslados.length > 0 &&
                selectedTraslados.length <
                  traslados.filter(
                    (t) =>
                      ["Programado", "Pre-Asignado"].includes(t.status) &&
                      t.status === selectedStatus
                  ).length
              }
              onChange={handleSelectAllChange}
              checked={selectAll}
              disabled={
                !(
                  (selectedStatus === "Programado" && selectedMovil) ||
                  selectedStatus === "Pre-Asignado"
                )
              }
            >
              {selectedStatus
                ? `Seleccionar Todos (${selectedStatus})`
                : "Seleccionar Todos"}
            </Checkbox>
          </div>
        )}
      <SimpleBarReact
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
          padding: "16px",
          paddingTop:
            selectedTraslados.length > 0 &&
            ["Programado", "Pre-Asignado"].includes(selectedStatus)
              ? "40px" // Espacio suficiente para el checkbox
              : "16px", // Sin espacio extra si no hay checkbox
        }}
      >
        {content}
      </SimpleBarReact>
      <PaginationControl
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={traslados.length}
        onPageChange={handleChangePage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Card>
  );
};

TrasladosColumn.propTypes = {
  traslados: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  onAsignarMovil: PropTypes.func.isRequired,
  selectedMovil: PropTypes.object,
  setSelectedMovil: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectedTraslados: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  setSelectedTraslados: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string,
  setSelectedStatus: PropTypes.func.isRequired,
};

export default TrasladosColumn;
