// src/components/AgendarDialog/EditPopoverContent.jsx
import React from "react";
import { Button, TimePicker } from "antd";
import PropTypes from "prop-types";

const EditPopoverContent = ({
  idaTime,
  setIdaTime,
  llegadaTime,
  setLlegadaTime,
  retornoRecogidaTime,
  setRetornoRecogidaTime,
  retornoLlegadaTime,
  setRetornoLlegadaTime,
  handleSaveTimes,
  form,
  selectedDay,
}) => (
  <div
    style={{ display: "flex", flexDirection: "column" }}
    onClick={(e) => e.stopPropagation()}
    role="presentation" // Indica que el div no es interactivo
    tabIndex={-1} // Evita que el div sea enfocado por el teclado
  >
    {selectedDay?.ida && (
      <>
        <label htmlFor="hora-recogida">Hora de Recogida:</label>
        <TimePicker
          id="hora-recogida"
          value={idaTime}
          onChange={setIdaTime}
          placeholder="Hora de Recogida"
          format="HH:mm"
          style={{ marginBottom: 8 }}
        />
        <label htmlFor="hora-llegada">Hora de Llegada:</label>
        <TimePicker
          id="hora-llegada"
          value={llegadaTime}
          onChange={setLlegadaTime}
          placeholder="Hora de Llegada"
          format="HH:mm"
          style={{ marginBottom: 8 }}
        />
      </>
    )}
    {selectedDay?.retorno && (
      <>
        <label htmlFor="hora-retorno-recogida">
          Hora de Retorno (Recogida):
        </label>
        <TimePicker
          id="hora-retorno-recogida"
          value={retornoRecogidaTime}
          onChange={setRetornoRecogidaTime}
          placeholder="Hora de Recogida Retorno"
          format="HH:mm"
          style={{ marginBottom: 8 }}
          disabled={!form?.getFieldValue("hora_retorno_recogida")}
        />
        <label htmlFor="hora-retorno-llegada">Hora de Retorno (Llegada):</label>
        <TimePicker
          id="hora-retorno-llegada"
          value={retornoLlegadaTime}
          onChange={setRetornoLlegadaTime}
          placeholder="Hora de Llegada Retorno"
          format="HH:mm"
          style={{ marginBottom: 8 }}
          disabled={!form?.getFieldValue("hora_retorno_llegada")}
        />
      </>
    )}
    <Button type="primary" onClick={handleSaveTimes} style={{ marginTop: 8 }}>
      Aceptar
    </Button>
  </div>
);

EditPopoverContent.propTypes = {
  idaTime: PropTypes.object,
  setIdaTime: PropTypes.func.isRequired,
  llegadaTime: PropTypes.object,
  setLlegadaTime: PropTypes.func.isRequired,
  retornoRecogidaTime: PropTypes.object,
  setRetornoRecogidaTime: PropTypes.func.isRequired,
  retornoLlegadaTime: PropTypes.object,
  setRetornoLlegadaTime: PropTypes.func.isRequired,
  handleSaveTimes: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  selectedDay: PropTypes.object,
};

export default EditPopoverContent;
