import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spin, Typography, Table, Input } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext";
import "./HistorialNovedadesDialog.css";

const { TextArea } = Input;

// Componente para cerrar la novedad
const CloseNovedadDialog = ({
  open,
  handleClose,
  novedadId,
  fetchNovedades,
}) => {
  const { user } = useUser();
  const [descripcionCierre, setDescripcionCierre] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCloseNovedad = async () => {
    setLoading(true);
    try {
      await api.put(
        `https://mogotaxsas.com/api/novedades/cerrar/${novedadId}`,
        {
          descripcionCierre,
          usuarioId: user.id,
        }
      );
      setLoading(false);
      handleClose();
      fetchNovedades();
    } catch (error) {
      console.error("Error al cerrar la novedad:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title="Cerrar Novedad"
      footer={null}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <TextArea
            placeholder="Descripción de Cierre"
            rows={4}
            value={descripcionCierre}
            onChange={(e) => setDescripcionCierre(e.target.value)}
          />
          <Button
            onClick={handleCloseNovedad}
            type="primary"
            style={{ marginTop: "20px" }}
          >
            Cerrar Novedad
          </Button>
        </>
      )}
    </Modal>
  );
};

CloseNovedadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  novedadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  fetchNovedades: PropTypes.func.isRequired,
};

// Componente para el botón de cerrar novedad
const CloseButton = ({ novedad, onCloseClick }) => {
  if (novedad.estado !== "Cerrada") {
    return (
      <Button onClick={() => onCloseClick(novedad)} type="primary" danger>
        Cerrar Novedad
      </Button>
    );
  }
  return null;
};

CloseButton.propTypes = {
  novedad: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

// Componente para renderizar la fila expandida
const ExpandedRow = ({ record }) => {
  return (
    <div className="novedad-collapse">
      <div className="novedad-header">
        {/* Ajuste del nivel a 5 o menor */}
        <Typography.Title level={5}>Información de Cierre</Typography.Title>
      </div>
      <div className="novedad-info">
        <Typography.Text>
          <strong>Descripción de Cierre:</strong>{" "}
          {record.descripcion_cierre ?? "N/A"}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          <strong>Usuario que Cerró:</strong>{" "}
          {record.UsuarioCierre?.nombre ?? "N/A"}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          <strong>Fecha de Cierre:</strong>{" "}
          {record.fecha_cierre
            ? new Date(record.fecha_cierre).toLocaleString()
            : "N/A"}
        </Typography.Text>
      </div>
    </div>
  );
};

ExpandedRow.propTypes = {
  record: PropTypes.object.isRequired,
};

// Función para el ícono de expansión
const ExpandIcon = ({ expanded, onExpand, record }) => {
  if (record.estado !== "Cerrada") {
    return null;
  }
  const iconProps = {
    onClick: (e) => onExpand(record, e),
  };
  return expanded ? (
    <UpOutlined {...iconProps} />
  ) : (
    <DownOutlined {...iconProps} />
  );
};

ExpandIcon.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

// Función para renderizar la fecha de creación
const renderFechaCreacion = (text) => new Date(text).toLocaleString();

// Función para renderizar la columna de acciones
const renderAcciones = (handleOpenCloseDialog) => {
  return function (text, record) {
    return (
      <CloseButton novedad={record} onCloseClick={handleOpenCloseDialog} />
    );
  };
};

// Función para renderizar la fila expandida
const expandedRowRender = (record) => {
  if (record.estado === "Cerrada") {
    return <ExpandedRow record={record} />;
  }
  return null;
};

// Función para determinar si la fila es expandible
const rowExpandable = (record) => record.estado === "Cerrada";

// Función para el ícono de expansión
const expandIcon = (props) => <ExpandIcon {...props} />;

// Componente principal
const HistorialNovedadesDialog = ({ open, handleClose, authorizationId }) => {
  const [novedades, setNovedades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNovedad, setSelectedNovedad] = useState(null);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);

  const fetchNovedades = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/novedades/${authorizationId}`
      );
      // Asegurarse de que response.data es un array
      const novedadesData = Array.isArray(response.data)
        ? response.data
        : [response.data];

      setNovedades(novedadesData);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener novedades:", error);
      setNovedades([]); // Asegura que novedades sea un array
      setLoading(false);
    }
  }, [authorizationId]);

  const handleOpenCloseDialog = (novedad) => {
    setSelectedNovedad(novedad);
    setCloseDialogOpen(true);
  };

  const handleCloseCloseDialog = () => {
    setSelectedNovedad(null);
    setCloseDialogOpen(false);
  };

  useEffect(() => {
    if (open) {
      fetchNovedades();
    }
  }, [open, fetchNovedades]);

  if (loading) {
    return (
      <Modal
        open={open}
        onCancel={handleClose}
        width="80%"
        footer={null}
        title="Historial de Novedades"
      >
        <Spin />
      </Modal>
    );
  }

  if (novedades.length === 0) {
    return (
      <Modal
        open={open}
        onCancel={handleClose}
        width="80%"
        footer={null}
        title="Historial de Novedades"
      >
        <Typography>
          No hay novedades disponibles para esta autorización.
        </Typography>
      </Modal>
    );
  }

  const dataSource = novedades.map((novedad, index) => ({
    ...novedad,
    key: index + 1,
  }));

  // Definición de las columnas ajustadas
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Usuario",
      dataIndex: ["Usuario", "nombre"],
      key: "usuario",
      render: (text, record) => record.Usuario?.nombre ?? "N/A",
    },
    {
      title: "Motivo",
      dataIndex: ["MotivoNovedad", "descripcion"],
      key: "motivo",
      render: (text, record) => record.MotivoNovedad?.descripcion ?? "N/A",
    },
    {
      title: "Responsable",
      dataIndex: ["ResponsableNovedad", "descripcion"],
      key: "responsable",
      render: (text, record) => record.ResponsableNovedad?.descripcion ?? "N/A",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: "Fecha de Creación",
      dataIndex: "createdAt",
      key: "fechaCreacion",
      render: renderFechaCreacion,
    },
    {
      title: "Acciones",
      key: "acciones",
      render: renderAcciones(handleOpenCloseDialog),
    },
  ];

  // Configuración de expandable (sin funciones anónimas)
  const expandableConfig = {
    expandedRowRender: expandedRowRender,
    rowExpandable: rowExpandable,
    expandIcon: expandIcon,
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width="80%"
      footer={null}
      title="Historial de Novedades"
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={expandableConfig}
      />
      {selectedNovedad && (
        <CloseNovedadDialog
          open={closeDialogOpen}
          handleClose={handleCloseCloseDialog}
          novedadId={selectedNovedad.id}
          fetchNovedades={fetchNovedades}
        />
      )}
    </Modal>
  );
};

// Validación de las props con PropTypes
HistorialNovedadesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  authorizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default HistorialNovedadesDialog;
