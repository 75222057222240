import api from "../../../axiosConfig";

const API_URL = "https://mogotaxsas.com/api";

export const fetchCiudades = async (departamentoId) => {
  try {
    const response = await api.get(
      `${API_URL}/ciudades?departamento_id=${departamentoId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener ciudades:", error);
  }
};

export const fetchDepartamentos = async () => {
  try {
    const response = await api.get(`${API_URL}/departamentos`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener departamentos:", error);
  }
};
