import React from "react";
import { Modal, Button, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import api from "../../../axiosConfig";

function DeleteUserDialog({ open, onClose, userId, userName, fetchUsuarios }) {
  const handleDelete = async () => {
    try {
      await api.delete(`https://mogotaxsas.com/api/usuarios/${userId}`);
      fetchUsuarios();
      onClose();
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="Confirmar Eliminación"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="delete" type="primary" danger onClick={handleDelete}>
          Eliminar
        </Button>,
      ]}
    >
      <Typography.Paragraph style={{ display: "flex", alignItems: "center" }}>
        <ExclamationCircleOutlined
          style={{ color: "#faad14", fontSize: 24, marginRight: 10 }}
        />
        <span>
          ¿Estás seguro de que quieres eliminar a{" "}
          <strong style={{ color: "#ff4d4f" }}>{userName}</strong>? Esta acción
          no se puede deshacer.
        </span>
      </Typography.Paragraph>
    </Modal>
  );
}

// Validación de PropTypes
DeleteUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  fetchUsuarios: PropTypes.func.isRequired,
};

export default DeleteUserDialog;
