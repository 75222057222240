// src/components/Users/UserList.js

import React, { useEffect, useState, useCallback, useMemo } from "react";
import api from "../../../axiosConfig";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
  Table,
  Button,
  Input,
  Avatar,
  Tag,
  Pagination,
  Space,
  Spin,
  message,
  Col,
  Row,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import EditUserForm from "./EditUserForm";
import DeleteUserDialog from "./DeleteUserDialog";
import ViewUserDialog from "./ViewUserDialog";
import AddUserForm from "./AddUserForm";
import UserHistoryDialog from "./UserHistoryDialog";

function UserList() {
  const [usuarios, setUsuarios] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [searchInput, setSearchInput] = useState(filtro); // Estado local para el input de búsqueda con debounce
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Por defecto 10 filas por página
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState("");
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [listLoading, setListLoading] = useState(false); // Estado de carga agregado

  // Estilos para encabezados fijos usando CSS-in-JS
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 1,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
  };

  /**
   * Función para obtener usuarios con manejo de carga y filtros de búsqueda.
   */
  const fetchUsuarios = useCallback(async () => {
    setListLoading(true); // Iniciar carga
    try {
      const params = {};
      if (filtro) params.search = filtro; // Pasar el parámetro de búsqueda al backend

      const response = await api.get("/usuarios", { params }); // Asegúrate de que esta sea la URL correcta
      setUsuarios(response.data);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
      message.error("Error al obtener los usuarios"); // Mostrar mensaje de error
    } finally {
      setListLoading(false); // Finalizar carga
    }
  }, [filtro]);

  /**
   * Implementa el debounce para el campo de búsqueda.
   * Espera 800 ms después de la última escritura antes de actualizar el filtro.
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      setFiltro(searchInput);
      setPage(1); // Reinicia la paginación al buscar
    }, 800); // 800 ms de retraso

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  /**
   * Función para manejar cambios en el campo de búsqueda.
   * Actualiza el estado local `searchInput`.
   */
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  /**
   * Obtiene los usuarios al montar el componente y cada vez que `filtro` cambia.
   */
  useEffect(() => {
    fetchUsuarios();
  }, [fetchUsuarios]);

  /**
   * Maneja el cambio de página en la paginación.
   */
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  /**
   * Maneja el cambio en el número de filas por página.
   */
  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setPage(1); // Reinicia la paginación al cambiar el número de filas por página
  };

  /**
   * Funciones para manejar acciones de los botones en la tabla.
   */
  const handleEditClick = useCallback((usuario) => {
    setSelectedUser(usuario);
    setEditDialogOpen(true);
  }, []);

  const handleViewClick = useCallback((usuario) => {
    setSelectedUser(usuario);
    setViewDialogOpen(true);
  }, []);

  const handleHistoryClick = useCallback((usuario) => {
    setSelectedUser(usuario);
    setHistoryDialogOpen(true);
  }, []);

  const handleOpenDeleteDialog = useCallback((usuario) => {
    setUserToDelete(usuario.id);
    setUserNameToDelete(usuario.nombre);
    setDeleteDialogOpen(true);
  }, []);

  /**
   * Define las columnas de la tabla.
   */
  const columns = useMemo(() => {
    const getEstadoColor = (estado) => {
      if (typeof estado !== "boolean") {
        return "gray";
      }

      switch (estado) {
        case true:
          return "green"; // Activo
        case false:
          return "red"; // Suspendido
        default:
          return "gray";
      }
    };

    return [
      {
        title: "Usuario",
        dataIndex: "nombre",
        key: "nombre",
        render: (text, usuario) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={usuario.avatar}>
              {!usuario.avatar && usuario.nombre.charAt(0).toUpperCase()}
            </Avatar>
            <span style={{ marginLeft: 10 }}>{usuario.nombre}</span>
          </div>
        ),
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Correo Electrónico",
        dataIndex: "correo_electronico",
        key: "correo_electronico",
        sorter: (a, b) =>
          a.correo_electronico.localeCompare(b.correo_electronico),
      },
      {
        title: "Número de Documento",
        dataIndex: "numero_documento",
        key: "numero_documento",
        sorter: (a, b) =>
          (a.numero_documento || "").localeCompare(b.numero_documento || ""),
      },
      {
        title: "Área",
        dataIndex: "area",
        key: "area",
        sorter: (a, b) => a.area.localeCompare(b.area),
      },
      {
        title: "Cargo",
        dataIndex: "cargo",
        key: "cargo",
        sorter: (a, b) => a.cargo.localeCompare(b.cargo),
      },
      {
        title: "Fecha de Creación",
        dataIndex: "fecha_creacion",
        key: "fecha_creacion",
        render: (text) => format(new Date(text), "PPP", { locale: es }),
        sorter: (a, b) =>
          new Date(a.fecha_creacion) - new Date(b.fecha_creacion),
      },
      {
        title: "Último Acceso",
        dataIndex: "ultimo_acceso",
        key: "ultimo_acceso",
        render: (text) => format(new Date(text), "PPP p", { locale: es }),
        sorter: (a, b) => new Date(a.ultimo_acceso) - new Date(b.ultimo_acceso),
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (estado) => {
          if (typeof estado !== "boolean") {
            return <Tag color="gray">Desconocido</Tag>;
          }
          return (
            <Tag color={getEstadoColor(estado)}>
              {estado ? "Activo" : "Suspendido"}
            </Tag>
          );
        },
        sorter: (a, b) => {
          const estadoA = a.estado ? 1 : 0;
          const estadoB = b.estado ? 1 : 0;
          return estadoA - estadoB;
        },
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, usuario) => (
          <Space size="middle">
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleViewClick(usuario)}
              type="default"
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                padding: "0 8px",
              }}
            />
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditClick(usuario)}
              type="default"
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                padding: "0 8px",
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => handleOpenDeleteDialog(usuario)}
              type="default"
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                padding: "0 8px",
              }}
            />
            <Button
              icon={<HistoryOutlined />}
              onClick={() => handleHistoryClick(usuario)}
              type="default"
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                padding: "0 8px",
              }}
            />
          </Space>
        ),
        width: 200,
      },
    ];
  }, [
    handleEditClick,
    handleViewClick,
    handleOpenDeleteDialog,
    handleHistoryClick,
  ]);

  /**
   * Define los usuarios paginados para mostrar en la tabla.
   */
  const paginatedUsuarios = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return usuarios.slice(startIndex, startIndex + rowsPerPage);
  }, [usuarios, page, rowsPerPage]);

  return (
    <div className="user-list-container">
      {/* Barra de herramientas superior */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            {/* Campo de Búsqueda con Debounce Implementado */}
            <Input
              placeholder="Buscar por nombre, correo o documento"
              value={searchInput}
              onChange={handleSearchChange}
              suffix={<SearchOutlined />}
              style={{ width: 500 }}
              allowClear
            />
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDialogOpen(true)}
            >
              Agregar Usuario
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Contenedor de la tabla y paginación con Spin */}
      <Spin spinning={listLoading} tip="Cargando usuarios...">
        <Table
          columns={columns.map((col) => ({
            ...col,
            onHeaderCell: () => ({
              style: tableHeaderStyle,
            }),
          }))}
          dataSource={paginatedUsuarios}
          pagination={false}
          rowKey="id"
          bordered
          scroll={{ y: 600 }} // Establece la altura del cuerpo de la tabla
        />
        {/* Paginación siempre visible */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
        >
          <Pagination
            current={page}
            pageSize={rowsPerPage}
            total={usuarios.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={(current, size) =>
              handleRowsPerPageChange(current, size)
            }
            pageSizeOptions={["5", "10", "25", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} usuarios`
            }
          />
        </div>
      </Spin>

      {/* Formularios y Diálogos */}
      {selectedUser && (
        <EditUserForm
          user={selectedUser}
          open={editDialogOpen}
          handleClose={() => setEditDialogOpen(false)}
          fetchUsuarios={fetchUsuarios}
        />
      )}
      {viewDialogOpen && (
        <ViewUserDialog
          user={selectedUser}
          open={viewDialogOpen}
          handleClose={() => setViewDialogOpen(false)}
        />
      )}
      {deleteDialogOpen && (
        <DeleteUserDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          userId={userToDelete}
          userName={userNameToDelete}
          fetchUsuarios={fetchUsuarios}
        />
      )}
      {historyDialogOpen && (
        <UserHistoryDialog
          userId={selectedUser.id}
          open={historyDialogOpen}
          handleClose={() => setHistoryDialogOpen(false)}
        />
      )}
      <AddUserForm
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        fetchUsuarios={fetchUsuarios}
      />
    </div>
  );
}

export default UserList;
