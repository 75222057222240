// src/components/Authorizations/Filters.js

import React, { useState, useEffect } from "react";
import { Row, Col, Input, Select, Button, DatePicker, message } from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import "./Filters.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filters = ({
  filter,
  onFilterChange,
  filterStatus,
  onFilterStatusChange,
  filterAdditionalStatus,
  onFilterAdditionalStatusChange,
  filterAuthType,
  onFilterAuthTypeChange,
  filterEPS,
  onFilterEPSChange,
  epsOptions,
  onOpenCreateForm,
  onOpenBulkUpload,
  filterNoPbs,
  onFilterNoPbsChange,
  filterDateRange,
  onFilterDateRangeChange,
  filterCustomDateRange,
  onFilterCustomDateRangeChange,
}) => {
  /**
   * Genera las opciones disponibles para el filtro de estado adicional
   * basado en el estado principal seleccionado.
   */
  const getAdditionalStatusOptions = () => {
    if (filterStatus === "Completada") {
      return [
        <Option key="X traslados" value="X traslados">
          X traslados
        </Option>,
        <Option key="X Fecha" value="X Fecha">
          X Fecha
        </Option>,
      ];
    } else if (filterStatus === "Cerrada") {
      return [
        <Option key="Sin Traslados" value="Sin Traslados">
          Sin Traslados
        </Option>,
        <Option key="Manualmente" value="Manualmente">
          Manualmente
        </Option>,
      ];
    }
    return [];
  };

  /**
   * Maneja el cambio en el rango de fechas personalizado.
   * Asegura que el rango no exceda los 3 meses.
   */
  const handleCustomDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const diffMonths = end.diff(start, "month", true);
      if (diffMonths > 3) {
        message.error("El rango no puede exceder los 3 meses");
        return;
      }
      onFilterCustomDateRangeChange(dates);
      onFilterDateRangeChange("");
    } else {
      onFilterCustomDateRangeChange([]);
    }
  };

  /**
   * Estado local para gestionar el valor del campo de búsqueda con debounce.
   */
  const [searchInput, setSearchInput] = useState(filter);

  /**
   * Sincroniza el estado local `searchInput` con la prop `filter` cuando cambia.
   */
  useEffect(() => {
    setSearchInput(filter);
  }, [filter]);

  /**
   * Implementa el debounce: llama a `onFilterChange` solo después de 500 ms
   * de inactividad en la escritura.
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      onFilterChange(searchInput);
    }, 800); // 500 ms de retraso

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput, onFilterChange]);

  /**
   * Maneja el cambio en el campo de búsqueda.
   * Actualiza el estado local `searchInput` en lugar de llamar directamente a `onFilterChange`.
   */
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
    // No es necesario llamar a setPage aquí, ya que `onFilterChange` se llama después del debounce
  };

  return (
    <div className="filters-container">
      {/* Primera línea: todos los filtros */}
      <Row
        gutter={[8, 8]}
        wrap={true}
        align="middle"
        style={{ marginBottom: 8 }}
      >
        {/* Filtro de Búsqueda General */}
        <Col>
          <Input
            placeholder="Buscar..."
            value={searchInput}
            onChange={handleSearchChange}
            suffix={<SearchOutlined />}
            allowClear
            style={{ width: 180 }}
          />
        </Col>

        {/* Filtro de Estado Principal */}
        <Col>
          <Select
            placeholder="Estado"
            value={filterStatus || undefined}
            onChange={(value) => {
              onFilterStatusChange(value);
              onFilterAdditionalStatusChange(undefined);
            }}
            style={{ width: 120 }}
            allowClear
          >
            <Option value="Activa">Activa</Option>
            <Option value="Suspendida">Suspendida</Option>
            <Option value="Completada">Completada</Option>
            <Option value="Cerrada">Cerrada</Option>
          </Select>
        </Col>

        {/* Filtro de Estado Adicional */}
        <Col>
          <Select
            placeholder="Estado Adicional"
            value={filterAdditionalStatus || undefined}
            onChange={onFilterAdditionalStatusChange}
            style={{ width: 150 }}
            allowClear
            disabled={
              !filterStatus || getAdditionalStatusOptions().length === 0
            }
          >
            {getAdditionalStatusOptions()}
          </Select>
        </Col>

        {/* Filtro de Tipo de Autorización (AUT/PRE/Ambas) */}
        <Col>
          <Select
            placeholder="AUT/PRE/Ambas"
            value={filterAuthType || undefined}
            allowClear
            onChange={onFilterAuthTypeChange}
            style={{ width: 130 }}
          >
            <Option value="autorizacion">AUT</Option>
            <Option value="pre_autorizacion">PRE</Option>
            <Option value="ambas">Ambas</Option>
          </Select>
        </Col>

        {/* Filtro de EPS */}
        <Col>
          <Select
            showSearch
            placeholder="EPS"
            value={filterEPS || undefined}
            onChange={onFilterEPSChange}
            style={{ width: 180 }}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {epsOptions.map((eps) => (
              <Option key={eps.id} value={eps.nombre_cliente}>
                {eps.nombre_cliente}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Filtro PBS/NOPBS */}
        <Col>
          <Select
            placeholder="PBS/NOPBS"
            value={filterNoPbs || undefined}
            onChange={onFilterNoPbsChange}
            style={{ width: 100 }}
            allowClear
          >
            <Option value="PBS">PBS</Option>
            <Option value="NOPBS">NOPBS</Option>
          </Select>
        </Col>

        {/* Filtro de Rango de Fechas Predeterminado */}
        <Col>
          <Select
            placeholder="Rango"
            value={filterDateRange || undefined}
            onChange={(value) => {
              onFilterDateRangeChange(value);
              onFilterCustomDateRangeChange([]);
            }}
            style={{ width: 150 }}
            allowClear
          >
            <Option value="current_week">Semana Actual</Option>
            <Option value="previous_week">Semana Anterior</Option>
            <Option value="current_month">Mes Actual</Option>
            <Option value="previous_month">Mes Anterior</Option>
          </Select>
        </Col>

        {/* Filtro de Rango de Fechas Personalizado */}
        <Col>
          <RangePicker
            value={filterCustomDateRange}
            onChange={handleCustomDateChange}
            allowClear
            format="YYYY-MM-DD"
            style={{ width: 220 }}
            placeholder={["Fecha Inicio", "Fecha Fin"]}
          />
        </Col>
      </Row>

      {/* Segunda línea: botones alineados a la derecha */}
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            onClick={onOpenCreateForm}
            style={{ marginRight: 8 }}
            icon={<PlusCircleOutlined />}
          >
            Nueva Autorización
          </Button>
          <Button
            type="primary"
            onClick={onOpenBulkUpload}
            icon={<UploadOutlined />}
          >
            Carga Masiva
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Filters.propTypes = {
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterStatus: PropTypes.string,
  onFilterStatusChange: PropTypes.func.isRequired,
  filterAdditionalStatus: PropTypes.string,
  onFilterAdditionalStatusChange: PropTypes.func.isRequired,
  filterAuthType: PropTypes.string,
  onFilterAuthTypeChange: PropTypes.func.isRequired,
  filterEPS: PropTypes.string,
  onFilterEPSChange: PropTypes.func.isRequired,
  epsOptions: PropTypes.array.isRequired,
  onOpenCreateForm: PropTypes.func.isRequired,
  onOpenBulkUpload: PropTypes.func.isRequired,
  filterNoPbs: PropTypes.string,
  onFilterNoPbsChange: PropTypes.func.isRequired,
  filterDateRange: PropTypes.string,
  onFilterDateRangeChange: PropTypes.func.isRequired,
  filterCustomDateRange: PropTypes.array.isRequired,
  onFilterCustomDateRangeChange: PropTypes.func.isRequired,
};

export default Filters;
