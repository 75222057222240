import React from "react";
import PropTypes from "prop-types";
import { Modal, Typography, Space, Divider, Button, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

// Componente ConfirmarTrasladoDialog para confirmar un traslado
function ConfirmarTrasladoDialog({ open, onClose, onConfirm, trasladoId }) {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title={
        <Space align="center">
          <CheckCircleOutlined style={{ fontSize: "24px", color: "#52c41a" }} />
          <Title level={5} style={{ margin: 0 }}>
            Confirmar Traslado
          </Title>
        </Space>
      }
      width={600}
      centered
      destroyOnClose
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          padding: "24px",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          {/* Información del Traslado */}
          <div>
            <Text type="secondary">
              Vas a confirmar el traslado con el siguiente{" "}
              <strong>CS-ID:</strong>
            </Text>
            <Title
              level={3}
              style={{
                backgroundColor: "#e6f7ff",
                padding: "12px",
                borderRadius: "8px",
                textAlign: "center",
                color: "#1890ff",
                marginTop: "8px",
              }}
            >
              {trasladoId || "CS-ID no disponible"}
            </Title>
          </div>
          <Divider />

          {/* Confirmación de Traslado */}
          <Space align="center">
            <CheckCircleOutlined
              style={{ fontSize: "20px", color: "#52c41a" }}
            />
            <Text>¿Estás seguro de que deseas confirmar este traslado?</Text>
          </Space>
        </Space>
        <Divider />
        {/* Acciones */}
        <Space
          style={{ display: "flex", justifyContent: "flex-end" }}
          size="middle"
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            type="primary"
            onClick={onConfirm}
            icon={<CheckCircleOutlined />}
          >
            Confirmar
          </Button>
        </Space>
      </Card>
    </Modal>
  );
}

// **Validación de las props con PropTypes**
ConfirmarTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Booleano que indica si el diálogo está abierto
  onClose: PropTypes.func.isRequired, // Función para cerrar el diálogo
  onConfirm: PropTypes.func.isRequired, // Función para confirmar el traslado
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Puede ser string o número
};

export default ConfirmarTrasladoDialog;
