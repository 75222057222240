import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // Importar prop-types
import { Modal, Typography, Card, Row, Col } from "antd";
import L from "leaflet";
import api from "../../../axiosConfig";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant";
import {
  ClockCircleOutlined,
  LineOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

// Importar las imágenes desde la carpeta de assets
import originIcon from "../../../Assets/img/map_origen.png";
import destinationIcon from "../../../Assets/img/map_destino.png";

const { Title, Text } = Typography;

function MapDialog({ open, onClose, origin, destination }) {
  const mapRef = useRef(null);
  const routingControlRef = useRef(null); // Guardar el control de rutas
  const mapInstanceRef = useRef(null); // Referencia para el mapa
  const [startAddress, setStartAddress] = useState("Calculando...");
  const [endAddress, setEndAddress] = useState("Calculando...");

  useEffect(() => {
    if (open && origin && destination) {
      // Verificar si el mapa ya existe
      if (!mapInstanceRef.current) {
        // Inicializar el mapa solo la primera vez
        mapInstanceRef.current = L.map(mapRef.current).setView(
          [origin.lat, origin.lng],
          13
        );

        // Agregar capa base de Google Maps
        L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
          attribution:
            'Map data &copy; <a href="https://www.google.com/maps">Google Maps</a>',
        }).addTo(mapInstanceRef.current);
      }

      // Remover el control de rutas anterior si existe
      if (routingControlRef.current) {
        routingControlRef.current.remove();
      }

      // Configurar y agregar la nueva ruta
      routingControlRef.current = L.Routing.control({
        waypoints: [
          L.latLng(origin.lng, origin.lat),
          L.latLng(destination.lng, destination.lat),
        ],

        router: L.Routing.osrmv1({
          serviceUrl: "https://mogotaxsas.com/osrm/route/v1/",
        }),
        lineOptions: {
          styles: [{ color: "blue", weight: 4 }],
        },
        // Crear los marcadores de inicio y fin utilizando las imágenes importadas
        createMarker: function (i, waypoint, n) {
          const markerOptions = {
            icon: L.icon({
              iconUrl: i === 0 ? originIcon : destinationIcon, // Usa las imágenes importadas
              iconSize: [38, 38], // Tamaño del ícono
              iconAnchor: [19, 38], // Anclaje del ícono
              popupAnchor: [0, -38], // Anclaje del popup
            }),
          };
          return L.marker(waypoint.latLng, markerOptions);
        },
        show: false,
        addWaypoints: false,
        draggableWaypoints: false,
        fitSelectedRoutes: true,
        showAlternatives: false,
        routeWhileDragging: false,
      }).addTo(mapInstanceRef.current);

      routingControlRef.current.on("routesfound", function (e) {
        const itineraryContainer = document.querySelector(
          ".leaflet-routing-container"
        );
        if (itineraryContainer) {
          itineraryContainer.style.display = "none";
        }

        setTimeout(() => {
          const route = e.routes[0];
          if (route) {
            const travelTimeElement = document.getElementById("travelTime");
            const distanceElement = document.getElementById("distance");

            const duration = route.summary.totalTime || route.duration;
            const distance = route.summary.totalDistance || route.distance;

            const travelTime = duration
              ? (duration / 60).toFixed(0) + " mins"
              : "No disponible";
            const distanceKm = distance
              ? (distance / 1000).toFixed(2) + " km"
              : "No disponible";

            if (travelTimeElement) {
              travelTimeElement.innerText = travelTime;
            }
            if (distanceElement) {
              distanceElement.innerText = distanceKm;
            }
          }
        }, 500);
      });

      routingControlRef.current.on("routingerror", function () {
        const travelTimeElement = document.getElementById("travelTime");
        const distanceElement = document.getElementById("distance");

        if (travelTimeElement) {
          travelTimeElement.innerText = "Error en la ruta";
        }
        if (distanceElement) {
          distanceElement.innerText = "Error en la ruta";
        }
      });

      const getAddress = async (lon, lat) => {
        try {
          const response = await api.get(
            `https://mogotaxsas.com/nominatim/reverse?format=json&lat=${lat}&lon=${lon}`
          );
          return response.data.display_name || "Dirección no disponible";
        } catch (error) {
          return "Dirección no disponible";
        }
      };

      getAddress(origin.lat, origin.lng).then((address) =>
        setStartAddress(address)
      );

      getAddress(destination.lat, destination.lng).then((address) =>
        setEndAddress(address)
      );
    }

    // Limpiar cuando se cierre el modal
    return () => {
      if (routingControlRef.current) {
        routingControlRef.current.remove();
      }
    };
  }, [open, origin, destination]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title="Ruta en Mapa"
      width={850}
      centered
      style={{
        backgroundColor: "#f4f4f4",
        padding: "24px",
        borderRadius: "8px",
      }}
    >
      <div ref={mapRef} style={{ height: "400px", marginBottom: "24px" }}></div>

      <div style={{ marginTop: "24px" }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              style={{
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ClockCircleOutlined
                style={{ fontSize: "24px", color: "#1890ff" }}
              />
              <Title level={5} style={{ marginTop: "8px" }}>
                Tiempo estimado de viaje
              </Title>
              <Text style={{ fontSize: "16px", color: "#555" }} id="travelTime">
                Calculando...
              </Text>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              style={{
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LineOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
              <Title level={5} style={{ marginTop: "8px" }}>
                Distancia
              </Title>
              <Text style={{ fontSize: "16px", color: "#555" }} id="distance">
                Calculando...
              </Text>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col span={12}>
            <Card
              style={{
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <EnvironmentOutlined
                style={{ fontSize: "24px", color: "#1890ff" }}
              />
              <Title level={5} style={{ marginTop: "8px" }}>
                Dirección de inicio
              </Title>
              <Text style={{ fontSize: "16px", color: "#555" }}>
                {startAddress}
              </Text>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              style={{
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <EnvironmentOutlined
                style={{ fontSize: "24px", color: "#1890ff" }}
              />
              <Title level={5} style={{ marginTop: "8px" }}>
                Dirección de destino
              </Title>
              <Text style={{ fontSize: "16px", color: "#555" }}>
                {endAddress}
              </Text>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

// Validación de las props con PropTypes
MapDialog.propTypes = {
  open: PropTypes.bool.isRequired, // `open` es requerido y debe ser un booleano
  onClose: PropTypes.func.isRequired, // `onClose` es requerido y debe ser una función
  origin: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired, // `origin` es requerido y debe tener las propiedades `lat` y `lng`
  destination: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired, // `destination` es requerido y debe tener las propiedades `lat` y `lng`
};

export default MapDialog;
