// src/Components/common/StatusTag.js
import React from "react";
import { Tag } from "antd";
import PropTypes from "prop-types";

const StatusTag = ({ color, text, icon }) => (
  <Tag
    color={color}
    style={{
      backgroundColor: color,
      borderColor: color,
      color: "#fff",
      opacity: 0.85,
      borderRadius: "4px",
      padding: "2px 8px",
      fontSize: "0.9em",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    }}
  >
    {text}
    {icon &&
      React.cloneElement(icon, { style: { color: "#fff", fontSize: "16px" } })}
  </Tag>
);

StatusTag.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default StatusTag;
