// BulkUploadPatients.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Typography,
  List,
  Upload,
  Row,
  Col,
  message,
  Alert,
  Space,
  Progress,
} from "antd";
import {
  UploadOutlined,
  CloudUploadOutlined,
  ReloadOutlined,
  DownloadOutlined, // Importar el icono de descarga
} from "@ant-design/icons";
import api from "../../../axiosConfig";

import { useFileUploadPatients } from "./useFileUploadPatients";
import "./BulkUpload.css"; // Archivo CSS adicional para estilos

const BulkUploadPatients = ({ fetchPatients }) => {
  const [file, setFile] = useState(null);
  const {
    loading,
    verificationStatus,
    setVerificationStatus,
    verifyFile,
    uploadFile,
    uploadStatus,
    resetUploadStatus,
  } = useFileUploadPatients();

  const handleFileUpload = async (file) => {
    if (file) {
      setFile(file);
      const success = await verifyFile(file);
      if (success) {
        message.success("Verificación exitosa. Puede proceder con la carga.");
      } else {
        message.error(
          "La verificación encontró errores. Por favor, revise los errores."
        );
      }
    }
  };

  const handleFileSubmit = async () => {
    if (file) {
      const success = await uploadFile(file, fetchPatients);
      if (success) {
        message.success("Pacientes cargados exitosamente.");
      } else {
        message.error("Error al cargar pacientes. Revise los errores.");
      }
    }
  };

  const resetForm = () => {
    setFile(null);
    setVerificationStatus({
      errors: [],
      correctEntries: 0,
      errorEntries: 0,
    });
    resetUploadStatus();
  };

  // Función para descargar el Excel
  const downloadExcel = async () => {
    try {
      const response = await api({
        url: "/pacientes/generate-excel",
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Ajusta si usas tokens
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "pacientes_carga_masiva.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el Excel:", error);
      message.error("Error al descargar el Excel");
    }
  };

  return (
    <Card
      style={{
        maxWidth: 1000,
        margin: "auto",
        marginTop: 40,
        padding: 30,
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography.Title
        level={2}
        style={{ textAlign: "center", color: "#1890ff" }}
      >
        Carga Masiva de Pacientes
      </Typography.Title>

      {/* Botón para descargar el Excel */}
      <Button
        icon={<DownloadOutlined />}
        onClick={downloadExcel}
        type="primary"
        style={{ marginBottom: 20 }}
      >
        Descargar Plantilla Excel
      </Button>
      <Row gutter={[32, 32]}>
        <Col xs={24} md={12}>
          <Typography.Title level={4} style={{ color: "#1890ff" }}>
            Instrucciones
          </Typography.Title>
          <List
            dataSource={[
              "1. Seleccione el archivo en formato .xlsx o .xls utilizando el botón 'Seleccionar Archivo'.",
              "2. El sistema verificará el archivo para asegurarse de que los datos sean correctos.",
              "3. Si hay errores, se mostrarán los detalles de los mismos. Corrija los errores en el archivo y vuelva a cargarlo.",
              "4. Si el archivo es verificado correctamente, haga clic en 'Cargar' para subir los pacientes.",
              "5. Puede reiniciar el proceso en cualquier momento haciendo clic en 'Reiniciar'.",
            ]}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} md={12}>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            {/* Botón de Seleccionar Archivo */}
            <Upload
              beforeUpload={(file) => {
                handleFileUpload(file);
                return false;
              }}
              accept=".xlsx,.xls"
              showUploadList={false}
            >
              <Button
                icon={<UploadOutlined />}
                block
                type="primary"
                size="large"
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  height: "50px",
                  fontSize: "16px",
                }}
              >
                Seleccionar Archivo
              </Button>
            </Upload>

            {/* Indicador de Progreso */}
            {loading.isLoading && (
              <div style={{ textAlign: "center" }}>
                <Progress
                  percent={loading.progress}
                  status="active"
                  style={{ marginBottom: 10 }}
                />
                <Typography.Text strong>
                  {loading.status === "uploading"
                    ? "Subiendo archivo..."
                    : "Procesando datos..."}
                </Typography.Text>
              </div>
            )}

            {/* Errores de Verificación */}
            {verificationStatus.errors?.length > 0 && (
              <div>
                <Alert
                  message="Errores de verificación:"
                  type="error"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
                <div className="error-container">
                  <List
                    dataSource={verificationStatus.errors}
                    renderItem={(error) => (
                      <List.Item key={error.row}>
                        <Typography.Text type="danger">
                          {`Fila: ${error.row} - Error: ${error.error}`}
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                </div>
                <Typography.Text type="danger">
                  {`Total de errores: ${verificationStatus.errorEntries}`}
                </Typography.Text>
              </div>
            )}

            {/* Verificación Exitosa */}
            {verificationStatus.correctEntries > 0 &&
              verificationStatus.errors.length === 0 && (
                <div>
                  <Alert
                    message="Verificación exitosa"
                    type="success"
                    showIcon
                    style={{ marginBottom: 10 }}
                  />
                  <div className="success-container">
                    <Typography.Text>
                      {`Total de pacientes correctos: ${verificationStatus.correctEntries}`}
                    </Typography.Text>
                  </div>
                </div>
              )}

            {/* Botones de Cargar y Reiniciar */}
            <Space size="middle">
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                onClick={handleFileSubmit}
                disabled={
                  verificationStatus.errors?.length > 0 ||
                  !file ||
                  loading.isLoading
                }
                style={{
                  backgroundColor: "#52c41a",
                  borderColor: "#52c41a",
                  height: "50px",
                  fontSize: "16px",
                }}
              >
                Cargar
              </Button>
              <Button
                icon={<ReloadOutlined />}
                onClick={resetForm}
                disabled={loading.isLoading}
                danger
                size="large"
                style={{
                  height: "50px",
                  fontSize: "16px",
                }}
              >
                Reiniciar
              </Button>
            </Space>

            {/* Resultados de la Carga */}
            {uploadStatus.totalUploaded > 0 && (
              <div>
                <Alert
                  message={`Total de pacientes cargados: ${uploadStatus.totalUploaded}`}
                  type="success"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
                <Typography.Text>
                  {`Total de pacientes con errores: ${uploadStatus.totalErrors}`}
                </Typography.Text>
                {uploadStatus.errorDetails?.length > 0 && (
                  <div>
                    <Alert
                      message="Errores durante la carga:"
                      type="error"
                      showIcon
                      style={{ marginTop: 10, marginBottom: 10 }}
                    />
                    <div className="error-container">
                      <List
                        dataSource={uploadStatus.errorDetails}
                        renderItem={(error) => (
                          <List.Item key={error.row}>
                            <Typography.Text type="danger">
                              {`Fila: ${error.row} - Error: ${error.error}`}
                            </Typography.Text>
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

BulkUploadPatients.propTypes = {
  fetchPatients: PropTypes.func.isRequired,
};

export default BulkUploadPatients;
