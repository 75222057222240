// src/components/Modules.js

import React, { useState, useEffect } from "react";
import { Layout, theme } from "antd";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../Sidebar";
import HeaderComponent from "../Header";
import Footer from "../Footer";
import "./Modules.css"; // Importa el CSS

// Importa tus componentes de rutas aquí
import Patients from "./patients/Patients";
import Authorizations from "./Authorizations/Authorizations";
import UserList from "./UserList/UserList";
import UserCards from "./UserCards/UserCards";
import Clients from "./clients/Clients";
import Drivers from "./Drivers/Drivers";
import Vehicles from "./Vehicles/Vehicles";
import TimeCheck from "./TimeCheck";
import VehiculoConductores from "./Assignments/VehiculoConductores";
import Institutions from "./Institutions/Institutions";
import CenterControl from "./CenterControl/CenterControl";
import Coordinacion from "./Coordinacion/Coordinacion";

const { Content } = Layout;

function Modules() {
  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const toggleDrawer = () => setOpen(!open);

  // Detectar si el dispositivo es móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Define marginLeft usando una lógica clara sin ternarios anidados
  let calculatedMarginLeft = 0;
  if (!isMobile) {
    calculatedMarginLeft = open ? 250 : 80;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar open={open} toggleDrawer={toggleDrawer} />

      <Layout
        className="site-layout"
        style={{
          marginLeft: calculatedMarginLeft, // Uso de la variable calculada
          transition: "all 0.2s",
        }}
      >
        <HeaderComponent toggleSidebar={toggleDrawer} />

        <Content
          style={{
            margin: "16px",
            padding: 0,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 64px - 70px)",
          }}
        >
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              flex: 1,
              overflow: "auto",
            }}
          >
            <Routes>
              <Route path="UserList" element={<UserList />} />
              <Route path="UserCards" element={<UserCards />} />
              <Route path="Patients" element={<Patients />} />
              <Route path="Authorizations" element={<Authorizations />} />
              <Route path="Clients" element={<Clients />} />
              <Route path="Drivers" element={<Drivers />} />
              <Route path="Vehicles" element={<Vehicles />} />
              <Route
                path="VehiculoConductores"
                element={<VehiculoConductores />}
              />
              <Route path="TimeCheck" element={<TimeCheck />} />
              <Route path="Institutions" element={<Institutions />} />
              <Route path="CenterControl" element={<CenterControl />} />
              <Route path="Coordinacion" element={<Coordinacion />} />
              <Route path="/" element={<div>Seleccione una opción</div>} />
            </Routes>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Modules;
