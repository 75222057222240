// src/components/Clients/DeleteClientDialog.js

import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import PropTypes from "prop-types";
import api from "../../../axiosConfig";

const DeleteClientDialog = ({
  open,
  onClose,
  clientId,
  clientName,
  fetchClients,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await api.delete(`/clientes/${clientId}`);
      message.success(`Cliente "${clientName}" eliminado con éxito.`);
      fetchClients(); // Actualizar la lista de clientes
      onClose();
    } catch (error) {
      console.error("Error al eliminar el cliente:", error);
      message.error("Error al eliminar el cliente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Eliminar Cliente"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={handleDelete}
          loading={loading}
        >
          Eliminar
        </Button>,
      ]}
    >
      <p>
        ¿Estás seguro de que deseas eliminar al cliente{" "}
        <strong>{clientName}</strong>?
      </p>
    </Modal>
  );
};

DeleteClientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  clientName: PropTypes.string.isRequired,
  fetchClients: PropTypes.func.isRequired,
};

export default DeleteClientDialog;
