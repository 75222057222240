// dateUtils.js
import {
  addHours,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
  // subDays, // No se necesita restar días
} from "date-fns";

// Función para ajustar la fecha según la zona horaria
export const ajustarFecha = (fecha) => {
  return addHours(new Date(fecha), 5); // Ajusta la fecha sumando las horas necesarias (en este caso, 5 horas para UTC-5)
};

// Función para obtener el rango de una semana (con inicio en lunes)
export const getWeekRange = (weeksAgo = 0) => {
  const start = startOfWeek(subWeeks(new Date(), weeksAgo), {
    weekStartsOn: 1, // Lunes
  });
  const end = endOfWeek(subWeeks(new Date(), weeksAgo), { weekStartsOn: 1 }); // Domingo (inclusivo)

  return { start, end };
};

// Función para obtener el rango de un mes (actual o anterior)
export const getMonthRange = (monthsAgo = 0) => {
  const start = startOfMonth(subMonths(new Date(), monthsAgo));
  const end = endOfMonth(subMonths(new Date(), monthsAgo)); // No restar días, el último día es inclusivo

  return { start, end };
};
