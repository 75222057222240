import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Spin } from "antd";
import PropTypes from "prop-types"; // Importar PropTypes
import api from "../../../axiosConfig";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const UserHistoryDialog = ({ userId, open, handleClose }) => {
  const [historial, setHistorial] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHistorial = async () => {
      try {
        const response = await api.get(
          `https://mogotaxsas.com/api/historial-usuario/${userId}`
        );
        setHistorial(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener el historial:", error);
        setLoading(false);
      }
    };

    if (open) {
      fetchHistorial();
    }
  }, [open, userId]);

  const columns = [
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <>
          {format(new Date(createdAt), "PPP", { locale: es })}{" "}
          {format(new Date(createdAt), "p", { locale: es })}
        </>
      ),
    },
    {
      title: "Acción",
      dataIndex: "accion",
      key: "accion",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
  ];

  return (
    <Modal
      open={open} // Cambiado de "visible" a "open"
      onCancel={handleClose}
      title="Historial de Usuario"
      footer={[
        <Button key="close" onClick={handleClose} type="primary">
          Cerrar
        </Button>,
      ]}
      width={800}
    >
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={historial}
          rowKey="id"
          pagination={false}
        />
      )}
    </Modal>
  );
};

// Validación de PropTypes
UserHistoryDialog.propTypes = {
  userId: PropTypes.number.isRequired, // number si es un número
  open: PropTypes.bool.isRequired, // open es requerido y debe ser booleano
  handleClose: PropTypes.func.isRequired, // handleClose es requerido y debe ser una función
};

export default UserHistoryDialog;
