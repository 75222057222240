// CreateNovedadForm.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Importa PropTypes para la validación de props
import { Button, Input, Modal, Select, Row, Col, Typography, Form } from "antd";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext";

const { Option } = Select;

const CreateNovedadForm = ({
  open,
  handleClose,
  fetchNovedades,
  authorizationId,
}) => {
  const { user } = useUser();
  console.log("CreateNovedadForm: Usuario en contexto:", user);

  const [novedad, setNovedad] = useState({
    descripcion: "",
    estado: "Activa",
    usuario_id: user ? user.id : "",
    motivo_novedad_id: "",
    responsable_novedad_id: "",
  });

  const [motivos, setMotivos] = useState([]);
  const [responsables, setResponsables] = useState([]);

  useEffect(() => {
    const fetchMotivos = async () => {
      try {
        const response = await api.get(
          "https://mogotaxsas.com/api/motivos-novedad"
        );
        setMotivos(response.data);
      } catch (error) {
        console.error("Error al obtener motivos de novedad:", error);
      }
    };

    const fetchResponsables = async () => {
      try {
        const response = await api.get(
          "https://mogotaxsas.com/api/responsables-novedad"
        );
        setResponsables(response.data);
      } catch (error) {
        console.error("Error al obtener responsables de novedad:", error);
      }
    };

    fetchMotivos();
    fetchResponsables();
  }, []);

  useEffect(() => {
    console.log(
      "CreateNovedadForm: Actualizando usuario_id en novedad:",
      user ? user.id : ""
    );
    setNovedad((prev) => ({ ...prev, usuario_id: user ? user.id : "" }));
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNovedad((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setNovedad((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    console.log("CreateNovedadForm: Enviando novedad al servidor:", {
      ...novedad,
      autorizacion_id: authorizationId,
    });
    try {
      const response = await api.post(`https://mogotaxsas.com/api/novedades`, {
        ...novedad,
        autorizacion_id: authorizationId,
      });
      console.log("CreateNovedadForm: Novedad creada:", response.data);
      fetchNovedades();
      handleClose();
    } catch (error) {
      console.error(
        "CreateNovedadForm: Error al crear novedad:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title="Crear Novedad"
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Crear
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 16 }}>
        <Typography.Title level={5}>Información de la Novedad</Typography.Title>
      </div>
      <Form layout="vertical">
        <Form.Item label="Descripción" required>
          <Input
            autoFocus
            name="descripcion"
            value={novedad.descripcion}
            onChange={handleChange}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item label="Motivo" required>
              <Select
                name="motivo_novedad_id"
                value={novedad.motivo_novedad_id}
                onChange={(value) =>
                  handleSelectChange("motivo_novedad_id", value)
                }
                placeholder="Selecciona un motivo"
              >
                {motivos.map((motivo) => (
                  <Option key={motivo.id} value={motivo.id}>
                    {motivo.descripcion}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Responsable" required>
              <Select
                name="responsable_novedad_id"
                value={novedad.responsable_novedad_id}
                onChange={(value) =>
                  handleSelectChange("responsable_novedad_id", value)
                }
                placeholder="Selecciona un responsable"
              >
                {responsables.map((responsable) => (
                  <Option key={responsable.id} value={responsable.id}>
                    {responsable.descripcion}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

// Agregar validación de PropTypes
CreateNovedadForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchNovedades: PropTypes.func.isRequired,
  authorizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default CreateNovedadForm;
