// Dashboard.js
import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Avatar } from "antd";
import {
  CarOutlined,
  UserOutlined,
  TeamOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

const Dashboard = ({ stats }) => {
  const cards = [
    {
      title: "Vehículos Totales",
      value: stats.totalVehiculos,
      icon: <CarOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Vehículos con Conductor",
      value: stats.vehiculosConConductor,
      icon: <CarOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Vehículos con Conductor Principal",
      value: stats.vehiculosConConductorPrincipal,
      icon: <CarOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Vehículos sin Conductor",
      value: stats.vehiculosSinConductor,
      icon: <CarOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Conductores Totales",
      value: stats.totalConductores,
      icon: <TeamOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Conductores Asignados",
      value: stats.conductoresAsignados,
      icon: <IdcardOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Conductores Principales Asignados",
      value: stats.conductoresPrincipalesAsignados,
      icon: <IdcardOutlined style={{ fontSize: "24px" }} />,
    },
    {
      title: "Conductores sin Vehículo",
      value: stats.conductoresSinVehiculo,
      icon: <UserOutlined style={{ fontSize: "24px" }} />,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
      {cards.map((card) => (
        <Col xs={24} sm={12} md={6} key={card.title}>
          <Card>
            <Card.Meta
              avatar={
                <Avatar
                  size={64}
                  style={{ backgroundColor: "#1890ff" }}
                  icon={card.icon}
                />
              }
              title={card.title}
              description={<h2>{card.value}</h2>}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

Dashboard.propTypes = {
  stats: PropTypes.shape({
    totalVehiculos: PropTypes.number.isRequired,
    vehiculosConConductor: PropTypes.number.isRequired,
    vehiculosConConductorPrincipal: PropTypes.number.isRequired,
    vehiculosSinConductor: PropTypes.number.isRequired,
    totalConductores: PropTypes.number.isRequired,
    conductoresAsignados: PropTypes.number.isRequired,
    conductoresPrincipalesAsignados: PropTypes.number.isRequired,
    conductoresSinVehiculo: PropTypes.number.isRequired,
  }).isRequired,
};

export default Dashboard;
