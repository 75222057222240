// src/utils/formatDates.js

import dayjs from "dayjs";

export const formatClientDates = (values) => ({
  ...values,
  fecha_ultima_revision: values.fecha_ultima_revision
    ? dayjs(values.fecha_ultima_revision).toISOString()
    : null,
  fecha_proxima_revision: values.fecha_proxima_revision
    ? dayjs(values.fecha_proxima_revision).toISOString()
    : null,
  fecha_contrato_firmado: values.fecha_contrato_firmado
    ? dayjs(values.fecha_contrato_firmado).toISOString()
    : null,
  fecha_vencimiento_contrato: values.fecha_vencimiento_contrato
    ? dayjs(values.fecha_vencimiento_contrato).toISOString()
    : null,
  fecha_creacion: values.fecha_creacion
    ? dayjs(values.fecha_creacion).toISOString()
    : null,
  fecha_revision_contrato: values.fecha_revision_contrato
    ? dayjs(values.fecha_revision_contrato).toISOString()
    : null,
  fecha_aprobacion: values.fecha_aprobacion
    ? dayjs(values.fecha_aprobacion).toISOString()
    : null,
});
