// src/Components/modules/patients/Form/AddPatientForm.js

import React, { useState, useEffect, useCallback } from "react";
import api from "../../../../axiosConfig";
import PropTypes from "prop-types";
import { Modal, Typography, message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import PatientForm from "./PatientForm";
import {
  fetchClientes,
  fetchCiudades,
  fetchTiposDocumento,
  fetchDepartamentos,
  fetchZonas,
  fetchDiagnosticos,
  fetchAfiliaciones,
  fetchRegimenes,
  fetchTipos,
  fetchEstados,
} from "./api";
import { useUser } from "../../../../UserContext"; // Importa el contexto de usuario

const { Title } = Typography;

/**
 * Componente para agregar un nuevo paciente.
 */
const AddPatientForm = ({ open, handleClose, fetchPatients }) => {
  const { user } = useUser(); // Obtiene el usuario actual del contexto
  const [loading, setLoading] = useState(false);
  const DEFAULT_ESTADO_ID = 1;
  const [data, setData] = useState({
    tiposDocumento: [],
    clientes: [],
    regimenes: [],
    afiliaciones: [],
    diagnosticos: [],
    tipos: [],
    estados: [],
    departamentos: [],
    zonas: [],
  });
  const [ciudades, setCiudades] = useState([]); // Solo 'ciudades' es utilizado

  /**
   * Función para obtener todos los datos necesarios para el formulario.
   */
  const fetchData = useCallback(async () => {
    try {
      const [
        tiposDocumentoData,
        departamentosData,
        zonasData,
        diagnosticosData,
        afiliacionesData,
        regimenesData,
        tiposData,
        estadosData,
        clientesData,
      ] = await Promise.all([
        fetchTiposDocumento(),
        fetchDepartamentos(),
        fetchZonas(),
        fetchDiagnosticos(),
        fetchAfiliaciones(),
        fetchRegimenes(),
        fetchTipos(),
        fetchEstados(),
        fetchClientes(),
      ]);

      setData({
        tiposDocumento: tiposDocumentoData,
        clientes: clientesData,
        regimenes: regimenesData,
        afiliaciones: afiliacionesData,
        diagnosticos: diagnosticosData,
        tipos: tiposData,
        estados: estadosData,
        departamentos: departamentosData,
        zonas: zonasData,
      });
    } catch (error) {
      console.error("Error al obtener datos:", error);
      message.error("Error al cargar datos necesarios.");
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetchData().finally(() => setLoading(false));
    }
  }, [open, fetchData]);

  /**
   * Maneja el cambio de departamento para cargar las ciudades correspondientes.
   * @param {number} departamentoId - ID del departamento seleccionado.
   */
  const handleDepartamentoChange = async (departamentoId) => {
    if (departamentoId) {
      try {
        const ciudadesData = await fetchCiudades(departamentoId);
        setCiudades(ciudadesData);
      } catch (error) {
        console.error("Error al cargar las ciudades:", error);
        message.error("Error al cargar ciudades.");
      }
    } else {
      setCiudades([]);
    }
  };

  /**
   * Maneja el envío del formulario para agregar un nuevo paciente.
   * @param {object} values - Valores del formulario.
   */
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const fecha_nacimientoISO = values.fecha_nacimiento
        ? values.fecha_nacimiento.format("YYYY-MM-DD")
        : null;

      // Formatear coordenadas según el estándar GeoJSON
      let coordenadasGeoJSON = null;
      if (values.coordenadas) {
        const [lng, lat] = values.coordenadas
          .split(",")
          .map((coord) => parseFloat(coord.trim()));
        coordenadasGeoJSON = {
          type: "Point",
          coordinates: [lng, lat], // [lng, lat]
        };
      }

      // **Verificar el ID del usuario**
      console.log("User ID:", user?.id);

      // Incluye el usuario_id en los datos que se envían al backend
      await api.post("https://mogotaxsas.com/api/pacientes", {
        ...values,
        fecha_nacimiento: fecha_nacimientoISO,
        coordenadas: coordenadasGeoJSON,
        usuario_id: user?.id,
      });

      message.success("Paciente agregado correctamente.");
      fetchPatients();
      handleClose(); // Cerrar el modal después de agregar el paciente
    } catch (error) {
      console.error("Error al crear el paciente:", error);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else if (error.request) {
        message.error("No se recibió respuesta del servidor.");
      } else {
        message.error("Error al configurar la solicitud.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      title={
        <div
          style={{
            background: "linear-gradient(90deg, #0a2e5c, #004d99)",
            padding: "12px",
            color: "white",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <UserAddOutlined style={{ marginRight: "8px" }} />
          <Title level={3} style={{ color: "white", margin: 0 }}>
            Agregar Paciente
          </Title>
        </div>
      }
      footer={null}
      width={900}
      destroyOnClose={true}
    >
      <PatientForm
        initialValues={{ estado_id: DEFAULT_ESTADO_ID }} // Estado "Activo"
        onSubmit={handleSubmit}
        loading={loading}
        zonas={data?.zonas || []}
        departamentos={data?.departamentos || []}
        ciudades={ciudades}
        onDepartamentoChange={handleDepartamentoChange}
        tiposDocumento={data?.tiposDocumento || []}
        clientes={data?.clientes || []}
        regimenes={data?.regimenes || []}
        afiliaciones={data?.afiliaciones || []}
        diagnosticos={data?.diagnosticos || []}
        tipos={data?.tipos || []}
        estados={data?.estados || []}
        handleCancel={handleClose}
      />
    </Modal>
  );
};

AddPatientForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchPatients: PropTypes.func.isRequired,
};

export default AddPatientForm;
