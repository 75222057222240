// src/Components/modules/Coordinacion/HistorialTrasladoDialog.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, List, Avatar, Typography, Card, Divider, Space } from "antd";
import {
  CarOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";

const { Text } = Typography;

/**
 * Componente HistorialTrasladoDialog para mostrar el historial de un traslado.
 */
function HistorialTrasladoDialog({ open, onClose, trasladoId }) {
  const [historial, setHistorial] = useState([]);

  useEffect(() => {
    if (trasladoId && open) {
      // Obtener el historial del traslado
      api
        .get(`https://mogotaxsas.com/api/traslados/historial/${trasladoId}`)
        .then((response) => {
          setHistorial(response.data);
        })
        .catch((error) => {
          console.error("Error al obtener el historial:", error);
        });
    }
  }, [trasladoId, open]);

  const getIconByAction = (action) => {
    switch (action) {
      case "Programo Traslado":
        return <CarOutlined style={{ color: "#1976D2" }} />;
      case "Finalizo el traslado":
        return <CheckCircleOutlined style={{ color: "#4CAF50" }} />;
      case "Error":
        return <CloseCircleOutlined style={{ color: "#F44336" }} />;
      default:
        return <ClockCircleOutlined style={{ color: "#FFC107" }} />;
    }
  };

  return (
    <Modal
      open={open} // Reemplazado 'visible' por 'open'
      onCancel={onClose}
      title={
        <Text strong style={{ color: "#2E3B4E" }}>
          Historial del Traslado
        </Text>
      }
      footer={null}
      width={800}
      centered
      style={{ borderRadius: "15px" }} // Aplicación de borderRadius directamente al Modal
    >
      {/* Contenedor para aplicar estilos sin usar 'bodyStyle' */}
      <div style={{ backgroundColor: "#f9f9f9", padding: "20px" }}>
        <List
          itemLayout="vertical"
          dataSource={historial}
          renderItem={(registro, index) => (
            <div key={registro.id}>
              <Card
                style={{ borderRadius: "8px", backgroundColor: "#fff" }}
                styles={{ padding: "16px" }} // 'bodyStyle' en Card no está deprecado
                bordered={false}
              >
                <List.Item>
                  <Space align="start" size="large">
                    <Avatar style={{ backgroundColor: "#3F51B5" }}>
                      {registro.Usuario.nombre.charAt(0)}
                    </Avatar>
                    <div>
                      <Text
                        strong
                        style={{ color: "#2E3B4E", fontSize: "16px" }}
                      >
                        {`${registro.Usuario.nombre} - ${registro.accion}`}
                      </Text>
                      <Text
                        style={{
                          display: "block",
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        {registro.descripcion}
                      </Text>
                      <Space>
                        {getIconByAction(registro.accion)}
                        <Text type="secondary">
                          {new Date(registro.fecha).toLocaleString()}
                        </Text>
                      </Space>
                    </div>
                  </Space>
                </List.Item>
              </Card>
              {index < historial.length - 1 && <Divider />}
            </div>
          )}
        />
      </div>
    </Modal>
  );
}

// Validación de las props con PropTypes
HistorialTrasladoDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Indica si el diálogo está abierto
  onClose: PropTypes.func.isRequired, // Función para cerrar el diálogo
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired, // ID del traslado
};

export default HistorialTrasladoDialog;
