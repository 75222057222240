// src/Components/modules/Coordinacion/ItemTraslado.js
import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Space, Row, Col } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  IdcardOutlined,
  EnvironmentOutlined,
  CarOutlined,
  DollarOutlined,
  MedicineBoxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  CarFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { format, addHours } from "date-fns";
import StatusTag from "../../common/StatusTag"; // Ajusta la ruta según tu estructura de carpetas
import ColoredCard from "../../common/ColoredCard"; // Importa el componente ColoredCard
import { formatCurrencyCOP } from "../../../utils/formatCurrency"; // Importa la función de formateo

const { Text } = Typography;

// Función para ajustar la fecha según la zona horaria
const ajustarFecha = (fecha) => {
  return addHours(new Date(fecha), 5); // Ajusta la fecha sumando las horas necesarias (en este caso, 5 horas para UTC-5)
};

// Funciones para obtener colores según el estado
const getStatusColor = (status) => {
  const statusColors = {
    Cancelado: "#b10e1a",
    Finalizado: "#4d4d4d",
    Fallido: "#4a2c04",
    Asignado: "#1f7362",
    "Pre-Asignado": "#0c27a0",
    Programado: "#6c06b5",
    Enterado: "#0c5b07",
    Iniciado: "#fa7114",
    Aceptado: "#088A08",
    "En Camino": "#FFBF00",
    Esperando: "#a44706",
    default: "#b0b500",
  };
  return statusColors[status] || statusColors.default;
};

const getConfirmacionColor = (confirmacion) => {
  return confirmacion === "Confirmado" ? "#52c41a" : "#f5222d"; // Verde si está confirmado, rojo si no
};

const getSeguimientoColor = (seguimiento) => {
  return seguimiento === "Validado" ? "#52c41a" : "#f5222d"; // Verde si validado, rojo si no
};

const getInicioStatusColor = (inicioStatus) => {
  const inicioStatusColors = {
    "Inicio Exitosamente": "#52c41a", // Verde
    "Ubicación No Válida para Iniciar": "#faad14", // Amarillo
    "Sin Conectividad Para Iniciar": "#faad14", // Amarillo
  };
  return inicioStatusColors[inicioStatus] || "#faad14"; // Por defecto amarillo
};

const getFinStatusColor = (finStatus) => {
  const finStatusColors = {
    "Finalizo Exitosamente": "#52c41a", // Verde
    "Ubicación No Válida para Finalizar": "#faad14", // Amarillo
    "Sin Conectividad Para Finalizar": "#faad14", // Amarillo
  };
  return finStatusColors[finStatus] || "#faad14"; // Por defecto amarillo
};

const ItemTraslado = ({ traslado, handleContextMenu }) => {
  const statusColor = getStatusColor(traslado.status);
  const confirmacionColor = getConfirmacionColor(traslado.confirmacion);
  const inicioStatusColor = getInicioStatusColor(traslado.inicio_status);
  const finStatusColor = getFinStatusColor(traslado.fin_status);

  return (
    <ColoredCard color={statusColor}>
      <Card
        className="traslados-item"
        key={traslado.id}
        onContextMenu={(event) => handleContextMenu(event, traslado)} // Manejador de menú contextual
        hoverable
        bordered={false} // Desactiva el borde para evitar doble borde
        style={{ padding: 0 }} // Eliminar padding predeterminado de Card
      >
        {/* Contenedor de CS-id y estado */}
        <div
          className="traslados-item-id-container"
          style={{
            backgroundColor: "#0a2e5c", // Color neutro oscuro
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            flexWrap: "wrap", // Permitir envoltura en pantallas pequeñas
          }}
        >
          <Text
            strong
            style={{
              color: "#fff",
              marginRight: "8px",
              wordBreak: "break-all",
            }}
          >
            CS-{traslado.id}
          </Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            {/* Tag de Estado del Traslado */}
            <StatusTag color={statusColor} text={traslado.status} />

            {/* Tag de Confirmación del Traslado con Icono */}
            <StatusTag
              color={confirmacionColor}
              text={
                traslado.confirmacion === "Confirmado"
                  ? "Confirmado"
                  : "Sin Confirmar"
              }
              icon={
                traslado.confirmacion === "Confirmado" ? (
                  <CheckCircleOutlined />
                ) : null
              }
            />

            {/* Tag de Inicio Status - Solo se muestra si existe inicio_status */}
            {traslado.inicio_status && (
              <StatusTag
                color={inicioStatusColor}
                text={traslado.inicio_status}
              />
            )}

            {/* Tag de Fin Status - Solo se muestra si existe fin_status */}
            {traslado.fin_status && (
              <StatusTag color={finStatusColor} text={traslado.fin_status} />
            )}

            {/* Tag de Confirmación del Traslado con Icono */}
            <StatusTag
              color={getSeguimientoColor(traslado.seguimiento)}
              text={
                traslado.seguimiento === "Validado" ? "Validado" : "Sin Validar"
              }
              icon={
                traslado.seguimiento === "Validado" ? (
                  <CheckCircleFilled />
                ) : null
              }
            />
          </div>
        </div>

        {/* Distribución de 3 columnas */}
        <Row gutter={[16, 16]} style={{ padding: "10px" }}>
          {/* Columna izquierda: Fechas y horas */}
          <Col xs={24} sm={12} md={8}>
            <Space direction="vertical" size="small">
              <Space>
                <ClockCircleOutlined />
                <Text>
                  {format(ajustarFecha(traslado.fecha), "dd/MM/yyyy")}
                </Text>
              </Space>
              <Space>
                <ClockCircleOutlined />
                <Text>Hora Recogida: {traslado.hora_recogida.slice(0, 5)}</Text>
              </Space>
              <Space>
                <ClockCircleOutlined />
                <Text>Hora Llegada: {traslado.hora_llegada.slice(0, 5)}</Text>
              </Space>
            </Space>
          </Col>

          {/* Columna central: Origen y destino */}
          <Col xs={24} sm={12} md={8} style={{ textAlign: "center" }}>
            <Space direction="vertical" size="small">
              <Space>
                <EnvironmentOutlined />
                <Text>
                  De: {traslado.origen_direccion} - {traslado.origen_ciudad}
                </Text>
              </Space>
              <Space>
                <EnvironmentOutlined />
                <Text>
                  A: {traslado.destino_direccion} - {traslado.destino_ciudad}
                </Text>
              </Space>
            </Space>
          </Col>

          {/* Columna derecha: Información del paciente */}
          <Col xs={24} sm={24} md={8} style={{ textAlign: "right" }}>
            <Space direction="vertical" size="small">
              <Space>
                <UserOutlined />
                <Text>{traslado.nombre_paciente}</Text>
              </Space>
              <Space>
                <Text>{traslado.tipo_documento_paciente}</Text>
              </Space>
              <Space>
                <IdcardOutlined />
                <Text>{traslado.numero_documento_paciente}</Text>
              </Space>
            </Space>
          </Col>
        </Row>

        {/* Empresa y Copago */}
        <Row
          gutter={[16, 16]}
          style={{ padding: "0 10px", textAlign: "center" }}
        >
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <MedicineBoxOutlined style={{ marginRight: 5 }} />
            <Text>{traslado.empresa}</Text>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <DollarOutlined style={{ marginRight: 5 }} />
            <Text>{formatCurrencyCOP(traslado.copago) || "$ 0,00"}</Text>
          </Col>
        </Row>

        {/* Trayecto, duración, distancia y observaciones */}
        <Row
          gutter={[16, 16]}
          style={{ padding: "0 10px", textAlign: "center" }}
        >
          <Col xs={24} sm={12} md={6}>
            <Space>
              <CarOutlined />
              <Text>Trayecto: {traslado.trayecto}</Text>
            </Space>
          </Col>
          {traslado.duracion_estimada && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <ClockCircleOutlined />
                <Text>{traslado.duracion_estimada} min</Text>
              </Space>
            </Col>
          )}
          {traslado.distancia_estimada && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <CarFilled />
                <Text>{traslado.distancia_estimada} km</Text>
              </Space>
            </Col>
          )}
          {traslado.observaciones_traslado && (
            <Col xs={24} sm={12} md={6}>
              <Space>
                <InfoCircleOutlined />
                <Text>Observaciones: {traslado.observaciones_traslado}</Text>
              </Space>
            </Col>
          )}
        </Row>

        {/* Tipo de traslado, vehículo y placa */}
        <div
          className="traslados-item-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap", // Permitir envoltura en pantallas pequeñas
            marginTop: "10px",
            padding: "10px",
          }}
        >
          {/* Tipo de viaje en el lado izquierdo */}
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            {traslado.tipo_traslado} - {traslado.tipo_viaje}
          </Text>

          {/* Si hay vehículo asignado, mostrar en el lado derecho */}
          {traslado.nombre_vehiculo && traslado.placa && (
            <Text style={{ display: "flex", alignItems: "center" }}>
              <DeleteOutlined style={{ marginRight: 5 }} />{" "}
              {traslado.nombre_vehiculo} - {traslado.placa}
            </Text>
          )}
        </div>
      </Card>
    </ColoredCard>
  );
};

// Validación de las props con PropTypes

ItemTraslado.propTypes = {
  traslado: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    confirmacion: PropTypes.string.isRequired,
    inicio_status: PropTypes.string, // Eliminado isRequired
    fin_status: PropTypes.string, // Eliminado isRequired
    fecha: PropTypes.string.isRequired,
    hora_recogida: PropTypes.string.isRequired,
    hora_llegada: PropTypes.string.isRequired,
    origen_direccion: PropTypes.string.isRequired,
    origen_ciudad: PropTypes.string.isRequired,
    destino_direccion: PropTypes.string.isRequired,
    destino_ciudad: PropTypes.string.isRequired,
    nombre_paciente: PropTypes.string.isRequired,
    tipo_documento_paciente: PropTypes.string.isRequired,
    numero_documento_paciente: PropTypes.string.isRequired,
    trayecto: PropTypes.string.isRequired,
    duracion_estimada: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    distancia_estimada: PropTypes.number,
    observaciones_traslado: PropTypes.string,
    empresa: PropTypes.string.isRequired,
    copago: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    nombre_vehiculo: PropTypes.string,
    placa: PropTypes.string,
    tipo_traslado: PropTypes.string.isRequired,
    tipo_viaje: PropTypes.string.isRequired,
    seguimiento: PropTypes.string,
    calendario: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
};
export default ItemTraslado;
