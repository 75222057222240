// src/utils/dayjs.js

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/es";

// Extender dayjs con los plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

// Establecer el locale a español
dayjs.locale("es");

// Establecer la zona horaria predeterminada
dayjs.tz.setDefault("America/Bogota");

export default dayjs;
