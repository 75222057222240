import { useState } from "react";
import api from "../../../../axiosConfig";

export const useFileUpload = () => {
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({
    errors: [],
    correctEntries: 0,
    errorEntries: 0,
  });
  const [uploadStatus, setUploadStatus] = useState({
    totalUploaded: 0,
    totalErrors: 0,
    errorDetails: [],
  });

  const verifyFile = async (file, setSnackbar) => {
    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    try {
      const response = await api.post(
        "https://mogotaxsas.com/api/autorizaciones/verify",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Simular un retraso de 2 segundos
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setSnackbar(
        "Verificación exitosa. Puede proceder con la carga.",
        "success"
      );
      setVerificationStatus({
        errors: [],
        correctEntries: response.data.correctEntries,
        errorEntries: response.data.errorEntries,
      });
    } catch (error) {
      console.error("Error al verificar autorizaciones:", error);
      setSnackbar("Error al verificar autorizaciones.", "error");
      if (error.response && error.response.data && error.response.data.errors) {
        setVerificationStatus({
          errors: error.response.data.errors,
          correctEntries: 0,
          errorEntries: error.response.data.errors.length,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadFile = async (file, setSnackbar, fetchAuthorizations) => {
    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    try {
      const response = await api.post(
        "https://mogotaxsas.com/api/autorizaciones/bulk",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Simular un retraso de 2 segundos
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setSnackbar("Autorizaciones cargadas exitosamente.", "success");
      fetchAuthorizations();
      setUploadStatus({
        totalUploaded: response.data.totalUploaded,
        totalErrors: response.data.totalErrors,
        errorDetails: response.data.errorDetails,
      });
    } catch (error) {
      console.error("Error al cargar autorizaciones:", error);
      setSnackbar("Error al cargar autorizaciones.", "error");
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorDetails
      ) {
        setUploadStatus({
          totalUploaded: 0,
          totalErrors: error.response.data.totalErrors,
          errorDetails: error.response.data.errorDetails,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const resetUploadStatus = () => {
    setUploadStatus({
      totalUploaded: 0,
      totalErrors: 0,
      errorDetails: [],
    });
  };

  return {
    loading,
    verificationStatus,
    setVerificationStatus,
    verifyFile,
    uploadFile,
    uploadStatus,
    resetUploadStatus,
  };
};
