// AsignarDialog.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Select, Form, notification } from "antd";

const { Option } = Select;

const AsignarDialog = ({
  open,
  onClose,
  vehiculos,
  conductores,
  zonas,
  onSubmit,
}) => {
  const [asignacion, setAsignacion] = useState({
    vehiculo_id: null,
    conductor_id: null,
    es_principal: false,
  });
  const [filteredVehiculos, setFilteredVehiculos] = useState(vehiculos);
  const [filteredConductores, setFilteredConductores] = useState(conductores);
  const [zonaFiltro, setZonaFiltro] = useState(null); // Inicializado como null

  const handleChange = (name, value) => {
    setAsignacion((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (!asignacion.vehiculo_id || !asignacion.conductor_id) {
      notification.error({
        message: "Error",
        description: "Debe seleccionar un vehículo y un conductor.",
        placement: "topRight",
      });
      return;
    }
    onSubmit(asignacion);
    setAsignacion({
      vehiculo_id: null,
      conductor_id: null,
      es_principal: false,
    });
    setZonaFiltro(null); // Resetear filtro al cerrar
  };

  const handleZonaFiltroChange = (value) => {
    setZonaFiltro(value === -1 ? null : value);
    if (value === -1) {
      setFilteredVehiculos(vehiculos);
      setFilteredConductores(conductores);
    } else {
      const vehiculosFiltrados = vehiculos.filter((v) => v.zona_id === value);
      const conductoresFiltrados = conductores.filter(
        (c) => c.zona_id === value
      );
      setFilteredVehiculos(vehiculosFiltrados);
      setFilteredConductores(conductoresFiltrados);
    }
  };

  useEffect(() => {
    setFilteredVehiculos(vehiculos);
    setFilteredConductores(conductores);
    setZonaFiltro(null);
    setAsignacion({
      vehiculo_id: null,
      conductor_id: null,
      es_principal: false,
    });
  }, [vehiculos, conductores, open]);

  return (
    <Modal
      title="Asignar Conductor a Vehículo"
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      okText="Asignar"
      cancelText="Cancelar"
    >
      <Form layout="vertical">
        <Form.Item label="Filtrar por Zona">
          <Select
            value={zonaFiltro ?? -1}
            onChange={handleZonaFiltroChange}
            placeholder="Seleccione una zona"
            style={{ width: "100%" }}
            allowClear
          >
            <Option value={-1}>Todas las Zonas</Option>
            {zonas.map((zona) => (
              <Option key={zona.id} value={zona.id}>
                {zona.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Vehículo" required>
          <Select
            showSearch
            placeholder="Seleccione un vehículo"
            value={asignacion.vehiculo_id}
            onChange={(value) => handleChange("vehiculo_id", value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredVehiculos.map((v) => (
              <Option key={v.id} value={v.id}>
                {`${v.placa} - ${v.codigo} - ${v.zona?.nombre || "N/A"}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Conductor" required>
          <Select
            showSearch
            placeholder="Seleccione un conductor"
            value={asignacion.conductor_id}
            onChange={(value) => handleChange("conductor_id", value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredConductores.map((c) => (
              <Option key={c.id} value={c.id}>
                {`${c.nombre} - ${c.numero_documento} - ${
                  c.zona?.nombre || "N/A"
                }`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Es Principal">
          <Select
            value={asignacion.es_principal ? "true" : "false"}
            onChange={(value) => handleChange("es_principal", value === "true")}
            placeholder="¿Es Principal?"
          >
            <Option value="true">Sí</Option>
            <Option value="false">No</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AsignarDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  vehiculos: PropTypes.arrayOf(PropTypes.object).isRequired,
  conductores: PropTypes.arrayOf(PropTypes.object).isRequired,
  zonas: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AsignarDialog;
