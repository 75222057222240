// VehiculoConductores.js
import React, { useState, useEffect, useCallback } from "react";
import { Spin, notification } from "antd";
import Dashboard from "./Dashboard";
import TablaAsignaciones from "./TablaAsignaciones";
import AsignarDialog from "./AsignarDialog";
import AsignarBuscadorFiltro from "./AsignarBuscadorFiltro";
import ConfirmDialog from "./ConfirmDialog";
import {
  fetchAsignaciones,
  fetchVehiculos,
  fetchConductores,
  fetchZonas,
  crearAsignacion,
} from "./apiService";
import { calcularStats } from "./statsUtils";

const VehiculoConductores = () => {
  const [asignaciones, setAsignaciones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [conductores, setConductores] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [asignarDialogOpen, setAsignarDialogOpen] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [zonaFiltro, setZonaFiltro] = useState(null); // Inicializado como null
  const [searchTerm, setSearchTerm] = useState("");
  const [asignacionPendiente, setAsignacionPendiente] = useState(null);
  const [currentPrincipal, setCurrentPrincipal] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const [asignacionesRes, vehiculosRes, conductoresRes, zonasRes] =
        await Promise.all([
          fetchAsignaciones(),
          fetchVehiculos(),
          fetchConductores(),
          fetchZonas(),
        ]);

      const vehiculosConZonas = mapWithZones(vehiculosRes.data, zonasRes.data);
      const conductoresConZonas = mapWithZones(
        conductoresRes.data,
        zonasRes.data
      );

      // Mapear las asignaciones para incluir Vehiculo y Conductor completos
      const asignacionesConDatos = asignacionesRes.data.map((asignacion) => {
        const vehiculo = vehiculosConZonas.find(
          (v) => v.id === asignacion.vehiculo_id
        );
        const conductor = conductoresConZonas.find(
          (c) => c.id === asignacion.conductor_id
        );
        return {
          ...asignacion,
          Vehiculo: vehiculo || {},
          Conductor: conductor || {},
        };
      });

      setAsignaciones(asignacionesConDatos);
      setVehiculos(vehiculosConZonas);
      setConductores(conductoresConZonas);
      setZonas(zonasRes.data);

      const newStats = calcularStats(
        asignacionesConDatos,
        vehiculosConZonas,
        conductoresConZonas
      );
      setStats(newStats);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener datos:", error);
      notification.error({
        message: "Error",
        description: "Error al obtener datos.",
        placement: "topRight",
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const mapWithZones = (items, zonas) => {
    return items.map((item) => {
      const zona = zonas.find(
        (zona) => String(zona.id) === String(item.zona_id)
      );
      return { ...item, zona: zona || {} };
    });
  };

  const actualizarStats = useCallback(
    (nuevasAsignaciones) => {
      const newStats = calcularStats(
        nuevasAsignaciones,
        vehiculos,
        conductores
      );
      setStats(newStats);
    },
    [vehiculos, conductores]
  );

  const handleAsignar = async (asignacion) => {
    try {
      const res = await crearAsignacion(asignacion);
      actualizarAsignaciones(
        asignacion,
        res.data.vehiculoConductor,
        res.data.currentPrincipal
      );
    } catch (error) {
      handleError(error, "Error al crear asignación");
    }
  };

  const actualizarAsignaciones = (
    asignacion,
    vehiculoConductor,
    currentPrincipal
  ) => {
    const vehiculo = vehiculos.find((v) => v.id === asignacion.vehiculo_id);
    const conductor = conductores.find((c) => c.id === asignacion.conductor_id);

    setAsignaciones((prev) => {
      const updatedAsignaciones = updatePrincipalState(prev, asignacion);
      const isNewAsignacion = checkIfNewAsignacion(prev, asignacion);

      if (isNewAsignacion) {
        updatedAsignaciones.push({
          ...vehiculoConductor,
          Vehiculo: vehiculo,
          Conductor: conductor,
        });
      }

      actualizarStats(updatedAsignaciones);
      return updatedAsignaciones;
    });

    if (currentPrincipal) {
      setCurrentPrincipal(currentPrincipal);
      notification.info({
        message: "Aviso",
        description: `Este vehículo ya tiene un conductor principal (${currentPrincipal.Conductor.nombre}). Será reemplazado.`,
        placement: "topRight",
      });
      setAsignacionPendiente(asignacion);
      setConfirmacion(true);
    } else {
      notification.success({
        message: "Éxito",
        description: "Asignación creada exitosamente.",
        placement: "topRight",
      });
      setAsignarDialogOpen(false);
    }
  };

  const updatePrincipalState = (prev, asignacion) => {
    return prev.map((a) => {
      if (a.es_principal && a.vehiculo_id === asignacion.vehiculo_id) {
        return { ...a, es_principal: false };
      }
      return a;
    });
  };

  const checkIfNewAsignacion = (prev, asignacion) => {
    return !prev.some(
      (a) =>
        a.vehiculo_id === asignacion.vehiculo_id &&
        a.conductor_id === asignacion.conductor_id
    );
  };

  const handleConfirmacion = async () => {
    setConfirmacion(false);
    if (asignacionPendiente) {
      try {
        const res = await crearAsignacion(asignacionPendiente);
        actualizarAsignaciones(asignacionPendiente, res.data.vehiculoConductor);
        notification.success({
          message: "Éxito",
          description: "Asignación confirmada exitosamente.",
          placement: "topRight",
        });
      } catch (error) {
        handleError(error, "Error al confirmar la asignación");
      }
    }
  };

  const handleError = (error, defaultMessage) => {
    console.error(defaultMessage, error);
    notification.error({
      message: "Error",
      description: error.response?.data?.mensaje || defaultMessage,
      placement: "topRight",
    });
  };

  const handleSearchTermChange = (event) =>
    setSearchTerm(event.target.value.trim());

  const handleZonaFiltroChange = (value) => {
    setZonaFiltro(value === -1 ? null : value);
  };

  if (loading) return <Spin />;

  return (
    <div>
      <Dashboard stats={stats} />
      <AsignarBuscadorFiltro
        onAsignarClick={() => setAsignarDialogOpen(true)}
        searchTerm={searchTerm}
        handleSearchTermChange={handleSearchTermChange}
        zonaFiltro={zonaFiltro}
        handleZonaFiltroChange={handleZonaFiltroChange}
        zonas={zonas}
      />
      <TablaAsignaciones
        asignaciones={asignaciones}
        zonaFiltro={zonaFiltro}
        searchTerm={searchTerm}
      />
      <AsignarDialog
        open={asignarDialogOpen}
        onClose={() => setAsignarDialogOpen(false)}
        vehiculos={vehiculos}
        conductores={conductores}
        zonas={zonas}
        onSubmit={handleAsignar}
        asignaciones={asignaciones}
        setConfirmacion={setConfirmacion}
        setAsignacionPendiente={setAsignacionPendiente}
        currentPrincipal={currentPrincipal}
        setCurrentPrincipal={setCurrentPrincipal}
      />
      <ConfirmDialog
        open={confirmacion}
        onClose={() => setConfirmacion(false)}
        onConfirm={handleConfirmacion}
        aviso={`Este vehículo ya tiene un conductor principal (${currentPrincipal?.Conductor?.nombre}). Será reemplazado.`}
        currentPrincipal={currentPrincipal}
      />
    </div>
  );
};

export default VehiculoConductores;
