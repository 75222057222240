import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Importa el contexto de usuario

const ProtectedRoute = ({ children }) => {
  const { user } = useUser(); // Verifica si el usuario está autenticado

  if (!user) {
    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      return children; // Si el usuario existe en localStorage, permitir acceso
    }
    return <Navigate to="/login" />; // Si no, redirige a login
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // Agrega la validación de 'children'
};

export default ProtectedRoute;
