// AddressInfo.jsx
import React from "react";
import { Form, Input, Select, Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;
const { Title } = Typography;

const AddressInfo = ({
  driver,
  departamentos,
  ciudades,
  zonas,
  handleDepartamentoChange,
  handleCiudadChange,
  handleInputChange,
  errors,
}) => (
  <>
    <Title
      level={4}
      style={{
        backgroundColor: "#0a2e5c",
        color: "white",
        padding: "8px 16px",
        borderRadius: "4px",
        marginTop: 24,
        marginBottom: 16,
      }}
    >
      Información de Domicilio
    </Title>
    <Row gutter={16}>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Dirección"
          required
          validateStatus={errors.direccion ? "error" : ""}
          help={errors.direccion}
        >
          <Input
            name="direccion"
            value={driver.direccion}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Departamento"
          required
          validateStatus={errors.departamento ? "error" : ""}
          help={errors.departamento}
        >
          <Select
            value={driver.departamento ? driver.departamento : undefined}
            onChange={handleDepartamentoChange}
            placeholder="Seleccione Departamento"
          >
            {departamentos.map((departamento) => (
              <Option key={departamento.id} value={departamento.id}>
                {departamento.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Ciudad/Municipio"
          required
          validateStatus={errors.ciudad ? "error" : ""}
          help={errors.ciudad}
        >
          <Select
            value={driver.ciudad ? driver.ciudad : undefined}
            onChange={handleCiudadChange}
            placeholder="Seleccione Ciudad"
            disabled={!driver.departamento}
          >
            {ciudades.map((ciudad) => (
              <Option key={ciudad.id} value={ciudad.id}>
                {ciudad.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Barrio"
          required
          validateStatus={errors.barrio ? "error" : ""}
          help={errors.barrio}
        >
          <Input
            name="barrio"
            value={driver.barrio}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Localidad"
          required
          validateStatus={errors.localidad ? "error" : ""}
          help={errors.localidad}
        >
          <Input
            name="localidad"
            value={driver.localidad}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Coordenadas"
          validateStatus={errors.coordenadas ? "error" : ""}
          help={errors.coordenadas}
        >
          <Input
            name="coordenadas"
            value={driver.coordenadas}
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          label="Zona"
          required
          validateStatus={errors.zona_id ? "error" : ""}
          help={errors.zona_id}
        >
          <Select
            value={driver.zona_id ? driver.zona_id : undefined}
            onChange={(value) =>
              handleInputChange({ target: { name: "zona_id", value } })
            }
            placeholder="Seleccione Zona"
          >
            {zonas.map((zona) => (
              <Option key={zona.id} value={zona.id}>
                {zona.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  </>
);

AddressInfo.propTypes = {
  driver: PropTypes.object.isRequired,
  departamentos: PropTypes.array.isRequired,
  ciudades: PropTypes.array.isRequired,
  zonas: PropTypes.array.isRequired,
  handleDepartamentoChange: PropTypes.func.isRequired,
  handleCiudadChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AddressInfo;
