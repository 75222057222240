// src/Components/modules/authorizations/Form/CreateAuthorizationForm.js

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Input, message, Typography, Form, Row, Col } from "antd";
import api from "../../../../axiosConfig";
import dayjs from "../../../../utils/dayjs";
import { useUser } from "../../../../UserContext";
import {
  UserAddOutlined,
  TeamOutlined,
  SafetyOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import PatientInfo from "../Form/PatientInfo";
import ResponsibleInfo from "../Form/ResponsibleInfo";
import AuthorizationData from "../Form/AuthorizationData";
import AuthorizationDates from "../Form/AuthorizationDates";
import LocationInfo from "../Form/LocationInfo";

const { TextArea } = Input;
const { Title } = Typography;

// Define los códigos de CUPS que requieren tratamiento especial
const SPECIAL_CUPS_CODES = ["FS33303", "FS33305"];

// Define los códigos de CUPS que requieren la nueva lógica de cálculo
const INTERMUNICIPAL_CODES = [
  "FS31324",
  "FS31325",
  "FS31326",
  "FS31327",
  "FS31355",
  "FS31356",
  "FS31357",
  "FS31358",
];

// Definir el estado inicial del formulario
const initialFormState = {
  paciente_id: "",
  nombre_paciente: "",
  tipo_documento_paciente: "",
  numero_documento_paciente: "",
  genero: "",
  departamento: "",
  ciudad: "",
  empresa: "",
  regimen: "",
  observaciones: "",
  nombre_responsable: "",
  parentesco_responsable: "",
  telefono_responsable: "",
  codigo_mipres: "",
  cups_codigo: "",
  cups_descripcion: "",
  tipo_viaje: "",
  tipo_traslado: "",
  numero_entrega: 1,
  entregas_autorizadas: 1,
  numero_autorizacion: "",
  numero_pre_autorizacion: "",
  proposito_id: "",
  proposito: "",
  tipo_autorizacion: "Abierta",
  inicio_fecha_autorizacion: null,
  final_fecha_autorizacion: null,
  estado_autorizacion: "Activa",
  categoria_autorizacion: "Autorización",
  total_traslados_asignados: "",
  kilometros_autorizados: "",
  kilometros_por_servicios: "",
  copago: 0,
  porcentaje_copago_selected: 0,
  copago_diario: 0,
  valor_maximo_copago: null,
  origen_institucion: "",
  destino_institucion: "",
  origen_coordenadas: null,
  destino_coordenadas: null,
  origen_departamento: "",
  origen_ciudad: "",
  origen_localidad: "",
  origen_barrio: "",
  origen_direccion: "",
  origen_indicaciones: "",
  destino_departamento: "",
  destino_ciudad: "",
  destino_localidad: "",
  destino_barrio: "",
  destino_direccion: "",
  destino_indicaciones: "",
  no_pbs: true,
  calendario: [],
};

const CreateAuthorizationForm = ({
  handleClose,
  fetchAuthorizations,
  authorization,
}) => {
  const { user } = useUser();

  const [form, setForm] = useState(initialFormState);
  const [patients, setPatients] = useState([]);
  const [cups, setCups] = useState([]);
  const [propositos, setPropositos] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [errors, setErrors] = useState({ codigo_mipres: "" });
  const [isMipresExisting, setIsMipresExisting] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const [datosOrigenPaciente, setDatosOrigenPaciente] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copagoFieldsDisabled, setCopagoFieldsDisabled] = useState(false);

  // Función para reiniciar el formulario
  const resetForm = () => {
    setForm(initialFormState);
    setSelectedDays([]);
    setDateRange([null, null]);
    setCalendarStartDate(null);
    setIsMipresExisting(false);
    setErrors({ codigo_mipres: "" });
    setDatosOrigenPaciente(null);
    setCopagoFieldsDisabled(false);
  };

  useEffect(() => {
    if (authorization) {
      setForm({
        ...authorization,
        inicio_fecha_autorizacion: authorization.inicio_fecha_autorizacion
          ? dayjs(authorization.inicio_fecha_autorizacion)
              .tz("America/Bogota")
              .format("YYYY-MM-DD")
          : undefined,
        final_fecha_autorizacion: authorization.final_fecha_autorizacion
          ? dayjs(authorization.final_fecha_autorizacion)
              .tz("America/Bogota")
              .format("YYYY-MM-DD")
          : undefined,
      });
      setSelectedDays(
        authorization.calendario
          ? authorization.calendario.map((d) => dayjs(d).format("YYYY-MM-DD"))
          : []
      );
      setDateRange([
        authorization.inicio_fecha_autorizacion
          ? dayjs(authorization.inicio_fecha_autorizacion)
          : null,
        authorization.final_fecha_autorizacion
          ? dayjs(authorization.final_fecha_autorizacion)
          : null,
      ]);
      setCalendarStartDate(
        authorization.inicio_fecha_autorizacion
          ? dayjs(authorization.inicio_fecha_autorizacion)
          : null
      );
    } else {
      resetForm();
    }
    fetchPatients();
    fetchCups();
    fetchPropositos();
    fetchInstituciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization]);

  const fetchInstituciones = async () => {
    try {
      const response = await api.get("/instituciones");
      setInstituciones(response.data);

      // Si estamos creando una nueva autorización (no editando)
      if (!authorization) {
        // Buscar la institución llamada "Sin Destino"
        const sinDestino = response.data.find(
          (inst) => inst.nombre.toLowerCase() === "sin destino"
        );

        if (sinDestino) {
          // Actualizar el formulario con los datos de "Sin Destino"
          updateFormWithInstitucionData(sinDestino, "destino");
        } else {
          console.warn(
            'No se encontró la institución "Sin Destino" en la lista de instituciones.'
          );
        }
      }
    } catch (error) {
      console.error("Error al obtener instituciones:", error);
      message.error("Error al cargar instituciones.");
    }
  };

  const calculateKilometrosPorServicios = useCallback(() => {
    const { total_traslados_asignados, kilometros_autorizados, tipo_viaje } =
      form;

    if (
      total_traslados_asignados &&
      kilometros_autorizados &&
      tipo_viaje &&
      total_traslados_asignados > 0
    ) {
      const isRedondo =
        typeof tipo_viaje === "string" &&
        tipo_viaje.toLowerCase() === "redondo";
      const kilometrosPorServicio = isRedondo
        ? kilometros_autorizados / (total_traslados_asignados * 2)
        : kilometros_autorizados / total_traslados_asignados;

      setForm((prevForm) => {
        const kilometrosPorServicioParsed = parseFloat(
          kilometrosPorServicio.toFixed(2)
        );
        const kilometrosActualParsed = parseFloat(
          prevForm.kilometros_por_servicios
        ).toFixed(2);

        if (Number(kilometrosActualParsed) === kilometrosPorServicioParsed) {
          return prevForm;
        }

        return {
          ...prevForm,
          kilometros_por_servicios: kilometrosPorServicioParsed,
        };
      });
    }
  }, [form]);

  useEffect(() => {
    calculateKilometrosPorServicios();
  }, [
    form.total_traslados_asignados,
    form.kilometros_autorizados,
    form.tipo_viaje,
    calculateKilometrosPorServicios,
  ]);

  useEffect(() => {
    if (selectedDays.length > 0) {
      const minTimestamp = Math.min(
        ...selectedDays.map((date) => dayjs(date).valueOf())
      );
      const minDate = dayjs(minTimestamp);
      setCalendarStartDate(minDate);
    } else {
      setCalendarStartDate(null);
    }
  }, [selectedDays]);

  // Recalcular copago y copago_diario cuando cambien los valores relevantes
  useEffect(() => {
    if (copagoFieldsDisabled) {
      calculateCopago();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.cups_codigo,
    form.porcentaje_copago_selected,
    form.total_traslados_asignados,
    form.valor_maximo_copago,
    copagoFieldsDisabled,
  ]);

  const handleDaySelection = (dayjsDate) => {
    if (!form.inicio_fecha_autorizacion || !form.final_fecha_autorizacion) {
      message.error(
        "Por favor, seleccione las fechas de inicio y fin primero."
      );
      return;
    }

    const inicioFecha = dayjs(form.inicio_fecha_autorizacion)
      .tz("America/Bogota")
      .startOf("day");
    const finalFecha = dayjs(form.final_fecha_autorizacion)
      .tz("America/Bogota")
      .endOf("day");

    if (dayjsDate.isBefore(inicioFecha) || dayjsDate.isAfter(finalFecha)) {
      message.error(
        "Seleccione un día dentro del rango de fechas de autorización."
      );
      return;
    }

    const dayString = dayjsDate.format("YYYY-MM-DD");
    let updatedDays = [...selectedDays];

    if (updatedDays.includes(dayString)) {
      updatedDays = updatedDays.filter((d) => d !== dayString);
    } else {
      updatedDays.push(dayString);
    }

    setSelectedDays(updatedDays);
    setForm((prevForm) => ({
      ...prevForm,
      calendario: updatedDays,
    }));
  };

  const fetchPatients = async () => {
    try {
      const response = await api.get("/pacientes");
      setPatients(response.data);
    } catch (error) {
      console.error("Error al obtener pacientes:", error);
      message.error("Error al cargar pacientes.");
    }
  };

  const fetchCups = async () => {
    try {
      const response = await api.get("/cups");
      console.log("Datos de CUPS:", response.data); // Agrega este log
      setCups(response.data);
    } catch (error) {
      console.error("Error al obtener CUPS:", error);
      message.error("Error al cargar CUPS.");
    }
  };

  const fetchPropositos = async () => {
    try {
      const response = await api.get("/propositos");
      setPropositos(response.data);
    } catch (error) {
      console.error("Error al obtener propósitos:", error);
      message.error("Error al cargar propósitos.");
    }
  };

  const fetchCiudades = async (departamentoId, setCiudades) => {
    try {
      const response = await api.get(
        `/ciudades?departamento_id=${departamentoId}`
      );
      setCiudades(response.data);
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      message.error("Error al cargar ciudades.");
    }
  };

  // Funciones auxiliares para obtener datos del paciente
  const getDatosOrigenPaciente = (patient) => {
    const coordenadas = patient.coordenadas?.coordinates || [];

    return {
      origen_departamento: patient.departamento_id,
      origen_ciudad: patient.ciudad_id,
      origen_localidad: patient.localidad || "",
      origen_barrio: patient.barrio || "",
      origen_direccion: patient.direccion || "",
      origen_indicaciones: patient.indicaciones || "",
      origen_coordenadas: coordenadas.length
        ? `${coordenadas[0]}, ${coordenadas[1]}`
        : "",
    };
  };

  const getBasicPatientData = (patient) => ({
    paciente_id: patient.id,
    nombre_paciente: patient.nombre,
    tipo_documento_paciente: patient.TipoDocumento?.descripcion || "",
    numero_documento_paciente: patient.numero_documento || "",
    genero: patient.genero || "",
  });

  const getAdditionalPatientData = (patient) => ({
    departamento: patient.Ciudad.Departamento?.nombre || "",
    ciudad: patient.Ciudad?.nombre || "",
    empresa: patient.Cliente?.nombre_cliente || "",
    regimen: patient.Regimen?.nombre || "",
    nombre_responsable: patient.nombre_responsable || "",
    parentesco_responsable: patient.parentesco_responsable || "",
    telefono_responsable: patient.telefono_responsable || "",
    diagnostico_id: patient.diagnostico_id || "",
    diagnostico_nombre: patient.Diagnostico?.nombre || "",
  });

  const getPatientFormData = (patient, departamentoId) => {
    const datosOrigen = getDatosOrigenPaciente(patient);
    return {
      ...getBasicPatientData(patient),
      ...getAdditionalPatientData(patient),
      origen_departamento: departamentoId || "",
      origen_ciudad: patient.ciudad_id || "",
      origen_localidad: patient.localidad || "",
      origen_barrio: patient.barrio || "",
      origen_indicaciones: patient.indicaciones || "",
      origen_direccion: patient.direccion || "",
      origen_coordenadas: datosOrigen.origen_coordenadas,
      origen_institucion: "",
    };
  };

  // Funciones auxiliares para manejar el cambio de institución
  const fetchInstitucion = async (selectedInstitucion) => {
    try {
      const response = await api.get(
        `https://mogotaxsas.com/api/instituciones/${selectedInstitucion}`
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error al obtener la institución ${selectedInstitucion}:`,
        error
      );
      throw error;
    }
  };

  const updateFormWithInstitucionData = (institucion, type) => {
    const {
      departamento_id,
      ciudad_id,
      localidad,
      barrio,
      direccion,
      coordenadas,
      indicaciones,
    } = institucion;

    const updates = {
      [`${type}_departamento`]: departamento_id,
      [`${type}_ciudad`]: ciudad_id,
      [`${type}_localidad`]: localidad || "",
      [`${type}_barrio`]: barrio || "",
      [`${type}_direccion`]: direccion,
      [`${type}_coordenadas`]: coordenadas
        ? `${coordenadas.coordinates[0]}, ${coordenadas.coordinates[1]}`
        : "",
      [`${type}_indicaciones`]: indicaciones || "",
    };

    setForm((prevForm) => ({
      ...prevForm,
      [`${type}_institucion`]: institucion.id,
      ...updates,
    }));
  };

  // Funciones auxiliares para manejar el cambio de paciente
  const updateFormWithPatientData = (patientData) => {
    setForm((prevForm) => ({
      ...prevForm,
      ...patientData,
    }));
  };

  const processSelectedPatient = async (patient) => {
    const departamentoId = patient.departamento_id;

    // Guardar los datos de origen del paciente
    setDatosOrigenPaciente(getDatosOrigenPaciente(patient));

    await fetchCiudades(departamentoId, setCiudadesOrigen);

    const patientFormData = getPatientFormData(patient, departamentoId);
    updateFormWithPatientData(patientFormData);
  };

  // Funciones de manejo de cambios
  const handlePatientChange = async (value) => {
    const selectedPatient = patients.find((p) => p.id === value);

    if (!selectedPatient) {
      setForm((prevForm) => ({ ...prevForm, paciente_id: "" }));
      return;
    }

    await processSelectedPatient(selectedPatient);
  };

  const handleCupsChange = (value) => {
    const selectedCups = cups.find((c) => c.codigo === value);

    if (selectedCups) {
      setForm((prevForm) => ({
        ...prevForm,
        cups_codigo: selectedCups.codigo,
        cups_descripcion: selectedCups.descripcion,
        tipo_viaje: selectedCups.tipo_viaje,
        tipo_traslado: selectedCups.tipo_traslado,
        valor_traslado: selectedCups.valor_traslado || 0,
        kilometraje_maximo: selectedCups.kilometraje_maximo || null,
      }));

      if (
        SPECIAL_CUPS_CODES.includes(selectedCups.codigo) ||
        INTERMUNICIPAL_CODES.includes(selectedCups.codigo)
      ) {
        // Lógica para códigos que requieren cálculos especiales
        setCopagoFieldsDisabled(true);
        setForm((prevForm) => ({
          ...prevForm,
          porcentaje_copago_selected: 0, // Establecer por defecto en 0
        }));
      } else {
        // CUPS que no requieren cálculos especiales
        setCopagoFieldsDisabled(false);
        setForm((prevForm) => ({
          ...prevForm,
          porcentaje_copago_selected: null,
          copago: 0,
          copago_diario: 0,
          valor_maximo_copago: null,
        }));
      }
    } else {
      setForm((prevForm) => ({ ...prevForm, cups_codigo: "" }));
      setCopagoFieldsDisabled(false);
      setForm((prevForm) => ({
        ...prevForm,
        porcentaje_copago_selected: null,
        copago: 0,
        copago_diario: 0,
        valor_maximo_copago: null,
      }));
    }
  };

  const calculateCopago = () => {
    const selectedCups = cups.find((c) => c.codigo === form.cups_codigo);

    if (!selectedCups) return;

    const { codigo } = selectedCups;
    const porcentaje = parseFloat(form.porcentaje_copago_selected) || 0;
    const totalTrasladosAsignados =
      parseInt(form.total_traslados_asignados, 10) || 1;
    const valorMaximoCopago = parseFloat(form.valor_maximo_copago) || null;

    let copagoTotalFinal = 0;
    let copagoDiarioFinal = 0;

    if (SPECIAL_CUPS_CODES.includes(codigo)) {
      // Lógica de cálculo para SPECIAL_CUPS_CODES
      const valorTraslado = parseFloat(selectedCups.valor_traslado) || 0;
      copagoTotalFinal =
        valorTraslado * (porcentaje / 100) * totalTrasladosAsignados;
      copagoDiarioFinal = valorTraslado * (porcentaje / 100);

      // Aplicar la regla del valor máximo a pagar por la EPS
      if (valorMaximoCopago && copagoTotalFinal > valorMaximoCopago) {
        copagoTotalFinal = valorMaximoCopago;
        copagoDiarioFinal = copagoTotalFinal / totalTrasladosAsignados;
      }
    } else if (INTERMUNICIPAL_CODES.includes(codigo)) {
      // Lógica de cálculo para INTERMUNICIPAL_CODES
      const kilometrajeMaximo =
        parseFloat(selectedCups.kilometraje_maximo) || 0;
      const valorKmIntermunicipal = 3600; // Valor fijo proporcionado
      copagoTotalFinal =
        kilometrajeMaximo *
        valorKmIntermunicipal *
        (porcentaje / 100) *
        totalTrasladosAsignados;
      copagoDiarioFinal =
        kilometrajeMaximo * valorKmIntermunicipal * (porcentaje / 100);

      // Aplicar la regla del valor máximo a pagar por la EPS
      if (valorMaximoCopago && copagoTotalFinal > valorMaximoCopago) {
        copagoTotalFinal = valorMaximoCopago;
        copagoDiarioFinal = copagoTotalFinal / totalTrasladosAsignados;
      }
    } else {
      // Lógica de cálculo para otros CUPS si es necesario
      copagoTotalFinal = form.copago;
      copagoDiarioFinal = copagoTotalFinal / totalTrasladosAsignados;
    }

    copagoDiarioFinal = parseFloat(copagoDiarioFinal.toFixed(2)); // Limita a 2 decimales
    copagoTotalFinal = parseFloat(copagoTotalFinal.toFixed(2)); // Limita a 2 decimales

    // Actualizar el estado del formulario
    setForm((prevForm) => ({
      ...prevForm,
      copago: copagoTotalFinal,
      copago_diario: copagoDiarioFinal,
    }));
  };

  const handlePropositoChange = (value) => {
    const selectedProposito = propositos.find((p) => p.descripcion === value);

    if (selectedProposito) {
      setForm((prevForm) => ({
        ...prevForm,
        proposito_id: selectedProposito.id,
        proposito: selectedProposito.descripcion,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        proposito_id: "",
        proposito: "",
      }));
    }
  };

  const handleInstitucionChange = async (value, type) => {
    if (!value) {
      if (type === "origen" && datosOrigenPaciente) {
        setForm((prevForm) => ({
          ...prevForm,
          origen_institucion: "",
          ...datosOrigenPaciente,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          [`${type}_institucion`]: "",
          [`${type}_departamento`]: "",
          [`${type}_ciudad`]: "",
          [`${type}_localidad`]: "",
          [`${type}_barrio`]: "",
          [`${type}_direccion`]: "",
          [`${type}_coordenadas`]: "",
          [`${type}_indicaciones`]: "",
        }));
      }
      return;
    }

    try {
      const institucion = await fetchInstitucion(value);
      const departamentoId = institucion.departamento_id;

      if (type === "origen") {
        await fetchCiudades(departamentoId, setCiudadesOrigen);
      } else {
        await fetchCiudades(departamentoId, setCiudadesDestino);
      }

      updateFormWithInstitucionData(institucion, type);
    } catch (error) {
      // Ya se manejó el error en fetchInstitucion
    }
  };

  const handleOrigenDepartamentoChange = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      origen_departamento: value,
      origen_ciudad: "",
    }));
    fetchCiudades(value, setCiudadesOrigen);
  };

  const handleDestinoDepartamentoChange = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      destino_departamento: value,
      destino_ciudad: "",
    }));
    fetchCiudades(value, setCiudadesDestino);
  };

  const handleCodigoMipresChange = (value) => {
    setForm((prevForm) => ({ ...prevForm, codigo_mipres: value }));

    if (value.length === 20) {
      validateMipresCode(value, form.paciente_id);
    } else {
      setIsMipresExisting(false);
    }
  };

  const handleTipoAutorizacionChange = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      tipo_autorizacion: value,
      calendario: [],
    }));
    setSelectedDays([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const specialHandlers = {
      origen_departamento: handleOrigenDepartamentoChange,
      destino_departamento: handleDestinoDepartamentoChange,
      codigo_mipres: handleCodigoMipresChange,
      tipo_autorizacion: handleTipoAutorizacionChange,
    };

    if (specialHandlers[name]) {
      specialHandlers[name](value);
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }

    // Si se está modificando el código MIPRES y existe un error, limpiarlo
    if (name === "codigo_mipres" && errors.codigo_mipres) {
      setErrors((prevErrors) => ({ ...prevErrors, codigo_mipres: "" }));
    }
  };

  const handleNoPbsChange = (checked) => {
    setForm((prevForm) => ({
      ...prevForm,
      no_pbs: checked,
      codigo_mipres: checked ? "" : prevForm.codigo_mipres,
      numero_entrega: 1,
      entregas_autorizadas: 1,
      total_traslados_asignados: checked
        ? 1
        : prevForm.total_traslados_asignados,
    }));

    if (checked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        codigo_mipres: "",
      }));
      setIsMipresExisting(false);
    }
  };

  // Funciones auxiliares para manejar el MIPRES
  const handleMaxEntregasReached = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      codigo_mipres:
        "El MIPRES ya ha alcanzado el número máximo de entregas autorizadas",
    }));
    message.error(
      "El MIPRES ya ha alcanzado el número máximo de entregas autorizadas"
    );
    setIsMipresExisting(true);
  };

  const handleValidMipres = (entregasAutorizadas, numeroEntregasActual) => {
    setForm((prevForm) => ({
      ...prevForm,
      numero_entrega: numeroEntregasActual + 1,
      entregas_autorizadas: entregasAutorizadas,
      total_traslados_asignados: entregasAutorizadas, // Actualizar también total_traslados_asignados
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      codigo_mipres: "",
    }));
    setIsMipresExisting(true);
  };

  const handleMipresAssignedToAnotherPatient = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      codigo_mipres: "El código MIPRES ya está asignado a otro paciente",
    }));
    message.error("El código MIPRES ya está asignado a otro paciente");
    setIsMipresExisting(true);
  };

  // Función principal refactorizada
  const validateMipresCode = async (codigo, paciente_id) => {
    try {
      const response = await api.get(
        `/mipres?codigo_mipres=${codigo}&paciente_id=${paciente_id}`
      );

      if (!response.data) {
        setIsMipresExisting(false);
        return;
      }

      const { entregas_autorizadas, entrega: numeroEntregasActual } =
        response.data;

      if (numeroEntregasActual >= entregas_autorizadas) {
        handleMaxEntregasReached();
        return;
      }

      handleValidMipres(entregas_autorizadas, numeroEntregasActual);
    } catch (error) {
      if (error.response?.status === 400) {
        handleMipresAssignedToAnotherPatient();
      } else {
        console.error("Error al validar el código MIPRES:", error);
        message.error("Error al validar el código MIPRES.");
      }
    }
  };

  const prepareFormData = () => {
    const {
      codigo_mipres,
      no_pbs,
      numero_autorizacion,
      numero_pre_autorizacion,
      porcentaje_copago_selected,
      kilometros_autorizados,
      kilometros_por_servicios,
      origen_institucion,
      origen_institucion_temporal,
      destino_institucion,
      destino_institucion_temporal,
      ...rest
    } = form;

    // Aquí decidimos crear campos nuevos para los lugares temporales:
    const formData = {
      ...rest,
      no_pbs,
      porcentaje_copago_total: porcentaje_copago_selected,
      observaciones: form.observaciones,
      usuario_id: user.id,
      inicio_fecha_autorizacion: form.inicio_fecha_autorizacion
        ? dayjs(form.inicio_fecha_autorizacion)
            .tz("America/Bogota")
            .format("YYYY-MM-DD")
        : null,
      final_fecha_autorizacion: form.final_fecha_autorizacion
        ? dayjs(form.final_fecha_autorizacion)
            .tz("America/Bogota")
            .format("YYYY-MM-DD")
        : null,
      calendario: form.calendario,
      ...(no_pbs ? {} : { codigo_mipres }),
      ...(numero_autorizacion ? { numero_autorizacion } : {}),
      ...(numero_pre_autorizacion ? { numero_pre_autorizacion } : {}),
      ...(kilometros_autorizados !== "" && kilometros_autorizados !== undefined
        ? { kilometros_autorizados }
        : {}),
      ...(kilometros_por_servicios !== "" &&
      kilometros_por_servicios !== undefined
        ? { kilometros_por_servicios }
        : {}),

      // Asignar instituciones si existen, de lo contrario asignar el temporal
      origen_institucion: origen_institucion || null,
      destino_institucion: destino_institucion || null,
      origen_lugar_temporal:
        !origen_institucion && origen_institucion_temporal
          ? origen_institucion_temporal
          : null,
      destino_lugar_temporal:
        !destino_institucion && destino_institucion_temporal
          ? destino_institucion_temporal
          : null,
    };

    console.log("Datos del formulario que se enviarán:", formData);

    return formData;
  };

  const validateMipresBeforeSubmit = async (formData) => {
    try {
      const response = await api.get(
        `/mipres?codigo_mipres=${form.codigo_mipres}&paciente_id=${form.paciente_id}`
      );

      if (response.data) {
        const numeroEntregaActual = response.data.entrega;
        if (numeroEntregaActual >= response.data.entregas_autorizadas) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            codigo_mipres:
              "Se han alcanzado el máximo número de entregas autorizadas para este código MIPRES",
          }));
          message.error(
            "Se han alcanzado el máximo número de entregas autorizadas para este código MIPRES"
          );
          return false;
        }

        formData.numero_entrega = numeroEntregaActual + 1;
        formData.total_traslados_asignados = response.data.entregas_autorizadas;
      }
      return true;
    } catch (error) {
      console.error("Error al validar MIPRES antes de enviar:", error);
      message.error("Error al validar el código MIPRES antes de enviar.");
      return false;
    }
  };

  const saveAuthorization = async (formData) => {
    try {
      if (authorization) {
        await api.put(`/autorizaciones/${authorization.id}`, formData);
        message.success("Autorización actualizada correctamente.");
      } else {
        await api.post("/autorizaciones", formData);
        message.success("Autorización creada correctamente.");
      }
    } catch (error) {
      console.error("Error al guardar la autorización:", error);
      message.error("Error al guardar la autorización.");
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (errors.codigo_mipres) {
      message.error(errors.codigo_mipres);
      return;
    }

    if (!form.numero_autorizacion && !form.numero_pre_autorizacion) {
      message.error(
        "Debe proporcionar al menos un número de autorización o pre-autorización."
      );
      return;
    }

    try {
      setLoading(true);
      const formData = prepareFormData();

      if (!form.no_pbs && form.codigo_mipres) {
        const mipresValid = await validateMipresBeforeSubmit(formData);
        if (!mipresValid) return;
      }

      await saveAuthorization(formData);
      fetchAuthorizations();
      handleClose();
      resetForm();
    } catch (error) {
      // Manejo de errores adicional si es necesario
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row gutter={24}>
        {/* Columna 1: Información del Paciente, Responsable y Fechas de Autorización */}
        <Col xs={24} sm={24} md={8}>
          {/* Información del Paciente */}
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <UserAddOutlined style={{ marginRight: "8px" }} />
              Información del Paciente
            </Title>
            <PatientInfo
              form={form}
              handleChange={handleChange}
              patients={patients}
              handlePatientChange={handlePatientChange}
              isEditMode={!!authorization}
            />
          </div>

          {/* Información del Responsable */}
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TeamOutlined style={{ marginRight: "8px" }} />
              Información del Responsable
            </Title>
            <ResponsibleInfo form={form} handleChange={handleChange} />
          </div>

          {/* Fechas de la Autorización */}
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CalendarOutlined style={{ marginRight: "8px" }} />
              Fechas de la Autorización
            </Title>
            <AuthorizationDates
              form={form}
              handleChange={handleChange}
              handleDaySelection={handleDaySelection}
              selectedDays={selectedDays}
              dateRange={dateRange}
              setDateRange={setDateRange}
              calendarStartDate={calendarStartDate || dayjs()}
              setCalendarStartDate={setCalendarStartDate}
            />
          </div>
        </Col>

        {/* Columna 2: Datos de la Autorización */}
        <Col xs={24} sm={24} md={8}>
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SafetyOutlined style={{ marginRight: "8px" }} />
              Datos de la Autorización
            </Title>
            <AuthorizationData
              form={form}
              handleChange={handleChange}
              cups={cups}
              handleCupsChange={handleCupsChange}
              errors={errors}
              isMipresExisting={isMipresExisting}
              handleNoPbsChange={handleNoPbsChange}
              propositos={propositos}
              handlePropositoChange={handlePropositoChange}
              isEditMode={!!authorization}
              copagoFieldsDisabled={copagoFieldsDisabled}
            />
          </div>
        </Col>

        {/* Columna 3: Información de Ubicación */}
        <Col xs={24} sm={24} md={8}>
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EnvironmentOutlined style={{ marginRight: "8px" }} />
              Origen del Traslado
            </Title>
            <LocationInfo
              form={form}
              handleChange={handleChange}
              instituciones={instituciones}
              ciudadesOrigen={ciudadesOrigen}
              setCiudadesOrigen={setCiudadesOrigen}
              ciudadesDestino={ciudadesDestino}
              setCiudadesDestino={setCiudadesDestino}
              handleInstitucionChange={handleInstitucionChange}
              isEditMode={!!authorization}
            />
          </div>
        </Col>
      </Row>

      {/* Observaciones */}
      <Row gutter={24}>
        <Col span={24}>
          <div style={{ marginBottom: "24px" }}>
            <Title
              level={4}
              style={{
                marginBottom: 16,
                backgroundColor: "#0a2e5c",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FileTextOutlined style={{ marginRight: "8px" }} />
              Observaciones
            </Title>
            <Form.Item>
              <TextArea
                name="observaciones"
                value={form.observaciones}
                onChange={handleChange}
                placeholder="Observaciones"
                rows={4}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      {/* Botones de Acción */}
      <Form.Item>
        <Row justify="end">
          <Col>
            <Button
              onClick={() => {
                resetForm();
                handleClose();
              }}
              style={{
                backgroundColor: "#d32f2f",
                color: "white",
                borderColor: "#d32f2f",
                borderRadius: "4px",
                marginRight: "8px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#b71c1c")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#d32f2f")
              }
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                backgroundColor: "#0a2e5c",
                color: "white",
                borderColor: "#0a2e5c",
                borderRadius: "4px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#004080")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#0a2e5c")
              }
            >
              {authorization ? "Actualizar" : "Guardar"}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

CreateAuthorizationForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  fetchAuthorizations: PropTypes.func.isRequired,
  authorization: PropTypes.object,
};

export default CreateAuthorizationForm;
