import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Typography,
  List,
  Avatar,
  Input,
  Button,
  Card,
  Divider,
  Space,
} from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  SendOutlined,
} from "@ant-design/icons";
import api from "../../../axiosConfig";
import { useUser } from "../../../UserContext"; // Importa el contexto del usuario

const { Title, Text } = Typography;
const { TextArea } = Input;

// Componente ObservacionesDialog para mostrar y agregar observaciones
function ObservacionesDialog({ open, onClose, trasladoId }) {
  const { user } = useUser(); // Obtiene el usuario del contexto
  const [observaciones, setObservaciones] = useState([]);
  const [nuevaObservacion, setNuevaObservacion] = useState("");
  const listEndRef = useRef(null); // Referencia para el final de la lista

  // Función para desplazarse automáticamente al final de la lista
  const scrollToBottom = () => {
    listEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (trasladoId && open) {
      // Obtener las observaciones del traslado
      api
        .get(`https://mogotaxsas.com/api/observaciones/${trasladoId}`)
        .then((response) => {
          setObservaciones(response.data);
          scrollToBottom(); // Desplazarse al final cuando se cargan las observaciones
        })
        .catch((error) => {
          console.error("Error al obtener las observaciones:", error);
        });
    }
  }, [trasladoId, open]);

  const handleAgregarObservacion = () => {
    if (nuevaObservacion.trim() && user) {
      api
        .post("https://mogotaxsas.com/api/observaciones", {
          traslado_id: trasladoId,
          usuario_id: user.id, // Usa el ID del usuario desde el contexto
          texto_observacion: nuevaObservacion,
        })
        .then((response) => {
          // Manualmente añade la observación con el nombre del usuario actual
          const nuevaObservacionConUsuario = {
            ...response.data,
            Usuario: { nombre: user.nombre }, // Añadimos el nombre del usuario actual
          };

          setObservaciones([...observaciones, nuevaObservacionConUsuario]);
          setNuevaObservacion(""); // Limpiar el campo de texto
          scrollToBottom(); // Desplazar hacia la nueva observación
        })
        .catch((error) => {
          console.error("Error al agregar la observación:", error);
        });
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      title={
        <Space align="center">
          <UserOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
          <Title level={4} style={{ margin: 0 }}>
            Observaciones de Seguimiento
          </Title>
        </Space>
      }
      width={700}
      centered
      destroyOnClose
    >
      <Card
        bordered={false}
        style={{
          maxHeight: "500px",
          overflowY: "auto",
          backgroundColor: "#fafafa",
          padding: "24px",
          borderRadius: "12px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <List
          dataSource={observaciones}
          renderItem={(observacion) => (
            <List.Item key={observacion.id} style={{ padding: 0 }}>
              <List.Item.Meta
                avatar={
                  observacion.Usuario ? (
                    <Avatar style={{ backgroundColor: "#52c41a" }} icon={null}>
                      {observacion.Usuario.nombre.charAt(0).toUpperCase()}
                    </Avatar>
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )
                }
                title={
                  <Space>
                    <Text strong style={{ color: "#2E3B4E" }}>
                      {observacion.Usuario
                        ? observacion.Usuario.nombre
                        : "Usuario desconocido"}
                    </Text>
                    <Text type="secondary" style={{ fontStyle: "italic" }}>
                      <CalendarOutlined />{" "}
                      {new Date(observacion.created_at).toLocaleString()}
                    </Text>
                  </Space>
                }
                description={
                  <Card
                    bordered={false}
                    style={{
                      backgroundColor:
                        observacion.Usuario &&
                        observacion.Usuario.nombre === user.nombre
                          ? "#e6f7ff"
                          : "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                      marginTop: "8px",
                    }}
                  >
                    <Text>{observacion.texto_observacion}</Text>
                  </Card>
                }
              />
            </List.Item>
          )}
        />
        <div ref={listEndRef} />
      </Card>
      <Divider style={{ margin: "24px 0" }} />
      <Space direction="vertical" style={{ width: "100%" }}>
        <TextArea
          rows={4}
          placeholder="Escribe tu observación..."
          value={nuevaObservacion}
          onChange={(e) => setNuevaObservacion(e.target.value)}
          style={{
            borderRadius: "8px",
            padding: "10px",
            resize: "none",
          }}
        />
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={handleAgregarObservacion}
          disabled={!nuevaObservacion.trim()}
          block
        >
          Enviar Observación
        </Button>
      </Space>
    </Modal>
  );
}

// **Validación de las props con PropTypes**
ObservacionesDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Indica si el diálogo está abierto
  onClose: PropTypes.func.isRequired, // Función para cerrar el diálogo
  trasladoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired, // CS-ID del traslado
};

export default ObservacionesDialog;
