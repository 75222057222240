// src/components/Modules/Vehicles/Vehicles.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import api from "../../../axiosConfig";
import {
  Table,
  Button,
  Input,
  Space,
  Pagination,
  Tooltip,
  Spin,
  message,
  Select,
  Row,
  Col,
  Modal,
  Typography,
  Tag,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AddVehicleForm from "./AddVehicleForm"; // Asegúrate de que la ruta sea correcta
import ViewVehicleDialog from "./ViewVehicleDialog"; // Necesitas crear este componente
import { useUser } from "../../../UserContext"; // Importa el contexto de usuario

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const Vehicles = () => {
  const { user } = useUser(); // Usa el contexto de usuario
  const [vehicles, setVehicles] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loadingZonas, setLoadingZonas] = useState(false);
  const [selectedZona, setSelectedZona] = useState(null);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  // Eliminado: const [loadingVehicle, setLoadingVehicle] = useState(false);

  // Función para obtener vehículos con manejo de carga
  const fetchVehicles = useCallback(async () => {
    setListLoading(true);
    try {
      const response = await api.get("/vehiculos"); // Asegúrate de que la ruta sea correcta
      setVehicles(response.data);
      console.log("Vehículos cargados:", response.data);
    } catch (error) {
      console.error("Error al obtener los vehículos:", error);
      message.error("Error al obtener los vehículos");
    } finally {
      setListLoading(false);
    }
  }, []);

  // Función para obtener zonas
  const fetchZonas = useCallback(async () => {
    setLoadingZonas(true);
    try {
      const response = await api.get("/zonas"); // Asegúrate de que la ruta sea correcta
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener las zonas:", error);
      message.error("Error al obtener las zonas");
    } finally {
      setLoadingZonas(false);
    }
  }, []);

  useEffect(() => {
    fetchVehicles();
    fetchZonas();
  }, [fetchVehicles, fetchZonas]);

  useEffect(() => {
    setPage(1);
  }, [filter, selectedZona]);

  const handleSearchChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setPage(1);
  };

  const handleEditClick = useCallback(async (vehicle) => {
    try {
      // Eliminado: setLoadingVehicle(true);
      const response = await api.get(`/vehiculos/${vehicle.id}`); // Asegúrate de que la ruta sea correcta
      setSelectedVehicle(response.data);
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el vehículo:", error);
      message.error("Error al obtener los datos del vehículo");
    } finally {
      // Eliminado: setLoadingVehicle(false);
    }
  }, []);

  const handleViewClick = useCallback(async (vehicle) => {
    try {
      // Eliminado: setLoadingVehicle(true);
      const response = await api.get(`/vehiculos/${vehicle.id}`); // Asegúrate de que la ruta sea correcta
      setSelectedVehicle(response.data);
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el vehículo:", error);
      message.error("Error al obtener los datos del vehículo");
    } finally {
      // Eliminado: setLoadingVehicle(false);
    }
  }, []);

  const handleOpenDeleteDialog = useCallback((vehicle) => {
    setVehicleToDelete(vehicle.id);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteVehicle = async () => {
    if (!vehicleToDelete) return;

    try {
      await api.delete(`/vehiculos/${vehicleToDelete}`, {
        data: { usuario_id: user.id }, // Incluye el ID del usuario
      });
      message.success("Vehículo eliminado con éxito");
      fetchVehicles();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error al eliminar el vehículo:", error);
      message.error("Error al eliminar el vehículo");
    }
  };

  const cancelDeleteVehicle = () => {
    setDeleteDialogOpen(false);
    setVehicleToDelete(null);
  };

  const filteredVehicles = useMemo(() => {
    return vehicles.filter((vehicle) => {
      const matchesFilter =
        vehicle.placa.toLowerCase().includes(filter.toLowerCase()) ||
        vehicle.marca.toLowerCase().includes(filter.toLowerCase()) ||
        vehicle.zona?.nombre?.toLowerCase().includes(filter.toLowerCase());

      const matchesZona = selectedZona
        ? vehicle.zona?.nombre === selectedZona
        : true;

      return matchesFilter && matchesZona;
    });
  }, [vehicles, filter, selectedZona]);

  const paginatedVehicles = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return filteredVehicles.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredVehicles, page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        title: "Código",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      },
      {
        title: "Placa",
        dataIndex: "placa",
        key: "placa",
        sorter: (a, b) => a.placa.localeCompare(b.placa),
      },
      {
        title: "Marca",
        dataIndex: "marca",
        key: "marca",
        sorter: (a, b) => a.marca.localeCompare(b.marca),
      },
      {
        title: "Modelo",
        dataIndex: "modelo",
        key: "modelo",
        sorter: (a, b) => a.modelo.localeCompare(b.modelo),
      },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
        sorter: (a, b) => a.color.localeCompare(b.color),
      },
      {
        title: "Zona",
        dataIndex: "zona",
        key: "zona",
        render: (zona) => zona?.nombre || "Sin zona",
        sorter: (a, b) =>
          (a.zona?.nombre || "").localeCompare(b.zona?.nombre || ""),
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (estado) => (
          <Tag color={estado === "Activo" ? "green" : "red"}>{estado}</Tag>
        ),
        sorter: (a, b) => a.estado.localeCompare(b.estado),
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, vehicle) => (
          <Space size="middle">
            <Tooltip title="Ver">
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewClick(vehicle)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Editar">
              <Button
                icon={<EditOutlined />}
                onClick={() => handleEditClick(vehicle)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Eliminar">
              <Popconfirm
                title="¿Estás seguro de eliminar este vehículo?"
                onConfirm={() => handleOpenDeleteDialog(vehicle)}
                okText="Sí"
                cancelText="No"
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  type="default"
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    padding: "0 8px",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
        width: 150,
      },
    ],
    [handleEditClick, handleViewClick, handleOpenDeleteDialog]
  );

  // Estilos para encabezados fijos usando CSS-in-JS
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 1,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
  };

  return (
    <div className="vehicles-container">
      {/* Barra de herramientas superior */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            <Search
              placeholder="Buscar vehículo"
              enterButton={<SearchOutlined />}
              value={filter}
              onChange={handleSearchChange}
              style={{ width: 500 }}
              allowClear
            />
            <Select
              placeholder="Filtrar por Zona"
              allowClear
              style={{ width: 200 }}
              onChange={(value) => setSelectedZona(value)}
              loading={loadingZonas}
              value={selectedZona}
            >
              {zonas.map((zona) => (
                <Option key={zona.id} value={zona.nombre}>
                  {zona.nombre}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDialogOpen(true)}
            >
              Agregar Vehículo
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Contenedor de la tabla con desplazamiento personalizado usando Ant Design */}
      <Spin spinning={listLoading} tip="Cargando vehículos...">
        <Table
          columns={columns.map((col) => ({
            ...col,
            onHeaderCell: () => ({
              style: tableHeaderStyle,
            }),
          }))}
          dataSource={paginatedVehicles}
          pagination={false}
          rowKey="id"
          bordered
          scroll={{ y: 600 }} // Establece la altura del cuerpo de la tabla
        />
        {/* Paginación siempre visible */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
        >
          <Pagination
            current={page}
            pageSize={rowsPerPage}
            total={filteredVehicles.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={handleRowsPerPageChange}
            pageSizeOptions={["5", "10", "25", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} vehículos`
            }
          />
        </div>
      </Spin>

      {/* Formularios y Diálogos */}

      {/* Agregar Vehículo */}
      {addDialogOpen && (
        <AddVehicleForm
          open={addDialogOpen}
          handleClose={() => setAddDialogOpen(false)}
          fetchVehicles={fetchVehicles}
        />
      )}

      {/* Editar Vehículo */}
      {editDialogOpen && selectedVehicle && (
        <AddVehicleForm
          open={editDialogOpen}
          handleClose={() => setEditDialogOpen(false)}
          fetchVehicles={fetchVehicles}
          initialData={selectedVehicle}
          isEdit={true}
        />
      )}

      {/* Ver Vehículo */}
      {viewDialogOpen && selectedVehicle && (
        <ViewVehicleDialog
          vehicle={selectedVehicle}
          open={viewDialogOpen}
          handleClose={() => setViewDialogOpen(false)}
        />
      )}

      {/* Confirmación de Eliminación */}
      <Modal
        title="Confirmar Eliminación"
        open={deleteDialogOpen} // Reemplazado 'visible' por 'open'
        onOk={confirmDeleteVehicle}
        onCancel={cancelDeleteVehicle}
        okText="Eliminar"
        cancelText="Cancelar"
        okButtonProps={{ danger: true }}
      >
        <Text>
          ¿Estás seguro de que deseas eliminar el vehículo con placa{" "}
          <strong>
            {vehicles.find((v) => v.id === vehicleToDelete)?.placa || ""}
          </strong>{" "}
          ?
        </Text>
      </Modal>
    </div>
  );
};

export default Vehicles;
