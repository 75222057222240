// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/reset.css"; // Importar los estilos de Ant Design
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES"; // Importar el locale español
import moment from "moment";
import "moment/locale/es"; // Importar la configuración regional de moment si lo usas
import "./index.css"; // Importa los estilos globales
// Configurar moment para español (opcional)
moment.locale("es");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
