// src/components/Footer.js

import React from "react";

function FooterComponent() {
  const version = "1.0.3 Beta"; // Cambia esto por la versión real de tu aplicación

  return (
    <div
      className="footer"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        width: "100%",
        backgroundColor: "#001529",
        color: "white",
        height: "50px", // Altura fija
      }}
    >
      <span>
        © {new Date().getFullYear()} Mogotax SAS. Todos los derechos reservados.
      </span>
      <span style={{ color: "green" }}>Versión {version}</span>
    </div>
  );
}

export default FooterComponent;
