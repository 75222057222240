import api from "../../../axiosConfig";

export const fetchAsignaciones = () => {
  return api.get("https://mogotaxsas.com/api/vehiculo-conductores");
};

export const fetchVehiculos = () => {
  return api.get("https://mogotaxsas.com/api/vehiculos");
};

export const fetchConductores = () => {
  return api.get("https://mogotaxsas.com/api/conductores");
};

export const fetchZonas = () => {
  return api.get("https://mogotaxsas.com/api/zonas");
};

export const crearAsignacion = (asignacion) => {
  return api.post(
    "https://mogotaxsas.com/api/vehiculo-conductores",
    asignacion
  );
};
