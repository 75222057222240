// src/components/Header.js
import React, { useState } from "react";
import { Button, Avatar, Dropdown, Tooltip } from "antd";
import {
  MenuOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSocket } from "../SocketContext";
import { useUser } from "../UserContext";

const HeaderComponent = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { socket } = useSocket();
  const { user, setUser } = useUser();

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => console.log(e));
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleLogout = () => {
    if (user && socket) {
      socket.emit("logout", user.id);
      socket.disconnect();
    }
    setUser(null);
    localStorage.removeItem("user");
    navigate("/login");
  };

  // Definir el menú usando la prop `items`
  const menu = {
    items: [
      {
        key: "logout",
        label: "Cerrar sesión",
        onClick: handleLogout,
      },
    ],
  };

  const userInitial = user?.nombre?.charAt(0).toUpperCase() || "U";

  const routeTitles = [
    { path: "/UserList", title: "Lista de Usuarios", icon: <UserOutlined /> },
    {
      path: "/UserCards",
      title: "Tarjetas de Usuarios",
      icon: <UserOutlined />,
    },
    { path: "/Patients", title: "Pacientes", icon: <UserOutlined /> },
    {
      path: "/Authorizations",
      title: "Autorizaciones",
      icon: <UserOutlined />,
    },
    { path: "/Clients", title: "Clientes", icon: <UserOutlined /> },
    { path: "/Drivers", title: "Conductores", icon: <UserOutlined /> },
    { path: "/Vehicles", title: "Vehículos", icon: <UserOutlined /> },
    {
      path: "/VehiculoConductores",
      title: "Asignaciones",
      icon: <UserOutlined />,
    },
    {
      path: "/TimeCheck",
      title: "Verificación de Hora",
      icon: <UserOutlined />,
    },
    { path: "/Institutions", title: "Instituciones", icon: <UserOutlined /> },
    {
      path: "/CenterControl",
      title: "Centro de Control",
      icon: <UserOutlined />,
    },
    { path: "/Coordinacion", title: "Coordinación", icon: <UserOutlined /> },
  ];

  const currentRoute = routeTitles.find((route) =>
    location.pathname.includes(route.path)
  );
  const currentTitle = currentRoute?.title || "Módulo Desconocido";
  const currentIcon = currentRoute?.icon || <UserOutlined />;

  return (
    <div
      className="header"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 10px",
        background: "#001529", // Fondo oscuro para el header
        color: "white",
        boxShadow: "0 2px 8px #f0f1f2",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      {/* Botón para alternar el Sidebar */}
      <Button
        type="text"
        onClick={toggleSidebar}
        icon={<MenuOutlined style={{ color: "white", fontSize: "20px" }} />}
      />

      {/* Título del módulo con ícono */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        {currentIcon}
        <h2
          style={{
            color: "white",
            marginLeft: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          {currentTitle}
        </h2>
      </div>

      {/* Controles de pantalla completa y configuración */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip
          title={
            isFullscreen ? "Salir de Pantalla Completa" : "Pantalla Completa"
          }
        >
          <Button
            type="text"
            onClick={handleFullscreen}
            icon={
              isFullscreen ? (
                <FullscreenExitOutlined
                  style={{ color: "white", fontSize: "20px" }}
                />
              ) : (
                <FullscreenOutlined
                  style={{ color: "white", fontSize: "20px" }}
                />
              )
            }
          />
        </Tooltip>
        <Dropdown menu={menu}>
          <Button
            type="text"
            icon={
              <SettingOutlined style={{ color: "white", fontSize: "20px" }} />
            }
          />
        </Dropdown>
        <Avatar
          style={{ marginLeft: "10px", backgroundColor: "#87d068" }}
          icon={<UserOutlined />}
        >
          {userInitial}
        </Avatar>
      </div>
    </div>
  );
};

HeaderComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default HeaderComponent;
