// src/Components/modules/authorizations/Form/LocationInfo.js

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Select,
  Form,
  Divider,
  Row,
  Col,
  message,
  Button,
  Typography,
} from "antd";
import { EnvironmentOutlined, PlusOutlined } from "@ant-design/icons";
import api from "../../../../axiosConfig";
import MapDialog from "../../../../Resources/GoogleApi/MapDialog"; // Asegúrate de que esta ruta sea correcta

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

/**
 * Verifica si el texto buscado no existe en la lista de instituciones
 * @param {string} texto
 * @param {Array} lista
 * @returns {boolean}
 */
const noExisteInstitucion = (texto, lista) => {
  if (!texto) return false;
  const textoLower = texto.toLowerCase();
  return !lista.some((inst) => inst.nombre.toLowerCase() === textoLower);
};

/**
 * Renderiza el menú del dropdown, agregando la opción de crear un lugar temporal
 * si no existe la institución buscada.
 * Utiliza el componente <Button> de Ant Design para mejorar la accesibilidad.
 * @param {ReactNode} menu - El menú original
 * @param {string} searchText - Texto buscado por el usuario
 * @param {Array} instituciones - Lista de instituciones existentes
 * @param {Function} agregarLugarTemporal - Función para agregar el lugar temporal
 * @returns {ReactNode} El nuevo menú con la opción adicional
 */
const renderDropdownMenu = (
  menu,
  searchText,
  instituciones,
  agregarLugarTemporal
) => (
  <>
    {menu}
    {noExisteInstitucion(searchText, instituciones) &&
      searchText.trim() !== "" && (
        <Button
          type="text"
          icon={<PlusOutlined style={{ marginRight: 8 }} />}
          onClick={agregarLugarTemporal}
          style={{
            padding: "8px 12px",
            cursor: "pointer",
            borderTop: "1px solid #eee",
            display: "flex",
            alignItems: "center",
            color: "#1890ff",
            width: "100%",
            textAlign: "left",
          }}
        >
          Agregar lugar temporal: <strong>{searchText}</strong>
        </Button>
      )}
  </>
);

const LocationInfo = ({
  form,
  handleChange,
  instituciones,
  ciudadesOrigen,
  setCiudadesOrigen,
  ciudadesDestino,
  setCiudadesDestino,
  handleInstitucionChange,
}) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [searchTextOrigen, setSearchTextOrigen] = useState("");
  const [searchTextDestino, setSearchTextDestino] = useState("");
  const [isMapDialogOpenDestino, setIsMapDialogOpenDestino] = useState(false); // Nuevo estado para el destino

  /**
   * Función para obtener la lista de departamentos desde la API.
   */
  const fetchDepartamentos = useCallback(async () => {
    try {
      const response = await api.get("/departamentos");
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener departamentos:", error);
      message.error("Error al cargar los departamentos.");
    }
  }, []);

  /**
   * Función para obtener las ciudades de un departamento específico.
   * @param {number} departamentoId - ID del departamento seleccionado.
   * @param {Function} setCiudades - Función para actualizar el estado de ciudades.
   */
  const fetchCiudades = useCallback(async (departamentoId, setCiudades) => {
    if (!departamentoId) return;
    try {
      const response = await api.get(
        `/ciudades?departamento_id=${departamentoId}`
      );
      setCiudades(response.data);
    } catch (error) {
      console.error("Error al obtener ciudades:", error);
      message.error("Error al cargar las ciudades.");
    }
  }, []);

  useEffect(() => {
    fetchDepartamentos();
  }, [fetchDepartamentos]);

  useEffect(() => {
    if (form.origen_departamento) {
      fetchCiudades(form.origen_departamento, setCiudadesOrigen);
    }
  }, [form.origen_departamento, fetchCiudades, setCiudadesOrigen]);

  useEffect(() => {
    if (form.destino_departamento) {
      fetchCiudades(form.destino_departamento, setCiudadesDestino);
    }
  }, [form.destino_departamento, fetchCiudades, setCiudadesDestino]);

  /**
   * Función para agregar una institución temporal al origen.
   */
  const agregarLugarTemporalOrigen = () => {
    handleChange({ target: { name: "origen_institucion", value: null } });
    handleChange({
      target: {
        name: "origen_institucion_temporal",
        value: searchTextOrigen,
      },
    });
    setSearchTextOrigen("");
  };

  /**
   * Función para agregar una institución temporal al destino.
   */
  const agregarLugarTemporalDestino = () => {
    handleChange({ target: { name: "destino_institucion", value: null } });
    handleChange({
      target: {
        name: "destino_institucion_temporal",
        value: searchTextDestino,
      },
    });
    setSearchTextDestino("");

    // Limpiar los demás campos de destino
    resetDestinationFields();
  };

  /**
   * Función para limpiar los campos de destino.
   */
  const resetDestinationFields = () => {
    const camposDestino = [
      "destino_localidad",
      "destino_barrio",
      "destino_direccion",
      "destino_indicaciones",
      "destino_coordenadas",
    ];

    camposDestino.forEach((campo) => {
      handleChange({ target: { name: campo, value: "" } });
    });
  };

  // Añadir useEffect para establecer "Domicilio" como lugar temporal si está vacío
  useEffect(() => {
    if (
      form.paciente_id &&
      !form.origen_institucion &&
      !form.origen_institucion_temporal
    ) {
      handleChange({
        target: { name: "origen_institucion_temporal", value: "Domicilio" },
      });
    }
  }, [
    form.paciente_id,
    form.origen_institucion,
    form.origen_institucion_temporal,
    handleChange,
  ]);

  /**
   * Maneja la selección de una ubicación en el mapa para el destino.
   * @param {string} direccion - Dirección seleccionada.
   * @param {string} localidad - Localidad seleccionada.
   * @param {string} coordenadas - Coordenadas seleccionadas (lng, lat).
   */
  const handleMapSelectDestino = (direccion, localidad, coordenadas) => {
    handleChange({ target: { name: "destino_direccion", value: direccion } });
    handleChange({ target: { name: "destino_localidad", value: localidad } });
    handleChange({
      target: { name: "destino_coordenadas", value: coordenadas },
    });
    setIsMapDialogOpenDestino(false);
  };

  return (
    <>
      <Divider />

      {/* Sección de Origen */}
      <Form.Item label="Lugar de Origen" required>
        <Select
          showSearch
          allowClear
          placeholder="Domicilio"
          value={
            form.origen_institucion
              ? form.origen_institucion
              : form.origen_institucion_temporal || undefined
          }
          onChange={(value) => {
            handleInstitucionChange(value, "origen");
            handleChange({
              target: { name: "origen_institucion_temporal", value: "" },
            });
          }}
          style={{ width: "100%" }}
          optionFilterProp="children"
          onSearch={(value) => setSearchTextOrigen(value)}
          dropdownRender={(menu) =>
            renderDropdownMenu(
              menu,
              searchTextOrigen,
              instituciones,
              agregarLugarTemporalOrigen
            )
          }
        >
          {instituciones.map((inst) => (
            <Option key={inst.id} value={inst.id}>
              {inst.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Departamento de Origen">
            <Select
              showSearch
              allowClear
              placeholder="Seleccione un departamento"
              value={form.origen_departamento || undefined}
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "origen_departamento",
                    value: value,
                  },
                });
                setCiudadesOrigen([]);
              }}
              style={{ width: "100%" }}
              optionFilterProp="children"
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Ciudad/Municipio de Origen">
            <Select
              showSearch
              allowClear
              placeholder="Seleccione una ciudad"
              value={form.origen_ciudad || undefined}
              onChange={(value) => {
                handleChange({
                  target: { name: "origen_ciudad", value: value },
                });
              }}
              style={{ width: "100%" }}
              optionFilterProp="children"
            >
              {ciudadesOrigen.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Otros campos de ubicación de origen */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Localidad">
            <Input
              name="origen_localidad"
              value={form.origen_localidad}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Barrio">
            <Input
              name="origen_barrio"
              value={form.origen_barrio}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Dirección">
        <Input
          name="origen_direccion"
          value={form.origen_direccion}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item label="Indicaciones">
        <TextArea
          name="origen_indicaciones"
          value={form.origen_indicaciones}
          onChange={handleChange}
          rows={3}
        />
      </Form.Item>

      <Form.Item label="Coordenadas (Origen)">
        <Input
          name="origen_coordenadas"
          value={form.origen_coordenadas || ""}
          onChange={(e) =>
            handleChange({
              target: { name: "origen_coordenadas", value: e.target.value },
            })
          }
          placeholder="Formato: lng, lat"
        />
      </Form.Item>

      {/* Sección de Destino */}
      <Title
        level={4}
        style={{
          marginBottom: 16,
          backgroundColor: "#0a2e5c",
          color: "white",
          padding: "8px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <EnvironmentOutlined style={{ marginRight: "8px" }} />
        Destino del Traslado
      </Title>
      <Form.Item label="Lugar de Destino" required>
        <Select
          showSearch
          allowClear
          placeholder="Seleccione un Lugar"
          value={
            form.destino_institucion
              ? form.destino_institucion
              : form.destino_institucion_temporal || undefined
          }
          onChange={(value) => {
            handleInstitucionChange(value, "destino");
            handleChange({
              target: { name: "destino_institucion_temporal", value: "" },
            });
          }}
          style={{ width: "100%" }}
          optionFilterProp="children"
          onSearch={(value) => setSearchTextDestino(value)}
          dropdownRender={(menu) =>
            renderDropdownMenu(
              menu,
              searchTextDestino,
              instituciones,
              agregarLugarTemporalDestino
            )
          }
        >
          {instituciones.map((inst) => (
            <Option key={inst.id} value={inst.id}>
              {inst.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Mostrar botón "Buscar en el Mapa" solo si se está creando un destino temporal */}
      {form.destino_institucion_temporal && (
        <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Button
              icon={<EnvironmentOutlined />}
              onClick={() => setIsMapDialogOpenDestino(true)}
              style={{ width: "100%" }}
            >
              Buscar en el Mapa
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Departamento de Destino" required>
            <Select
              showSearch
              allowClear
              placeholder="Seleccione un departamento"
              value={form.destino_departamento || undefined}
              onChange={(value) => {
                handleChange({
                  target: {
                    name: "destino_departamento",
                    value: value,
                  },
                });
                setCiudadesDestino([]);
              }}
              style={{ width: "100%" }}
              optionFilterProp="children"
            >
              {departamentos.map((dep) => (
                <Option key={dep.id} value={dep.id}>
                  {dep.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Ciudad/Municipio de Destino" required>
            <Select
              showSearch
              allowClear
              placeholder="Seleccione una ciudad"
              value={form.destino_ciudad || undefined}
              onChange={(value) => {
                handleChange({
                  target: { name: "destino_ciudad", value: value },
                });
              }}
              style={{ width: "100%" }}
              optionFilterProp="children"
            >
              {ciudadesDestino.map((city) => (
                <Option key={city.id} value={city.id}>
                  {city.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Otros campos de ubicación de destino */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Localidad">
            <Input
              name="destino_localidad"
              value={form.destino_localidad}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Barrio" required>
            <Input
              name="destino_barrio"
              value={form.destino_barrio}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Dirección" required>
        <Input
          name="destino_direccion"
          value={form.destino_direccion}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item label="Indicaciones">
        <TextArea
          name="destino_indicaciones"
          value={form.destino_indicaciones}
          onChange={handleChange}
          rows={3}
        />
      </Form.Item>

      <Form.Item label="Coordenadas (Destino)" required>
        <Input
          name="destino_coordenadas"
          value={form.destino_coordenadas || ""}
          onChange={(e) =>
            handleChange({
              target: { name: "destino_coordenadas", value: e.target.value },
            })
          }
          placeholder="Formato: lng, lat"
        />
      </Form.Item>

      {/* Diálogo del Mapa para Destino */}
      <MapDialog
        open={isMapDialogOpenDestino}
        onClose={() => setIsMapDialogOpenDestino(false)}
        onSelect={handleMapSelectDestino}
        initialCoords={form.destino_coordenadas}
      />
    </>
  );
};

// Definición de PropTypes para validar los props recibidos
LocationInfo.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  instituciones: PropTypes.array.isRequired,
  ciudadesOrigen: PropTypes.array.isRequired,
  setCiudadesOrigen: PropTypes.func.isRequired,
  ciudadesDestino: PropTypes.array.isRequired,
  setCiudadesDestino: PropTypes.func.isRequired,
  handleInstitucionChange: PropTypes.func.isRequired,
};

export default LocationInfo;
