// src/components/Drivers/Drivers.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import api from "../../../axiosConfig";
import {
  Table,
  Button,
  Input,
  Space,
  Pagination,
  Tooltip,
  Spin,
  message,
  Select,
  Row,
  Col,
  Modal,
  Typography,
  Tag,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
  HistoryOutlined, // Importamos HistoryOutlined desde Ant Design Icons
} from "@ant-design/icons";
import AddDriverForm from "./Form/AddDriverForm";
import ViewDriverDialog from "./ViewDriverDialog";
import HistorialConductorDialog from "./HistorialConductorDialog"; // Importamos el componente del historial
import { useUser } from "../../../UserContext";

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const Drivers = () => {
  const { user } = useUser();
  const [drivers, setDrivers] = useState([]);
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [loadingZonas, setLoadingZonas] = useState(false);
  const [selectedZona, setSelectedZona] = useState(null);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);
  const [listLoading, setListLoading] = useState(false);

  // Estados para el historial del conductor
  const [historialDialogOpen, setHistorialDialogOpen] = useState(false);
  const [selectedConductorId, setSelectedConductorId] = useState(null);

  // Función para obtener conductores con manejo de carga
  const fetchDrivers = useCallback(async () => {
    setListLoading(true);
    try {
      const response = await api.get("/conductores");
      setDrivers(response.data);
      console.log("Conductores cargados:", response.data);
    } catch (error) {
      console.error("Error al obtener los conductores:", error);
      message.error("Error al obtener los conductores");
    } finally {
      setListLoading(false);
    }
  }, []);

  // Función para obtener tipos de documento
  const fetchTiposDocumento = useCallback(async () => {
    try {
      const response = await api.get("/tipos-documento");
      setTiposDocumento(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de documento:", error);
      message.error("Error al obtener tipos de documento");
    }
  }, []);

  // Función para obtener zonas
  const fetchZonas = useCallback(async () => {
    setLoadingZonas(true);
    try {
      const response = await api.get("/zonas");
      setZonas(response.data);
    } catch (error) {
      console.error("Error al obtener las zonas:", error);
      message.error("Error al obtener las zonas");
    } finally {
      setLoadingZonas(false);
    }
  }, []);

  useEffect(() => {
    fetchDrivers();
    fetchTiposDocumento();
    fetchZonas();
  }, [fetchDrivers, fetchTiposDocumento, fetchZonas]);

  useEffect(() => {
    setPage(1);
  }, [filter, selectedZona]);

  const handleSearchChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setPage(1);
  };

  const handleEditClick = useCallback(async (driver) => {
    try {
      setListLoading(true);
      const response = await api.get(`/conductores/${driver.id}`);
      setSelectedDriver(response.data);
      setEditDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el conductor:", error);
      message.error("Error al obtener los datos del conductor");
    } finally {
      setListLoading(false);
    }
  }, []);

  const handleViewClick = useCallback(async (driver) => {
    try {
      setListLoading(true);
      const response = await api.get(`/conductores/${driver.id}`);
      setSelectedDriver(response.data);
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error al obtener el conductor:", error);
      message.error("Error al obtener los datos del conductor");
    } finally {
      setListLoading(false);
    }
  }, []);

  const handleOpenDeleteDialog = useCallback((driver) => {
    setDriverToDelete(driver.id);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteDriver = async () => {
    if (!driverToDelete) return;

    try {
      await api.delete(`/conductores/${driverToDelete}`, {
        data: { usuario_id: user.id },
      });
      message.success("Conductor eliminado con éxito");
      fetchDrivers();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error al eliminar el conductor:", error);
      message.error("Error al eliminar el conductor");
    }
  };

  const cancelDeleteDriver = () => {
    setDeleteDialogOpen(false);
    setDriverToDelete(null);
  };

  // Función para manejar la apertura del historial
  const handleHistorialClick = useCallback((driver) => {
    setSelectedConductorId(driver.id);
    setHistorialDialogOpen(true);
  }, []);

  const filteredDrivers = useMemo(() => {
    return drivers.filter((driver) => {
      const matchesFilter =
        driver.nombre.toLowerCase().includes(filter.toLowerCase()) ||
        driver.numero_documento.toLowerCase().includes(filter.toLowerCase());

      const matchesZona = selectedZona
        ? driver.zona?.nombre === selectedZona
        : true;

      return matchesFilter && matchesZona;
    });
  }, [drivers, filter, selectedZona]);

  const paginatedDrivers = useMemo(() => {
    const startIndex = (page - 1) * rowsPerPage;
    return filteredDrivers.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredDrivers, page, rowsPerPage]);

  const columns = useMemo(
    () => [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Tipo de Documento",
        dataIndex: "tipo_documento_id",
        key: "tipo_documento_id",
        render: (tipo_documento_id) => {
          const tipo = tiposDocumento.find(
            (tipo) => tipo.id === tipo_documento_id
          );
          return tipo ? tipo.descripcion : "Desconocido";
        },
        sorter: (a, b) => {
          const tipoA =
            tiposDocumento.find((tipo) => tipo.id === a.tipo_documento_id)
              ?.descripcion || "";
          const tipoB =
            tiposDocumento.find((tipo) => tipo.id === b.tipo_documento_id)
              ?.descripcion || "";
          return tipoA.localeCompare(tipoB);
        },
      },
      {
        title: "Número de Documento",
        dataIndex: "numero_documento",
        key: "numero_documento",
        sorter: (a, b) => a.numero_documento.localeCompare(b.numero_documento),
      },
      {
        title: "Ciudad, Departamento",
        dataIndex: "ciudad",
        key: "ciudad",
        render: (ciudad, record) => {
          const ciudadNombre = ciudad?.nombre || ciudad || "Sin Ciudad";
          const departamentoNombre =
            record.departamento?.nombre ||
            record.departamento ||
            "Sin Departamento";
          return `${ciudadNombre}, ${departamentoNombre}`;
        },
        sorter: (a, b) => {
          const ciudadA = `${a.ciudad?.nombre || a.ciudad || ""}, ${
            a.departamento?.nombre || a.departamento || ""
          }`;
          const ciudadB = `${b.ciudad?.nombre || b.ciudad || ""}, ${
            b.departamento?.nombre || b.departamento || ""
          }`;
          return ciudadA.localeCompare(ciudadB);
        },
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (estado) => {
          let color = "";
          switch (estado.toLowerCase()) {
            case "activo":
              color = "green";
              break;
            case "inactivo":
              color = "red";
              break;
            default:
              color = "gray";
          }
          return (
            <Tag color={color}>
              {estado.charAt(0).toUpperCase() + estado.slice(1)}
            </Tag>
          );
        },
        sorter: (a, b) => a.estado.localeCompare(b.estado),
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, driver) => (
          <Space size="middle">
            <Tooltip title="Ver">
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewClick(driver)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Editar">
              <Button
                icon={<EditOutlined />}
                onClick={() => handleEditClick(driver)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>

            <Tooltip title="Eliminar">
              <Popconfirm
                title="¿Estás seguro de eliminar este conductor?"
                onConfirm={() => handleOpenDeleteDialog(driver)}
                okText="Sí"
                cancelText="No"
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="default"
                  danger
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    padding: "0 8px",
                  }}
                />
              </Popconfirm>
            </Tooltip>

            <Tooltip title="Historial">
              <Button
                icon={<HistoryOutlined />}
                onClick={() => handleHistorialClick(driver)}
                type="default"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "0 8px",
                }}
              />
            </Tooltip>
          </Space>
        ),
        width: 200, // Ajustamos el ancho para acomodar el nuevo botón
      },
    ],
    [
      tiposDocumento,
      handleEditClick,
      handleViewClick,
      handleOpenDeleteDialog,
      handleHistorialClick,
    ]
  );

  // Estilos para encabezados fijos usando CSS-in-JS
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 1,
    boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)",
  };

  return (
    <div className="drivers-container">
      {/* Barra de herramientas superior */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            <Search
              placeholder="Buscar conductor"
              enterButton={<SearchOutlined />}
              value={filter}
              onChange={handleSearchChange}
              style={{ width: 500 }}
              allowClear
            />
            <Select
              placeholder="Filtrar por Zona"
              allowClear
              style={{ width: 200 }}
              onChange={(value) => setSelectedZona(value)}
              loading={loadingZonas}
              value={selectedZona}
            >
              {zonas.map((zona) => (
                <Option key={zona.id} value={zona.nombre}>
                  {zona.nombre}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDialogOpen(true)}
            >
              Agregar Conductor
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Contenedor de la tabla con desplazamiento personalizado usando Ant Design */}
      <Spin spinning={listLoading} tip="Cargando conductores...">
        <Table
          columns={columns.map((col) => ({
            ...col,
            onHeaderCell: () => ({
              style: tableHeaderStyle,
            }),
          }))}
          dataSource={paginatedDrivers}
          pagination={false}
          rowKey="id"
          bordered
          scroll={{ y: 600 }} // Establece la altura del cuerpo de la tabla
        />
        {/* Paginación siempre visible */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
        >
          <Pagination
            current={page}
            pageSize={rowsPerPage}
            total={filteredDrivers.length}
            onChange={handleChangePage}
            showSizeChanger
            onShowSizeChange={handleRowsPerPageChange}
            pageSizeOptions={["5", "10", "25", "50", "100"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} de ${total} conductores`
            }
          />
        </div>
      </Spin>

      {/* Formularios y Diálogos */}

      {/* Agregar Conductor */}
      {addDialogOpen && (
        <AddDriverForm
          open={addDialogOpen}
          handleClose={() => setAddDialogOpen(false)}
          fetchDrivers={fetchDrivers}
        />
      )}

      {/* Editar Conductor */}
      {editDialogOpen && selectedDriver && (
        <AddDriverForm
          open={editDialogOpen}
          handleClose={() => setEditDialogOpen(false)}
          fetchDrivers={fetchDrivers}
          initialData={selectedDriver}
          isEdit={true}
        />
      )}

      {/* Ver Conductor */}
      {viewDialogOpen && selectedDriver && (
        <ViewDriverDialog
          driver={selectedDriver}
          open={viewDialogOpen}
          handleClose={() => setViewDialogOpen(false)}
        />
      )}

      {/* Confirmación de Eliminación */}
      <Modal
        title="Confirmar Eliminación"
        open={deleteDialogOpen}
        onOk={confirmDeleteDriver}
        onCancel={cancelDeleteDriver}
        okText="Eliminar"
        cancelText="Cancelar"
        okButtonProps={{ danger: true }}
      >
        <Text>
          ¿Estás seguro de que deseas eliminar al conductor{" "}
          <strong>
            {drivers.find((d) => d.id === driverToDelete)?.nombre || ""}
          </strong>{" "}
          ?
        </Text>
      </Modal>

      {/* Diálogo del Historial del Conductor */}
      {historialDialogOpen && (
        <HistorialConductorDialog
          open={historialDialogOpen}
          onClose={() => setHistorialDialogOpen(false)}
          conductorId={selectedConductorId}
        />
      )}
    </div>
  );
};

export default Drivers;
