// src/components/Clients/Form/CountrySelect.js

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import countryList from "react-select-country-list";

const { Option } = Select;

/**
 * Componente reutilizable para seleccionar un país.
 * Utiliza react-select-country-list para obtener la lista de países.
 */
const CountrySelect = ({
  value,
  onChange,
  placeholder = "Selecciona un país",
  disabled = false,
}) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <Select
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      value={value}
      disabled={disabled}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      style={{ width: "100%" }}
    >
      {options.map((country) => (
        <Option key={country.value} value={country.label}>
          {country.label}
        </Option>
      ))}
    </Select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CountrySelect;
