import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingIndicator = () => (
  <Box mt={2} display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
);

export default LoadingIndicator;
